import React from 'react'
import { StatsCardContainer, StatsValue, StatsLabel } from '../../styles'
import { Icon } from 'semantic-ui-react'

function nFormatter(num, digits) {
    var si = [
      { value: 1, symbol: "" },
      { value: 1E3, symbol: "k" },
      { value: 1E6, symbol: "M" },
      { value: 1E9, symbol: "G" },
      { value: 1E12, symbol: "T" },
      { value: 1E15, symbol: "P" },
      { value: 1E18, symbol: "E" }
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
  }

const StatsCard = ({type, value, diff}) => {
    if(type === 'total-applications') {
        return (
            <StatsCardContainer type={type}>
            <div className="row">
                <div className="col-sm-7">
                    <StatsLabel>Počet přihlášek</StatsLabel>
                    <StatsValue>{value}</StatsValue>
                    <StatsLabel>Celkem</StatsLabel>
                </div>
                <div className='col-sm-5 stats-icon-container'>
                    <Icon name='list alternate outline' />
                </div>
            </div>
            </StatsCardContainer>
        )
    } else if(type === 'total-kids') {
        return (
            <StatsCardContainer type={type}>
            <div className="row">
                <div className="col-sm-7">
                    <StatsLabel>Počet dětí</StatsLabel>
                    <StatsValue>{value.total_kids} / <span className='small'>{value.total_capacity}</span></StatsValue>
                    <StatsLabel>Poměr: {value.percentage} %</StatsLabel>
                </div>
                <div className='col-sm-5 stats-icon-container'>
                    <Icon name='users' />
                </div>
            </div>
            </StatsCardContainer>
        )
    } else if(type === 'today-applications') {
        return (
            <StatsCardContainer type={type}>
            <div className="row">
                <div className="col-sm-7">
                    <StatsLabel>Dnes přihlášek</StatsLabel>
                    <StatsValue>{value}</StatsValue>
                    <StatsLabel>Rozdíl: {diff} %</StatsLabel>
                </div>
                <div className='col-sm-5 stats-icon-container'>
                    <Icon name='calendar alternate outline' />
                </div>
            </div>
            </StatsCardContainer>
        )
    } else if(type === 'today-kids') {
        return (
            <StatsCardContainer type={type}>
            <div className="row">
                <div className="col-sm-7">
                    <StatsLabel>Dnes dětí</StatsLabel>
                    <StatsValue>{value}</StatsValue>
                    <StatsLabel>Rozdíl: {diff} %</StatsLabel>
                </div>
                <div className='col-sm-5 stats-icon-container'>
                    <Icon name='chart line' />
                </div>
            </div>
            </StatsCardContainer>
        )
    } else if(type === 'paid-unpaid') {
        return (
            <StatsCardContainer type={type}>
            <div className="row">
                <div className="col-sm-7">
                    <StatsLabel>Zaplacené přihlášky</StatsLabel>
                    <StatsValue>{value.paid} / <span className='small'>{value.sum}</span></StatsValue>
                    <StatsLabel>Zaplaceno: {value.percentage} %</StatsLabel>
                </div>
                <div className='col-sm-5 stats-icon-container'>
                    <Icon name="money bill alternate outline" />
                </div>
            </div>
            </StatsCardContainer>
        )
    } if(type === 'number-paid') {
        return (
            <StatsCardContainer type={type}>
            <div className="row">
                <div className="col-sm-7">
                    <StatsLabel>Celková částka</StatsLabel>
                    <StatsValue>{nFormatter(value.paid, 3)} / <span className='small'>{nFormatter(value.total, 3)}</span></StatsValue>
                    <StatsLabel>Průměr za dítě: {value.avg.toFixed(0)} Kč</StatsLabel>
                </div>
                <div className='col-sm-5 stats-icon-container'>
                    <Icon name="money bill alternate outline" />
                </div>
            </div>
            </StatsCardContainer>
        )
    } else {
        return (
            <StatsCardContainer type={type}>
                <div className="row">
                    <div className="col-sm-12">
                        <StatsLabel>Vytvořte typ statistiky</StatsLabel>
                    </div>
                </div>
            </StatsCardContainer>
        )
    }
}

export default StatsCard