import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { NavLink } from 'react-router-dom'
import { Images } from '../../../config';
import firebase from 'firebase'
import { Button } from 'semantic-ui-react'


const MobileSidebar = (props) => {
    const [isOpen, setIsOpen] = useState(false);
  
    const toggle = () => setIsOpen(!isOpen);

    const logout = () => {
        firebase.auth().signOut()
        window.location.reload();
    }
  
    return (
      <div>
        <Navbar color="light" light expand="md" className='mobile_navbar' style={{position: 'fixed', top: 0, left: 0, right: 0, zIndex: 999}}>
          <NavbarBrand href="/">
              <img src={Images.logo_c} style={{height: 25}} />
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <NavItem>
                <NavLink to={`${process.env.PUBLIC_URL}/klub/nastenka`} activeClassName='active'>Nástěnka</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to={`${process.env.PUBLIC_URL}/klub/nabidky`} activeClassName='active'>Speciální nabídky</NavLink>
              </NavItem>
              {window.location.origin !== 'https://klub.tabory-krouzky.cz' &&
              <>
              <NavItem>
                <NavLink to={`${process.env.PUBLIC_URL}/klub/rezervace`} activeClassName='active'>Moje rezervace</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to={`${process.env.PUBLIC_URL}/klub/lekce/list`} activeClassName='active'>Moje lekce</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to={`${process.env.PUBLIC_URL}/klub/permanentky`} activeClassName='active'>Moje permanentky</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to={`${process.env.PUBLIC_URL}/klub/lekce/watchlist`} activeClassName='active'>Hlídané lekce</NavLink>
              </NavItem>

              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Rozvrh lekcí
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>
                    <NavLink to={`${process.env.PUBLIC_URL}/klub/rozvrh/kids`} activeClassName='active'>Cvičení pro děti</NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink to={`${process.env.PUBLIC_URL}/klub/rozvrh/moms`} activeClassName='active'>Cvičení pro maminky</NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink to={`${process.env.PUBLIC_URL}/klub/rozvrh/hobby`} activeClassName='active'>Kroužky</NavLink>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              </>
              }
              <NavItem>
                <NavLink to={`${process.env.PUBLIC_URL}/klub/muj-ucet`} activeClassName='active'>Můj účet</NavLink>
              </NavItem>
              <NavItem>
                <Button fluid icon='\ef7c' outline onClick={logout}>Odhlásit se</Button>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
  
  export default MobileSidebar;