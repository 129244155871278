import React, { Component } from 'react'
import { Card, CardHeader, CardBody } from '../../../styles'
import Schedule from '../../containers/workouts/Schedule'
import { getCalendar, getCalendarItem } from '../../../api/workouts';
import moment from 'moment'
import { Row, Col } from 'reactstrap'
import { Dropdown } from 'semantic-ui-react'
import _ from 'loadsh'
import queryString from 'query-string'
import { getLocationsValues } from '../../../api';

const viewOpts = [
    {
        key: 0,
        value: 'moms',
        text: 'Cvičení pro maminky'
    },
    {
        key: 1,
        value: 'kids',
        text: 'Cvičení s dětmi'
    },
    {
        key: 2,
        value: 'hobby',
        text: 'Kroužky'
    }
]

const isFullOpts = [
    {
        key: 0,
        value: null,
        text: 'Vše'
    },
    {
        key: 1,
        value: false,
        text: 'Volno'
    },
    {
        key: 2,
        value: true,
        text: 'Plno'
    }
]

const ageOpts = [
    {
        key: 0,
        value: null,
        text: 'Vše'
    },
    {
        key: 1,
        value: 6,
        text: '4 - 6 měsíců'
    },
    {
        key: 2,
        value: 7,
        text: '7 - 10 měsíců'
    },
    {
        key: 3,
        value: 8,
        text: '11 - 17 měsíců'
    }
    ,
    {
        key: 4,
        value: 9,
        text: '1,5 - 2,5 roky'
    }
]

const hobbyAgeOpts = [
    {
        key: 0,
        value: 0,
        text: '6-9 let'
    },
    {
        key: 1,
        value: 1,
        text: '9-15 let'
    }
]

class EmbedSchedule extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            items: [],
            baseData: [],
            loadingData: [],
            header: 'Rorzvh lekcí',
            filter: false,
            filters: [],
            loadingLocs: true
        })
    }

    componentDidMount() {

        const { type } = this.props.match.params
        document.body.classList.add('embed')
        this._getLocOpts()

        this.setCardHeader(type)

    }

    setCardHeader = (type) => {
        if(type === 'moms') {
            this.setState({
                header: 'Rozvrh cvičení pro maminky'
            })
        } else if(type === 'kids') {
            this.setState({
                header: 'Rozvrh cvičení s dětmi'
            })
        } else if(type === 'hobby') {
            this.setState({
                header: 'Rozvrh kroužků'
            })
        }
    }

    _getLocOpts = () => {
        getLocationsValues()
        .then((res) => {
            this.setState({
                locOpts: res,
                loadingLocs: false
            })
        })
    }

    _getCalendarItems = (date) => {
        const { type } = this.props.match.params
        const formatDate = moment(date).format('YYYY-MM-DD')

        getCalendar(formatDate, type)
        .then((res) => {
            if(res.length !== 0) {
                if(_.keys(this.state.filters).length !== 0) {

                    const newEvents = []
                    res.map((item) => {
                        const newItem = {
                            title: item.title,
                            start: moment(item.start).toDate(),
                            end: moment(item.end).toDate(),
                            id: item.id,
                            color: item.color,
                            is_full: item.is_full,
                            age_group: type === 'kids' ? item.age_group : null,
                            description: item.description,
                            id_location: item.id_location
                        }
                        newEvents.push(newItem)
                        return ''
                    })

                    this.setState({
                        items: _.filter(newEvents, this.state.filters),
                        baseData: newEvents,
                        hasSome: true
                    })

                } else {

                    const newEvents = []
                    res.map((item) => {
                        const newItem = {
                            title: item.title,
                            start: moment(item.start).toDate(),
                            end: moment(item.end).toDate(),
                            id: item.id,
                            color: item.color,
                            is_full: item.is_full,
                            age_group: type === 'kids' ? item.age_group : null,
                            description: item.description,
                            id_location: item.id_location
                        }
                        newEvents.push(newItem)
                    })

                    this.setState({
                        items: newEvents,
                        baseData: newEvents,
                        hasSome: true
                    })
                }
            } else {
                this.setState({
                    hasSome: false
                })
            }
        })
    }

    _getCalendarItem = (id) => {
        const { type } = this.props.match.params

        getCalendarItem(id, type)
        .then((res) => {
            if(res.code === 1) {
                this.setState({
                    selectedItem: res.data,
                    itemExist: true
                })
            } else {
                this.setState({
                    selectedItem: {},
                    itemExist: false
                })
            }
        })
    }

    onChangeSelect = (e, data) => {
        this.props.history.push(`${process.env.PUBLIC_URL}/embed/rozvrh/${data.value}`)
    }

    filterByQuery = (e, data) => {
        if(data.value !== null) {
            this.setState({
                filter: true,
                filters: {
                    ...this.state.filters,
                    [data.name]: data.value
                }
            }, () => {
                this.setState({
                    items: _.filter(this.state.baseData, this.state.filters)
                })
            })
        } else {
            this.setState({
                filters: _.omit(this.state.filters, [data.name])
            }, () => {
                this.setState({
                    items: _.keys(this.state.filters).length !== 0 ? _.filter(this.state.baseData, this.state.filters) : this.state.baseData,
                    filter: _.keys(this.state.filters).length !== 0 ? true : false
                })
            })
        }
    }

    render() {

        const { items, header, locOpts } = this.state
        const { type } = this.props.match.params
        const parsed = queryString.parse(this.props.location.search);

        return (
            <Card>
                <CardHeader>
                    <Row>
                        <Col sm={4}>
                            {header}
                        </Col>
                        <Col sm={2} className='text-right'>
                            <Dropdown 
                                options={locOpts}
                                onChange={this.filterByQuery}
                                name="id_location"
                                placeholder="Lokalita"
                                selection
                                fluid
                            />
                        </Col>
                        <Col sm={type === 'kids' ? 2 : 3} className='text-right'>
                            <Dropdown 
                                options={viewOpts}
                                value={type}
                                onChange={this.onChangeSelect}
                                selection
                                fluid
                            />
                        </Col>
                        <Col sm={type === 'kids' ? 2 : 3} className='text-right'>
                            <Dropdown 
                                options={isFullOpts}
                                name='is_full'
                                onChange={this.filterByQuery}
                                selection
                                placeholder='Volno / plno'
                                fluid
                            />
                        </Col>
                        {type === 'kids' &&
                        <Col sm={2} className='text-right'>
                            <Dropdown 
                                options={ageOpts}
                                name='age_group'
                                onChange={this.filterByQuery}
                                selection
                                placeholder='Věk'
                                fluid
                            />
                        </Col>
                        }
                        {type === 'hobby' &&
                        <Col sm={2} className='text-right'>
                            <Dropdown 
                                options={hobbyAgeOpts}
                                name='age_group'
                                onChange={this.filterByQuery}
                                selection
                                placeholder='Věk'
                                fluid
                            />
                        </Col>
                        }
                    </Row>
                </CardHeader>
                <CardBody>
                    <Schedule dates={items} getData={this._getCalendarItems} getItem={this._getCalendarItem} selectedItem={this.state.selectedItem} type={type} />
                </CardBody>
            </Card>
        )
    }

}

export default EmbedSchedule