import React, { Component } from 'react'
import { sendEmail, getLecturesFilter } from '../../../api/workouts'
import { setPaid, getApplication, updatePrepaidApplication } from '../../../api/prepaids'
import { getToken } from '../../../api'
import Loading from '../../containers/Loading'
import NoReservations from '../../containers/club/NoReservations'
import { Row, Col } from 'reactstrap'
import { Card, CardControls, CardBody, CardHeader, DateItem, EmailItem, FormButtonGroup } from '../../../styles'
import { Dropdown, Modal, Form, Input, Button, Icon, Label } from 'semantic-ui-react'
import moment from 'moment'
import { shortText, formatMoney } from '../../../api/'
import PriceOptions from './PriceOptions'
import { connect } from 'react-redux'
import { setMessage } from '../../../actions/messageActions'
import ApplicationUserForm from '../../forms/mom-workouts/ApplicationUserForm'
import ApplicationDatesTable from '../../tables/workouts/ApplicationDatesTable'
import PrepaidDetailForm from '../../forms/prepaids/PrepaidDetailForm'

class PrepaidOrderDetailView extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            item: {},
            loadingData: true,
            loadingPayment: false,
            user: {
                first_name: '',
                last_name: '',
                email: '',
                address: '',
                post_code: '',
                city: ''
            }
        })
    }

    componentDidMount() {
        this._getApplication()
        this._lecturesFilter()
    }

    onChangeSelect = (e, data) => {
        this.setPaidHandler(data.value, this.state.item.id_prepaids_user)
    }

    onChangeSelectWorkout = (e, data) => {        
        this.setState({
            item: {
                ...this.state.item,
                [data.name]: data.value
            }
        })
    }

    onChange = (e) => this.setState({
        item: {
            ...this.state.item,
            [e.target.name]: e.target.value
        }
    })

    onChangePayment = (e) => this.setState({
        newPayment: {
            ...this.state.newPayment,
            [e.target.name]: e.target.value
        }
    })

    setPaidHandler = (val, id) => {

        this.setState({
            loadingPayment: true
        })

        getToken().then((token) => {
            const data = {
                token: token,
                val: val,
                id_application: id
            }

            setPaid(data)
            .then((res) => {
                this.props.setMessage(res)
                this._getApplication()

                this.setState({
                    loadingPayment: false
                })
            })
        })
    }

    updateItem = () => {

        const { item } = this.state
        const { id } = this.props.match.params

        this.setState({
            loadingData: true
        })

        getToken().then((token) => {
            const data = {
                token: token,
                id_workout: item.id_workout,
                num_of_lectures: item.num_of_lectures,
                active: item.active,
                id_application: id
            }

            updatePrepaidApplication(data)
            .then((res) => {
                this.props.setMessage(res)
                this._getApplication()
                this.setState({
                    loadingData: false
                })
            })
        })
    }

    _getApplication = () => {

        const { id } = this.props.match.params

        this.setState({
            loadingData: true,
            hasData: false
        })

        getToken().then((token) => {
            const data = {
                token: token,
                id_application: id
            }

            getApplication(data)
            .then((res) => {
                
                if(res.code === 1) {
                    this.setState({
                        loadingData: false,
                        hasData: true,
                        item: res.data,
                        user: {
                            first_name: res.data.first_name,
                            last_name: res.data.last_name,
                            email: res.data.email,
                            address: res.data.address,
                            post_code: res.data.post_code,
                            city: res.data.city,
                            phone: res.data.phone
                        }
                    })
                }
                
            })
        })
    }

    _sendEmail = (paid, id) => {
        const type = 'prepaid'
        getToken().then((token) => {
            const data = {
                token: token,
                emails: [
                    id
                ]
            }

            if(paid === 0) {
                sendEmail(data, 'reminder', type)
                .then((res) => {
                    this.props.setMessage(res)
                })
            } else if(paid === 1) {
                sendEmail(data, 'confirm', type)
                .then((res) => {
                    this.props.setMessage(res)
                })
            }
        })
    }

    _lecturesFilter = () => {

        var group = null;
        var type = 'moms';

        getLecturesFilter(type, group)
        .then((res) => {
            this.setState({
                lecturesFilter: res,
                loadingLecturesFilter: false
            })
        })
    }

    content = () => {

        const { type } = this.props.match.params
        const { setMessage } = this.props

        return (
            <Row>
                <Col sm={8}>
                    <Card className='mb-5'>
                        <CardHeader>
                            Detail permanentky
                        </CardHeader>
                        <CardBody>
                            <PrepaidDetailForm item={this.state.item} onChange={this.onChange} lecturesFilter={this.state.lecturesFilter} onChangeSelect={this.onChangeSelectWorkout} submit={this.updateItem} loading={this.loadingData} />
                        </CardBody>
                    </Card>
                    <Card className='mb-5'>
                        <CardHeader>
                            Uživatel
                        </CardHeader>
                        <CardBody>
                            <ApplicationUserForm user={this.state.user} />
                        </CardBody>
                    </Card>
                </Col>
                <Col sm={4}>
                    <Card>
                        <CardHeader>
                            Platba
                        </CardHeader>
                        <CardBody>
                            <PriceOptions 
                                item={this.state.item}
                                onChangeSelect={this.onChangeSelect}
                                loadingPayment={this.state.loadingPayment}
                                handleSendPaymentEmail={this._sendEmail}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }

    render() {
        const { loadingData, hasData } = this.state

        if(loadingData) {
            return (
                <Loading />
            )
        } else if(!loadingData && !hasData) {
            return (
                <NoReservations text='Tato přihláška neexistuje' />
            )  
        } else {
            return (
                <>
                    <this.content />
                </>
            )
        }
    }

}

const mapStateToProps = state => ({
    user: state.user.user,
    loadingUser: state.user.loading
})
  
export default connect(mapStateToProps, { setMessage })(PrepaidOrderDetailView)