import React from 'react'
import Widget from './Widget'


const WidgetsContainer = ({ items, changeLecture, deleteModal }) => {
    return (
        items.map((item) => {
            return (
                <Widget item={item} changeLecture={changeLecture} deleteModal={deleteModal} />
            )
        })
    )
}

export default WidgetsContainer