const firebaseConfigData = {
    apiKey: "AIzaSyD7TW_X46XPPJk_UUal8o0nyRFoyTqcCQA",
    authDomain: "kidsjoy-79679.firebaseapp.com",
    databaseURL: "https://kidsjoy-79679.firebaseio.com",
    projectId: "kidsjoy-79679",
    storageBucket: "kidsjoy-79679.appspot.com",
    messagingSenderId: "657909974458",
    appId: "1:657909974458:web:c034f7e8602b739ef4c983",
    measurementId: "G-KWGDT1BBE7"
};

export default firebaseConfigData