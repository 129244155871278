import React, { Component } from 'react'
import { Card, CardControls, CardBody } from '../../styles'
import { EditUserForm } from '../forms'
import { getUsersOrganization, logUser, getToken, getUser } from '../../api'
import { setMessage } from '../../actions/messageActions'
import { connect } from 'react-redux'
import firebase from 'firebase'

class EditUserView extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            organizationsValues: [],
            user: {
                user_first_name: '',
                user_last_name: '',
                user_email: '',
                user_admin: 0,
                user_organization: '',
                user_passwd: '',
                user_passwd_check: '',
                is_lecturer: 0
            },
            errors: {
                user_first_name: false,
                user_last_name: false,
                user_email: false,
                user_admin: false,
                user_organization: false,
                user_passwd: false,
                user_passwd_check: false
            },
            isNew: false,
            loading: true
        })
    }

    componentDidMount() {

        const { id } = this.props.match.params

        getUsersOrganization()
        .then((data) => this.setState({ organizationsValues: data }))

        this.refresh()

        id === 'new' && this.setState({ isNew: true, loading: false })
    }

    refresh = () => {
        const { id } = this.props.match.params

        if(id !== 'new') {
            getUser(id)
            .then((data) => {
                this.setState({
                    user: data
                })
            })
            .then(() => {
                this.setState({
                    loading: false
                })
            })
        } else {
            this.setState({
                loading: false
            })
        }
    }

    onChange = (e) => {
        this.setState({
            user: {
                ...this.state.user,
                [e.target.name]: e.target.value
            },
            errors: {
                ...this.state.errors,
                [e.target.name]: false
            }
        })
    }

    onChangeSelect = (e, data) => this.setState({
        user: {
            ...this.state.user,
            [data.name]: data.value
        },
        errors: {
            ...this.state.errors,
            [data.name]: false
        }
    })

    submit = () => {

        const { setMessage } = this.props
        const { user } = this.state

        if(user.user_email !== '') {

            if(user.user_passwd !== '' || user.user_passwd_check !== '') {

                if(user.user_passwd === user.user_passwd_check) {

                    if(user.user_first_name !== '') {

                        if(user.user_last_name !== '') {

                            if(user.user_organization !== '') {
                                getToken().then((idToken) => {
                                    this.setState({
                                        user: {
                                            ...this.state.user,
                                            token: idToken
                                        }
                                    }, () => {
                                        logUser(this.state.user)
                                        .then((res) => {
                                            setMessage(res)
                                        })
                                    })
                                })
                            } else {
                                setMessage({type: 'error', text: 'Není vybrána organizace'})
                                this.setState({ errors: { ...this.state.errors, user_organization: true } })
                            }

                        } else {
                            setMessage({type: 'error', text: 'Příjmení uživatele není vyplněné'})
                            this.setState({ errors: { ...this.state.errors, user_last_name: true } })
                        }

                    } else {
                        setMessage({type: 'error', text: 'Jméno uživatele není vyplněné'})
                        this.setState({ errors: { ...this.state.errors, user_first_name: true } })
                    }

                } else {
                    setMessage({type: 'error', text: 'Hesla se neshodují'})
                    this.setState({ errors: { ...this.state.errors, user_passwd_check: true } })
                }

            } else {
                setMessage({type: 'error', text: 'Heslo není vyplněné'})
                this.setState({ errors: { ...this.state.errors, user_passwd: true } })
            } 

        } else {
            setMessage({type: 'error', text: 'Email uživatele není vyplněný'})
            this.setState({ errors: { ...this.state.errors, user_email: true } })
        }
    }

    render() {
        return (
            <div className='row'>
                <div className='col-sm-8'>
                    <Card>
                        <CardControls>
                            <div className='row'>
                                <div className='col-sm-6 text-left'>
                                    <h2>Základní informace</h2>
                                </div>
                            </div>
                        </CardControls>
                        <CardBody>
                            <EditUserForm 
                                loading={this.state.loading} 
                                isNew={this.state.isNew} 
                                user={this.state.user} 
                                errors={this.state.errors} 
                                onChange={this.onChange} 
                                onChangeSelect={this.onChangeSelect} 
                                values={this.state.organizationsValues} 
                                submit={this.submit} 
                            />
                        </CardBody>
                    </Card>
                </div>
            </div>
        )
    }

}

const mapStateToProps = state => ({})
  
export default connect(mapStateToProps, { setMessage })(EditUserView)