import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setMessage } from '../../actions/messageActions'
import { Card, CardControls, CardBody } from '../../styles'
import EditCampForm from '../forms/EditCampForm'
import { getCamp, getUsersOrganization, createCamp, getToken, updateCamp, uploadCampImage, updateCampSecondary } from '../../api'
import { CampSecondaryForm } from '../forms'

class EditCampView extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            loading: true,
            camp: {
                camp_name: '',
                id_organization: '',
                camp_description: '',
                camp_logo: '',
                camp_state: 0
            },
            organizationsValues: [],
            toUpload: []
        })
    }

    componentDidMount() {

        getUsersOrganization()
        .then((data) => this.setState({ organizationsValues: data }))

        if(!this.props.loadingUser) {
            this.refresh()
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.loadingUser !== this.props.loadingUser) {
            this.refresh()
        }
    }

    refresh = () => {

        const { id } = this.props.match.params

        if(id !== 'new') {

            const { user } = this.props

            getToken().then((token) => {
                const data = {
                    token: token,
                    id_item: id,
                    id_org: user.id_organization
                }
    
                getCamp(data)
                .then((res) => {
                    if(res.code === 1) {
                        this.setState({
                            camp: res.data,
                            loading: false
                        })
                    } else {
                        console.log(res.text)
                    }
                })
            })
            
        } else {
            this.setState({
                loading: false
            })
        }
    }

    onChange = (e) => this.setState({
        camp: {
            ...this.state.camp,
            [e.target.name]: e.target.value
        }
    })

    onChangeSelect = (e, data) => this.setState({
        camp: {
            ...this.state.camp,
            [data.name]: data.value
        }
    })

    submit = () => {
        const { id } = this.props.match.params

        if(id !== 'new') {

            getToken().then((idToken) => {
                this.setState({
                    camp: {
                        ...this.state.camp,
                        token: idToken
                    }
                }, () => {
                    updateCamp(id, this.state.camp)
                    .then((res) => {
                        this.props.setMessage(res)
                        this.setState({
                            loading: true
                        }, () => {
                            this.refresh()
                        })
                    })
                })
            })
            
        } else {

            this.setState({
                loading: true
            })

            getToken().then((idToken) => {
                this.setState({
                    camp: {
                        ...this.state.camp,
                        token: idToken
                    }
                }, () => {
                    createCamp(this.state.camp)
                    .then((res) => {
                        this.setState({
                            loading: false
                        })
                        this.props.setMessage(res)
                    })
                    .then(() => {
                        this.props.history.push('/admin/manage/camps')
                    })
                    .catch((err) => {
                        this.props.setMessage(err)
                    })
                })
            })

        }
    }

    onDrop = (files) => {
        this.setState({
            toUpload: files
        })
    }

    submitSecondary = () => {

        const { id } = this.props.match.params

        getToken().then((idToken) => {
            this.setState({
                camp: {
                    ...this.state.camp,
                    token: idToken
                }
            }, () => {

                if(this.state.toUpload.length !== 0) {
                    this.uploadImage(this.state.toUpload)
                }

                updateCampSecondary(id, this.state.camp)
                .then((res) => {
                    this.props.setMessage(res)
                    this.setState({
                        loading: true
                    }, () => {
                        this.refresh()
                    })
                })
            })
        })
    }

    uploadImage = (image) => {

        const { id } = this.props.match.params

        this.setState({
			loading: true,
			uploadState: {
				beforeUpload: false,
				isUploading: true,
				uploaded: false
			}
        })
        
        const fd = new FormData();

        image.forEach(file => {
            fd.append('image', file, file.name);
        })
        
        uploadCampImage(id, fd)
        .then((res) => {
            this.setState({
                loading: false,
                toUpload: []
            }, () => {
                this.refresh()
            })
        })
        .catch((err) => {
            this.props.setMessage(err)
            this.setState({
                loading: false
            })
        })
    }

    render() {

        const { id } = this.props.match.params

        return (
            <div className='row'>
                <div className='col-sm-8'>
                    <Card>
                        <CardControls>
                            <div className='row'>
                                <div className='col-sm-6 text-left'>
                                    <h2>Základní informace</h2>
                                </div>
                            </div>
                        </CardControls>
                        <CardBody>
                            <EditCampForm 
                                loading={this.state.loading}
                                camp={this.state.camp}
                                onChange={this.onChange}
                                onChangeSelect={this.onChangeSelect}
                                values={this.state.organizationsValues}
                                submit={this.submit}
                            />
                        </CardBody>
                    </Card>
                </div>
                <div className='col-sm-4'>
                    <Card>
                        <CardControls>
                            <div className='row'>
                                <div className='col-sm-12 text-left'>
                                    <h2>Další informace</h2>
                                </div>
                            </div>
                        </CardControls>
                        <CardBody>
                            <CampSecondaryForm 
                                camp={this.state.camp}
                                loading={this.state.loading}
                                isNew={id === 'new' ? true : false}
                                onDrop={this.onDrop}
                                onChangeSelect={this.onChangeSelect}
                                submit={this.submitSecondary}
                            />
                        </CardBody>
                    </Card>
                </div>
            </div>
        )
    }

}

const mapStateToProps = state => ({
    user: state.user.user,
    loadingUser: state.user.loading
})
  
export default connect(mapStateToProps, { setMessage })(EditCampView)