import styled from 'styled-components'

export const ListItem = styled.div`
    width: 100%;
    padding: 1.5rem;
    border-radius: 3px;
    border: 1px solid #ececec;
    margin-bottom: 3rem;

    p {
        margin-bottom: 0;
    }

    @media (max-width: 767px) {
        div[class*="col-sm-"] {
            margin-bottom: 1.5rem;
        }
    }

    h2 {
        font-size: 16px;
    }
`