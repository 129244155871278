import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { NavLink } from 'react-router-dom'
import { Images } from '../../../config';
import firebase from 'firebase'
import { Button } from 'semantic-ui-react'


const SidebarLecturersMobile = (props) => {
    const [isOpen, setIsOpen] = useState(false);
  
    const toggle = () => setIsOpen(!isOpen);

    const logout = () => {
        firebase.auth().signOut()
        window.location.reload();
    }
  
    return (
      <div>
        <Navbar color="light" light expand="md" className='mobile_navbar' style={{position: 'fixed', top: 0, left: 0, right: 0, zIndex: 999}}>
          <NavbarBrand href="/">
              <img src={Images.logo_c} style={{height: 25}} alt="" />
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Cvičení pro maminky
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>
                    <NavLink to={`${process.env.PUBLIC_URL}/admin/manage/workouts/moms/list`} activeClassName='active'>Seznam lekcí</NavLink>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Cvičení pro děti
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>
                    <NavLink to={`${process.env.PUBLIC_URL}/admin/manage/workouts/kids/list`} activeClassName='active'>Seznam lekcí</NavLink>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Kroužky
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>
                    <NavLink to={`${process.env.PUBLIC_URL}/admin/manage/workouts/hobby/list`} activeClassName='active'>Seznam lekcí</NavLink>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <Button fluid icon='\ef7c' outline onClick={logout}>Odhlásit se</Button>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
  
  export default SidebarLecturersMobile;