import React, { Component } from 'react'
import { intToBool, toggleLocationState, shortText, getToken, formatMoney } from '../../../api'
import { disableWorkoutApplication, sendEmail, enableWorkoutApplication, togglePriority } from '../../../api/workouts'
import { Table, Icon, Button, Checkbox, Modal, Popup } from 'semantic-ui-react'
import _ from 'loadsh'
import moment from 'moment'
import { StateIcon, MultipleActions } from '../../../styles'
import { Link } from 'react-router-dom'
import { Row, Col } from 'reactstrap'

class WorkoutsApplicationsTable extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            selectedIds: [],
            loading: {
                reminder: false,
                confirm: false,
                disable: false
            },
        })
    }

    clearSelected = () => this.setState({ selectedIds: [] })

    _renderMultipleSelectActions = () => {
        return (
            <MultipleActions>
                <div className='row'>
                    <div className='col-sm-1'>
                        <p>Vybráno: {this.state.selectedIds.length}</p>
                    </div>
                    <div className='col-sm-9'>
                        <Button color='red' onClick={() => this.multipleRemindersHandler('reminder')} loading={this.state.loading.reminder}>Odeslat upomínku</Button>
                        <Button color='green' onClick={() => this.multipleRemindersHandler('confirm')} loading={this.state.loading.confirm}>Odeslat potvrzení</Button>
                        <Button className='ghost' onClick={() => this.multipleRemindersHandler('disable')} loading={this.state.loading.disable}>Stornovat přihlášky</Button>
                    </div>
                    <div className='col-sm-2 text-right'>
                        <Button onClick={this.clearSelected} className='ghost'>Vyprázdnit</Button>
                    </div>
                </div>
            </MultipleActions>
        )
    }

    handleMultipleSelect = (event, data, id) => {

        if(data.checked) {
            this.setState({
                selectedIds: [
                    ...this.state.selectedIds,
                    id
                ]
            })
        } else {
            this.setState({
                selectedIds: this.state.selectedIds.filter((i) => i !== id)
            })
        }
        
    }

    _sendEmail = (actionType) => {

        const { type, setMessage } = this.props

        getToken().then((token) => {
            const data = {
                token: token,
                emails: this.state.selectedIds
            }

            sendEmail(data, actionType, type)
            .then((res) => {
                setMessage(res)
                this.setState({
                    loading: {
                        ...this.state.loading,
                        [actionType]: false
                    }
                })
            })
        })

    }

    multipleRemindersHandler = (type) => {

        this.setState({
            loading: {
                ...this.state.loading,
                [type]: true
            }
        })

        getToken().then((token) => {
            const data = {
                token: token,
                ids: this.state.selectedIds
            }

            if(type === 'reminder') {
                this._sendEmail(type)
            } else if(type === 'confirm') {
                this._sendEmail(type)
            } else if(type === 'disable') {
                this._disableApplications(this.props.type)
            }

        })
    }

    _disableApplicationModal = (type, id_application) => {
        return (
            <Modal trigger={<Button icon><Icon name='trash' /></Button>}>
                <Modal.Header>
                    Stornovat přihlášku #{id_application}?
                </Modal.Header>
                <Modal.Content>
                    Stornováním přihlášky uvolníte kapacitu ve všech zapsaných lekcích. Přihlášku můžete vrátit zpět do aktivních.
                </Modal.Content>
                <Modal.Actions className='d-flex justify-content-end'>
                    <Button className='red' onClick={() => this._disableApplication(type, id_application)}>Stornovat přihlášku</Button>
                </Modal.Actions>
            </Modal>
        )
    }

    _enableApplicationModal = (type, id_application) => {
        return (
            <Modal trigger={<Button icon><Icon name='check' /></Button>}>
                <Modal.Header>
                    Obnovit přihlášku #{id_application}?
                </Modal.Header>
                <Modal.Content>
                    Obnovením přihlášky zaplníte kapacitu ve všech <strong>lekcích obsažených v přihlášce</strong>. Přihlášku můžete později znovu stornovat.
                </Modal.Content>
                <Modal.Actions className='d-flex justify-content-end'>
                    <Button primary onClick={() => this._enableApplication(type, id_application)}>Obnovit přihlášku</Button>
                </Modal.Actions>
            </Modal>
        )
    }

    _disableApplication = (type, id) => {
        getToken().then((token) => {
            const data = {
                token: token,
                ids: [
                    id
                ]
            }

            disableWorkoutApplication(data, type)
            .then((res) => {
                this.props.refresh()
                this.props.setMessage(res)
            })

        })
    }

    _enableApplication = (type, id) => {
        getToken().then((token) => {
            const data = {
                token: token,
                ids: [
                    id
                ]
            }

            enableWorkoutApplication(data, type)
            .then((res) => {
                this.props.refresh()
                this.props.setMessage(res)
            })

        })
    }

    _disableApplications = (type) => {
        getToken().then((token) => {
            const data = {
                token: token,
                ids: this.state.selectedIds
            }

            disableWorkoutApplication(data, type)
            .then((res) => {
                this.props.refresh()
                this.props.setMessage(res)
            })

        })
    }

    _renderLectures = (items) => {

        return (
            items.map((item) => {

                var setState = item.state === 1 ? 'Aktivní' : (item.state === 2 ? 'Omluveno' : 'Nahrazeno')

                return (
                    <Row className='py-3 border_bottom d-sm-flex align-items-center'>
                        <Col sm={7}>
                            <span className='application_dates'>{moment(item.date).format('DD. MM. YYYY (H:mm)')}</span>
                        </Col>
                        <Col sm={5}>
                            <p className={'text-center bubble state-' + item.state}>{setState}</p>
                        </Col>
                    </Row>
                )
            })
        )
    }

    _togglePriority = (id, type) => {
        getToken().then((token) => {
            const data = {
                token: token
            }

            togglePriority(data, id, type)
            .then((res) => {
                this.props.setMessage(res)
                this.props.refresh()
            })
            .catch((err) => {
                this.props.setMessage({type: 'error', text: 'Něco se pokazilo, zkuste to prosím později'})
            })
        })
    }

    render() {

        const { items, column, direction, sort, type, code } = this.props

        return (
            <div className='admin_table_wrapper'>
                <Table sortable striped className='main-table select_table' unstackable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={column === 'id_application' ? direction : null}
                                onClick={sort('id_application')}
                                width={1}
                            >
                                #
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'last_name' ? direction : null}
                                onClick={sort('last_name')}
                                width={3}
                            >
                                Uživatel
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'total_price' ? direction : null}
                                onClick={sort('total_price')}
                                width={1}
                            >
                                Částka
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'id_date' ? direction : null}
                                onClick={sort('id_date')}
                                width={5}
                                className='mw_420'
                            >
                                Lekce
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'created' ? direction : null}
                                onClick={sort('created')}
                                width={2}
                                className='mw_150'
                            >
                                Vytvořeno
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'paid' ? direction : null}
                                onClick={sort('paid')}
                                width={1}
                            >
                                Zaplaceno
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'state' ? direction : null}
                                onClick={sort('state')}
                                width={1}
                            >
                                Status
                            </Table.HeaderCell>

                            <Table.HeaderCell width={3} className='mw_200'></Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                    {_.map(items, ({ id_application, id_workout, first_name, last_name, email, total_price, paid, state, created, workout_name, location_name, is_free, date, priority }, index) => (
                        <Table.Row key={id_application}>
                            <Table.Cell>
                                <Checkbox checked={this.state.selectedIds.includes(id_application) ? true : false} onChange={(event, data) => this.handleMultipleSelect(event, data, id_application)} />
                            </Table.Cell>
                            <Table.Cell>
                                {code}{id_application}
                            </Table.Cell>
                            <Table.Cell>
                                <strong>{first_name + ' ' + last_name}</strong><br />{email}
                            </Table.Cell>
                           
                            <Table.Cell>
                                {date !== null && is_free === 1 ? <span className='bubble'>Zkušební</span> : formatMoney(total_price) + '\xa0Kč' }
                            </Table.Cell>

                            <Table.Cell>
                                <Row className='d-sm-flex align-items-center'>
                                    <Col sm={10} className='col-8'>
                                        <strong><Link to={'/admin/manage/workouts/'+ type +'/edit/' + id_workout}>{workout_name}</Link></strong><br />
                                        {location_name} | {date !== null && date.length > 0 && moment(date[0].date).format('ddd. (H:mm)')}
                                    </Col>
                                    <Col sm={2} className='col-4'>
                                        <Popup className='dates_popup' trigger={<Button icon><Icon name='bars' /></Button>} content={this._renderLectures(date)} on='click' />
                                    </Col>
                                </Row>
                            </Table.Cell>

                            <Table.Cell>
                                {moment(created).format('DD. MM. YYYY')}
                            </Table.Cell>

                            <Table.Cell className='text-center'>
                                <StateIcon state={paid} />
                            </Table.Cell>

                            <Table.Cell className='text-center'>
                                <StateIcon state={state} />
                            </Table.Cell>

                            <Table.Cell className='text-right'>
                                <div className={`priority priority-${priority}`} onClick={() => this._togglePriority(id_application, type)}></div>
                                {state === 1 ? this._disableApplicationModal(type, id_application) : this._enableApplicationModal(type, id_application)}
                                <Link to={'/admin/manage/workouts/'+ type +'/applications/detail/' + id_application}><Button icon><Icon name='edit' /></Button></Link>
                            </Table.Cell>

                        </Table.Row>
                    ))}
                    </Table.Body>
                </Table>
                {this.state.selectedIds.length !== 0 &&
                    <this._renderMultipleSelectActions />
                }
            </div>
        )
    }
}

export default WorkoutsApplicationsTable