import React, { Component } from 'react'
import { intToBool, toggleLocationState, shortText, getToken } from '../../../api'
import { Table, Icon, Button, Popup } from 'semantic-ui-react'
import _ from 'loadsh'
import moment from 'moment'
import { StateIcon } from '../../../styles'
import { Link } from 'react-router-dom'

class PrepaidsOrdersTable extends Component {

    constructor(props) {
        super(props)
        this.state = ({})
    }

    render() {

        const { items, column, direction, sort, type } = this.props

        return (
            <div className='admin_table_wrapper'>
                <Table sortable striped className='main-table select_table' unstackable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell
                                sorted={column === 'id_prepaid' ? direction : null}
                                onClick={sort('id_prepaid')}
                                width={1}
                            >
                                #
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'first_name' ? direction : null}
                                onClick={sort('first_name')}
                                width={3}
                            >
                                Jméno
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'email' ? direction : null}
                                onClick={sort('email')}
                                width={4}
                            >
                                Kontakt
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'price_per_lecture' ? direction : null}
                                onClick={sort('price_per_lecture')}
                                width={3}
                                className='mw_300'
                            >
                                Permanentka
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'num_of_lectures' ? direction : null}
                                onClick={sort('num_of_lectures')}
                                width={3}
                                className='mw_200'
                            >
                                Lekce
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'valid_months' ? direction : null}
                                onClick={sort('valid_months')}
                                width={2}
                            >
                                Zbývá lekcí
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'paid' ? direction : null}
                                onClick={sort('paid')}
                                width={1}
                            >
                                Zaplaceno
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'active' ? direction : null}
                                onClick={sort('active')}
                                width={1}
                            >
                                Aktivní
                            </Table.HeaderCell>

                            <Table.HeaderCell width={3}></Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                    {_.map(items, ({ id_prepaids_user, first_name, last_name, paid, email, phone, prepaid_name, active, workout_name, num_of_lectures, valid_months, state }, index) => (
                        <Table.Row key={id_prepaids_user}>
                            <Table.Cell>
                                {id_prepaids_user}
                            </Table.Cell>
                            <Table.Cell>
                                {first_name} {last_name}
                            </Table.Cell>
                            <Table.Cell>
                                {email}<br />{phone}
                            </Table.Cell>
                            <Table.Cell>
                                {prepaid_name}
                            </Table.Cell>
                            <Table.Cell>
                                {workout_name}
                            </Table.Cell>
                            <Table.Cell>
                                {num_of_lectures}
                            </Table.Cell>
                            <Table.Cell className='text-center'>
                                <StateIcon state={paid} />
                            </Table.Cell>
                            <Table.Cell className='text-center'>
                                <StateIcon state={active} />
                            </Table.Cell>
                            <Table.Cell className='text-right'>
                                <Link to={'/admin/manage/workouts/'+ type +'/prepaids/orders/detail/' + id_prepaids_user}><Button icon><Icon name='edit' /></Button></Link>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                    </Table.Body>
                </Table>
            </div>
        )
    }
}

export default PrepaidsOrdersTable