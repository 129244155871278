import React, { Component } from 'react'
import { intToBool, toggleLocationState, shortText, getToken } from '../../../api'
import { Table, Icon, Button, Popup } from 'semantic-ui-react'
import _ from 'loadsh'
import moment from 'moment'
import { StateIcon } from '../../../styles'
import { Link } from 'react-router-dom'

class CategoriesTable extends Component {

    constructor(props) {
        super(props)
        this.state = ({})
    }

    render() {

        const { items, column, direction, sort, type } = this.props

        return (
            <div>
                <Table sortable striped className='main-table select_table'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell
                                sorted={column === 'id_category' ? direction : null}
                                onClick={sort('id_category')}
                                width={1}
                            >
                                #
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'category_name' ? direction : null}
                                onClick={sort('category_name')}
                                width={9}
                            >
                                Název kategorie
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'created' ? direction : null}
                                onClick={sort('created')}
                                width={3}
                            >
                                Vytvořena
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'state' ? direction : null}
                                onClick={sort('state')}
                                width={1}
                            >
                                Status
                            </Table.HeaderCell>

                            <Table.HeaderCell width={2}></Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                    {_.map(items, ({ id_category, category_name, created, state }, index) => (
                        <Table.Row key={id_category}>
                            <Table.Cell>
                                {id_category}
                            </Table.Cell>
                            <Table.Cell>
                                {category_name}
                            </Table.Cell>

                            <Table.Cell>
                                {moment(created).format('DD. MM. YYYY h:mm')}
                            </Table.Cell>

                            <Table.Cell className='text-center'>
                                <StateIcon state={state} />
                            </Table.Cell>

                            <Table.Cell className='text-right'>
                                <Link to={'/admin/manage/workouts/kids/categories/edit/' + id_category}><Button icon><Icon name='edit' /></Button></Link>
                            </Table.Cell>

                        </Table.Row>
                    ))}
                    </Table.Body>
                </Table>
            </div>
        )
    }
}

export default CategoriesTable