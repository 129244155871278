import React from 'react'
import { DashboardWidget, WidgetName, WidgetDesc, WidgetDate, WidgetType } from '../../../styles/club'
import { Row, Col } from 'reactstrap'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { shortText } from '../../../api'
import { Button } from 'semantic-ui-react'

const setType = (type) => {
    switch(type) {
        case 'moms':
            return 'Cvičení pro maminky'

        case 'kids':
            return 'Cvičení s dětmi'

        case 'hobby':
            return 'Kroužky'

        default:
            return 'Lekce'
    }
}

const Widget = ({ item, changeLecture, deleteModal }) => {
    return (
        <DashboardWidget bg={item.event_color}>
            <Row>
                <Col sm={8} className='mb-4'>
                    <WidgetType>{setType(item.type)}</WidgetType>
                    <WidgetName>{item.name}</WidgetName>
                </Col>

                <Col sm={4} className='text-right mb-4'>
                    <WidgetDate>{moment(item.date).format('DD. MM. YYYY H:ss')}</WidgetDate>
                </Col>
            </Row>

            <Row>
                <Col sm={8}>
                    <WidgetDesc>{shortText(item.description, 120)}</WidgetDesc>
                </Col>
                <Col sm={4} className='text-sm-right mt-sm-3'>
                    {item.can_change === 1 && item.state === 2 && changeLecture(item.type, item.id_signed, item.is_free)}
                    {item.state === 1 && deleteModal(item.id_signed, item.type)}
                    {item.state === 3 && <Button disabled icon>Žádné akce</Button>}
                </Col>
            </Row>
            
        </DashboardWidget>
       
    )
}

export default Widget