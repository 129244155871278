import React from 'react'
import { Card, CardControls, CardBody, CardHeader, DateItem, EmailItem, FormButtonGroup } from '../../../styles'
import { Dropdown, Modal, Form, Input, Button, Icon, Label } from 'semantic-ui-react'
import moment from 'moment'
import { shortText, formatMoney } from '../../../api/'

const paidOpt = [
    {key: '1', value: 0, text: 'Nezaplaceno'},
    {key: '2', value: 1, text: 'Zaplaceno'},
]

const PriceOptions = ({ item, onChangeSelect, loadingPayment, loadingMail, handleSendPaymentEmail, onChange, updatePriceHandler, loadingPrice }) => {
    return (
        <div className='row'>
                <div className='col-sm-12'>
                    <Form>
                        <Form.Field>
                            <div className="row">
                                <div className="col-sm-6 pr-0">
                                    <label>Stav platby</label>
                                    <Dropdown
                                        name='paid'
                                        options={paidOpt}
                                        placeholder='Zaplaceno / nezaplaceno'
                                        selection
                                        onChange={onChangeSelect}
                                        fluid
                                        value={item.paid}
                                        className={'state-active-' + item.paid}
                                        loading={loadingPayment}
                                    />
                                </div>
                                <div className='col-sm-6'>
                                    <Button 
                                        onClick={() => handleSendPaymentEmail(item.paid, item.id_prepaids_user)} 
                                        negative={item.paid === 0 ? true : false} 
                                        positive={item.paid !== 0 && !item.send_confirm ? true : false} 
                                        style={{height: 38, marginTop: 27}}
                                        loading={loadingMail}
                                    >
                                        {(item.paid) === 0 ? 'Odeslat upomínku' : 'Odeslat potvrzení'}
                                    </Button>
                                </div>
                            </div>
                        </Form.Field>
                        <Form.Field className='relative'>
                            <label>Celková cena</label>
                            <Input 
                                type='text'
                                name='price'
                                value={item.total_price}
                                labelPosition='right'
                                onChange={onChange}
                            />
                            <Button positive onClick={updatePriceHandler} className='inside_input' loading={loadingPrice}>Uložit cenu</Button>
                        </Form.Field>
                    </Form>
                </div>
            </div>
    )
}

export default PriceOptions