import React, { Component } from 'react'
import { getOffers } from '../../../api/offers'
import OffersItem from './OffersItem'
import { Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom'
import { Button } from 'semantic-ui-react'

class OffersWidget extends Component {
    constructor(props) {
        super(props)

        this.state = ({
            items: [],
            loading: true,
            hasData: false
        })
    }

    componentDidMount() {
        this._getOffers()
    }

    _getOffers = () => {
        getOffers()
        .then((res) => {
            if(res.code === 1) {
                this.setState({
                    items: res.data,
                    loading: false,
                    hasData: true
                })
            } else {
                this.setState({
                    items: [],
                    hasData: false,
                    loading: false
                })
            }
        })
    }

    displayData = () => {

        const { width, limit, numOfItems } = this.props

        if(limit) {
            return this.state.items.slice(0, numOfItems).map((item, index) => {
                return (
                    <OffersItem item={item} width={width} index={index} />
                )
            })
        } else {
            return this.state.items.map((item, index) => {
                return (
                    <OffersItem item={item} width={width} index={index} />
                )
            })
        }
    }

    render() {

        const { limit, numOfItems } = this.props

        return (
            <>
                <Row>
                    {this.displayData()}
                </Row>
                {limit &&
                <Row>
                    <Col sm={12} className='text-center'>
                        <Link to={'/klub/nabidky'}>
                            <Button primary>Další nabídky (+{this.state.items.length - numOfItems})</Button>
                        </Link>
                    </Col>
                </Row>
                }
            </>
        )
    }
}

export default OffersWidget