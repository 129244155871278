import React from 'react'
import { NavLink } from 'react-router-dom'
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText
} from 'reactstrap'

const NavbarMobileDropdown = ({title, items}) => {

    return (
        <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
                {title}
            </DropdownToggle>
            <DropdownMenu right>
                {items.map((item) => {
                    return (
                        <DropdownItem>
                            <NavLink to={`${item.link}`} activeClassName='active'>{item.name}</NavLink>
                        </DropdownItem>
                    )
                })}
            </DropdownMenu>
        </UncontrolledDropdown>
    )

}

export default NavbarMobileDropdown