import React, { Component } from 'react'
import { getApplication, setPaid, sendEmail, changePrice } from '../../../api/workouts'
import { getToken } from '../../../api'
import Loading from '../../containers/Loading'
import NoReservations from '../../containers/club/NoReservations'
import { Row, Col } from 'reactstrap'
import { Card, CardBody, CardHeader, CardFooter } from '../../../styles'
import { Button, Tab } from 'semantic-ui-react'
import PriceOptions from './PriceOptions'
import { connect } from 'react-redux'
import { setMessage } from '../../../actions/messageActions'
import ApplicationUserForm from '../../forms/mom-workouts/ApplicationUserForm'
import ApplicationDatesTable from '../../tables/workouts/ApplicationDatesTable'
import ChangeKidsWorkoutModal from '../../modals/ChangeKidsWorkoutModal'
import ApplicationKidsForm from '../../forms/mom-workouts/ApplicationKidsForm'
import MoveToPaidModal from '../../modals/MoveToPaidModal'
import AddNewLectureModal from '../../modals/AddNewLectureModal'
import ApplicationNoteForm from '../../forms/mom-workouts/ApplicationNoteForm'

class WorkoutApplicationDetailView extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            item: {},
            loadingData: true,
            loadingPayment: false,
            user: {
                first_name: '',
                last_name: '',
                email: '',
                address: '',
                post_code: '',
                city: ''
            },
            kids: [],
            panes: [],
            price: '',
            loadingPrice: false,
            token: ''
        })
    }

    componentDidMount() {
        this._getApplication()
        this._getToken()
    }

    onChangeSelect = (e, data) => {
        this.setPaidHandler(data.value, this.state.item.id_application)
    }

    onChange = (e) => this.setState({
        [e.target.name]: e.target.value
    })

    onChangePayment = (e) => this.setState({
        newPayment: {
            ...this.state.newPayment,
            [e.target.name]: e.target.value
        }
    })

    setPaidHandler = (val, id) => {

        const { type } = this.props.match.params

        this.setState({
            loadingPayment: true
        })

        getToken().then((token) => {
            const data = {
                token: token,
                val: val,
                id_application: id
            }

            setPaid(data, type)
            .then((res) => {
                this.props.setMessage(res)
                this._getApplication()

                this.setState({
                    loadingPayment: false
                })
            })
        })
    }

    updatePriceHandler = () => {

        const { id, type } = this.props.match.params

        this.setState({
            loadingPrice: true
        })

        getToken().then((token) => {
            const data = {
                newPrice: this.state.price,
                type: type,
                id_application: id,
                token: token
            }

            changePrice(data, type)
            .then((res) => {
                this.props.setMessage(res)
                this._getApplication()
                this.setState({
                    loadingPrice: false
                })
            })
            .catch((err) => {
                this.props.setMessage({type: 'error', text: 'Něco se pokazilo, zkuste to prosím za chvíli'})
                this.setState({
                    loadingPrice: false
                })
            })
        })
    }

    _getToken = () => {
        getToken().then((token) => {
            this.setState({
                token: token
            })
        })
    }

    _getApplication = () => {

        const { id, type } = this.props.match.params

        this.setState({
            loadingData: true,
            hasData: false
        })

        getToken().then((token) => {
            const data = {
                token: token,
                id_application: id
            }

            getApplication(data, type)
            .then((res) => {
                
                if(res.code === 1) {
                    console.log(res.data.dates)
                    this.setState({
                        loadingData: false,
                        hasData: true,
                        item: res.data,
                        price: res.data.total_price,
                        user: {
                            first_name: res.data.first_name,
                            last_name: res.data.last_name,
                            email: res.data.email,
                            address: res.data.address,
                            post_code: res.data.post_code,
                            city: res.data.city,
                            phone: res.data.phone,
                            id_user: res.data.id_user,
                            note: res.data.note
                        },
                        kids: res.data.kids,
                        dates: res.data.dates,
                        is_free: type === 'kids' && res.data.dates !== false && res.data.dates[0].is_free === 1 ? true : false
                    }, () => {
                        if(type !== 'moms') {
                            this._setupPanes()
                        }
                    })
                }
                
            })
        })
    }

    _sendEmail = (paid, id) => {
        const { type } = this.props.match.params
        getToken().then((token) => {
            const data = {
                token: token,
                emails: [
                    id
                ]
            }
            if(paid === 0) {
                sendEmail(data, 'reminder', type)
                .then((res) => {
                    this.props.setMessage(res)
                })
            } else if(paid === 1) {
                sendEmail(data, 'confirm', type)
                .then((res) => {
                    this.props.setMessage(res)
                })
            }
        })
    }

    _setupPanes = () => {
        var kidsTabs = []

        this.state.kids.map((kid) => {
            var tab = {
                menuItem: kid.first_name + ' ' + kid.last_name,
                render: () => <Tab.Pane><ApplicationKidsForm kids={kid} /></Tab.Pane>
            }

            kidsTabs = [
                ...kidsTabs,
                tab
            ]
        })

        this.setState({
            panes: kidsTabs
        })
    }

    _renderKids = () => {
        return <><div className='kids_tabs mb-5'><Tab menu={{ secondary: true, pointing: true }} panes={this.state.panes} /></div></>
    }

    content = () => {

        const { type, id } = this.props.match.params
        const { setMessage } = this.props

        return (
            <Row>
                <Col sm={8}>
                    <Card className='mb-5'>
                        <CardHeader>
                            Uživatel
                        </CardHeader>
                        <CardBody>
                            <ApplicationUserForm user={this.state.user} token={this.state.token} />
                        </CardBody>
                    </Card>
                    <Card className='mb-5'>
                        <CardHeader>
                            Poznámka
                        </CardHeader>
                        <CardBody>
                            <ApplicationNoteForm user={this.state.user} token={this.state.token} id={id} type={type} />
                        </CardBody>
                    </Card>
                    {type !== 'moms' && <this._renderKids />}
                    <Card>
                        <CardHeader>
                            Objednané lekce
                        </CardHeader>
                        <ApplicationDatesTable items={this.state.dates} type={type} setMessage={setMessage} refresh={this._getApplication} />
                        <CardFooter>
                            <AddNewLectureModal
                                type={type} 
                                id_user={this.state.user.id_user}
                                id_application={id}
                                refresh={this._getApplication}
                                setMessage={this.props.setMessage}
                            />
                        </CardFooter>
                    </Card>
                </Col>
                <Col sm={4}>
                    <Card className='mb-5'>
                        <CardHeader>
                            Platba
                        </CardHeader>
                        <CardBody>
                            <PriceOptions 
                                item={this.state.item}
                                onChangeSelect={this.onChangeSelect}
                                loadingPayment={this.state.loadingPayment}
                                handleSendPaymentEmail={this._sendEmail}
                                updatePriceHandler={this.updatePriceHandler}
                                onChangePrice={this.onChange}
                                price={this.state.price}
                                loadingPrice={this.state.loadingPrice}
                            />
                        </CardBody>
                    </Card>
                    <Card className='mb-5'>
                        <CardHeader>
                            Nastavení
                        </CardHeader>
                        <CardBody>
                            {!this.state.is_free && type === 'kids' &&
                            <>
                            <ChangeKidsWorkoutModal 
                                type={type} 
                                id_user={this.state.user.id_user}
                                id_application={id}
                                refresh={this._getApplication}
                                setMessage={this.props.setMessage}
                            />
                            <p className='small mt-3 text-center'>Po změně kurzu budou dosavadní lekce smazány a nahrazeny lekcemi z nového kurzu.</p>
                            </>
                            }
                            {this.state.is_free && type === 'kids' &&
                            <>
                            <label>Změna kurzu</label>
                            <Button disabled icon fluid>Lekce zdarma - nahraďte lekci</Button>
                            <p className='small mt-3 text-center'>Kurz bude automaticky vybrán podle zkušební lekce.</p>
                            </>
                            }

                            {this.state.is_free &&
                            <MoveToPaidModal 
                                type={type} 
                                id_user={this.state.user.id_user}
                                id_application={id}
                                refresh={this._getApplication}
                                setMessage={this.props.setMessage}
                            />
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }

    render() {
        const { loadingData, hasData } = this.state

        if(loadingData) {
            return (
                <Loading />
            )
        } else if(!loadingData && !hasData) {
            return (
                <NoReservations text='Tato přihláška neexistuje' />
            )  
        } else {
            return (
                <>
                    <this.content />
                </>
            )
        }
    }

}

const mapStateToProps = state => ({
    user: state.user.user,
    loadingUser: state.user.loading
})
  
export default connect(mapStateToProps, { setMessage })(WorkoutApplicationDetailView)