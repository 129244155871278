import React, { Component } from 'react'
import { intToBool, toggleLocationState } from '../../api'
import { AreYouSureModal } from '../modals'
import { Link } from 'react-router-dom'
import { Table } from 'semantic-ui-react'
import _ from 'loadsh'

class UsersTable extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            showModal: false,
            idToDelete: 2
        })
    }

    toggleStateHandler = (id) => {
        toggleLocationState(id)
        .then(() => {
            this.props.refresh()
        })
    }

    toggleModal = (id) => {
        this.setState({
            showModal: !this.state.showModal,
            idToDelete: !this.state.showModal ? id : ''
        })
    }

    render() {

        const { items, column, direction, sort } = this.props

        return (
            <div>
                <Table sortable striped className='main-table'>
                    <Table.Header>
                        <Table.Row>

                            <Table.HeaderCell
                                sorted={column === 'id_user' ? direction : null}
                                onClick={sort('id_user')}
                                width={1}
                            >
                                #
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'user_first_name' ? direction : null}
                                onClick={sort('user_first_name')}
                                width={3}
                            >
                                Jméno
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'user_last_name' ? direction : null}
                                onClick={sort('user_last_name')}
                                width={3}
                            >
                                Příjmení
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'user_email' ? direction : null}
                                onClick={sort('user_email')}
                                width={3}
                            >
                                E-mail
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'organization_name' ? direction : null}
                                onClick={sort('organization_name')}
                                width={3}
                            >
                                Organizace
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'user_state' ? direction : null}
                                onClick={sort('user_state')}
                                width={1}
                            >
                                Stav
                            </Table.HeaderCell>

                            <Table.HeaderCell width={1}></Table.HeaderCell>
                            <Table.HeaderCell width={1}></Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                    {_.map(items, ({ id_user, organization_name, user_first_name, user_last_name, user_email, user_state }) => (
                        <Table.Row key={id_user}>
                            <Table.Cell>
                                {id_user}
                            </Table.Cell>
                            <Table.Cell>
                                {user_first_name}
                            </Table.Cell>
                            <Table.Cell>
                                {user_last_name}
                            </Table.Cell>
                            <Table.Cell>
                                {user_email}
                            </Table.Cell>
                            <Table.Cell>
                                {organization_name}
                            </Table.Cell>
                            <Table.Cell>
                                {intToBool(user_state) ? <i class="icofont-check-circled icon-state" onClick={() => this.toggleStateHandler(id_user)}></i> : <i class="icofont-close-circled icon-state" onClick={() => this.toggleStateHandler(id_user)}></i>}
                            </Table.Cell>
                            <Table.Cell>
                                <i class="icofont-trash" onClick={() => this.toggleModal(id_user)}></i>
                            </Table.Cell>
                            <Table.Cell>
                                <Link to={`${process.env.PUBLIC_URL}/admin/manage/users/edit/` + id_user}><i class="icofont-ui-edit"></i></Link>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                    </Table.Body>
                </Table>

            <AreYouSureModal show={this.state.showModal} toggle={this.toggleModal} id={this.state.idToDelete} refresh={this.props.refresh}/>

            </div>
        )
    }
}

export default UsersTable