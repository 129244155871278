import React, { Component } from 'react'
import { Accordion, Icon } from 'semantic-ui-react'
import ParentsForm from '../../forms/tours/ParentsForm'
import KidsForm from '../../forms/tours/KidsForm'
import SelectTourForm from '../../forms/tours/SelectTourForm'
import { getSelectedTour } from '../../../api/tours'
import AdditionalInfoForm from '../../forms/application/AdditionalInfoForm'

class ApplicationAccordion extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            activeIndex: 0,
            finishedParents: false,
            finishedKids: false,
            finishedTour: false,
            data: {
                parents: [],
                kids: [],
                tour: ''
            },
            selectedTour: {},
            isSelected: false,
            loadingKids: false,
            loadingParents: false,
            loadingTour: false,
            isLogged: false,
            user: {},
            loading: true
        })
    }

    componentDidMount() {
        const { selected, isLogged, user } = this.props

        if(selected) {
            this._getSelectedTour(selected)

            this.setState({
                data: {
                    ...this.state.data,
                    tour: selected
                }
            })
        }

        this.setState({
            isLogged: isLogged,
            user: isLogged ? user : {},
            loading: false
        })
    }

    _getSelectedTour = (id) => {

        const { saveSelected } = this.props

        getSelectedTour(id)
        .then((res) => {
            if(res.code === 1) {
                this.setState({
                    selectedTour: res.data,
                    isSelected: true
                })
            }
            saveSelected(res.data)
        })
    }

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index
    
        this.setState({ activeIndex: newIndex })
    }

    setParents = (data) => {
        this.setState({
            loadingParents: true,
            finishedParents: true,
            activeIndex: 1,
            data: {
                ...this.state.data,
                parents: this.state.data.parents.length === 0 ? this.state.data.parents.concat(data) : data
            }
        }, () => {
            this.setState({ 
                loadingParents: false 
            })

            this.props.saveData(this.state.data)
        })
    }

    setKids = (data) => {
        this.setState({
            loadingKids: true,
            finishedKids: true,
            activeIndex: 2,
            data: {
                ...this.state.data,
                kids: this.state.data.kids.length === 0 ? this.state.data.kids.concat(data) : data
            }
        }, () => {
            this.setState({ loadingKids: false })
            this.props.saveData(this.state.data)
        })
    }

    setTour = (data, id_organization) => {

        this._getSelectedTour(data)

        this.setState({
            loadingTour: true,
            finishedTour: true,
            activeIndex: 3,
            data: {
                ...this.state.data,
                tour: data,
                id_organization: id_organization
            }
        }, () => {
            this.setState({ loadingTour: false })
            this.props.saveData(this.state.data)
        })
    }

    setTourButton = () => {
        this.setState({
            loadingTour: true,
            finishedTour: true,
            activeIndex: 3
        })
    }

    setAdditional = (data) => {
        this.setState({
            loadingAdditional: true,
            finishedAdditional: true,
            activeIndex: 4,
            data: {
                ...this.state.data,
                note: data.note
            }
        }, () => {
            this.setState({ loadingAdditional: false })
            this.props.saveData(this.state.data)
        })
    }

    render() {
        const { activeIndex, finishedKids, finishedParents, finishedTour, finishedAdditional, isLogged, user } = this.state
        const { toursList, loading } = this.props

        return (
            <Accordion styled fluid>
                <Accordion.Title
                    active={activeIndex === 0}
                    index={0}
                    onClick={this.handleClick}
                    className={finishedParents ? 'tab_finished' : 'tab_waiting'}
                >
                    <Icon name='dropdown' />
                    Zákonný zástupce
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 0} className={finishedParents ? 'tab_finished' : 'tab_waiting'}>
                    {!this.state.loading &&
                    <ParentsForm loading={loading} submit={this.setParents} isLogged={isLogged} user={isLogged ? user : null} />
                    }
                </Accordion.Content>

                <Accordion.Title
                    active={activeIndex === 1}
                    index={1}
                    onClick={finishedParents && this.handleClick}
                    className={finishedKids ? 'tab_finished' : 'tab_waiting'}
                >
                    <Icon name='dropdown' />
                    Informace o dětech
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 1} className={finishedKids ? 'tab_finished' : 'tab_waiting'}>
                    <KidsForm loading={this.state.loadingKids} submit={this.setKids} />
                </Accordion.Content>

                <Accordion.Title
                    active={activeIndex === 2}
                    index={2}
                    onClick={finishedParents && this.handleClick}
                    className={finishedKids ? 'tab_finished' : 'tab_waiting'}
                >
                    <Icon name='dropdown' />
                    Vybraný zájezd
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 2} className={finishedKids ? 'tab_finished' : 'tab_waiting'}>
                    <SelectTourForm loading={this.state.loadingKids} submit={this.setTour} setButton={this.setTourButton} toursList={toursList} selectedItem={this.state.selectedTour} isSelected={this.state.isSelected} />
                </Accordion.Content>

                <Accordion.Title
                    active={activeIndex === 3}
                    index={3}
                    onClick={finishedTour && this.handleClick}
                    className={finishedAdditional ? 'tab_finished' : 'tab_waiting'}
                >
                    <Icon name='dropdown' />
                    4. krok: Další informace
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 3} className={finishedAdditional ? 'tab_finished' : 'tab_waiting'}>
                    {!loading &&
                        <AdditionalInfoForm submit={this.setAdditional} />
                    }
                </Accordion.Content>

            </Accordion>
        )
    }

}

export default ApplicationAccordion