import React, { Component } from 'react'
import firebase from 'firebase'
import { Loader } from 'semantic-ui-react'
import { LoadingContainer } from '../../styles'

class LoadingView extends Component {

    componentDidMount() {

        document.title = 'Přihlašování... | 1FIT Klub'

        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                firebase.auth().currentUser.getIdTokenResult()
                .then((idTokenResult) => {
                    if (!!idTokenResult.claims.isAdmin || idTokenResult.claims.isAdmin === undefined) {
                        
                          this.props.history.push(`${process.env.PUBLIC_URL}/admin/dashboard`)
                        
                    } else {
                        this.props.history.push('/klub/nastenka')
                    }
                })
            } else {
                this.props.history.push('/klub/auth/prihlaseni')
            }
          });
    }

    render() {
        return (
            <LoadingContainer>
                <Loader active />
            </LoadingContainer>
        )
    }

}

export default LoadingView