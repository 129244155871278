
import React, { Component } from 'react'
import EmbedNavigation from '../../containers/EmbedNavigation'
import { Container, Row, Col } from 'reactstrap'
import { Accordion, Icon } from 'semantic-ui-react'
import MomsWorkoutsApplicationAccordion from '../../containers/workouts/MomsWorkoutsApplicationAccordion'
import KidsWorkoutsApplicationAccordion from '../../containers/workouts/KidsWorkoutsApplicationAccordion'
import { createApplication } from '../../../api/workouts'
import ApplicationSummary from '../../forms/mom-workouts/ApplicationSummary'
import firebase from 'firebase'
import { connect } from 'react-redux'
import { saveClubUser } from '../../../actions/userActions'
import { setMessage } from '../../../actions/messageActions'
import Loading from '../../containers/Loading'
import { getCalendarItem } from '../../../api/workouts'
import HeaderApplications from '../../containers/club/HeaderApplications'
import ServicesBlocks from '../../containers/applications/ServicesBlocks'
import ReactPixel from 'react-facebook-pixel';

class EventApplicationView extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            toursList: [],
            saved: {
                person: [
                    {
                        first_name: '',
                        last_name: '',
                        email: '',
                        phone: '',
                        address: '',
                        city: '',
                        post_code: ''
                    }
                ],
                id_organization: '',
                event: ''
            },
            userInfo: {
                terms: false,
                newsletter: false,
                gdpr: false,
                note: '',
            },
            item: {},
            isLogged: false,
            loading: true
        })
    }

    componentDidMount = () => {

        ReactPixel.track('InitiateCheckout');

        const { loadingUser } = this.props
        const { selected } = this.props.match.params

        this.checkUser()
        this._getEvent()

        if(this.state.isLogged && !loadingUser) {
            this.setUser()
        }

        document.body.classList.add('application')
    }

    componentDidUpdate(prevProps) {
        const { loadingUser } = this.props

        if(prevProps.loadingUser !== loadingUser && !loadingUser && this.state.isLogged) {
            this.setUser()
        }
    }

    checkUser = () => {
        firebase.auth().onAuthStateChanged((user) => {
            if(user) {
                firebase.auth().currentUser.getIdTokenResult()
                .then((idTokenResult) => {
                    if (!idTokenResult.claims.isAdmin && idTokenResult.claims.isAdmin !== undefined) {
                        this.setState({
                            isLogged: true
                        })
                    } else {
                        this.setState({
                            loading: false
                        })
                    }
                })
            } else {
                this.setState({
                    loading: false
                })
            }
        })
    }

    setUser = () => {

        const { user } = this.props

        const loggedPerson = [
            {
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
                phone: user.phone,
                address: user.address,
                city: user.city,
                post_code: user.post_code
            }
        ]

        this.setState({
            saved: {
                ...this.state.saved,
                person: loggedPerson
            },
            loading: false
        })
    }

    _getEvent = () => {

        const { selected, type } = this.props.match.params

        getCalendarItem(selected, type)
        .then((res) => {
            if(res.code === 1) {
                this.setState({
                    item: res.data
                })
            }
        })
        .catch((err) => {
            this.props.setMessage({type: 'error', text: 'Nepodařilo se získat data o vybraném termínu, zkuste to prosím později'})
        })
    }

    saveData = (data) => {
        this.setState({
            saved: data
        })
    }

    handleCheckbox = (e, data) => {
        this.setState({
            userInfo: {
                ...this.state.userInfo,
                [data.name]: data.checked
            } 
        })
    }

    submit = () => {

        const { type, all } = this.props.match.params
        const { saved, userInfo } = this.state

        this.setState({
            loadingData: true
        })

        const data = {
            person: saved.person,
            event: saved.event,
            note: saved.note ? saved.note : '',
            id_coupon: saved.id_coupon ? saved.id_coupon : null,
            coupon_value: saved.coupon_value ? saved.coupon_value : null,
            coupon: saved.coupon ? saved.coupon : false,
            kids: type === 'kids' || type === 'hobby' ? saved.kids : [],
            gdpr: userInfo.gdpr ? 1 : 0,
            terms: userInfo.terms ? 1 : 0,
            newsletter: userInfo.newsletter ? 1 : 0,
            isAll: all !== undefined && all === 'all' ? true : ''
        }

        createApplication(data, type)
        .then((res) => {
            this.setState({
                loadingData: false
            })
            ReactPixel.track('Purchase', { value: 0.00, currency: 'CZK' });
            this.props.history.push(`${process.env.PUBLIC_URL}/prihlaska/cviceni/${type}/done/potvrzeni/${res.link}/${all !== undefined && all === 'all' && all}`)
        })
        .catch((err) => {
            this.props.setMessage({type: 'error', text: 'Při odesílání přihlášky se vyskytla chyba. Zkuste to prosím později.'})
        })
    }

    momsWorkoutsContent = () => {

        const { selected, type } = this.props.match.params
        const { isLogged, loading, saved, item } = this.state

        return (
            <Row>
                <Col sm={8} className='mb-5'>
                    {!loading &&
                    <MomsWorkoutsApplicationAccordion 
                        toursList={this.state.toursList} 
                        selected={selected}
                        type={type}
                        saveData={this.saveData} 
                        saveSelected={this.saveSelected} 
                        isLogged={isLogged} 
                        user={isLogged ? this.props.user : null} 
                        loading={loading}
                        eventItem={item}
                    />
                    }
                </Col>
                <Col sm={4}>
                    <ApplicationSummary 
                        email={saved.person[0].email} 
                        first_name={saved.person[0].first_name} 
                        last_name={saved.person[0].last_name}
                        phone={saved.person[0].phone}
                        data={saved}
                        loading={loading}
                        handleCheckbox={this.handleCheckbox}
                        terms={this.state.userInfo.terms}
                        gdpr={this.state.userInfo.gdpr}
                        newsletter={this.state.userInfo.newsletter}
                        submit={this.submit}
                        eventItem={item}
                        type={type}
                        loadingSend={this.state.loadingData}
                    />
                </Col>
            </Row>
        )
    }

    kidsWorkoutsContent = () => {

        const { selected, type, all } = this.props.match.params
        const { isLogged, loading, saved, item } = this.state

        const isAll = all === 'all' ? true : false

        return (
            <Row>
                <Col sm={8} className='mb-5'>
                    {!loading &&
                    <KidsWorkoutsApplicationAccordion 
                        toursList={this.state.toursList} 
                        selected={selected}
                        type={type}
                        saveData={this.saveData} 
                        saveSelected={this.saveSelected} 
                        isLogged={isLogged} 
                        user={isLogged ? this.props.user : null} 
                        loading={loading}
                        eventItem={item}
                        all={isAll}
                    />
                    }
                </Col>
                <Col sm={4}>
                    <ApplicationSummary 
                        email={saved.person[0].email} 
                        first_name={saved.person[0].first_name} 
                        last_name={saved.person[0].last_name}
                        phone={saved.person[0].phone}
                        loading={loading}
                        handleCheckbox={this.handleCheckbox}
                        terms={this.state.userInfo.terms}
                        gdpr={this.state.userInfo.gdpr}
                        newsletter={this.state.userInfo.newsletter}
                        submit={this.submit}
                        eventItem={item}
                        data={saved}
                        coupon={saved.coupon}
                        coupon_value={saved.coupon_value}
                        type={type}
                        loadingSend={this.state.loadingData}
                        all={isAll}
                    />
                </Col>
            </Row>
        )
    }

    accordion = (type) => {
        if(type === 'moms') {
            return <this.momsWorkoutsContent />
        } else if(type === 'kids') {
            return <this.kidsWorkoutsContent />
        } else if(type === 'hobby') {
            return <this.kidsWorkoutsContent />
        }
    }

    render() {

        const { utm, type } = this.props.match.params
        const { loading } = this.state

        return (
            <>
            <HeaderApplications hasRoute={true} route={'Přihláška na cvičení'} backlink={'/prihlaska'} />
            <Container className='mt-5 mb-5'>
                {!loading ?
                this.accordion(type)
                :
                <Loading inEmbed={true} />
                }        
            </Container>
            <div className='other_services'>
                <Container>
                    <h2 className='text-center'>Vyberte si z naší nabídky</h2>
                    <ServicesBlocks />
                </Container>
            </div>
            </>
        )
    }

}

const mapStateToProps = state => ({
    user: state.user.user,
    loadingUser: state.user.loading
})
  
export default connect(mapStateToProps, { setMessage, saveClubUser })(EventApplicationView)