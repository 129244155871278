import React, { Component } from 'react'
import EditPrepaidForm from '../../forms/mom-workouts/EditPrepaidForm'
import { Card, CardHeader, CardBody } from '../../../styles'
import { getToken } from '../../../api'
import { connect } from 'react-redux'
import { setMessage } from '../../../actions/messageActions'
import { getWorkoutsOpts, createPrepaid, getPrepaid, getLecturesFilter } from '../../../api/workouts'

class EditPrepaidView extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            data: {
                id_workout: '',
                num_of_lectures: '',
                price: '',
                valid_months: '',
                state: '',
                name: '',
            },
            loadingOpts: true,
            workoutOpts: []
        })
    }

    componentDidMount() {

        const { loadingUser } = this.props
        const { id } = this.props.match.params

        if(!loadingUser) {
            this._getOpts()
            if(id !== 'new') {
                this._getPrepaid()
            }
        }
    }

    componentDidUpdate(prevProps) {
        const { loadingUser } = this.props
        const { id } = this.props.match.params

        if(!loadingUser && prevProps.loadingUser !== loadingUser) {
            this._getOpts()
            if(id !== 'new') {
                this._getPrepaid()
            }
        }

    }

    _getOpts = () => {

        const { user } = this.props
        const { type } = this.props.match.params

        getToken().then((token) => {
            const data = {
                token: token,
                id_org: user.id_organization
            }

            getLecturesFilter(data, type)
            .then((res) => {
                this.setState({
                    loadingOpts: false,
                    opts: res.data
                })
            })
        })
        .catch((err) => {
            this.props.setMessage({type: 'error', text: 'Nepodařilo se získat bezpečnostní token, aktualizuji stránku'})
            window.location.reload();
        })
    }

    _getPrepaid = () => {

        const { user } = this.props
        const { type, id } = this.props.match.params

        this.setState({
            loading: true
        })

        getToken().then((token) => {
            const send = {
                token: token,
                id_org: user.id_organization,
                id_item: id
            }

            getPrepaid(send, type)
            .then((res) => {
                if(res.code === 1) {
                    this.setState({
                        data: res.data,
                        loading: false
                    })
                } else {
                    this.props.history.push(`${process.env.PUBLIC_URL}/admin/manage/workouts/${type}/prepaid/list`)
                }
                
            })
        })
        .catch((err) => {
            this.props.setMessage({type: 'error', text: 'Nepodařilo se získat bezpečnostní token, aktualizuji stránku'})
            window.location.reload();
        })
        
    }

    submit = (data) => {
        const { user } = this.props
        const { type, id } = this.props.match.params

        this.setState({
            loading: true
        })

        const action = id !== 'new' ? 'update' : 'insert'

        getToken().then((token) => {
            const send = {
                token: token,
                id_organization: user.id_organization,
                id_workout: data.id_workout,
                name: data.name,
                num_of_lectures: data.num_of_lectures,
                price_per_lecture: data.price_per_lecture,
                valid_months: data.valid_months,
                id_prepaid: id !== 'new' ? id : '',
                created_by: user.id_user,
                updated_by: user.id_user
            }

            createPrepaid(send, action, type)
            .then((res) => {
                this.setState({
                    loading: false
                })
                this.props.setMessage(res)
                this.props.history.push(`${process.env.PUBLIC_URL}/admin/manage/workouts/${type}/prepaid/edit/${res.link}`)
            })
        })
    }



    render() {
        return (
            <Card>
                <CardHeader>
                    Úprava permanentky
                </CardHeader>
                <CardBody>
                    <EditPrepaidForm initial={this.state.data} loadingOpts={this.state.loadingOpts} loading={this.state.loading} opts={this.state.opts} submit={this.submit} />
                </CardBody>
            </Card>
        )
    }

}

const mapStateToProps = state => ({
    user: state.user.user,
    loadingUser: state.user.loading
})
  
export default connect(mapStateToProps, { setMessage })(EditPrepaidView)