import React, { Component } from 'react'
import { intToBool, toggleLocationState, shortText, getToken } from '../../../api'
import { Table, Icon, Button, Popup, Modal } from 'semantic-ui-react'
import _ from 'loadsh'
import moment from 'moment'
import { StateIcon } from '../../../styles'
import { Link } from 'react-router-dom'
import { deleteItem } from '../../../api/workouts'
import ChangeLectureModal from '../../modals/ChangeLectureModal'

class NextLecturesTable extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            showModal: false,
            loading: false
        })
    }

    hideModal = () => this.setState({ showModal: false })
    openModal = () => this.setState({ showModal: true })

    _deleteItem = (id) => {

        this.setState({
            loading: true
        })

        const { setMessage, refresh, type } = this.props

        getToken().then((token) => {
            const data = {
                token: token
            }

            deleteItem(data, id, type)
            .then((res) => {
                setMessage(res)
                refresh()

                this.setState({
                    loading: false
                })
            })

        })
    }

    deleteModal = (id_item, type) => {
        return (
            <Modal trigger={<Button className='red' icon>Omluvit</Button>} onClose={this.hideModal}>
                <Modal.Header>
                    Omluvit se z této lekce
                </Modal.Header>
                <Modal.Content>
                    {type !== 'hobby' && <p>Po omluvení budete z lekce vyřazeni a budete si ji moci nahradit jindy.</p>}
                    {type === 'hobby' && <p>Po omluvení budete z lekce vyřazeni a <strong>nebudete si ji moci nahradit jindy.</strong></p>}
                </Modal.Content>
                <Modal.Actions className='justify-content-end'>
                    <Button className='red' onClick={() => this._deleteItem(id_item)} loading={this.state.loading} disabled={this.state.loading}>Omluvit lekci</Button>
                </Modal.Actions>
            </Modal>
        )
    }

    _changeLectureModal = (type, id_signed) => {

        const { setMessage, refresh } = this.props

        return (
            <ChangeLectureModal type={type} id={id_signed} refresh={refresh} setMessage={setMessage} />
        )
    }   

    render() {

        const { items, column, direction, sort, type, canChange } = this.props

        return (
            <div>
                <Table sortable striped className='main-table'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell
                                sorted={column === 'id_application' ? direction : null}
                                onClick={sort('id_application')}
                                width={1}
                            >
                                #
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'workout_name' ? direction : null}
                                onClick={sort('workout_name')}
                                width={3}
                            >
                                Název lekce
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'date' ? direction : null}
                                onClick={sort('date')}
                                width={3}
                            >
                                Datum a čas
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'location_name' ? direction : null}
                                onClick={sort('location_name')}
                                width={2}
                            >
                                Lokalita
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'state' ? direction : null}
                                onClick={sort('state')}
                                width={1}
                            >
                                Status
                            </Table.HeaderCell>

                            <Table.HeaderCell width={2}></Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                    {_.map(items, ({ id_date, id_signed, id_workout, date, total_price, location_name, paid, state, created, name }, index) => (
                        <Table.Row key={id_date}>
                            <Table.Cell>
                                {id_date}
                            </Table.Cell>
                            <Table.Cell>
                                {name}
                            </Table.Cell>
                            <Table.Cell>
                                {moment(date).format('DD. MM. YYYY h:ss')}
                            </Table.Cell>
                            <Table.Cell>
                                {location_name}
                            </Table.Cell>
                            <Table.Cell className='text-center'>
                                <StateIcon state={state} />
                            </Table.Cell>
                            <Table.Cell>
                                <Link to={`/admin/manage/workouts/${type}/dates/list/${id_date}/detail`}>
                                    <Button icon>Detail</Button>
                                </Link>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                    </Table.Body>
                </Table>
            </div>
        )
    }
}

export default NextLecturesTable