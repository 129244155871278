import React, { Component } from 'react'
import OffersWidget from '../../containers/offers/OffersWidget';

class ClubOffersView extends Component {

    render() {
        return (
            <>
                <OffersWidget width={4} />
            </>
        )
    }

}

export default ClubOffersView