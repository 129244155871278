import React from 'react'

function ApplicationsSuspended() {

    return (
        <div className='d-flex justify-content-center align-items-center' style={{height: 'calc(100vh - 106px)'}}>
            <div>
            <h1 className='text-center mb-3'>Aktuálně neprobíhají kurzy. Budeme Vás informovat v září.</h1>
            <p className='text-center' style={{fontSize: 20}}>Děkujeme za pochopení tým 1FIT</p>
            </div>
        </div>
    )
}

export default ApplicationsSuspended