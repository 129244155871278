import React from 'react'
import { Form, Input, Button, Dropdown } from 'semantic-ui-react'
import { FormButtonGroup } from '../../styles'
import moment from 'moment'

const EditDateForm = ({loading, submit, date, camps, locations, onChange, onChangeSelect}) => {
    return (
        <Form loading={loading} onSubmit={submit}>
            <Form.Field>
                <label>Datum od</label>
                <Input 
                    type='date'
                    name='date_from'
                    placeholder='Datum'
                    value={date.date_from ? moment(date.date_from).format("YYYY-MM-DD") : ''}
                    onChange={onChange}
                />
            </Form.Field>
            <Form.Field>
                <label>Datum do</label>
                <Input 
                    type='date'
                    name='date_to'
                    placeholder='Datum'
                    value={date.date_to ? moment(date.date_to).format("YYYY-MM-DD") : ''}
                    onChange={onChange}
                />
            </Form.Field>
            <Form.Field>
                <label>Kapacita termínu</label>
                <Input 
                    type='number'
                    name='date_capacity'
                    placeholder='Kapacita termínu'
                    value={date.date_capacity}
                    onChange={onChange}
                />
            </Form.Field>
            <Form.Field>
                <label>Tábor</label>
                <Dropdown 
                    name='id_camp'
                    placeholder='Vyberte možnost'
                    options={camps}
                    value={date.id_camp}
                    onChange={onChangeSelect}
                    selection
                    search
                />
            </Form.Field>
            <Form.Field>
                <label>Lokalita</label>
                <Dropdown 
                    name='id_location'
                    placeholder='Vyberte možnost'
                    options={locations}
                    value={date.id_location}
                    onChange={onChangeSelect}
                    selection
                    search
                />
            </Form.Field>
            <FormButtonGroup>
                <Button primary>Uložit změny</Button>
            </FormButtonGroup>
        </Form>
    )
}

export default EditDateForm