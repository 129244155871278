import React, { Component } from 'react'
import { Card, CardHeader, CardBody } from '../../../styles'
import DefaultForm from '../../forms/DefaultForm'
import { Row, Col } from 'reactstrap'
import { connect } from 'react-redux'
import { setMessage } from '../../../actions/messageActions'
import { saveClubUser } from '../../../actions/userActions'
import { getToken } from '../../../api'
import { updateClubUser } from '../../../api/club'

const profileFormFields = [
    {
        id: 0,
        name: 'first_name',
        label: 'Křestní jméno',
        placeholder: 'Zadejte vaše křestní jméno',
        type: 'text',
        required: true,
        desc: 'Jméno a příjmení potřebujeme pro správné vyplnění přihlášek na naše lekce'
    },
    {
        id: 1,
        name: 'last_name',
        label: 'Příjmení',
        placeholder: 'Zadejte vaše příjmení',
        type: 'text',
        required: true,
    },
    {
        id: 2,
        name: 'email',
        label: 'E-mail',
        placeholder: 'Zadejte váš e-mail',
        type: 'text',
        disabled: true,
        desc: 'Na tento e-mail vám budou chodit důležité informace ohledně vašich lekcí'
    },
    {
        id: 3,
        name: 'phone',
        label: 'Telefonní číslo',
        placeholder: 'Zadejte vaše telefonní číslo',
        type: 'text',
        required: true,
        desc: 'Telefonní číslo využíváme pro sdělení velmi důležitých informací'
    },
    {
        id: 4,
        name: 'address',
        label: 'Adresa',
        placeholder: 'Zadejte vaši adresu',
        type: 'text',
        required: true,
        desc: 'Vaši adresesu potřebujeme pro vyplňování přihlášek na naše lekce'
    },
    {
        id: 5,
        name: 'city',
        label: 'Město',
        placeholder: 'Zadejte město',
        type: 'text',
        required: true
    },
    {
        id: 6,
        name: 'post_code',
        label: 'PSČ',
        placeholder: 'Zadejte PSČ',
        type: 'text',
        required: true
    },
    {
        id: 7,
        name: 'newsletter',
        label: 'Souhlas se zasíláním newsletteru',
        type: 'select',
        options: [
            { key: 0, value: 0, text: 'Nesouhlasím' },
            { key: 1, value: 1, text: 'Souhlasím' } 
        ],
        required: true
    }
]

class UserProfileView extends Component {

    _isMounted = false

    constructor(props) {
        super(props)

        this.state = ({
            user: {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                second_parent: '',
                address: '',
                city: '',
                post_code: '',
                newsletter: 1
            },
            loading: true
        })
    }

    componentDidMount() {

        this._isMounted = true;

        this.setState({
            isMounted: this._isMounted
        })

        const { loadingUser, user } = this.props

        if(!loadingUser) {
            this.setUser(user)
        }
    }

    componentWillUnmount() {
        this._isMounted = false;

        this.setState({
            isMounted: this._isMounted
        })
    }

    componentDidUpdate(prevProps) {
        const { loadingUser, user } = this.props

        if(prevProps.loadingUser !== loadingUser && !loadingUser) {
            this.setUser(user)
        }
    }

    setUser = (user) => {
        if(this._isMounted) {
            this.setState({
                user: {
                    first_name: user.first_name,
                    last_name: user.last_name,
                    email: user.email,
                    phone: user.phone,
                    second_parent: user.second_parent,
                    address: user.address,
                    city: user.city,
                    post_code: user.post_code,
                    newsletter: user.newsletter,
                },
                loading: false
            })
        }
    }

    submitUserInfo = (user) => {

        this.setState({
            loading: true
        })

        getToken().then((token) => {
            const data = {
                token: token,
                first_name: user.first_name,
                last_name: user.last_name,
                phone: user.phone,
                second_parent: user.second_parent,
                address: user.address,
                city: user.city,
                post_code: user.post_code,
                newsletter: user.newsletter
            }

            updateClubUser(data)
            .then((res) => {
                this.props.setMessage(res)
                this.props.saveClubUser(token)
                this.setState({
                    loading: false
                })
            })
        })
        .catch((err) => {
            this.props.setMessage({type: 'error', text: 'Nepodařilo se získat bezpečnostní token, aktualizuji stránku'})
            window.location.reload();
        })
    }

    render() {
        return (
            <Row>
                <Col sm={8}>
                    <Card>
                        <CardHeader>
                            Moje kontaktní informace
                        </CardHeader>
                        <CardBody>
                            {this.state.isMounted &&
                            <DefaultForm fields={profileFormFields} loading={this.state.loading} data={this.state.user} submit={this.submitUserInfo} />
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }

}

const mapStateToProps = state => ({
    user: state.user.user,
    loadingUser: state.user.loading
})
  
export default connect(mapStateToProps, { setMessage, saveClubUser })(UserProfileView)