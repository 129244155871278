import React, { Component } from 'react'
import { Accordion, Icon } from 'semantic-ui-react'
import PersonForm from '../../forms/mom-workouts/PersonForm'
import SelectEventForm from '../../forms/mom-workouts/SelectEventForm'
import { getSelectedTour } from '../../../api/tours'
import PrepaidSelectForm from '../../forms/prepaids/PrepaidSelectForm'

class PrepaidsAccordion extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            activeIndex: 0,
            finishedPerson: false,
            finishedKids: false,
            finishedTour: false,
            data: {
                person: [],
                event: ''
            },
            selectedTour: {},
            isSelected: false,
            loadingKids: false,
            loadingPerson: false,
            loadingTour: false,
            isLogged: false,
            user: {},
            loading: true,
            event: {}
        })
    }

    componentDidMount() {
        const { isLogged, user, eventItem } = this.props

        this.setState({
            isLogged: isLogged,
            user: isLogged ? user : {},
            loading: false,
            event: eventItem
        })
    }

    componentDidUpdate(prevProps) {
        if(prevProps.eventItem !== this.props.eventItem) {
            this.setState({
                event: this.props.eventItem
            })
        }
    }

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index
    
        this.setState({ activeIndex: newIndex })
    }

    setPerson = (data) => {
        this.setState({
            loadingPerson: true,
            finishedPerson: true,
            activeIndex: 1,
            data: {
                ...this.state.data,
                person: this.state.data.person.length === 0 ? this.state.data.person.concat(data) : data
            }
        }, () => {
            this.setState({ 
                loadingPerson: false 
            })

            this.props.saveData(this.state.data, 'person')
        })
    }

    setEvent = (event, date, price, is_full) => {
        this.setState({
            loadingEvent: true,
            finishedEvent: true,
            activeIndex: 2,
            data: {
                ...this.state.data,
                event: {
                    id_workout: event,
                    id_date: date,
                    price_lecture: is_full ? 0 : price,
                    is_sub: is_full ? 1 : 0,
                }
            }
        }, () => {
            this.setState({ loadingTour: false })
            this.props.saveData(this.state.data, 'event')
        })
    }

    _selectPrepaid = (data) => {
        this.props.saveSelected(data)
    }

    render() {
        const { activeIndex, finishedPerson, isLogged, user, event } = this.state
        const { loading, type, id } = this.props

        return (
            <Accordion styled fluid>
                <Accordion.Title
                    active={activeIndex === 0}
                    index={0}
                    onClick={this.handleClick}
                    className={finishedPerson ? 'tab_finished' : 'tab_waiting'}
                >
                    <Icon name='dropdown' />
                    Osobní informace
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 0} className={finishedPerson ? 'tab_finished' : 'tab_waiting'}>
                    {!this.state.loading &&
                    <PersonForm loading={loading} submit={this.setPerson} isLogged={isLogged} user={isLogged ? user : null} />
                    }
                </Accordion.Content>

                <Accordion.Title
                    active={activeIndex === 1}
                    index={1}
                    onClick={finishedPerson && this.handleClick}
                    className={finishedPerson ? 'tab_finished' : 'tab_waiting'}
                >
                    <Icon name='dropdown' />
                    Výběr permanentky
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 1} className={finishedPerson ? 'tab_finished' : 'tab_waiting'}>
                    {!loading &&
                        <PrepaidSelectForm type={type} id={id} select={this.setEvent} event={event} selectPrepaid={this._selectPrepaid} />
                    }
                </Accordion.Content>

            </Accordion>
        )
    }

}

export default PrepaidsAccordion