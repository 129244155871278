import React, { Component } from 'react'
import { Sidebar, SidebarNav, SidebarItem, SidebarDivider, SidebarHeader } from '../../styles'
import { NavLink } from 'react-router-dom'
import Dropdown from './sidebar/Dropdown'
import { Images } from '../../config'

const SettingsDropdownItems = [
    {
        link: '/admin/manage/organizations',
        name: 'Správa organizací'
    },
    {
        link: '/admin/manage/users',
        name: 'Správa uživatelů'
    },
    {
        link: '/admin/settings/coupons',
        name: 'Správa slevových kuponů'
    },
    {
        link: '/admin/settings/recommendations',
        name: 'Přehled doporučení'
    }
]

const CampsDropdownItems = [
    {
        link: '/admin/manage/applications',
        name: 'Správa přihlášek'
    },
    {
        link: '/admin/manage/dates',
        name: 'Termíny'
    },
    {
        link: '/admin/manage/locations',
        name: 'Lokality'
    },
    {
        link: '/admin/manage/camps',
        name: 'Tábory'
    }
]

const ToursDropdownItems = [
    {
        link: '/admin/manage/tours/applications',
        name: 'Správa přihlášek'
    },
    {
        link: '/admin/manage/tours/list',
        name: 'Termíny zájezdů'
    },
    {
        link: '/admin/manage/tours/edit/new',
        name: 'Nový zájezd'
    }
]

const MomWorkoutsItems = [
    {
        link: '/admin/manage/workouts/moms/schedule',
        name: 'Rozvrh lekcí'
    },
    {
        link: '/admin/manage/workouts/moms/applications/list',
        name: 'Správa přihlášek'
    },
    {
        link: '/admin/manage/workouts/moms/prepaids/orders',
        name: 'Správa objednávek permanentek'
    },
    {
        link: '/admin/manage/workouts/moms/prepaids/list',
        name: 'Seznam permanentek'
    },
    {
        link: '/admin/manage/workouts/moms/list',
        name: 'Seznam lekcí'
    },
    {
        link: '/admin/manage/locations',
        name: 'Lokality'
    },
    {
        link: '/admin/manage/workouts/moms/edit/new',
        name: 'Nová lekce'
    }
]

const KidsWorkoutsItems = [
    {
        link: '/admin/manage/workouts/kids/schedule',
        name: 'Rozvrh lekcí'
    },
    {
        link: '/admin/manage/workouts/kids/applications/list',
        name: 'Správa přihlášek'
    },
    {
        link: '/admin/manage/workouts/kids/prepaids/orders',
        name: 'Správa objednávek permanentek'
    },
    {
        link: '/admin/manage/workouts/kids/prepaids/list',
        name: 'Seznam permanentek'
    },
    {
        link: '/admin/manage/workouts/kids/list',
        name: 'Seznam lekcí'
    },
    {
        link: '/admin/manage/workouts/kids/categories/list',
        name: 'Seznam kategorií'
    },
    {
        link: '/admin/manage/locations',
        name: 'Lokality'
    },
    {
        link: '/admin/manage/workouts/kids/edit/new',
        name: 'Nová lekce'
    }
]

const HobbyWorkoutsItems = [
    {
        link: '/admin/manage/workouts/hobby/schedule',
        name: 'Rozvrh kroužků'
    },
    {
        link: '/admin/manage/workouts/hobby/applications/list',
        name: 'Správa přihlášek'
    },
    {
        link: '/admin/manage/workouts/hobby/list',
        name: 'Seznam kroužků'
    },
    {
        link: '/admin/manage/locations',
        name: 'Lokality'
    },
    {
        link: '/admin/manage/workouts/hobby/edit/new',
        name: 'Nový koružek'
    }
]


class SidebarContainer extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            dropdown: false
        })
    }
    
    handleDropdown = () => this.setState({ dropdown: !this.state.dropdown })

    render() {

        const { isMobile } = this.props

        return (
            <Sidebar isMobile={isMobile}>
                <SidebarHeader>
                    <img src={Images.logo_c} />
                </SidebarHeader>
                <SidebarNav>
                    <SidebarItem icon="\ec5e" iconColor='#5e72e4'>
                        <NavLink to={`${process.env.PUBLIC_URL}/admin/dashboard`} activeClassName='active'>Nástěnka</NavLink>
                    </SidebarItem>

                    {/*
                    <Dropdown 
                        name='Tábory'
                        items={CampsDropdownItems}
                        icon='\ee45'
                    />
                    */}

                    <Dropdown 
                        name='Zájezdy'
                        items={ToursDropdownItems}
                        icon='\ee42'
                    />

                    <Dropdown 
                        name='Cvičení pro maminky'
                        items={MomWorkoutsItems}
                        icon='\ed02'
                    />

                    <Dropdown 
                        name='Cvičení pro děti'
                        items={KidsWorkoutsItems}
                        icon='\eba1'
                    />

                    <Dropdown 
                        name='Kroužky'
                        items={HobbyWorkoutsItems}
                        icon='\eda0'
                    />

                    <SidebarDivider />

                    <SidebarItem icon='\f005'>
                        <NavLink to={`${process.env.PUBLIC_URL}/admin/manage/vouchers`} activeClassName='active'>Vouchery</NavLink>
                    </SidebarItem>

                    <SidebarItem icon='\eef1'>
                        <NavLink to={`${process.env.PUBLIC_URL}/admin/user/contacts`} activeClassName='active'>Kontakty</NavLink>
                    </SidebarItem>

                    <SidebarDivider />

                    <Dropdown 
                        name='Nastavení'
                        items={SettingsDropdownItems}
                        icon='\efe1'
                    />
                </SidebarNav>
            </Sidebar>
        )
    }

}

export default SidebarContainer