import React, { Component } from 'react'
import { intToBool, toggleLocationState, shortText, getToken } from '../../../api'
import { Table, Icon, Button, Popup } from 'semantic-ui-react'
import _ from 'loadsh'
import moment from 'moment'
import { StateIcon } from '../../../styles'
import { Link } from 'react-router-dom'

class PrepaidsUserTable extends Component {

    constructor(props) {
        super(props)
        this.state = ({})
    }

    render() {

        const { items, column, direction, sort, type } = this.props

        return (
            <div className='admin_table_wrapper'>
                <Table sortable striped className='main-table select_table' unstackable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell
                                sorted={column === 'id_prepaid' ? direction : null}
                                onClick={sort('id_prepaid')}
                                width={1}
                            >
                                #
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'prepaid_name' ? direction : null}
                                onClick={sort('prepaid_name')}
                                width={3}
                                className='mw_300'
                            >
                                Název
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'workout_name' ? direction : null}
                                onClick={sort('workout_name')}
                                width={5}
                                className='mw_300'
                            >
                                Lekce
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'price_per_lecture' ? direction : null}
                                onClick={sort('price_per_lecture')}
                                width={1}
                            >
                                Cena za lekci
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'remaining_lectures' ? direction : null}
                                onClick={sort('remaining_lectures')}
                                width={3}
                            >
                                Zbývající lekce
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'valid_months' ? direction : null}
                                onClick={sort('valid_months')}
                                width={3}
                            >
                                Zbývající platnost
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'paid' ? direction : null}
                                onClick={sort('paid')}
                                width={1}
                            >
                                Zaplaceno
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'active' ? direction : null}
                                onClick={sort('active')}
                                width={1}
                            >
                                Aktivní
                            </Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                    {_.map(items, ({ id_prepaid, workout_name, created, prepaid_name, price_per_lecture, num_of_lectures, remaining_lectures, valid_months, state, active, paid }, index) => (
                        <Table.Row key={id_prepaid}>
                            <Table.Cell>
                                {id_prepaid}
                            </Table.Cell>
                            <Table.Cell>
                                {prepaid_name}
                            </Table.Cell>
                            <Table.Cell>
                                {workout_name}
                            </Table.Cell>
                            <Table.Cell>
                                {price_per_lecture},- Kč
                            </Table.Cell>
                            <Table.Cell>
                                {remaining_lectures}/{num_of_lectures}
                            </Table.Cell>
                            <Table.Cell>
                                <Popup 
                                    trigger={
                                        <span className='bubble'>{-(moment().diff(moment(created).add(valid_months, 'months'), 'months', true)).toFixed(1)} měsíce</span>
                                    }
                                    content={moment(created).add(valid_months, 'months').format('D. M. YYYY')}
                                />
                                
                            </Table.Cell>
                            <Table.Cell className='text-center'>
                                <StateIcon state={paid} />
                            </Table.Cell>
                            <Table.Cell className='text-center'>
                                <StateIcon state={active} />
                            </Table.Cell>
                        </Table.Row>
                    ))}
                    </Table.Body>
                </Table>
            </div>
        )
    }
}

export default PrepaidsUserTable