import React, { Component } from 'react'
import { toggleDateState, shortText } from '../../../api'
import { Table, Button, Popup } from 'semantic-ui-react'
import _ from 'loadsh'
import moment from 'moment'

class ToursTable extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            idToDelete: 2,
            show: props.type === 'homepage' ? false : true,
            items: [],
            baseItems: []
        })
    }

    toggleStateHandler = (id) => {
        toggleDateState(id)
        .then(() => {
            this.props.refresh()
        })
    }

    toggleModal = (id) => {
        this.setState({
            showModal: !this.state.showModal,
            idToDelete: !this.state.showModal ? id : ''
        })
    }

    handleShow = () => {
        this.setState({ 
            show: !this.state.show,
        })
    }

    render() {

        const { items, sort, column, direction } = this.props

        return (
            <div className='relative'>
                <div className={this.state.show ? 'collapsable-table show' : 'collapsable-table hidden'}>
                <Table sortable striped className='embed-table'>
                    <Table.Header>
                        <Table.Row>

                            <Table.HeaderCell
                                sorted={column === 'id_date' ? direction : null}
                                onClick={sort('id_date')}
                            >
                                #
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'camp_name' ? direction : null}
                                onClick={sort('camp_name')}
                                width={4}
                            >
                                Tábor
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'date_from' ? direction : null}
                                onClick={sort('date_from')}
                                width={2}
                            >
                                Termín
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'camp_age' ? direction : null}
                                onClick={sort('camp_age')}
                                width={7}
                            >
                                Popis
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'date_capacity' ? direction : null}
                                onClick={sort('date_capacity')}
                            >
                                Volná místa
                            </Table.HeaderCell>
                            <Table.HeaderCell width={3}></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                <Table.Body>
                {_.map(items, ({ id_tour, tour_name, category, location, id_category, id_location_type, tour_description, date_from, date_to, capacity, signed, is_external, external_link, is_full }) => (
                    <Table.Row key={id_tour} className={is_full && 'full_date'}>
                        <Table.Cell>
                            {id_tour}
                        </Table.Cell>
                        <Table.Cell width='2'>
                            <h4>{tour_name}</h4>
                            <p style={{color: '#777', fontWeight: '400', fontSize: 16 }}>{location} | {category}</p>
                        </Table.Cell>
                        <Table.Cell className='date date_from'>
                            <span className='only-phone display-block'>Termín: </span>{moment(date_from).format("D. M.")} - {moment(date_to).format("D. M. YYYY")}
                        </Table.Cell>
                        <Table.Cell className='date date_to'>
                            <p style={{color: '#777', fontSize: 16, fontWeight: '400'}}>{shortText(tour_description, 180)}</p>
                        </Table.Cell>
                        <Table.Cell className='capacity'>
                            <span className='only-phone display-block'>Volná místa: </span> {(capacity - signed) <= 0 ? '0/' + capacity : (capacity - signed) + '/' + capacity}
                        </Table.Cell>
                        <Table.Cell className='application_button'>
                            {is_external !== 1 ?
                            <Button className="btn" href={`${process.env.PUBLIC_URL}/prihlaska/zajezdy/${id_tour}`} target="_blank">{!is_full ? 'Rezervace' : 'Jako náhradník'}</Button>
                            :
                            <Popup 
                                content={<p style={{color: 'red'}}>Přihlašujete se na víkendový pobyt, které vedou instruktorky naší organizace Sportýna ve spolupráci s cestovní kanceláří Capro. Tuto rezervaci neuvidíte ve své administraci.</p>} 
                                trigger={<Button className="btn" href={external_link} target="_blank">Rezervace</Button>}
                                position='bottom right'
                            />
                            
                            }
                        </Table.Cell>
                    </Table.Row>
                ))}
                </Table.Body>
            </Table>
            {this.props.type === 'homepage' &&
            <div className='table-buttons'>
                <Button onClick={this.handleShow}>{!this.state.show ? 'Ukázat vše' : 'Schovat'}</Button>
            </div>
            }
            </div>
            </div>
        )
    }
}

export default ToursTable