import React from 'react'
import { Form, Button, Message } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

const LoginForm = ({onChange, data, submit, loading, hasError, errorMessage}) => {

    document.title = "Přihlášení do klubu | 1FIT Klub"

    return (
        <div>
            <div className="login_intro">
                <h1>Přihlaste se prosím</h1>
                <p>Nemáte svůj účet? <Link to={`/klub/auth/registrace`}>Zaregistrujte se</Link></p>
            </div>
            <Form loading={loading} onSubmit={submit}>
                <Form.Field>
                    <Form.Input 
                        type="email"
                        name='email'
                        label='E-mail'
                        placeholder='Zadejte e-mail'
                        value={data.email}
                        onChange={onChange}
                        required
                    />
                </Form.Field>
                <Form.Field>
                    <Form.Input 
                        type="password"
                        name='passwd'
                        label='Heslo'
                        placeholder='Zadejte heslo'
                        value={data.passwd}
                        onChange={onChange}
                        required
                    />
                </Form.Field>
                {hasError &&
                <Message negative>
                    {errorMessage}
                </Message>
                }
                <Button primary fluid>Přihlásit se</Button>
            </Form>
            <div className="after_login mt-3">
                <p><Link to={`/klub/auth/zapomenute-heslo`}>Zapomněli jste heslo?</Link></p>
            </div>
        </div>
    )
}

export default LoginForm