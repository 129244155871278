import React, { Component } from 'react'
import { UserId } from '../../../styles'
import { Button, Icon } from 'semantic-ui-react'
import HeaderApplications from '../../containers/club/HeaderApplications'
import ServicesBlocks from '../../containers/applications/ServicesBlocks'
import { Container } from 'reactstrap'
import { getVS } from '../../../api'
import ApplicationsSuspended from '../../containers/applications/ApplicationsSuspended'

class PaymentConfirm extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            yourId: 'Vaše ID'
        })
    }

    componentDidMount() {
        const { confirm } = this.props.match.params
        document.body.classList.add('application')

        if(confirm === 'voucher') {
            this.setState({
                yourId: 'Kód voucheru'
            })
        }
    }

    renderMessage = (code) => {

        const { confirm } = this.props.match.params

        if(code === '0' && confirm !== 'voucher') {
            return 'Platba byla úspěšně provedena!'
        } else if(code === '0' && confirm === 'voucher') {
            return 'Voucher byl úspěšně zaplacen!'
        } else if(code === '5') {
            return 'Chybý povinné pole'
        } else if(code === '14') {
            return 'Duplikátní číslo platby'
        } else if(code === '15') {
            return 'Objekt nenalezen'
        } else if(code === '16') {
            return 'Částka k autorizaci překročila původní částku platby'
        } else if(code === '17') {
            return 'Částka k zaplacení překročila povolenou (autorizovanou) částku'
        } else if(code === '18') {
            return 'Součet vracených částek překročil zaplacenou částku'
        } else if(code === '25') {
            return 'Uživatel není oprávněn k provedení operace'
        } else if(code === '26') {
            return 'Technický problém při spojení s autorizačním centrem'
        } else if(code === '27') {
            return 'Chybný typ platby'
        } else if(code === '28') {
            return 'Zamítnuto v 3D ověření'
        } else if(code === '30') {
            return 'Zamítnuto v autorizačním centru'
        } else if(code === '31') {
            return 'Chybný podpis'
        } else if(code === '32') {
            return 'Expirovaná karta'
        } else if(code === '35') {
            return 'Platnost odkazu této platby již vypršela'
        } else if(code === '38') {
            return 'Nepodporovaná karta'
        } else if(code === '40') {
            return 'Zamítnuto ve Fraud detection system'
        } else if(code === '50') {
            return 'Držitel karty zrušil platbu'
        } else if(code === '83') {
            return 'Operace zrušena vydavatelem'
        } else if(code === '85') {
            return 'Zakázáno na základě pravidel obchodníka'
        } else if(code === '200') {
            return 'Žádost o doplňující informace'
        } else if(code === '300') {
            return 'Podmíněně zamítnuto – vydavatel požaduje SCA'
        } else if(code === '1000') {
            return 'Technický problém'
        }
    }

    render() {

        const { id, type, code, workoutType } = this.props.match.params

        return (
            <div>
                <HeaderApplications hasRoute={true} route={'Platební brána'} backlink={'/prihlaska'} />
                {/*
                <div className='container'>
                    <div className='mt-5 application_message'>
                        <Icon className={type === 'success' ? 'application_icon' : 'application_icon error'} name={type === 'success' ? 'thumbs up' : 'thumbs down'} />
                        <h1>{this.renderMessage(code)}</h1>
                        <p>{code === '14' && 'Tuto platbu jste pravděpodobně již provedl/a. Pokud ne, kontaktujte nás prosím na info@kidsjoy.cz'}</p>
                        <div className='application_success_info mt-5'>
                            <p className='text-center'>{this.state.yourId}</p>
                            <UserId>
                                {getVS(id, workoutType)}
                            </UserId>
                        </div>
                        <div className='row'>
                            <div className='col-sm-12 text-center'>
                                <Button size='massive' color='green' href='https://www.1fit.cz'>Zpět na 1FIT</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='other_services'>
                    <Container>
                        <h2 className='text-center'>Vyberte si z naší nabídky</h2>
                        <ServicesBlocks />
                    </Container>
                </div>
                */}
                <ApplicationsSuspended />
            </div>
        )

    }

}

export default PaymentConfirm