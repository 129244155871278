import React, { Component } from 'react'
import { Header, HeaderRoute } from '../../../styles/applications'
import { Navbar, Collapse, NavbarText, Nav, NavbarBrand } from 'reactstrap'
import { connect } from 'react-redux'
import { Button } from 'semantic-ui-react'
import firebase from 'firebase'
import { withRouter, Link } from 'react-router-dom'
import { Images } from '../../../config'
import { logOutUser } from '../../../actions/userActions'

class HeaderContainer extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            logged: false,
            loading: true,
            userEmail: ''
        })
    }

    logOut = () => {
        firebase.auth().signOut()
        window.location.reload();
        this.props.logOutUser()
    }

    componentDidMount() {
        const { loadingUser, user } = this.props

        if(!loadingUser && user.email !== undefined) {
            this.setState({
                userEmail: user.email,
                logged: true,
                loading: false
            })
        } else if (!loadingUser && user.email === undefined) {
            this.setState({
                logged: false,
                loading: false
            })
        }
    }

    componentDidUpdate(prevProps) {
        const { loadingUser, user } = this.props

        if(prevProps.loadingUser !== loadingUser && !loadingUser) {
            if(user.email !== undefined) {
                this.setState({
                    userEmail: user.email,
                    logged: true,
                    loading: false
                })
            } else if (user.email === undefined) {
                this.setState({
                    logged: false,
                    loading: false
                })
            }
        }
    }

    render() {
        const { route, hasRoute, backlink } = this.props
        return (
            <Header>
                <Navbar light expand="md">
                    <Link to={process.env.PUBLIC_URL + backlink}><div className='navbar-brand'><img src={Images.logo_w} className='app_logo' /></div></Link>
                    {hasRoute && <HeaderRoute>{route}</HeaderRoute>}
                    <Collapse isOpen={true} navbar>
                        <Nav className="ml-auto align-items-center" navbar>
                            <NavbarText className='mr-5'>
                                {this.state.logged && !this.state.loading &&
                                    <>Přihlášen jako: <strong>{this.state.userEmail}</strong></> 
                                }

                                {this.state.loading &&
                                <>
                                    <Link to={`${process.env.PUBLIC_URL}/klub/auth/prihlaseni`}>Přihlásit se</Link>
                                    <Link to={`${process.env.PUBLIC_URL}/klub/auth/registrace`}><Button>Vytvořit účet</Button></Link>
                                </>
                                }

                            </NavbarText>
                            <NavbarText>
                                {!this.props.loadingUser && this.props.user && 
                                    <Button icon='\ef7c' outline onClick={this.logOut}>Odhlásit se</Button>
                                }
                            </NavbarText>
                        </Nav>
                    </Collapse>
                </Navbar>
            </Header>
        )
    }

}

const mapStateToProps = state => ({
    user: state.user.user,
    loadingUser: state.user.loading,
})
  
export default connect(mapStateToProps, { logOutUser })(withRouter(HeaderContainer))