import React from 'react'
import { Form, Input, Button, Dropdown, TextArea } from 'semantic-ui-react'
import { FormButtonGroup } from '../../styles'

const EditCampForm = ({ loading, camp, onChange, onChangeSelect, values, submit }) => {
    return (
        <Form loading={loading} onSubmit={submit}>
            <Form.Field>
                <label>Název táboru</label>
                <Input 
                    type='text'
                    name='camp_name'
                    placeholder='Název táboru'
                    value={camp.camp_name}
                    onChange={onChange}
                />
            </Form.Field>
            <Form.Field>
                <label>Organizace</label>
                <Dropdown 
                    name='id_organization'
                    placeholder='Vyberte možnost'
                    options={values}
                    value={camp.id_organization}
                    onChange={onChangeSelect}
                    selection
                    search
                />
            </Form.Field>
            <Form.Field>
                <label>Popis</label>
                <TextArea
                    name='camp_description'
                    placeholder='Popis'
                    value={camp.camp_description}
                    onChange={onChange}
                />
            </Form.Field>
            <FormButtonGroup>
                <Button primary>Uložit změny</Button>
            </FormButtonGroup>
        </Form>
    )
}

export default EditCampForm