import React, { Component } from 'react'
import { Modal, Button, Icon } from 'semantic-ui-react'
import ChangeLectureSchedule from '../containers/workouts/ChangeLectureSchedule'
import { getCalendar, getCalendarItem, changeLecture, changeKidsWorkout, addLectureToApplication } from '../../api/workouts'
import { getToken } from '../../api'
import moment from 'moment'

class AddNewLectureModal extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            dates: []
        })
    }

    componentDidMount() {
        const { type } = this.props
        const formatDate = moment().format('YYYY-MM-DD')

        if(type !== 'kids') {
            this._getCalendarItems(formatDate, type)
        } else {
            const { ageGroup } = this.props
            this._getCalendarItemsKids(formatDate, type, ageGroup)
        }
        
    }

    _getCalendarItems = (date, type) => {
        const formatDate = moment(date).format('YYYY-MM-DD')

        getCalendar(formatDate, type)
        .then((res) => {

            const newEvents = []
            res.map((item) => {
                if(!item.is_full) {
                    if(moment(item.start).isAfter()) {
                        if(item.can_change !== 0) {
                            const newItem = {
                                title: item.title,
                                start: moment(item.start).toDate(),
                                end: moment(item.end).toDate(),
                                id: item.id,
                                color: item.color,
                                is_full: item.is_full,
                                age_group: type !== 'moms' ? item.age_group : null,
                                scheduleCat: item.cat,
                                description: item.description,
                                id_location: item.id_location,
                                id_workout: item.id_workout,
                                group: item.group,
                                state: item.state
                            }
                            newEvents.push(newItem)
                        }
                    }
                }
            })

            this.setState({
                dates: newEvents
            })
        })
    }

    _getCalendarItemsKids = (date, type, ageGroup) => {
        const formatDate = moment(date).format('YYYY-MM-DD')
        getCalendar(formatDate, type)
        .then((res) => {

            const newEvents = []
            res.map((item) => {
                if(!item.is_full) {
                    if(moment(item.start).isAfter()) {
                        if(item.is_full === false) {
                            const newItem = {
                                title: item.title,
                                start: moment(item.start).toDate(),
                                end: moment(item.end).toDate(),
                                id: item.id,
                                color: item.color,
                                is_full: item.is_full,
                                age_group: type !== 'moms' ? item.age_group : null,
                                scheduleCat: item.cat,
                                description: item.description,
                                id_location: item.id_location,
                                id_workout: item.id_workout,
                                group: item.group,
                                state: item.state
                            }
                            newEvents.push(newItem)
                        }
                        
                    }
                }
            })

            this.setState({
                dates: newEvents
            })
        })
    }

    _getCalendarItem = (id) => {
        const { type } = this.props

        getCalendarItem(id, type)
        .then((res) => {
            if(res.code === 1) {
                this.setState({
                    selectedItem: res.data,
                    itemExist: true
                })
            } else {
                this.setState({
                    selectedItem: {},
                    itemExist: false
                })
            }
        })
    }

    setToChange = (id_date, id_workout) => {

        this.setState({
            loadingChange: true
        })

        const { refresh, setMessage, id_user, id_application, type } = this.props

        getToken().then((token) => {
            const data = {
                token: token,
                id_user: id_user,
                id_date: id_date,
                id_workout: id_workout,
                id_application: id_application
            }

            addLectureToApplication(data, type)
            .then((res) => {
                refresh()
                setMessage(res)

                this.setState({
                    loadingChange: false
                })
            })
        })
    }

    render() {

        const { type } = this.props

        return (
            <Modal size='fullscreen' trigger={<Button>Přidat lekci</Button>} closeIcon>
                <Modal.Header>
                    Vyberte lekci, kterou chcete přidat
                </Modal.Header>
                <Modal.Content>
                    <ChangeLectureSchedule 
                        loadingChange={this.state.loadingChange} 
                        getData={type !== 'kids' ? this._getCalendarItems : this._getCalendarItemsKids} 
                        setToChange={this.setToChange} 
                        dates={this.state.dates} 
                        getItem={this._getCalendarItem} 
                        selectedItem={this.state.selectedItem} 
                        type={type}
                    />
                </Modal.Content>
            </Modal>
        )
    }

}

export default AddNewLectureModal