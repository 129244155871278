import React, { Component } from 'react'
import { intToBool, toggleLocationState, shortText, getToken } from '../../../api'
import { unsetSub } from '../../../api/workouts'
import { Table, Icon, Button, Popup, Modal } from 'semantic-ui-react'
import _ from 'loadsh'
import moment from 'moment'
import { StateIcon } from '../../../styles'
import { Link } from 'react-router-dom'
import { deleteItem } from '../../../api/workouts'
import ChangeLectureModal from '../../modals/ChangeLectureModal'

class DateKidsTable extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            showModal: false,
            loading: false,
            loadingUnset: false
        })
    }

    hideModal = () => this.setState({ showModal: false })
    openModal = () => this.setState({ showModal: true })

    render() {

        const { items, column, direction, sort, type, canChange, isLecturer, isSub } = this.props
        return (
            <div>
                <Table sortable striped className='main-table'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell
                                sorted={column === 'id_application' ? direction : null}
                                onClick={sort('id_application')}
                                width={3}
                            >
                                Jméno
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'workout_name' ? direction : null}
                                onClick={sort('workout_name')}
                                width={3}
                            >
                                Příjmení
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'total_price' ? direction : null}
                                onClick={sort('total_price')}
                                width={6}
                            >
                                Zdravotní omezení
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'paid' ? direction : null}
                                onClick={sort('paid')}
                                width={2}
                            >
                                Věk
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'state' ? direction : null}
                                onClick={sort('state')}
                                width={2}
                            >
                                Status
                            </Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                    {_.map(items, ({ id_application, health_issues, first_name, last_name, state, birthday }, index) => (
                        <Table.Row key={id_application}>
                            <Table.Cell>
                                {first_name}
                            </Table.Cell>
                           
                            <Table.Cell>
                                {last_name}
                            </Table.Cell>

                            <Table.Cell>
                                {health_issues}
                            </Table.Cell>

                            <Table.Cell>
                                {moment().diff(birthday, 'years', true).toFixed(2)}
                            </Table.Cell>

                            <Table.Cell className='text-center'>
                                {state === 1 && <p className={'bubble state-' + state}>Aktivní</p>}
                                {state === 2 && <p className={'bubble state-' + state}>Omluveno</p>}
                                {state === 3 && <p className={'bubble state-' + state}>Nahrazeno</p>}
                            </Table.Cell>

                        </Table.Row>
                    ))}
                    </Table.Body>
                </Table>
            </div>
        )
    }
}

export default DateKidsTable