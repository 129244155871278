import React, { Component } from 'react'
import { unsubscribe } from '../../api'

class UnsubscribeView extends Component {

    constructor() {
        super()
        this.state = ({
            message: 'Načítám'
        })
    }

    componentDidMount() {
        
        const { id, email } = this.props.match.params

        const data = {
            id: id,
            email: email
        }

        unsubscribe(data)
        .then((res) => {
            this.setState({
                message: res.text
            })
        })
        .catch((e) => {
            console.log(e)
        })

    }

    render() {
        return (
            <div className="text-center d-flex align-items-center justify-content-center h-100vh">
                <p>{this.state.message}</p>
            </div>
            
        )
    }

}

export default UnsubscribeView