import React, { Component } from 'react'
import LoginForm from '../../forms/auth/LoginForm'
import SignupForm from '../../forms/auth/SignupForm'
import { Row, Col } from 'reactstrap'
import { createClubUser } from '../../../api/club'
import { connect } from 'react-redux'
import { setMessage } from '../../../actions/messageActions'
import { saveClubUser, saveUser } from '../../../actions/userActions'
import firebase from 'firebase'
import { getToken } from '../../../api'
import { Images } from '../../../config'
import ResetPasswordForm from '../../forms/auth/ResetPasswordForm'

class ClubSignUp extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            loginForm: true,
            signupForm: false,
            loading: false,
            login: {
                email: '',
                passwd: '',
            },
            signup: {
                first_name: '',
                last_name: '',
                email: '',
                passwd: '',
                passwd_check: '',
                gdpr: false,
                newsletter: false,
                terms: false
            },
            errorsSignup: {
                passwd_check: ''
            },
        })
    }

    componentDidMount() {

        const { email } = this.props.match.params

        this.setState({
            login: {
                ...this.state.login,
                email: email
            }
        })

        firebase.auth().onAuthStateChanged((user) => {
            if(user) {
                this.props.history.push(`${process.env.PUBLIC_URL}/`)
            }
        })
    }

    onChangeLogin = (e) => this.setState({
        login: {
            ...this.state.login,
            [e.target.name]: e.target.value
        }
    })

    submitLogin = () => {

        const { login } = this.state
        const { code, email } = this.props.match.params

        this.setState({
            loading: true
        })

        firebase.auth().confirmPasswordReset(code, login.passwd)
        .then(() => {
            this.props.setMessage({type: 'success', text: 'Heslo bylo nastaveno, můžete se přihlásit'})
            this.props.history.push('/klub/auth/prihlaseni')
            this.setState({
                loading: false
            })
        })
        .catch((err) => {
            var message = ''

            if(err.code === 'auth/invalid-action-code') {
                message = this.expiredMessage()
            } else if(err.code === 'auth/wrong-password') {
                message = 'Zadané heslo není správné'
            } else if(err.code === 'auth/user-disabled') {
                message = 'Tento účet byl pozastaven'
            }

            this.setState({
                errorMessage: message,
                hasError: true,
                loading: false
            })
        })
            
    }

    expiredMessage = () => {
        const { code, email } = this.props.match.params
        return (
            <p>Platnost odkazu již vypršela. <a href={"https://app.1fit.cz/api/www/workouts/reset-password-link/" + email}>Klikněte sem pro nový odkaz</a></p>
        )
    }

    render() {

        const { type } = this.props.match.params

        return (
            <>
            <Row className='d-flex align-items-center justify-content-center auth_row'>
                <Col lg={type === 'prihlaseni' ? 3 : 4} md={6} className={`login_box login_box_${type} p-5`}>
                    <img className='login_logo' src={Images.logo_c} />
                    <ResetPasswordForm loading={this.state.loading} hasError={this.state.hasError} errorMessage={this.state.errorMessage} onChange={this.onChangeLogin} data={this.state.login} submit={this.submitLogin} />
                </Col>
            </Row>
                
            </>
        )
    }

}

const mapStateToProps = state => ({
    loadingUser: state.user.loading,
    user: state.user.user
})
  
export default connect(mapStateToProps, { setMessage, saveClubUser, saveUser })(ClubSignUp)