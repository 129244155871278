import React, { Component } from 'react'
import { intToBool, toggleCampState, shortText, getToken, deleteCoupon } from '../../api'
import { AreYouSureModal } from '../modals'
import { Link } from 'react-router-dom'
import { Table, Button, Icon, Modal } from 'semantic-ui-react'
import _ from 'loadsh'
import { setMessage } from '../../actions/messageActions'
import { connect } from 'react-redux'

class CouponsTable extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            showModal: false,
            idToDelete: '',
            modalDelete: false,
            loadingDelete: false
        })
    }

    toggleStateHandler = (id) => {
        toggleCampState(id)
        .then(() => {
            this.props.refresh()
        })
    }

    toggleModal = (id) => {
        this.setState({
            showModal: !this.state.showModal,
            idToDelete: !this.state.showModal ? id : ''
        })
    }

    deleteCouponHandler = (id) => {

        this.setState({
            loadingDelete: true
        })

        getToken().then((token) => {
            const data = {
                token: token,
                id: id
            }

            deleteCoupon(data)
            .then((res) => {

                this.setState({
                    idToDelete: '',
                    loadingDelete: false
                })

                this.props.refresh()
                this.closeModal()
                this.props.setMessage(res)
            })
            
        })
    }

    openModal = (id) => this.setState({ modalDelete: true, idToDelete: id })
    closeModal = () => this.setState({ modalDelete: false, idToDelete: '' })

    _renderModalDelete = () => {
        return (
            <Modal open={this.state.modalDelete} onClose={this.closeModal}>
                <Modal.Header>Smazat slevový kupon?</Modal.Header>
                <Modal.Content image>
                    <Modal.Description>
                        <p>Opravdu chcete smazat tento slevový kupon? Společně s ním zmizí i z přihlášek, kde byl použit.</p>
                        <p><strong>Tuto akci nelze vrátit</strong></p>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='black' onClick={this.handleCloseModalPayment}>
                        Zpět
                    </Button>
                    <Button
                        positive
                        icon='checkmark'
                        labelPosition='right'
                        content="Ano, chci změnit"
                        loading={this.state.loadingDelete}
                        onClick={() => this.deleteCouponHandler(this.state.idToDelete)}
                    />
                </Modal.Actions>
            </Modal>
        )
    }

    render() {

        const { items, sort, column, direction } = this.props

        return (
            <div>
                <Table sortable striped className='main-table'>
                    <Table.Header>
                        <Table.Row>

                            <Table.HeaderCell
                                width={1}
                                sorted={column === 'id_coupon' ? direction : null}
                                onClick={sort('id_coupon')}
                            >
                                #
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'coupon_text' ? direction : null}
                                onClick={sort('coupon_text')}
                            >
                                Kód kuponu
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'coupon_value' ? direction : null}
                                onClick={sort('coupon_value')}
                            >
                                Hodnota kuponu
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'num_of_times' ? direction : null}
                                onClick={sort('num_of_times')}
                            >
                                Počet použití
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={column === 'num_of_times' ? direction : null}
                                onClick={sort('num_of_times')}
                            >
                                Procentuálně
                            </Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                <Table.Body>
                {_.map(items, ({ id_coupon, coupon_text, coupon_value, num_of_times, percentage }) => (
                    <Table.Row key={id_coupon}>
                        <Table.Cell>
                            {id_coupon}
                        </Table.Cell>
                        <Table.Cell>
                            {coupon_text}
                        </Table.Cell>
                        <Table.Cell>
                            {coupon_value + ',- Kč'}
                        </Table.Cell>
                        <Table.Cell>
                            {num_of_times}
                        </Table.Cell>
                        <Table.Cell>
                            {percentage + ' %'}
                        </Table.Cell>
                        <Table.Cell>
                            <Button icon className='ghost' onClick={() => this.openModal(id_coupon)} ><Icon name='trash'/></Button>
                        </Table.Cell>
                    </Table.Row>
                ))}
                </Table.Body>
            </Table>
            <this._renderModalDelete />
            </div>
        )
    }
}

const mapStateToProps = state => ({})
  
export default connect(mapStateToProps, { setMessage })(CouponsTable)