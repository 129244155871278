import React, { Component } from 'react'
import { intToBool, shortText, toggleDateState, getToken, unsetSub } from '../../api'
import { AreYouSureModal } from '../modals'
import { Link } from 'react-router-dom'
import { Table, Button, Icon } from 'semantic-ui-react'
import _ from 'loadsh'
import moment from 'moment'
import { connect } from 'react-redux'
import { setMessage } from '../../actions/messageActions'

class DateApplicationsTable extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            showModal: false,
            idToDelete: 2
        })
    }

    toggleStateHandler = (id) => {
        toggleDateState(id)
        .then(() => {
            this.props.refresh()
        })
    }

    toggleModal = (id) => {
        this.setState({
            showModal: !this.state.showModal,
            idToDelete: !this.state.showModal ? id : ''
        })
    }

    unsetSubHandler = (id_date, id_kids) => {

        getToken().then((token) => {
            const data = {
                token: token,
                id_kids: id_kids,
                id_date: Number(id_date)
            }

            unsetSub(data)
            .then((res) => {
                this.props.setMessage(res)
                this.props.refresh()
            })
        })
    }

    render() {

        const { items, sort, column, direction, date, sub, id_date, userAdmin } = this.props

        return (
            <div>
                <Table sortable striped className='main-table'>
                    <Table.Header>
                        <Table.Row>

                            <Table.HeaderCell
                                sorted={column === 'id_kids' ? direction : null}
                                onClick={sort('id_kids')}
                            >
                                #
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'kids_name' ? direction : null}
                                onClick={sort('kids_name')}
                            >
                                Jméno
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'kids_birthday' ? direction : null}
                                onClick={sort('kids_birthday')}
                            >
                                Věk
                            </Table.HeaderCell>

                            {userAdmin !== 0 &&
                            <Table.HeaderCell
                                sorted={column === 'parent_email' ? direction : null}
                                onClick={sort('parent_email')}
                            >
                                Email
                            </Table.HeaderCell>
                            }

                            <Table.HeaderCell
                                sorted={column === 'parent_email' ? direction : null}
                                onClick={sort('parent_email')}
                            >
                                Telefon
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'paid' ? direction : null}
                                onClick={sort('paid')}
                            >
                                Zaplaceno
                            </Table.HeaderCell>

                            <Table.HeaderCell></Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>
                <Table.Body>
                {_.map(items, ({ id_kids, kids_name, kids_birthday, parent_phone, paid, id_link, parent_email }) => (
                    <Table.Row key={id_kids}>
                        <Table.Cell>
                            {id_link}
                        </Table.Cell>
                        <Table.Cell>
                            {kids_name}
                        </Table.Cell>
                        <Table.Cell>
                            {Math.abs(moment(kids_birthday).diff(moment(date), 'years', true)).toFixed(1)}
                        </Table.Cell>
                        {userAdmin !== 0 &&
                        <Table.Cell>
                            {parent_email}
                        </Table.Cell>
                        }
                        <Table.Cell>
                            {parent_phone}
                        </Table.Cell>
                        <Table.Cell>
                        {intToBool(paid) ? <i class="icofont-check-circled icon-paid"></i> : <i class="icofont-close-circled icon-paid"></i>}
                        </Table.Cell>
                        <Table.Cell>
                            <Button icon onClick={() => this.toggleModal(id_kids)}><Icon name='trash' /></Button>
                            <Button icon href={`${process.env.PUBLIC_URL}/admin/manage/applications/detail/` + id_link}><Icon name='eye' /></Button>
                            {sub && <Button icon onClick={() => this.unsetSubHandler(id_date, id_kids)}><Icon name='angle up' /></Button>}
                        </Table.Cell>
                    </Table.Row>
                ))}
                </Table.Body>
            </Table>

            <AreYouSureModal show={this.state.showModal} toggle={this.toggleModal} id={this.state.idToDelete} refresh={this.props.refresh}/>

            </div>
        )
    }
}

const mapStateToProps = state => ({})
  
export default connect(mapStateToProps, { setMessage })(DateApplicationsTable)