import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import { Card, CardHeader, CardBody } from '../../../styles'
import CreateCategoryForm from '../../forms/kids-workouts/CreateCategoryForm'
import LectureInfoForm from '../../forms/mom-workouts/LectureInfoForm'
import { connect } from 'react-redux'
import { setMessage } from '../../../actions/messageActions'
import { getToken, getLecturersOpts } from '../../../api'
import { getInfo, getCategoryAdmin } from '../../../api/workouts'
import moment from 'moment'
import { createWorkout, updateWorkout, getWorkoutItem, createCategory } from '../../../api/workouts'

class EditCategoryView extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            loading: true,
            formData: {},
            loadingOpts: true,
            loadingInfo: true,
            lecturers: []
        })
    }

    componentDidMount() {
        const { id, type } = this.props.match.params
        const { loadingUser } = this.props

        if(id !== 'new') {
            if(!loadingUser) {
                this._getCategoryItem()
            }
        } else {
            this.setState({
                loading: false
            })
        }
    }

    componentDidUpdate(prevProps) {

        const { id } = this.props.match.params
        const { loadingUser } = this.props

        if(loadingUser !== prevProps.loadingUser && !loadingUser && id !== 'new') {
            this._getCategoryItem()
        }
    }

    _getCategoryItem = () => {

        const { user } = this.props
        const { id, type } = this.props.match.params

        getToken().then((token) => {
            const data = {
                token: token,
                id_org: user.id_organization,
                id_category: id
            }

            getCategoryAdmin(data, type)
            .then((res) => {
                if(res.code === 1) {
                    this.setState({
                        formData: res.data,
                        isOwner: true,
                        loading: false
                    })

                    this._getInfo()

                } else {
                    this.setState({
                        isOwner: false,
                        formData: {},
                        loading: false
                    })
                }
            })
        })

    }

    _getInfo = () => {

        const { user } = this.props
        const { id, type } = this.props.match.params

        this.setState({
            loadingInfo: true
        })

        getToken().then((token) => {
            const data = {
                token: token,
                id_org: user.id_organization,
                id_item: id
            }

            getInfo(data, type)
            .then((res) => {
                if(res.code === 1) {
                    this.setState({
                        info: res.data,
                        loadingInfo: false
                    })
                }
            })
        })
    }
    
    submit = (data) => {

        const { id, type } = this.props.match.params

        this.setState({
            loading: true
        })

        const { user, setMessage } = this.props

        getToken().then((token) => {
            var send = {
                token: token,
                category_name: data.category_name,
                id_organization: user.id_organization,
                state: data.state,
                created_by: user.id_user,
                updated_by: user.id_user
            }

            if(id === 'new') {

                createCategory(send)
                .then((res) => {
                    this.setState({
                        loading: false
                    })
                    setMessage(res)
                    this.props.history.push(`${process.env.PUBLIC_URL}/admin/manage/workouts/${type}/categories/edit/${res.link}`)
                })
            } else {
                updateWorkout(send, id, type)
                .then((res) => {
                    this.setState({
                        loading: false
                    })
                    setMessage(res)
                    this._getCategoryItem()
                })
            }
        })
        
    }

    render() {

        const { id } = this.props.match.params

        return (
            <Row>
                <Col sm={8}>
                    <Card>
                        <CardHeader>
                            Vytvořit novou kategorii
                        </CardHeader>
                        <CardBody>
                            <CreateCategoryForm isNew={id === 'new' ? true : false} loading={this.state.loading} submit={this.submit} initial={this.state.formData} />
                        </CardBody>
                    </Card>
                </Col>
                <Col sm={4}>
                    {id !== 'new' &&
                    <Card>
                        <CardHeader>
                            Informace o záznamu
                        </CardHeader>
                        <CardBody>
                            <LectureInfoForm data={this.state.info} loading={this.state.loadingInfo} />
                        </CardBody>
                    </Card>
                    }
                </Col>
            </Row>
        )
    }

}

const mapStateToProps = state => ({
    user: state.user.user,
    loadingUser: state.user.loading
})
  
export default connect(mapStateToProps, { setMessage })(EditCategoryView)