import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import TourReservations from '../../containers/club/TourReservations'
import MyEvents from '../../containers/club/MyEvents'
import { getToken } from '../../../api';

class ClubMyEventsList extends Component {

    _isMounted = false;

    constructor(props) {
        super(props)
        this.state = ({
            loadingToken: true,
            token: ''
        })
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({
            isMounted: this._isMounted
        })

        getToken().then((token) => {
            this.setState({
                token: token,
                loadingToken: false
            })
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.setState({
            isMounted: this._isMounted
        })
    }

    render() {

        const { token, loadingToken } = this.state

        return (
            <Row>
                {this.state.isMounted && !loadingToken && 
                <Col sm={12} className='mb-5'>
                    <TourReservations active={true} />
                </Col>
                }
                {this.state.isMounted && !loadingToken &&
                <Col sm={12} className='mb-5'>
                    <MyEvents type='moms' active={true} canChange={true} token={token} />
                </Col>
                }
                {this.state.isMounted && !loadingToken &&
                <Col sm={12} className='mb-5'>
                    <MyEvents type='kids' active={true} canChange={true} token={token} />
                </Col>
                }
                {this.state.isMounted && !loadingToken &&
                <Col sm={12} className='mb-5'>
                    <MyEvents type='hobby' active={true} canChange={false} token={token} />
                </Col>
                }
            </Row>
        )
    }

}

export default ClubMyEventsList