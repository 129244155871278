import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import UserPrepaids from '../../containers/club/UserPrepaids'

class ClubMyPrepaidsView extends Component {

    _isMounted = false;

    constructor(props) {
        super(props)
        this.state = ({})
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({
            isMounted: this._isMounted
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.setState({
            isMounted: this._isMounted
        })
    }

    render() {
        return (
            <Row>
                {this.state.isMounted &&
                <Col sm={12} className='mb-5'>
                    <UserPrepaids type='moms' active={true} canChange={true} />
                </Col>
                }
            </Row>
        )
    }

}

export default ClubMyPrepaidsView