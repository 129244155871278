import React, { Component } from 'react'
import { Icon, Step, Button } from 'semantic-ui-react'
import { ParentForm, KidsForm, TermsForm, ConfirmForm, VoucherSelectForm, VoucherConfirmForm } from '../forms'
import { StepCard, StuckContainer, UserId } from '../../styles'
import { createApplication, requestPaymentLink } from '../../api'
import { EmbedNavigation } from '../containers'
import ReactGA from 'react-ga'
import firebase from 'firebase'
import { Row, Col, Container } from 'reactstrap'

class VouchersStoreView extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            paymentLink: '',
            loading: false,
            logged: false,
            selectedVoucher: [{
                id_voucher_type: 'not-selected',
                price: 0,
                value: 0
            }]
        })
    }

    componentDidMount() {

        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
              this.setState({
                logged: true
              })
            } else {
                this.setState({
                    logged: false
                })
            }
        });

        const { utm } = this.props.match.params

        document.body.classList.add(utm)
        document.body.classList.add('body-application')

        ReactGA.initialize('UA-155260757-1', {
            debug: true,
            cookieDomain: 'kidsjoy.cz'
        });

        ReactGA.pageview('/vouchers');
    }

    submitHandler = () => {

        const { utm } = this.props.match.params

        this.setState({
            loading: true
        })

        console.log(this.state.data)

        createApplication(this.state.data)
        .then((res) => {
            this.setState({
                res: {
                    type: res.type,
                    message: res.message,
                    id: res.id
                },
                loading: false
            })

            const data = {
                order_num: res.id,
                amount: this.state.price,
                utm: utm
            }

            requestPaymentLink(data)
            .then((res) => {
                this.setState({
                    paymentLink: res.link
                })
            })

            ReactGA.event({
                category: 'User',
                action: 'Úspěšné přihlášení'
            });
        })
    }

    _renderMessage = ({type, message, id}) => {

        const { utm } = this.props.match.params

        return (
            <div className='push_from_nav application application_message'>
                <Icon className='application_icon' name={type === 'success' ? 'thumbs up' : 'thumbs down'} />
                <h1>{message}</h1>
                <div className='application_success_info'>
                    <p className='text-center'>Vaše ID</p>
                    <UserId>
                        {id}
                    </UserId>

                    <p>Věnujte prosím pozornost informacím, které dorazí na Vámi uvedený email v přihlášce.</p>
                    <p>Platbu můžete uhradit platební bránou nebo převodem na účet.</p>
                    <p>Platbu je nutné uhradit nejpozději do 7 pracovních dnů a to převodem na účet (viz níže)</p>

                    <div className='row card-payment'>
                        <div className='col-sm-6'>
                            <h2>{this.state.price},- Kč</h2>
                        </div>
                        <div className='col-sm-6 text-center'>
                            <Button size='massive' color='green' href={this.state.paymentLink}>Zaplatit kartou</Button>
                        </div>
                    </div>
                    
                    <div className='row banktransfer-payment'>
                        <div className='col-sm-6'>
                            <h3>Platba převodem</h3>
                            <p>Cena: <strong>{this.state.price},- Kč</strong></p>
                            <p>Platbu prosím zašlete na číslo účtu: <strong>5669472379/0800</strong></p>
                            <p>Variabilní symbol: <strong>{id}</strong></p>
                        </div>
                        <div className='col-sm-6 qr'>
                            <img src={'https://api.paylibo.com/paylibo/generator/czech/image?accountNumber=5669472379&bankCode=0800&amount='+this.state.price+'&currency=CZK&vs='+ id} className='qr_payment img-fluid' />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <Button href={utm === 'kidsjoy' ? 'https://www.kidsjoy.cz' : 'https://www.sportjoy.cz'} target='_parent'>Zpět na {utm === 'kidsjoy' ? 'Kidsjoy' : 'Sportjoy'}</Button>
                        </div>
                    </div>
                    
                </div>
            </div>
        )
    }

    selectVoucherHandler = (data) => {
        this.setState({
            selectedVoucher: data
        })
    }

    render() {

        const { utm } = this.props.match.params
        
        return (
            <div className='embed' id='iFrameSizer'>
                <EmbedNavigation utm={utm} type='voucher' />
                <Container className='container-embed'>
                    <Row>
                        <Col sm={8}>
                            <VoucherSelectForm selectVoucher={this.selectVoucherHandler} />
                        </Col>
                        <Col sm={4}>
                            <VoucherConfirmForm selectedVoucher={this.state.selectedVoucher} />
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }

}

export default VouchersStoreView