import React, { useState } from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
} from 'reactstrap'

import { NavLink } from 'react-router-dom'
import NavbarMobileDropdown from './NavbarMobileDropdown';

const SettingsDropdownItems = [
    {
        link: '/admin/manage/organizations',
        name: 'Správa organizací'
    },
    {
        link: '/admin/manage/users',
        name: 'Správa uživatelů'
    },
    {
        link: '/admin/settings/coupons',
        name: 'Správa slevových kuponů'
    },
    {
        link: '/admin/settings/recommendations',
        name: 'Přehled doporučení'
    }
]

const CampsDropdownItems = [
    {
        link: '/admin/manage/applications',
        name: 'Správa přihlášek'
    },
    {
        link: '/admin/manage/dates',
        name: 'Termíny'
    },
    {
        link: '/admin/manage/locations',
        name: 'Lokality'
    },
    {
        link: '/admin/manage/camps',
        name: 'Tábory'
    }
]

const ToursDropdownItems = [
    {
        link: '/admin/manage/tours/applications',
        name: 'Správa přihlášek'
    },
    {
        link: '/admin/manage/tours/list',
        name: 'Termíny zájezdů'
    },
    {
        link: '/admin/manage/tours/edit/new',
        name: 'Nový zájezd'
    }
]

const MomWorkoutsItems = [
    {
        link: '/admin/manage/workouts/moms/schedule',
        name: 'Rozvrh lekcí'
    },
    {
        link: '/admin/manage/workouts/moms/applications/list',
        name: 'Správa přihlášek'
    },
    {
        link: '/admin/manage/workouts/moms/prepaids/orders',
        name: 'Správa objednávek permanentek'
    },
    {
        link: '/admin/manage/workouts/moms/prepaids/list',
        name: 'Seznam permanentek'
    },
    {
        link: '/admin/manage/workouts/moms/list',
        name: 'Seznam lekcí'
    },
    {
        link: '/admin/manage/locations',
        name: 'Lokality'
    },
    {
        link: '/admin/manage/workouts/moms/edit/new',
        name: 'Nová lekce'
    }
]

const KidsWorkoutsItems = [
    {
        link: '/admin/manage/workouts/kids/schedule',
        name: 'Rozvrh lekcí'
    },
    {
        link: '/admin/manage/workouts/kids/applications/list',
        name: 'Správa přihlášek'
    },
    {
        link: '/admin/manage/workouts/kids/prepaids/orders',
        name: 'Správa objednávek permanentek'
    },
    {
        link: '/admin/manage/workouts/kids/prepaids/list',
        name: 'Seznam permanentek'
    },
    {
        link: '/admin/manage/workouts/kids/list',
        name: 'Seznam lekcí'
    },
    {
        link: '/admin/manage/workouts/kids/categories/list',
        name: 'Seznam kategorií'
    },
    {
        link: '/admin/manage/locations',
        name: 'Lokality'
    },
    {
        link: '/admin/manage/workouts/kids/edit/new',
        name: 'Nová lekce'
    }
]

const HobbyWorkoutsItems = [
    {
        link: '/admin/manage/workouts/hobby/schedule',
        name: 'Rozvrh kroužků'
    },
    {
        link: '/admin/manage/workouts/hobby/applications/list',
        name: 'Správa přihlášek'
    },
    {
        link: '/admin/manage/workouts/hobby/list',
        name: 'Seznam kroužků'
    },
    {
        link: '/admin/manage/locations',
        name: 'Lokality'
    },
    {
        link: '/admin/manage/workouts/hobby/edit/new',
        name: 'Nový koružek'
    }
]

const NavbarMobile = () => {

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    return (
        <Navbar color="light" fixed="top" light expand="md" className='mobile_navbar'>
            <NavbarBrand href="/" style={{fontWeight: '900'}}>1FIT | Administrace</NavbarBrand>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
                <NavItem>
                    <NavLink to={`${process.env.PUBLIC_URL}/admin/dashboard`} activeClassName='active'>Nástěnka</NavLink>
                </NavItem>
                <NavbarMobileDropdown title='Cvičení pro maminky' items={MomWorkoutsItems} />
                <NavbarMobileDropdown title='Cvičení pro děti' items={KidsWorkoutsItems} />
                <NavbarMobileDropdown title='Kroužky pro děti' items={HobbyWorkoutsItems} />
                <NavbarMobileDropdown title='Zájezdy' items={ToursDropdownItems} />
                <NavbarMobileDropdown title='Nastavení' items={SettingsDropdownItems} />

            </Nav>
            </Collapse>
        </Navbar>
    )
}

export default NavbarMobile