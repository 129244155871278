import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import { Card, CardHeader, CardBody } from '../../../styles'
import CreateTourForm from '../../forms/tours/CreateTourForm'
import { createTour, getTourItem, updateTour } from '../../../api/tours'
import { connect } from 'react-redux'
import { setMessage } from '../../../actions/messageActions'
import { getToken } from '../../../api'
import moment from 'moment'

class EditTourView extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            loading: true,
            formData: {}
        })
    }

    componentDidMount() {
        const { id } = this.props.match.params
        const { loadingUser } = this.props

        if(id !== 'new') {
            if(!loadingUser) {
                this._getTourItem()
            }
        } else {
            this.setState({
                loading: false
            })
        }
    }

    componentDidUpdate(prevProps) {

        const { id } = this.props.match.params
        const { loadingUser } = this.props

        if(loadingUser !== prevProps.loadingUser && !loadingUser && id !== 'new') {
            this._getTourItem()
        }
    }

    _getTourItem = () => {

        const { user } = this.props
        const { id } = this.props.match.params

        getToken().then((token) => {
            const data = {
                token: token,
                id_org: user.id_organization,
                id_item: id
            }

            getTourItem(data)
            .then((res) => {
                if(res.code === 1) {
                    this.setState({
                        formData: res.data,
                        isOwner: true,
                        loading: false
                    })
                } else {
                    this.setState({
                        isOwner: false,
                        formData: {},
                        loading: false
                    })
                }
            })
        })

    }
    
    submit = (data) => {

        const { id } = this.props.match.params

        this.setState({
            loading: true
        })

        const { user, setMessage } = this.props

        getToken().then((token) => {
            var send = {
                token: token,
                tour_name: data.tour_name,
                date_from: moment(data.date_from).format('YYYY-MM-DD'),
                date_to: moment(data.date_to).format('YYYY-MM-DD'),
                tour_description: data.tour_description,
                tour_payment_deposit_kids: data.tour_payment_deposit_kids,
                tour_payment_final_kids: data.tour_payment_final_kids,
                tour_payment_deposit_adults: data.tour_payment_deposit_adults,
                tour_payment_final_adults: data.tour_payment_final_adults,
                external_link: data.external_link,
                is_external: data.is_external,
                id_organization: user.id_organization,
                capacity: data.capacity,
                state: data.state,
                id_category: data.id_category,
                id_location_type: data.id_location_type
            }


            if(id === 'new') {
                createTour(send)
                .then((res) => {
                    this.setState({
                        loading: false
                    })
                    setMessage(res)
                    this.props.history.push(`${process.env.PUBLIC_URL}/admin/manage/tours/edit/${res.link}`)
                })
            } else {
                send = {
                    ...send,
                    id_tour: id
                }

                updateTour(send)
                .then((res) => {
                    this.setState({
                        loading: false
                    })
                    setMessage(res)
                    this._getTourItem()
                })
            }
    
            
        })

        
    }

    render() {
        return (
            <Row>
                <Col sm={8}>
                    <Card>
                        <CardHeader>
                            Vytvořit nový zájezd
                        </CardHeader>
                        <CardBody>
                            <CreateTourForm loading={this.state.loading} submit={this.submit} initial={this.state.formData} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }

}

const mapStateToProps = state => ({
    user: state.user.user,
    loadingUser: state.user.loading
})
  
export default connect(mapStateToProps, { setMessage })(EditTourView)