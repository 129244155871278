import React, { useState, useEffect } from 'react'
import { Form, Button } from 'semantic-ui-react'
import { Row, Col } from 'reactstrap'
import { useDispatch } from "react-redux";
import { updateUser } from '../../../api/users';
import { getToken } from '../../../api';
import { jsonToFormData } from '../../../tools';
import { setMessage } from '../../../actions/messageActions'

const ApplicationUserForm = ({ user, token }) => {

    const dispatch = useDispatch();
    const [data, setData] = useState({});
    const [loadingData, setLoadingData] = useState(true)

    useEffect(() => {
        setData(user)
        setLoadingData(false)
    }, [user])

    const onChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    function submit() {
        setLoadingData(true)
        const newData = jsonToFormData(data)

        updateUser(newData, token)
        .then((res) => {
            dispatch(setMessage(res.data))
            setLoadingData(false)
        })
        .catch((err) => {
            setLoadingData(false)
            dispatch(setMessage({type: 'error', text: 'Něco se pokazilo, zkuste to prosím později'}))
        })
        
    }

    return (
        <Form loading={loadingData} onSubmit={submit}>
            <Row className='mb-3'>
                <Col sm={6}>
                    <Form.Field>
                        <Form.Input 
                            name='first_name'
                            label='Křestní jméno'
                            value={data.first_name}
                            onChange={onChange}
                        />
                    </Form.Field>
                </Col>
                <Col sm={6}>
                    <Form.Field>
                        <Form.Input 
                            name='last_name'
                            label='Příjmení'
                            value={data.last_name}
                            onChange={onChange}
                        />
                    </Form.Field>
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col sm={6}>
                    <Form.Field>
                        <Form.Input 
                            name='email'
                            label='Email'
                            value={data.email}
                            onChange={onChange}
                        />
                    </Form.Field>
                </Col>
                <Col sm={6}>
                    <Form.Field>
                        <Form.Input 
                            name='phone'
                            label='Telefon'
                            value={data.phone}
                            onChange={onChange}
                        />
                    </Form.Field>
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col sm={4}>
                    <Form.Field>
                        <Form.Input 
                            name='address'
                            label='Adresa'
                            value={data.address}
                            onChange={onChange}
                        />
                    </Form.Field>
                </Col>
                <Col sm={4}>
                    <Form.Field>
                        <Form.Input 
                            name='city'
                            label='Město'
                            value={data.city}
                            onChange={onChange}
                        />
                    </Form.Field>
                </Col>
                <Col sm={4}>
                    <Form.Field>
                        <Form.Input 
                            name='post_code'
                            label='PSČ'
                            value={data.post_code}
                            onChange={onChange}
                        />
                    </Form.Field>
                </Col>
            </Row>
            <Row className='d-flex justify-content-center'>
                <Button primary>Uložit změny</Button>
            </Row>
        </Form>
    )
}

export default ApplicationUserForm