import React, { Component } from 'react'
import { getRecommendations } from '../../api'
import { Card, CardControls, CardHeader } from '../../styles'

class RecommendationsView extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            recommendations: []
        })
    }

    componentDidMount() {
        getRecommendations()
        .then((data) => {
            this.setState({
                recommendations: data
            })
        })
    }

    render() {
        return (
            <div>
                <Card>
                    <CardHeader>
                        <div className='row'>
                            <div className='col-sm-9 text-left'>
                                <h2>Přehled doporučení</h2>
                            </div>
                        </div>
                    </CardHeader>
                </Card>
            </div>
        )
    }

}

export default RecommendationsView