import React from 'react'
import { ListItem } from '../../../styles/tours'
import { Row, Col } from 'reactstrap'
import moment from 'moment'
import { shortText, formatMoney } from '../../../api'

var total_price = 0

const TourListItem = ({ item }) => {

    total_price = Number(item.tour_payment_deposit_adults) + Number(item.tour_payment_final_adults)

    return (
        <ListItem>
            <Row>
                <Col sm={4}>
                    <p className='small'>Název</p>
                    <h2>{item.tour_name}</h2>
                </Col>
                <Col sm={4}>
                    <p className='small'>Termín</p>
                    <p>{moment(item.date_from).format('DD.MM.YYYY')} - {moment(item.date_to).format('DD.MM.YYYY')}</p>
                </Col>
                <Col sm={2}>
                    <p className='small'>Cena za osobu</p>
                    <p>{formatMoney(total_price, ' ', '.')} Kč</p>
                </Col>
                <Col sm={1}>
                    <p className='small'>Kapacita</p>
                    <p>{item.capacity}</p>
                </Col>
            </Row>
        </ListItem>
    )
}

export default TourListItem