import React, { useState } from 'react'
import { SidebarItem } from '../../../styles'
import { NavLink } from 'react-router-dom'

const Dropdown = ({ items, name, icon }) => {
    const [open, openDropdown] = useState(false);
    const toggle = () => openDropdown(!open);

    return (
        <SidebarItem icon={icon} dropdown>
            <span className='divider' className={ open ? 'divider active' : 'divider inactive'} onClick={toggle}>{name}</span>
            <ul className={ open ? 'dropdown active' : 'dropdown inactive'}>
                {
                    items.map((item, index) => {
                        return <li key={index}><NavLink to={`${process.env.PUBLIC_URL + item.link}`} activeClassName='active'>{item.name}</NavLink></li>
                    })
                }
            </ul>
        </SidebarItem>
        
    )
}

export default Dropdown