import React, { Component } from 'react'
import { Header, HeaderRoute } from '../../../styles'
import { Navbar, Collapse, NavbarText, Nav, NavbarBrand } from 'reactstrap'
import { connect } from 'react-redux'
import { Button } from 'semantic-ui-react'
import firebase from 'firebase'
import { withRouter } from 'react-router-dom'
import { logOutUser } from '../../../actions/userActions'

class HeaderContainer extends Component {

    logOut = () => {
        firebase.auth().signOut()
        this.props.logOutUser()
        window.location.reload();
    }

    render() {
        const { route, loadingRoute, bg } = this.props
        return (
            <Header bg={bg}>
                <Navbar light expand="md">
                    {/*<HeaderRoute>{!loadingRoute && route.heading}</HeaderRoute>*/}
                    <Collapse isOpen={true} navbar>
                        <Nav className="ml-auto align-items-center" navbar>
                            <NavbarText className='mr-5'>Přihlášen jako: {!this.props.loadingUser ? <strong>{this.props.user.email}</strong> : 'Načítám...'}</NavbarText>
                            <NavbarText>
                                <Button icon='\ef7c' outline onClick={this.logOut}>Odhlásit se</Button>
                            </NavbarText>
                        </Nav>
                    </Collapse>
                </Navbar>
            </Header>
        )
    }

}

const mapStateToProps = state => ({
    user: state.user.user,
    loadingUser: state.user.loading,
})
  
export default connect(mapStateToProps, { logOutUser })(withRouter(HeaderContainer))