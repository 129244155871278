import React, { Component } from 'react'
import { Card, CardHeader, CardBody } from '../../../styles'
import Schedule from '../../containers/workouts/Schedule'
import { getCalendar, getCalendarItem } from '../../../api/workouts';
import moment from 'moment'
import { Row, Col } from 'reactstrap'
import { Modal, Icon, Button } from 'semantic-ui-react'
import { getToken } from '../../../api'
import { getSignedDashboard } from '../../../api/club'
import _ from 'lodash'
import WidgetsContainer from '../../containers/club/WidgetsContainer'
import ChangeLectureModal from '../../modals/ChangeLectureModal'
import { connect } from 'react-redux'
import { setMessage } from '../../../actions/messageActions'
import { logOutUser } from '../../../actions/userActions'
import { deleteItem } from '../../../api/workouts'
import LoadingWidgets from '../../containers/club/LoadingWidgets';
import OffersWidget from '../../containers/offers/OffersWidget';
import EmptyWidget from '../../containers/club/EmptyWidget';
import firebase from 'firebase'

const viewOpts = [
    {
        key: 0,
        value: 'moms',
        text: 'Cvičení pro maminky'
    },
    {
        key: 1,
        value: 'kids',
        text: 'Cvičení s dětmi'
    },
    {
        key: 2,
        value: 'hobby',
        text: 'Kroužky'
    }
]

class ClubDashboard extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            items: [],
            loadingData: [],
            header: 'Rorzvh lekcí',
            loadingWidgets: true,
            datesWidget: [],
            loadingWidget: true,
            hasWidget: false
        })
    }

    componentDidMount() {

        const { type } = this.props.match.params
        const { loadingUser } = this.props
        document.body.classList.remove('application')

        this.setCardHeader(type)
        {!loadingUser &&
            this._getSignedDashboard()
        }
        
    }

    componentDidUpdate(prevProps) {
        const { loadingUser } = this.props

        if(!loadingUser && prevProps.loadingUser !== loadingUser) {
            this._getSignedDashboard()
        }
    }

    setCardHeader = (type) => {
        if(type === 'moms') {
            this.setState({
                header: 'Rozvrh cvičení pro maminky'
            })
        } else if(type === 'kids') {
            this.setState({
                header: 'Rozvrh cvičení s dětmi'
            })
        } else if(type === 'hobby') {
            this.setState({
                header: 'Rozvrh kroužků'
            })
        }
    }

    _deleteItem = (id, type) => {

        this.setState({
            loadingWidget: true
        })

        const { setMessage } = this.props

        getToken().then((token) => {
            const data = {
                token: token
            }

            deleteItem(data, id, type)
            .then((res) => {
                setMessage(res)
                this._getSignedDashboard()

                this.setState({
                    loadingWidget: false
                })
            })
        })
        .catch((err) => {
            this.props.setMessage({type: 'error', text: 'Nepodařilo se získat bezpečnostní token, aktualizuji stránku'})
            window.location.reload();
        })
    }

    _getCalendarItems = (date) => {
        let { type } = this.props.match.params
        const formatDate = moment(date).format('YYYY-MM-DD')

        if(type === undefined) {
            type = 'moms'
        }

        getCalendar(formatDate, type)
        .then((res) => {

            const newEvents = []
            res.map((item) => {
                const newItem = {
                    title: item.title,
                    start: moment(item.start).toDate(),
                    end: moment(item.end).toDate(),
                    id: item.id,
                    color: item.color,
                    is_full: item.is_full
                }
                newEvents.push(newItem)
            })

            this.setState({
                items: newEvents
            })
        })
    }

    _getSignedDashboard = () => {

        this.setState({
            loadingWidget: true
        })

        const formatDate = moment().format('YYYY-MM-DD')

        getToken().then((token) => {
            const data = {
                token: token
            }

            getSignedDashboard(data, formatDate)
            .then((res) => {
                if(res.code === 1) {
                    var moms = res.data.moms
                    var kids = res.data.kids
                    var hobby = res.data.hobby
    
                    var d = moms.concat(kids, hobby);
                    d = d.filter(i => moment(i.date).isAfter())
                    d = _.orderBy(d, ['date'],['asc']);
    
                    this.setState({
                        datesWidget: d,
                        loadingWidgets: false,
                        hasWidget: true
                    })
                } else {
                    this.setState({
                        datesWidget: [],
                        loadingWidgets: false,
                        hasWidget: false
                    })
                }
            })
            .catch((err) => {
                this.props.setMessage({type: 'error', text: 'Nepodařilo se získat Vaše příští lekce, zkuste to později'})
            })
        })
        .catch((err) => {
            this.props.setMessage({type: 'error', text: 'Nepodařilo se získat bezpečnostní token, budete odhlášeni'})
            firebase.auth().signOut()
            window.location.reload();
            this.props.logOutUser()
        })
    }

    _getCalendarItem = (id) => {
        const { type } = this.props.match.params

        getCalendarItem(id, type)
        .then((res) => {
            if(res.code === 1) {
                this.setState({
                    selectedItem: res.data,
                    itemExist: true
                })
            } else {
                this.setState({
                    selectedItem: {},
                    itemExist: false
                })
            }
        })
    }

    onChangeSelect = (e, data) => {
        this.props.history.push(`${process.env.PUBLIC_URL}/klub/nastenka/${data.value}`)
    }

    _changeLectureModal = (type, id_signed, is_free) => {

        const { setMessage } = this.props

        return (
            <ChangeLectureModal type={type} id={id_signed} refresh={this._getSignedDashboard} setMessage={setMessage} is_free={is_free} />
        )
    }

    deleteModal = (id_item, type) => {
        return (
            <Modal trigger={<Button icon>Omluvit</Button>} onClose={this.hideModal}>
                <Modal.Header>
                    Omluvit se z této lekce
                </Modal.Header>
                <Modal.Content>
                    {type !== 'hobby' && <p>Po omluvení budete z lekce vyřazeni a budete si ji moci nahradit jindy.</p>}
                    {type === 'hobby' && <p>Po omluvení budete z lekce vyřazeni a <strong>nebudete si ji moci nahradit jindy.</strong></p>}
                </Modal.Content>
                <Modal.Actions className='justify-content-end'>
                    <Button className='red' onClick={() => this._deleteItem(id_item, type)} loading={this.state.loading} disabled={this.state.loading}>Omluvit lekci</Button>
                </Modal.Actions>
            </Modal>
        )
    }

    render() {

        const { loadingWidgets, hasWidget } = this.state
        let { type } = this.props.match.params

        if(type === undefined) {
            type = 'moms'
        }

        return (
            <>
            {window.location.origin !== 'https://klub.tabory-krouzky.cz' ?
            <Row>
                <Col sm={6}>
                    <h4 className='dashboard_heading'>Následující lekce</h4>
                    {!loadingWidgets && hasWidget ?
                        <WidgetsContainer items={this.state.datesWidget} changeLecture={this._changeLectureModal} deleteModal={this.deleteModal} />
                        :
                        <EmptyWidget />
                    }
                </Col>
                <Col sm={6}>
                    <h4 className='dashboard_heading'>Speciální nabídky</h4>
                    {!loadingWidgets ?
                        <OffersWidget width={6} limit={true} numOfItems={4} />
                        :
                        <LoadingWidgets />
                    }
                </Col>
            </Row>
            :
            <Row>
                <Col sm={12}>
                    <h4 className='dashboard_heading'>Speciální nabídky</h4>
                    {!loadingWidgets ?
                        <OffersWidget width={4} />
                        :
                        <LoadingWidgets />
                    }
                </Col>
            </Row>
            }
            {/*
            <Card>
                <CardHeader>
                    <Row>
                        <Col sm={9}>
                            {header}
                        </Col>
                        <Col sm={3} className='text-right'>
                            <Dropdown 
                                options={viewOpts}
                                value={type}
                                onChange={this.onChangeSelect}
                                selection
                            />
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Schedule dates={items} getData={this._getCalendarItems} getItem={this._getCalendarItem} selectedItem={this.state.selectedItem} type={type} />
                </CardBody>
            </Card>
            */}
            </>
        )
    }

}

const mapStateToProps = state => ({
    loadingUser: state.user.loading
})
  
export default connect(mapStateToProps, { setMessage, logOutUser })(ClubDashboard)