import React from 'react'
import { Card, CardControls, CardBody } from '../../styles'
import { NavLink } from 'react-router-dom/cjs/react-router-dom'

const EmptyTable = ({ section, text, link }) => {
    return (
        <Card>
            <CardControls>
                <h2>{section}</h2>
            </CardControls>
            <CardBody>
                <p>{text}</p>
            </CardBody>
            <CardControls>
                <NavLink className='btn btn-primary' to={`${process.env.PUBLIC_URL}` + link}>Vytvořit nový</NavLink>
            </CardControls>
        </Card>
    )
}

export default EmptyTable