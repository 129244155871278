import React, { Component } from 'react'
import { getToken } from '../../../api'
import { getLecturesList, getParticipants, getDateKids } from '../../../api/workouts'
import { connect } from 'react-redux'
import Loading from '../../containers/Loading'
import NoReservations from '../../containers/club/NoReservations'
import { Card, CardBody, CardHeader } from '../../../styles'
import _ from 'loadsh'
import { Row, Col } from 'reactstrap'
import { Dropdown } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import DateParticipantsTable from '../../tables/workouts/DateParticipantsTable'
import { setMessage } from '../../../actions/messageActions'
import DateKidsTable from '../../tables/workouts/DateKidsTable'
import { url } from '../../../config'

class DateDetailView extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            active: [],
            loadingData: true,
            hasData: false,
            column: 'id_workout',
            direction: 'descending',
            kids: [],
            loadingKids: true
        })
    }

    setCardHeader = (type) => {
        switch (type) {
            case 'moms':
                return 'Seznam přihlášených (Cvičení pro maminky)'
            
            case 'kids':
                return 'Seznam přihlášených na lekci'

            case 'hobby':
                return 'Seznam přihlášených'
    
            default: 
                return 'Moje rezervace'
        }
    }

    componentDidMount() {
        const { loadingUser } = this.props

        if(!loadingUser) {
            this._getList()
        }
    }

    componentDidUpdate(prevProps) {

        const { loadingUser } = this.props

        if(prevProps.loadingUser !== loadingUser && !loadingUser) {
            this._getList()
        }
    }

    _getList = () => {

        const { user } = this.props
        const { type, id } = this.props.match.params

        this.setState({
            hasData: false,
            loadingData: true
        })

        getToken().then((token) => {
            const data = {
                token: token,
                id_org: user.id_organization,
                id_date: id
            }

            getParticipants(data, type)
            .then((res) => {
                if(res.code === 1) {
                    this.setState({
                        active: res.data.filter((i) => i.paid === 1 && !i.is_sub),
                        reservations: res.data.filter((i) => i.paid === 0 && !i.is_sub),
                        subs: res.data.filter((i) => i.is_sub),
                        hasData: true,
                        loadingData: false,
                        isLecturer: user.is_lecturer === 1 ? true : false,
                        id_workout: res.data[0].id_workout
                    })

                    if(type !== 'moms') {
                        getDateKids(data, type)
                        .then((res) => {
                            if(res.code === 1) {
                                this.setState({
                                    kids: res.data,
                                    loadingKids: false,
                                    hasKids: true
                                })
                            } else {
                                this.setState({
                                    kids: [],
                                    loadingKids: false,
                                    hasKids: false
                                })
                            }
                        })
                        .catch((err) => {
                            this.props.setMessage({type: 'error', text: 'Nepodařilo se získat děti, zkuste to prosím za chvíli'})
                        })
                    }

                } else {
                    this.setState({
                        hasData: false,
                        loadingData: false,
                        isLecturer: user.is_lecturer === 1 ? true : false
                    })
                }
            })
            .catch((err) => {
                this.props.setMessage({type: 'error', text: 'Nepodařilo se získat data, zkuste to prosím za chvíli'})
            })
        })
    }

    handleSort = (clickedColumn) => () => {
        const { column, items, direction } = this.state
    
        if (column !== clickedColumn) {
          this.setState({
            column: clickedColumn,
            items: _.sortBy(items, [clickedColumn]),
            direction: 'ascending',
          })
    
          return
        }
    
        this.setState({
            items: items.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    }

    content = () => {

        const { type } = this.props.match.params
        const { setMessage } = this.props

        return (
            <>
            <Card className='mb-5'>
                <CardHeader>
                    <Row>
                        <Col sm={9}>
                            Aktivní účastníci
                        </Col>
                    </Row>
                </CardHeader>
                {this.state.active.length !== 0 ?
                <DateParticipantsTable isSub={false} items={this.state.active} isLecturer={this.state.isLecturer} sort={this.handleSort} column={this.state.column} direction={this.state.direction} type={type} refresh={this._getList} setMessage={setMessage} />
                :
                <NoReservations text='Tento termín nemá aktivní účastníky' />
                }
            </Card>
            <Card className='mb-5'>
                <CardHeader>
                    <Row>
                        <Col sm={9}>
                            Rezervace
                        </Col>
                    </Row>
                </CardHeader>
                {this.state.reservations.length !== 0 ?
                <DateParticipantsTable isSub={false} items={this.state.reservations} isLecturer={this.state.isLecturer} sort={this.handleSort} column={this.state.column} direction={this.state.direction} type={type} refresh={this._getList} setMessage={setMessage} />
                :
                <NoReservations text='Tento termín nemá rezervace' />
                }
            </Card>
            <Card className='mb-5'>
                <CardHeader>
                    <Row>
                        <Col sm={9}>
                            Náhradníci
                        </Col>
                    </Row>
                </CardHeader>
                {this.state.subs.length !== 0 ?
                <DateParticipantsTable isSub={true} items={this.state.subs} sort={this.handleSort} isLecturer={this.state.isLecturer} column={this.state.column} direction={this.state.direction} type={type} refresh={this._getList} setMessage={setMessage} />
                :
                <NoReservations text='Tento termín nemá náhradníky' />
                }
            </Card>

            {type !== 'moms' &&
            <Card className='mb-5'>
                <CardHeader>
                    <Row>
                        <Col sm={9}>
                            Přihlášené děti
                        </Col>
                    </Row>
                </CardHeader>
                {!this.state.loadingKids && this.state.kids.length !== 0 ?
                <DateKidsTable items={this.state.kids} sort={this.handleSort} isLecturer={this.state.isLecturer} column={this.state.column} direction={this.state.direction} type={type} refresh={this._getList} setMessage={setMessage} />
                :
                <NoReservations text='Tento termín nemá děti' />
                }
            </Card>
            }
            </>
        )
    }

    exportApplications = () => {

        const { type, id } = this.props.match.params
        const { id_workout, isLecturer } = this.state

        this.setState({
            loadingExport: true
        })
        const exportUrl = isLecturer ? 'export/export-applications-from-date-lecturer/' : 'export/export-applications-from-date/'
        getToken().then((token) => {
            //$id, $type, $date, $workout
            window.open(url.api + exportUrl + token + '/' + type + '/' + id + '/' + id_workout, "_blank")

            this.setState({
                loadingExport: false
            })
        })
        .catch((err) => {
            this.props.setMessage({type: 'error', text: 'Nepodařilo se získat bezpečnostní token, aktualizuji stránku'})
            window.location.reload();
        })
    }

    _renderExportDropdown = () => {
        return (
            <Dropdown
                text='Export'
                icon='download'
                floating
                labeled
                button
                className='icon'
                loading={this.state.loadingExport}
            >
                <Dropdown.Menu>
                    <Dropdown.Header icon='file outline' content='Typ exportu' />
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={this.exportApplications} icon='list' text='Všechny přihlášky' />
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    render() {
        const { loadingData, hasData } = this.state
        const { type } = this.props.match.params

        if(loadingData) {
            return (
                <Loading />
            )
        } else if(!loadingData && !hasData) {
            return (
                <Card>
                    <CardHeader>
                        Seznam přihlášených na lekci
                    </CardHeader>
                    <CardBody>
                        <NoReservations text='Tato lekce nemá žádné přihlášky' />
                    </CardBody>
                </Card>
            )  
        } else {
            return (
                <>
                <Row className='mb-4 text-right'>
                    <Col>
                        {!this.props.loadingUser && this._renderExportDropdown()}
                    </Col>
                </Row>
                {this.content()}
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    user: state.user.user,
    loadingUser: state.user.loading
})
  
export default connect(mapStateToProps, { setMessage })(DateDetailView)