import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setMessage } from '../../actions/messageActions'
import { Card, CardControls, CardBody, CardHeader, DateItem, EmailItem, FormButtonGroup } from '../../styles'
import { getApplicationDetail, intToBool, createAddPayment, confirmAddPayment, setPaid, getToken, sendReminder, sendConfirm, getDates, changeKidsDate, updatePrice, addKidsDate, itemEmailHistory, toggleNewsletter, togglePhotos, getAddPayments, shortText, formatMoney, deleteAddPayment } from '../../api'
import { ParentsForm, KidsDetailForm } from '../forms'
import { Dropdown, Modal, Form, Input, Button, Icon, Label } from 'semantic-ui-react'
import moment from 'moment'
import AddKidForm from '../forms/AddKidForm'

const paidOpt = [
    {key: '1', value: 0, text: 'Nezaplaceno'},
    {key: '2', value: 1, text: 'Zaplaceno'},
]

class ApplicationDetailView extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            data: [],
            dates: [],
            payments: [],
            toChange: {
                old: '',
                new: '',
                kid: ''
            },
            toAdd: {
                new: '',
                kid: ''
            },
            newPayment: {
                subject: '',
                message: '',
                amount: '',
            },
            showModal: false,
            addDateModal: false,
            disableModalOpen: false,
            loading: true,
            loadingPayment: false,
            loadingForms: false,
            loadingPrice: false,
            loadingMail: false,
            price: '',
            emailHistory: [],
            emailModal: false,
            hasHistory: false,
            loadingHistory: true,
            addKidModal: false,
            showHasSub: true,
            loadingPayments: true,
            paymentsModal: false,
            hasPayments: false,
            loadingPaymentForm: false
        })
    }

    componentDidMount() {

        //this.emailHistoryHandler()
        getDates()
        .then((data) => {
            this.setState({
                dates: data
            })
        })
        
        if(!this.props.loadingUser) {
            this.refresh()
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.loadingUser !== this.props.loadingUser) {
            this.refresh()
        }
    }

    refresh = () => {

        this.setState({
            loadingForms: true
        })

        const { id } = this.props.match.params
        const { user } = this.props

        getToken().then((token) => {

            const data = {
                token: token,
                id_item: id,
                id_org: user.id_organization
            }

            getApplicationDetail(data)
            .then((res) => {
                if(res.code === 1) {
                    this.setState({
                        data: res.data,
                        loading: false,
                        loadingForms: false,
                        price: res.data.parents.total_price,
                        hasData: true
                    })
                } else {
                    this.setState({
                        data: res.data,
                        loading: false,
                        loadingForms: false,
                        price: 0,
                        hasData: false
                    })
                }
            })
        })
    }

    emailHistoryHandler = () => {

        const { id } = this.props.match.params

        this.setState({
            loadingHistory: true
        })

        getToken().then((token) => {
            const data = {
                token: token,
                id_send_to: id
            }
    
            itemEmailHistory(data)
            .then((data) => {
                this.setState({
                    emailHistory: data,
                    hasHistory: data.type && data.type === 'error' ? false : true,
                    loadingHistory: false
                })
            })
        })

    }

    getAddPaymentsHandler = () => {
        const { id } = this.props.match.params

        this.setState({
            loadingPayments: true
        })

        getToken().then((token) => {
            const data = {
                token: token,
                id_application: id
            }

            getAddPayments(data)
            .then((data) => {
                this.setState({
                    payments: data.data,
                    hasPayments: data.type !== 'error' ? true : false,
                    loadingPayments: false
                })
            })
        })
    }

    toggleNewsletterHandler = () => {
        getToken().then((token) => {
            const data = {
                token: token,
                id: this.state.data.parents.id_parents
            }

            toggleNewsletter(data)
            .then((res) => {
                this.props.setMessage(res)
                this.refresh()
            })
        })
    }

    togglePhotosHandler = () => {
        getToken().then((token) => {
            const data = {
                token: token,
                id: this.state.data.parents.id_parents
            }

            togglePhotos(data)
            .then((res) => {
                this.props.setMessage(res)
                this.refresh()
            })
        })
    }

    changeDate = (date, kid) => {
        this.setState({
            toChange: {
                ...this.state.toChange,
                old: date,
                kid: kid
            },
            showModal: true
        })
    }

    addDate = (kid) => {
        this.setState({
            toAdd: {
                kid: kid
            },
            addDateModal: true
        })
    }

    setAddNewDate = (date) => {
        this.setState({
            toAdd: {
                ...this.state.toAdd,
                new: date,
            },
            loadingForms: true
        }, () => {
            this.addDateHandler()
        })
    }

    setNewDate = (date, kid) => {
        this.setState({
            toChange: {
                ...this.state.toChange,
                new: date,
            },
            loadingForms: true
        }, () => {
            this.changeDateHandler()
            this.handleHideModal()
        })
    }

    addDateHandler = () => {

        const { id } = this.props.match.params

        getToken().then((token) => {
            const data = {
                token: token,
                kid: this.state.toAdd.kid,
                new: this.state.toAdd.new,
                id: id
            }

            addKidsDate(data)
            .then((res) => {
                this.props.setMessage(res)
                this.setState({
                    toAdd: {
                        new: '',
                        kid: ''
                    }
                })
                this.handleAddHideModal()
                this.refresh()
            })
        })
        
    }

    changeDateHandler = () => {
        changeKidsDate(this.state.toChange)
        .then((res) => {
            this.props.setMessage(res)
            this.setState({
                toChange: {
                    new: '',
                    old: '',
                    kid: ''
                }
            })

            this.refresh()
        })
    }

    addNewPaymentHandler = () => {

        const { newPayment } = this.state
        const { id } = this.props.match.params

        if(newPayment.subject !== '' && newPayment.amount !== '' && newPayment.message !== '') {
            this.setState({
                loadingPaymentForm: true
            })
            getToken().then((token) => {
                const data = {
                    token: token,
                    subject: newPayment.subject,
                    amount: Number(newPayment.amount),
                    message: newPayment.message,
                    id_application: id
                }

                createAddPayment(data)
                .then((res) => {
                    if(res.type === 'success') {
                        this.handlePaymentsCloseModal()
                        this.clearNewPayment()
                    } 
                    this.props.setMessage(res)
                })
                .then((res) => {
                    this.setState({
                        loadingPaymentForm: false
                    })
                })
            })
        }
    }

    deleteAddPaymentHandler = (id) => {
        getToken().then((token) => {
            const data = {
                token: token,
                id_payment: id
            }

            deleteAddPayment(data)
            .then((res) => {
                this.props.setMessage(res)
                this.getAddPaymentsHandler()
            })
        })
    }

    confirmAddPaymentHandler = (id_payment) => {
        const { id } = this.props.match.params
        getToken().then((token) => {
            const data = {
                token: token,
                id_payment: id_payment,
                id_application: id
            }

            confirmAddPayment(data)
            .then((res) => {
                this.props.setMessage(res)
                this.getAddPaymentsHandler()
            })
        })
    }

    clearNewPayment = () => this.setState({ newPayment: { subject: '', message: '', amount: '' } })

    handleOpenModal = () => this.setState({ showModal: true })
    handleHideModal = () => this.setState({ showModal: false, loadingForms: false })
    handleAddOpenModal = () => this.setState({ addDateModal: true })
    handleAddHideModal = () => this.setState({ addDateModal: false, loadingForms: false })
    openAddKid = () => this.setState({ addKidModal: true, loadingForms: false })
    closeAddKid = () => this.setState({ addKidModal: false, loadingForms: false })
    handleEmailOpenModal = () => {
        this.setState({ emailModal: true }) 
        this.emailHistoryHandler()
    }
    handleEmailCloseModal = () => this.setState({ emailModal: false })
    handlePaymentsCloseModal = () => this.setState({ paymentsModal: false })
    handlePaymentsOpenModal = () => {
        this.setState({ paymentsModal: true })
        this.getAddPaymentsHandler()
    }

    setPaidHandler = (val, id) => {

        this.setState({
            loadingPayment: true
        })

        getToken().then((token) => {
            const data = {
                token: token,
                paid: val,
                id: id
            }

            setPaid(data)
            .then((res) => {
                this.props.setMessage(res)
                this.refresh()

                this.setState({
                    loadingPayment: false
                })
            })
        })
    }

    onChangeSelect = (e, data) => {
        this.setPaidHandler(data.value, this.state.data.parents.id_application)
    }

    onChange = (e) => this.setState({
        [e.target.name]: e.target.value
    })

    onChangePayment = (e) => this.setState({
        newPayment: {
            ...this.state.newPayment,
            [e.target.name]: e.target.value
        }
    })

    updatePriceHandler = () => {

        this.setState({
            loadingPrice: true
        })

        const { id } = this.props.match.params

        getToken().then((token) => {
            const data = {
                token: token,
                price: this.state.price,
                id: id
            }

            updatePrice(data)
            .then((res) => {
                this.props.setMessage(res)

                this.setState({
                    loadingPrice: false
                })

                this.refresh()
            })

        })
    }

    handleSendPaymentEmail = () => {

        this.setState({
            loadingMail: true
        })

        const { id } = this.props.match.params

        if(this.state.data.parents.paid === 0) {
            sendReminder(id)
            .then((res) => {
                this.props.setMessage(res)
                this.refresh()
                this.setState({
                    loadingMail: false
                })
            })
        } else {
            sendConfirm(id)
            .then((res) => {
                this.props.setMessage(res)
                this.refresh()

                this.setState({
                    loadingMail: false
                })
            })
        }
    }

    convertEmailType = (type) => {
        if(type === 'reminder') {
            return 'Upomínka platby'
        } else if(type === 'confirm-payment') {
            return 'Potvrzení platby'
        } else if(type === 'thank-you') {
            return 'Potvrzení registrace'
        } else if(type === 'change-date') {
            return 'Změna termínu'
        } else if(type === 'canceled-date') {
            return 'Zrušení termínu'
        } else if(type === 'new-add-payment') {
            return 'Doplňková platba'
        } else {
            return 'Neznámý typ'
        }
    }

    _renderKids = () => {
        return (
            this.state.data.kids.map((kid) => {
                return (
                    <div className='row'>
                        <div className='col-sm-12'>
                            <Card className='mt-3'>
                                <CardHeader>
                                    <div className='row'>
                                        <div className='col-sm-6 text-left'>
                                            <h2>{kid.kids_name}</h2>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <KidsDetailForm kid={kid} refresh={this.refresh} email={this.state.data.parents.parent_email} changeDate={this.changeDate} addDate={this.addDate} loading={this.state.loadingForms} />
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                )
            })
        )
    }

    _renderEmails = () => {
        return this.state.emailHistory.map((item) => {
            return (
                <EmailItem key={item.id_email}>
                    <div className='row'>
                        <div className={this.props.user.user_admin === 1 ? 'col-sm-4' : 'col-sm-6'}>
                            <p>{this.convertEmailType(item.email_type)}</p>
                        </div>
                        {this.props.user.user_admin === 1 &&
                        <div className='col-sm-4'>
                            <p>{item.email_send_to}</p>
                        </div>
                        }
                        <div className={this.props.user.user_admin === 1 ? 'col-sm-4' : 'col-sm-6'}>
                            <p>{moment(item.created).format('LLL')}</p>
                        </div>
                    </div>
                </EmailItem>
            )
        })
    }

    _renderPayments = () => {

        const { id } = this.props.match.params

        return this.state.payments.map((item) => {
            var payment_num = id + '00' + item.id_payment
            return (
                <EmailItem key={item.id_payment}>
                    <div className='row'>
                        <div className='col-sm-1'>
                            <p>#{payment_num}</p>
                        </div>
                        <div className='col-sm-2'>
                            <p>{moment(item.created).format('D. M. YYYY H:mm')}</p>
                        </div>
                        <div className='col-sm-3'>
                            <p>{item.subject}</p>
                        </div>
                        <div className='col-sm-3'>
                            <p>{shortText(item.message, 80)}</p>
                        </div>
                        <div className='col-sm-2'>
                            <p>{formatMoney(item.amount, ",", ".")} Kč</p>
                        </div>
                        <div className='col-sm-1 justify-content-end align-items-center'>
                            {this._sureDeletePaymentModal(item.id_payment, payment_num)}
                            {item.paid !== 1 && this._sureConfirmPaymentModal(item.id_payment, payment_num)}
                        </div>
                    </div>
                </EmailItem>
            )
        })
    }

    _sureDeletePaymentModal = (id, num) => {
        return (
            <Modal trigger={<Button icon><Icon name='trash' /></Button>}>
                <Modal.Header>
                    Opravdu chcete platbu #{num} smazat?
                </Modal.Header>
                <Modal.Content>
                    <p>Smazání platby je nevratné. Informujte o zrušení platby rodiče.</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button danger onClick={() => this.deleteAddPaymentHandler(id)}>Smazat platbu</Button>
                </Modal.Actions>
            </Modal>
        )
    }

    _sureConfirmPaymentModal = (id, num) => {
        return (
            <Modal trigger={<Button icon><Icon name='check' /></Button>}>
                <Modal.Header>
                    Opravdu byla platba #{num} zaplacena?
                </Modal.Header>
                <Modal.Content>
                    <p>Po potvrzení platby bude rodičovi odeslán email přijetí platby emailem.</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button primary onClick={() => this.confirmAddPaymentHandler(id)}>Potvrdit platbu</Button>
                </Modal.Actions>
            </Modal>
        )
    }

    _renderEmailsModal = () => {
        return (
            <Modal open={this.state.emailModal} onClose={() => this.handleEmailCloseModal()} closeIcon size='small'>
                <Modal.Header>Historie odeslaných zpráv</Modal.Header>
                <Modal.Content>
                    {!this.state.loadingHistory && this.state.hasHistory &&
                        <this._renderEmails />
                    }
                    {this.state.loadingHistory &&
                        <EmailItem><div className='row'><div className='col-sm-12 text-center'><p>Načítám historii</p></div></div></EmailItem>
                    }
                    {!this.state.loadingHistory && !this.state.hasHistory &&
                        <EmailItem><div className='row'><div className='col-sm-12 text-center'><p>U této přihlášky není historie zpráv dostupná</p></div></div></EmailItem>
                    }
                </Modal.Content>
            </Modal>
        )
    }

    _renderAdditinalPaymentsModal = () => {
        return (
            <Modal open={this.state.paymentsModal} onClose={() => this.handlePaymentsCloseModal()} closeIcon size='large'>
                <Modal.Header>
                    <div className="row">
                        <div className="col-sm-6">
                            Doplňkové platby
                        </div>
                        <div className="col-sm-6 justify-content-end">
                            <this._renderCreatePaymentModal />
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Content>
                    {!this.state.loadingPayments && this.state.hasPayments &&
                        <this._renderPayments />
                    }
                    {this.state.loadingPayments &&
                        <EmailItem><div className='row'><div className='col-sm-12 text-center'><p>Načítám historii</p></div></div></EmailItem>
                    }
                    {!this.state.loadingPayments && !this.state.hasPayments &&
                        <EmailItem><div className='row'><div className='col-sm-12 text-center'><p>Tato přihláška nemá žádné doplňkové platby</p></div></div></EmailItem>
                    }
                </Modal.Content>
            </Modal>
        )
    }

    _renderCreatePaymentModal = () => {
        return (
            <Modal trigger={<Button primary>Nová platba</Button>}>
                <Modal.Header>
                    Nová doplňková platba
                </Modal.Header>
                <Form onSubmit={this.addNewPaymentHandler} loading={this.state.loadingPaymentForm}>
                    <Modal.Content>
                        <Form.Field>
                            <Form.Input 
                                label="Předmět platby"
                                type="text"
                                placeholder="Zadejte předmět patby"
                                name="subject"
                                value={this.state.newPayment.subject}
                                onChange={this.onChangePayment}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input 
                                label="Částka"
                                type="number"
                                placeholder="Zadejte částku"
                                name="amount"
                                value={this.state.newPayment.amount}
                                onChange={this.onChangePayment}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.TextArea 
                                label="Zpráva"
                                placeholder="Zadejte zprávu pro rodiče"
                                name="message"
                                value={this.state.newPayment.message}
                                onChange={this.onChangePayment}
                            />
                        </Form.Field>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button primary>Vytvořit platbu</Button>
                    </Modal.Actions>
                </Form>
                
            </Modal>
        )
    }

    _renderDatesModal = () => {
        return (
            <Modal open={this.state.showModal} onClose={() => this.handleHideModal()} closeIcon size='large'>
                <Modal.Header>Vyberte termín ke změně</Modal.Header>
                <Modal.Content>
                    <this._renderDates action='change' />
                </Modal.Content>
            </Modal>
        )
    }

    _renderNewDatesModal = () => {
        return (
            <Modal open={this.state.addDateModal} onClose={() => this.handleAddHideModal()} closeIcon size='large'>
                <Modal.Header>Vyberte termín k přidání</Modal.Header>
                <Modal.Content>
                    <this._renderDates action='new' />
                </Modal.Content>
            </Modal>
        )
    }

    _renderNewKidModal = () => {

        return (
            <Modal open={this.state.addKidModal} onClose={() => this.closeAddKid()} closeIcon size='large'>
                <Modal.Header>Přidat dítě</Modal.Header>
                <Modal.Content>
                    <AddKidForm id={this.state.data.parents.id_parents} close={this.closeAddKid} refresh={this.refresh} />
                </Modal.Content>
            </Modal>
        )
    }

    _renderDates = ({action}) => {
        return (
            this.state.dates.map((date) => {
                return (
                    <DateItem className='row' key={date.id_date}>
                        <div className='col-sm-1 camp_name_uppercase'>
                            {date.camp_name}
                        </div>
                        <div className='col-sm-3'>
                            {moment(date.date_from).format('D. M.')} - {moment(date.date_to).format('D. M. YYYY')}
                        </div>
                        <div className='col-sm-4'>
                            {date.location_address}
                        </div>
                        <div className='col-sm-2'>
                            volné {date.date_capacity - date.date_signed}/{date.date_capacity}
                        </div>
                        <div className='col-sm-2'>
                            {action === 'change' && <Button onClick={() => this.setNewDate(date.id_date)}>Vybrat termín</Button>}
                            {action === 'new' && <Button onClick={() => this.setAddNewDate(date.id_date)}>Přidat termín</Button>}
                        </div>
                    </DateItem>
                )
            })
            
        )
    }

    _renderAgreements = ({data}) => {

        const { id } = this.props.match.params

        return (
            <div className='row agreements'>
                <div className='col-sm-12'>
                    <div className='row'>
                        <div className='col-sm-2'>
                            <p>ID</p>
                            <span className='as-icon'>#{id}</span>
                        </div>
                        <div className='col-sm-3'>
                            <p>Obchodní podmínky</p>
                            {intToBool(data.parent_tos) ? <i class="icofont-check-circled icon-paid"></i> : <i class="icofont-close-circled icon-paid"></i>}
                        </div>
                        <div className='col-sm-2'>
                            <p>Osobní údaje</p>
                            {intToBool(data.parent_gdpr) ? <i class="icofont-check-circled icon-paid"></i> : <i class="icofont-close-circled icon-paid"></i>}
                        </div>
                        <div className='col-sm-2'>
                            <p>Focení</p>
                            {intToBool(data.parent_photos) ? <i class="icofont-check-circled icon-state icon-paid" onClick={this.togglePhotosHandler}></i> : <i class="icofont-close-circled icon-state icon-paid" onClick={this.togglePhotosHandler}></i>}
                        </div>
                        <div className='col-sm-3'>
                            <p>Newsletter</p>
                            {intToBool(data.parent_newsletter) ? <i class="icofont-check-circled icon-state icon-paid" onClick={this.toggleNewsletterHandler}></i> : <i class="icofont-close-circled icon-state icon-paid" onClick={this.toggleNewsletterHandler}></i>}
                        </div>
                    </div>
                </div>
               
            </div>
        )
    }

    _renderPriceLabel = () => {
        return (
            <div className="ui label label">
                Kč { this.state.data.parents.coupon_text !== null && ' | ' + this.state.data.parents.coupon_text }
            </div>
        )
    }

    _renderPriceOptions = ({data}) => {
        return (
            <div className='row'>
                <div className='col-sm-3 pr-2'>
                    <Form>
                        <Form.Field>
                            <label>Celková cena</label>
                            <Input 
                                type='text'
                                name='price'
                                value={this.state.price}
                                label={<this._renderPriceLabel />}
                                labelPosition='right'
                                onChange={this.onChange}
                            />
                        </Form.Field>
                    </Form>
                </div>
                <div className='col-sm-3 p-0'>
                    <Form>
                        <Form.Field>
                            <label>&nbsp;</label>
                            <Button positive onClick={this.updatePriceHandler} style={{height: 38}} loading={this.state.loadingPrice}>Uložit cenu</Button>
                            <Button icon onClick={this.handlePaymentsOpenModal} style={{height: 38}}><Icon name='plus' /></Button>
                        </Form.Field>
                    </Form>
                </div>
                <div className='col-sm-3 pr-2'>
                    <Form>
                        <Form.Field>
                            <label>Platba</label>
                            <Dropdown
                                name='paid'
                                options={paidOpt}
                                placeholder='Zaplaceno / nezaplaceno'
                                selection
                                onChange={this.onChangeSelect}
                                fluid
                                value={data.paid}
                                className={'state-active-' + data.paid}
                                loading={this.state.loadingPayment}
                            />
                        </Form.Field>
                    </Form>
                </div>
                <div className='col-sm-3 pl-2'>
                    <Form>
                        <Form.Field>
                            <label>&nbsp;</label>
                            <Button 
                                onClick={this.handleSendPaymentEmail} 
                                negative={this.state.data.parents.paid === 0 ? true : false} 
                                positive={this.state.data.parents.paid !== 0 && !this.state.data.parents.send_confirm ? true : false} 
                                style={{height: 38}}
                                disabled={this.state.data.parents.send_confirm}
                                loading={this.state.loadingMail}
                            >
                                {(this.state.data.parents.paid) === 0 ? 'Odeslat upomínku' : (this.state.data.parents.send_confirm ? 'Potvrzení odesláno' : 'Odeslat potvrzení')}
                            </Button>
                            <Button icon onClick={this.handleEmailOpenModal} style={{height: 38}}><Icon name='clock' /></Button>
                        </Form.Field>
                    </Form>
                </div>
            </div>
        )
    }

    _renderSubMessage = () => {
        return (
            <Label className='has_sub_message'>
                Tato přihláška obsahuje náhradníka
                <Icon name='delete' onClick={this.closeHasSub} />
            </Label>
        )
    }

    closeHasSub = () => {
        this.setState({ showHasSub: false })
    }

    render() {
        return (
            <div>
                <div className='row'>
                    <div className='col-sm-12'>
                        <Card className='mb-3'>
                            <CardBody>
                                {!this.state.loading &&
                                <this._renderAgreements data={this.state.data.parents} />
                                }
                            </CardBody>
                        </Card>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-12'>
                        <Card className='mb-3'>
                            <CardBody>
                                {!this.state.loading &&
                                <this._renderPriceOptions data={this.state.data.parents}/>
                                }
                            </CardBody>
                        </Card>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-12'>
                        <Card>
                            <CardHeader>
                                <div className='row'>
                                    <div className='col-sm-6 text-left'>
                                        <h2>Rodiče</h2>
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody>
                                {!this.state.loading &&
                                <ParentsForm parents={this.state.data.parents} isAdmin={this.props.user.user_admin === 1 ? true : false} loading={this.state.loadingForms} refresh={this.refresh} />
                                }
                            </CardBody>
                        </Card>
                    </div>
                </div>
                {!this.state.loading &&
                    <this._renderKids />
                }
                <FormButtonGroup>
                    <Button onClick={this.openAddKid}>Přidat dítě</Button>
                </FormButtonGroup>
                {!this.state.loading &&
                <this._renderNewKidModal />
                }
                <this._renderDatesModal />
                <this._renderNewDatesModal />
                <this._renderEmailsModal />
                <this._renderAdditinalPaymentsModal />
                {this.state.data.has_sub && this.state.showHasSub && <this._renderSubMessage />}
            </div>
        )
    }

}

const mapStateToProps = state => ({
    user: state.user.user,
    loadingUser: state.user.loading
})
  
export default connect(mapStateToProps, { setMessage })(ApplicationDetailView)