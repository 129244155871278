import React, { Component } from 'react'
import { Accordion, Icon, Popup } from 'semantic-ui-react'
import PersonForm from '../../forms/mom-workouts/PersonForm'
import SelectEventForm from '../../forms/mom-workouts/SelectEventForm'
import { getSelectedTour } from '../../../api/tours'
import KidsForm from '../../forms/kids-workouts/KidsForm'
import AdditionalInfoForm from '../../forms/application/AdditionalInfoForm'

class KidsWorkoutsApplicationAccordion extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            activeIndex: 0,
            finishedPerson: false,
            finishedKids: false,
            finishedTour: false,
            data: {
                person: [],
                kids: [],
                event: ''
            },
            selectedTour: {},
            isSelected: false,
            loadingKids: false,
            loadingPerson: false,
            loadingTour: false,
            isLogged: false,
            user: {},
            loading: true,
            event: {}
        })
    }

    componentDidMount() {
        const { isLogged, user, eventItem } = this.props

        this.setState({
            isLogged: isLogged,
            user: isLogged ? user : {},
            loading: false,
            event: eventItem
        })
    }

    componentDidUpdate(prevProps) {
        if(prevProps.eventItem !== this.props.eventItem) {
            this.setState({
                event: this.props.eventItem
            })
        }
    }

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index
    
        this.setState({ activeIndex: newIndex })
    }

    setPerson = (data) => {
        this.setState({
            loadingPerson: true,
            finishedPerson: true,
            activeIndex: 1,
            data: {
                ...this.state.data,
                person: this.state.data.person.length === 0 ? this.state.data.person.concat(data) : data
            }
        }, () => {
            this.setState({ 
                loadingPerson: false 
            })

            this.props.saveData(this.state.data, 'person')
        })
    }

    setEvent = (event, date, price, is_full, is_free, semester) => {

        const { all } = this.props

        this.setState({
            loadingEvent: true,
            finishedEvent: true,
            activeIndex: 3,
            data: {
                ...this.state.data,
                event: {
                    id_workout: event,
                    id_date: date,
                    price_lecture: is_full || is_free === 1 && !all ? 0 : price,
                    is_sub: is_full ? 1 : 0,
                    semester: semester ? semester : null
                }
            }
        }, () => {
            this.setState({ loadingTour: false })
            this.props.saveData(this.state.data, 'event')
        })
    }

    setEventHobby = (event, date, price, is_full, is_free, semester, times_per_week, price_twice_week) => {
        this.setState({
            loadingEvent: true,
            finishedEvent: true,
            activeIndex: 3,
            data: {
                ...this.state.data,
                event: {
                    id_workout: event,
                    id_date: date,
                    price_lecture: is_full || is_free === 1 ? 0 : price,
                    is_sub: is_full ? 1 : 0,
                    semester: semester ? semester : null,
                    times_per_week: times_per_week ? times_per_week : 1,
                    price_twice_week: price_twice_week
                }
            }
        }, () => {
            this.setState({ loadingTour: false })
            this.props.saveData(this.state.data, 'event')
        })
    }

    setAdditional = (data) => {
        this.setState({
            loadingAdditional: true,
            finishedAdditional: true,
            activeIndex: 4,
            data: {
                ...this.state.data,
                note: data.note,
                coupon: data.coupon,
                coupon_value: data.coupon_value,
                id_coupon: data.id_coupon
            }
        }, () => {
            this.setState({ loadingTour: false })
            this.props.saveData(this.state.data, 'additional')
        })
    }

    setKids = (data) => {
        this.setState({
            loadingKids: true,
            finishedKids: true,
            activeIndex: 2,
            data: {
                ...this.state.data,
                kids: this.state.data.kids.length === 0 ? this.state.data.kids.concat(data) : data
            }
        }, () => {
            this.setState({ loadingKids: false })
            this.props.saveData(this.state.data, 'kids')
        })
    }

    render() {
        const { activeIndex, finishedPerson, finishedKids, finishedEvent, finishedAdditional, isLogged, user, event } = this.state
        const { loading, type } = this.props

        return (
            <Accordion styled fluid>
                <Accordion.Title
                    active={activeIndex === 0}
                    index={0}
                    onClick={this.handleClick}
                    className={finishedPerson ? 'tab_finished' : 'tab_waiting'}
                >
                    <Icon name='dropdown' />
                    <span>1. krok: Osobní informace</span>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 0} className={finishedPerson ? 'tab_finished' : 'tab_waiting'}>
                    {!this.state.loading &&
                    <PersonForm loading={loading} submit={this.setPerson} isLogged={isLogged} user={isLogged ? user : null} />
                    }
                </Accordion.Content>

                <Accordion.Title
                    active={activeIndex === 1}
                    index={1}
                    onClick={finishedPerson && this.handleClick}
                    className={finishedKids ? 'tab_finished' : 'tab_waiting'}
                >
                    <Icon name='dropdown' />
                    {!finishedPerson ? <Popup content='Nejdříve dokončete 1. krok' trigger={<span>2. krok: Informace o dítěti</span>} /> : <span>2. krok: Informace o dítěti</span>}
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 1} className={finishedKids ? 'tab_finished' : 'tab_waiting'}>
                    {!this.state.loading &&
                    <KidsForm loading={loading} submit={this.setKids} />
                    }
                </Accordion.Content>

                <Accordion.Title
                    active={activeIndex === 2}
                    index={2}
                    onClick={finishedKids && this.handleClick}
                    className={finishedEvent ? 'tab_finished' : 'tab_waiting'}
                >
                    <Icon name='dropdown' />
                    {!finishedKids ? <Popup content='Nejdříve dokončete 2. krok' trigger={<span>3. krok: Potvrzení lekce</span>} /> : <span>3. krok: Potvrzení lekce</span>}
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 2} className={finishedEvent ? 'tab_finished' : 'tab_waiting'}>
                    {!loading &&
                        <SelectEventForm submit={type !== 'hobby' ? this.setEvent : this.setEventHobby} event={event} type={type} />
                    }
                </Accordion.Content>

                <Accordion.Title
                    active={activeIndex === 3}
                    index={3}
                    onClick={finishedEvent && this.handleClick}
                    className={finishedAdditional ? 'tab_finished' : 'tab_waiting'}
                >
                    <Icon name='dropdown' />
                    {!finishedEvent ? <Popup content='Nejdříve dokončete 3. krok' trigger={<span>4. krok: Další informace</span>} /> : <span>4. krok: Další informace</span>}
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 3} className={finishedAdditional ? 'tab_finished' : 'tab_waiting'}>
                    {!loading &&
                        <AdditionalInfoForm submit={this.setAdditional} event={event} type={type} />
                    }
                </Accordion.Content>

            </Accordion>
        )
    }

}

export default KidsWorkoutsApplicationAccordion