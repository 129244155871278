import React, { Component } from 'react'
import { getOffer } from '../../../api/offers'
import { Row, Col } from 'reactstrap'
import Loading from '../../containers/Loading'
import OfferInfoCard from '../../containers/offers/OfferInfoCard'

class OfferView extends Component {

    constructor(props) {
        super(props)
        
        this.state = ({
            offer: {},
            loading: true
        })
    }

    componentDidMount() {
        this._getOffer()
    }

    _getOffer = () => {
        const { id } = this.props.match.params
        getOffer(id)
        .then((res) => {
            if(res.code === 1) {
                this.setState({
                    offer: res.data,
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                })
            }
        })
    }

    content = () => {

        const { offer } = this.state

        return (
            <Row>
                <Col sm={8}>
                    <h1 style={{color: '#fff', fontSize: 22}} className='mb-5 heading_offer'>{offer.name}</h1>
                    <Row className='mb-5' style={{marginTop: '6rem'}}>
                        <Col sm={12}>
                            <h4 className='mb-3' style={{fontSize: 18}}>O partnerovi</h4>
                            <p>{offer.about_partner}</p>
                        </Col>
                    </Row>
                    <Row className='mb-5'>
                        <Col sm={12}>
                            <h4 className='mb-3' style={{fontSize: 18}}>Popis výhody</h4>
                            <p>{offer.description}</p>
                        </Col>
                    </Row>
                    <Row className='mb-5'>
                        <Col sm={12}>
                            <h4 className='mb-3' style={{fontSize: 18}}>Jak slevu uplatnit</h4>
                            <p>{offer.how_to_use}</p>
                        </Col>
                    </Row>
                </Col>
                <Col sm={4}>
                    <OfferInfoCard item={this.state.offer} />
                </Col>
            </Row>
        )
    }

    render() {
        if(this.state.loading) {
            return <Loading />
        } else {
            return this.content()
        }
    }

}

export default OfferView