import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap'
import HeaderApplications from '../../containers/club/HeaderApplications'
import { ServiceBlock } from '../../../styles/applications'
import { Images } from '../../../config'
import { Link } from 'react-router-dom'
import ServicesBlocks from '../../containers/applications/ServicesBlocks'
import ApplicationsSuspended from '../../containers/applications/ApplicationsSuspended'

class ApplicationMainView extends Component {

    componentDidMount() {
        document.body.classList.add('application')
        document.title = "Naše nabídka | 1FIT Klub"
    }

    render() {
        return (
            <>
            <HeaderApplications hasRoute={true} route={'Naše služby'} backlink={'/prihlaska'} />
            <Container>
                {/*
                <Row>
                    <Col sm={8} className='offset-sm-2 text-center mb-5 mt-5'>
                        <h1>Vyberte si z naší nabídky</h1>
                    </Col>
                </Row>
                <ServicesBlocks />
                */}
                <ApplicationsSuspended />
            </Container>
            </>
        )
    }

}

export default ApplicationMainView