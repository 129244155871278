import React, { Component } from 'react'
import { intToBool, toggleLocationState, shortText, getToken } from '../../../api'
import { changeDateProps, changeLectureTime } from '../../../api/workouts'
import { Table, Icon, Button, Popup, Modal, Dropdown, Form, Checkbox } from 'semantic-ui-react'
import _ from 'loadsh'
import moment from 'moment'
import { StateIcon, MultipleActions } from '../../../styles'
import { Link } from 'react-router-dom'
import { deleteItem } from '../../../api/workouts'
import ChangeLectureModal from '../../modals/ChangeLectureModal'
import { setMessage } from '../../../actions/messageActions'

const yesNoOpts = [
    {
        key: 0,
        value: 0,
        text: 'Ne'
    },
    {
        key: 1,
        value: 1,
        text: 'Ano'
    }
]

class DatesTable extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            showModal: false,
            loading: false,
            loadingDelete: false,
            selectedIds: [],
            loading: {
                delete: false
            }
        })
    }

    hideModal = () => this.setState({ showModal: false })
    openModal = () => this.setState({ showModal: true })

    clearSelected = () => this.setState({ selectedIds: [] })

    handleMultipleSelect = (event, data, id) => {
        if(data.checked) {
            this.setState({
                selectedIds: [
                    ...this.state.selectedIds,
                    Number(id)
                ]
            })
        } else {
            this.setState({
                selectedIds: this.state.selectedIds.filter((i) => i !== id)
            })
        }
    }

    _deleteItem = (id) => {

        this.setState({
            loading: true
        })

        const { setMessage, refresh, type } = this.props

        getToken().then((token) => {
            const data = {
                token: token
            }

            deleteItem(data, id, type)
            .then((res) => {
                setMessage(res)
                refresh()

                this.setState({
                    loading: false
                })
            })

        })
    }

    _trash = (id, action) => {
        this.setState({
            loadingDelete: true
        })

        const ids = [
            id
        ]

        this.props.trash(ids, action)
    }

    _trashMultiple = (action) => {
        this.setState({
            loadingDelete: true
        })

        const ids = this.state.selectedIds

        this.props.trash(ids, action)
    }

    _renderMultipleSelectActions = () => {
        return (
            <MultipleActions>
                <div className='row'>
                    <div className='col-sm-1'>
                        <p>Vybráno: {this.state.selectedIds.length}</p>
                    </div>
                    <div className='col-sm-6'>
                        <this.deleteDateModalMultiple />
                        <Button onClick={() => this.clearSelected()} className='ghost'>Vyprázdnit</Button>
                    </div>
                </div>
            </MultipleActions>
        )
    }

    _changeTime = (id) => {

        const { type, setMessage, refresh } = this.props

        getToken().then((token) => {
            const updateData = {
                token: token,
                id: id,
                time: this.state.data.new_time,
                change_all: this.state.data.change_all
            }

            changeLectureTime(updateData, type)
            .then((res) => {
                setMessage(res)
                refresh()
            })
        })
    }

    onChange = (e) => this.setState({
        data: {
            ...this.state.data,
            [e.target.name]: e.target.value
        }
    })

    handleCheckbox = (e, data) => {
        this.setState({
            data: {
                ...this.state.data,
                [data.name]: data.checked
            } 
        })
    }

    deleteDateModal = (id_item) => {
        return (
            <Modal trigger={<Dropdown.Item icon='trash' text='Smazat termín lekce' />} onClose={this.hideModal}>
                <Modal.Header>
                    Smazat tento termín?
                </Modal.Header>
                <Modal.Content>
                    <p>Spolu s termínem se smažou i záznamy o přihlášených účastnících.</p>
                    <p><strong>Tuto akci nelze vrátit zpět.</strong> Pokud si nejste jisti, můžete u lekce pouze vypnout přihlašování.</p>
                </Modal.Content>
                <Modal.Actions className='justify-content-end'>
                    <Button className='red' onClick={() => this._trash(id_item, 'date')} loading={this.state.loadingDelete} disabled={this.state.loadingDelete}>Smazat termín</Button>
                </Modal.Actions>
            </Modal>
        )
    }

    deleteDateModalMultiple = () => {
        return (
            <Modal trigger={<Button className='red'>Smazat termíny</Button>} onClose={this.hideModal}>
                <Modal.Header>
                    Smazat tyto termíny?
                </Modal.Header>
                <Modal.Content>
                    <p>Spolu s termínem se smažou i záznamy o přihlášených účastnících.</p>
                    <p><strong>Tuto akci nelze vrátit zpět.</strong> Pokud si nejste jisti, můžete u lekce pouze vypnout přihlašování.</p>
                </Modal.Content>
                <Modal.Actions className='justify-content-end'>
                    <Button className='red' onClick={() => this._trashMultiple('date')} loading={this.state.loadingDelete} disabled={this.state.loadingDelete}>Smazat termíny</Button>
                </Modal.Actions>
            </Modal>
        )
    }

    changeTimeModal = (id_item) => {
        return (
            <Modal trigger={<Dropdown.Item icon='clock outline' text='Změnit čas' />} onClose={this.hideModal}>
                <Form onSubmit={() => this._changeTime(id_item)}>
                    <Modal.Header>
                        Změna času termínu #{id_item}
                    </Modal.Header>
                    <Modal.Content>
                        <Form.Field>
                            <Form.Input 
                                type='time'
                                name='new_time'
                                label='Nový čas'
                                required
                                placeholder='Zadejte nový čas'
                                onChange={this.onChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Checkbox
                                label='Použít pro všechny termíny v tento den a hodinu'
                                name='change_all'
                                onChange={this.handleCheckbox}
                            />
                        </Form.Field>
                    </Modal.Content>
                    <Modal.Actions className='justify-content-end'>
                        <Button primary loading={this.state.loadingDelete} disabled={this.state.loadingDelete}>Provést změnu</Button>
                    </Modal.Actions>
                </Form>
            </Modal>
        )
    }

    _changeLectureModal = (type, id_signed, age_group = 0) => {

        const { setMessage, refresh } = this.props

        return (
            <ChangeLectureModal type={type} id={id_signed} refresh={refresh} setMessage={setMessage} ageGroup={age_group} />
        )
    }
    
    onChangeSelect = (e, data, id_date, type) => {
        getToken().then((token) => {
            const updateData = {
                token: token,
                id: id_date,
                values: {
                    [data.name]: data.value
                }
            }

            changeDateProps(updateData, type)
            .then((res) => {
                this.props.setMessage(res)
                this.props.refresh()
            })
        })
    } 

    render() {

        const { items, type } = this.props

        return (
            <div className='admin_table_wrapper'>
                <Table striped className='main-table select_table' unstackable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell
                                width={1}
                            >
                                
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                width={5}
                                className='mw_200'
                            >
                                Datum a čas (pol.)
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                width={3}
                            >
                                Výměna
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                width={3}
                            >
                                Přihlašování
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                width={3}
                            >
                                Lekce zdarma
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                width={1}
                            >
                                
                            </Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                    {_.map(items, ({ id_date, date, can_change, state, is_free, semester }, index) => (
                        <Table.Row key={id_date}>
                            <Table.Cell>
                                <Checkbox checked={this.state.selectedIds.includes(id_date) ? true : false} onChange={(event, data) => this.handleMultipleSelect(event, data, id_date)} />
                            </Table.Cell>
                            <Table.Cell>
                                {moment(date).format('DD. MM. YYYY H:ss')} ({semester}.)
                            </Table.Cell>
            
                            <Table.Cell className='text-center'>
                                <Dropdown 
                                    name='can_change'
                                    placeholder='Vyberte možnost'
                                    options={yesNoOpts}
                                    value={can_change}
                                    onChange={(e, data) => this.onChangeSelect(e, data, id_date, type)}
                                    selection
                                    fluid
                                    className={'state-active-' + can_change}
                                />
                            </Table.Cell>

                            <Table.Cell className='text-right'>
                                <Dropdown 
                                    name='state'
                                    placeholder='Vyberte možnost'
                                    options={yesNoOpts}
                                    value={state}
                                    onChange={(e, data) => this.onChangeSelect(e, data, id_date, type)}
                                    selection
                                    fluid
                                    className={'state-active-' + state}
                                />
                            </Table.Cell>

                            <Table.Cell className='text-right'>
                                <Dropdown 
                                    name='is_free'
                                    placeholder='Vyberte možnost'
                                    options={yesNoOpts}
                                    value={Number(is_free)}
                                    onChange={(e, data) => this.onChangeSelect(e, data, id_date, type)}
                                    selection
                                    fluid
                                    className={'state-active-' + is_free}
                                />
                            </Table.Cell>

                            <Table.Cell>
                                <Dropdown text='' icon='ellipsis vertical' inline>
                                    <Dropdown.Menu>
                                        {this.changeTimeModal(id_date)}
                                        {/*<Dropdown.Item icon='calendar alternate outline' text='Změnit datum' />*/}
                                        <Dropdown.Divider />
                                        {this.deleteDateModal(id_date)}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Table.Cell>

                        </Table.Row>
                    ))}
                    </Table.Body>
                </Table>
                {this.state.selectedIds.length !== 0 &&
                    <this._renderMultipleSelectActions />
                }
            </div>
        )
    }
}

export default DatesTable