import React, { Component } from 'react'
import { getOrganizations } from '../../api'
import { OrganizationsTable, EmptyTable } from '../containers'
import { Card, CardControls, Button, CardHeader } from '../../styles'
import { Link } from 'react-router-dom'
import _ from 'loadsh'
import { Dropdown, Input } from 'semantic-ui-react'

const stateOptions = [
    {key: '1', value: null, text: 'Vše'},
    {key: '2', value: 0, text: 'Nezveřejněno'},
    {key: '3', value: 1, text: 'Zveřejněno'}
]

class OrganizationsView extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            organizations: [],
            baseData: [],
            column: 'id_organization',
            direction: 'descending',
            hasSome: false,
            filter: false
        })
    }

    componentDidMount() {
        this.getOrganizationsHandler()
    }

    getOrganizationsHandler = () => {
        getOrganizations()
        .then((data) => {
            if(data.length !== 0) {
                if(_.keys(this.state.filters).length !== 0) {
                    this.setState({
                        organizations: _.filter(data, this.state.filters),
                        baseData: data,
                        hasSome: true
                    })
                } else {
                    this.setState({
                        organizations: data,
                        baseData: data,
                        hasSome: true
                    })
                }
            } else {
                this.setState({
                    hasSome: false
                })
            }
        })
        .catch((err) => {
            console.log(err)
        })
    }

    handleSort = (clickedColumn) => () => {
        const { column, organizations, direction } = this.state
    
        if (column !== clickedColumn) {
          this.setState({
            column: clickedColumn,
            organizations: _.sortBy(organizations, [clickedColumn]),
            direction: 'ascending',
          })
    
          return
        }
    
        this.setState({
          data: organizations.reverse(),
          direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    }

    onChangeSelect = (e, data) => {
        if(data.value !== null) {
            this.setState({
                filter: true,
                filters: {
                    ...this.state.filters,
                    [data.name]: data.value
                }
            }, () => {
                this.setState({
                    organizations: _.filter(this.state.baseData, this.state.filters)
                })
            })
        } else {
            this.setState({
                filters: _.omit(this.state.filters, [data.name])
            }, () => {
                this.setState({
                    organizations: _.keys(this.state.filters).length !== 0 ? _.filter(this.state.baseData, this.state.filters) : this.state.baseData,
                    filter: _.keys(this.state.filters).length !== 0 ? true : false
                })
            })
        }
    }

    render() {
        return (
            <div>
            {this.state.hasSome ?
            <Card>
                <CardHeader>
                    <div className='row'>
                        <div className='col-sm-9 text-left'>
                            <h2>Správa organizací</h2>
                        </div>
                        <div className='col-sm-3 text-right'>
                            <Link to={`${process.env.PUBLIC_URL}/admin/manage/organizations/edit/new`}><Button icon="\efc2">Nový</Button></Link>
                        </div>
                    </div>
                </CardHeader>
                <CardControls>
                    <div className='row'>
                        <div className='col-sm-6 text-left'>
                            <Input 
                                placeholder='Hledat...'
                                fluid
                                icon='search'
                            />
                        </div>
                        <div className='col-sm-6 text-left'>
                            <Dropdown
                                name='organization_state'
                                options={stateOptions}
                                placeholder='Vyberte stav'
                                selection
                                onChange={this.onChangeSelect}
                                fluid
                            />
                        </div>
                    </div>
                </CardControls>
                <OrganizationsTable items={this.state.organizations} refresh={this.getOrganizationsHandler} sort={this.handleSort} column={this.state.column} direction={this.state.direction} /> 
            </Card>
            : <EmptyTable section='Správa organizací' text='Nemáte vytvořenou žádnou organizaci. Vytvořte nejdříve první záznam.' link='/manage/organizations/edit/new' />}
            </div>
        )
    }

}

export default OrganizationsView