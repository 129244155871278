import React from 'react'
import { Form, Button, Dropdown } from 'semantic-ui-react'
import { FormButtonGroup, InnerImage } from '../../styles'
import Dropzone from 'react-dropzone'
import { url } from '../../config'

const options = [
    { key: 'no', value: 0, text: 'Nezveřejněno' },
    { key: 'yes', value: 1, text: 'Zveřejněno' },
]

const CampSecondaryForm = ({ camp, onChangeSelect, loading, onDrop, isNew, submit }) => {

    const maxSize = 524288;

    return (
        <Form loading={loading} onSubmit={submit}>
            <Form.Field>
                <Dropdown 
                    name='camp_state'
                    placeholder='Vyberte možnost'
                    options={options}
                    value={camp.camp_state}
                    onChange={onChangeSelect}
                    selection
                    className={'state-active-' + camp.camp_state}
                />
            </Form.Field>

            <Form.Field>
                <label>Ilustrační obrázek</label>
                <Dropzone onDrop={onDrop} accept="image/png, image/jpeg, image/gif" minSize={0} maxSize={maxSize} multiple={false}>
                    {({getRootProps, getInputProps, rejectedFiles, isDragReject, acceptedFiles}) => {
                        const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
                        return (
                            <section className="">
                                <div {...getRootProps({className: 'dropzone p-3 text-center rad'})}>
                                    <input {...getInputProps()} />
                                    <i class="icofont-upload-alt"></i>
                                    {acceptedFiles.length > 0 ? acceptedFiles.map(acceptedFile => (
                                        <p>{acceptedFile.name}</p>
                                    )) : <p>Drag &amp; drop, or click to select files</p>}
                                    
                                    {isFileTooLarge && (
                                        <div className="text-danger mt-2">
                                        Obrázek je moc velký.
                                        </div>
                                    )}

                                    {isDragReject && (
                                        <div className="text-danger mt-2">
                                        Tento formát není podporován.
                                        </div>
                                    )}
                                </div>
                            </section>
                        )
                    }}
                </Dropzone>
                {camp.camp_logo !== '' && !isNew &&
                    <InnerImage src={url.images + 'camp/' + camp.id_camp + '/' + camp.camp_logo} className='img_preview' />
                }
            </Form.Field>

            <FormButtonGroup>
                <Button primary>Uložit změny</Button>
            </FormButtonGroup>
        </Form>
    )
}

export default CampSecondaryForm