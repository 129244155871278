import React from 'react'
import { Form } from 'semantic-ui-react'
import moment from 'moment'

const LectureInfoForm = ({ data, loading }) => {
    return (
        <Form loading={loading}>
            <Form.Field>
                <Form.Input 
                    type='text'
                    label='Vytvořeno'
                    value={!loading ? moment(data.created).format('D. M. YYYY H:mm') : ''}
                    disabled
                />
                <Form.Input 
                    type='text'
                    label='Vytvořil/a'
                    value={!loading ? data.created_by : ''}
                    disabled
                />
                <Form.Input 
                    type='text'
                    label='Naposledy změněno'
                    value={!loading ? moment(data.updated).format('D. M. YYYY H:mm') : ''}
                    disabled
                />
                <Form.Input 
                    type='text'
                    label='Naposledy změnil/a'
                    value={!loading ? data.updated_by : ''}
                    disabled
                />
            </Form.Field>
        </Form>
    )
}

export default LectureInfoForm