import React, { Component } from 'react'
import { Form, Button, Dropdown } from 'semantic-ui-react'
import moment from 'moment'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css';
import 'moment/locale/cs';
import { DateUtils } from 'react-day-picker';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import { Row, Col } from 'reactstrap';
import { FormButtonGroup } from '../../../styles';
import { Section } from '../../../styles/forms'
import ColorPicker from './ColorPicker'

const isFreeOpts = [
    {
        key: 0,
        value: 0,
        text: 'Ne'
    },
    {
        key: 1,
        value: 1,
        text: 'Ano'
    }
]

class CreateLectureForm extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            data: {
                valid_until: new Date(),
                name: '',
                description: '',
                capacity: '',
                is_free: 0,
                price_lecture: 0,
                price_points: 0,
                state: 1,
                venue_address: '',
                time: '',
                id_lecturer: '',
                id_location: null,
                lecturer_name: null
            },
            displayColorPicker: false
        })

        this.handleDayChange = this.handleDayChange.bind(this);
    }

    componentDidMount() {

        const { initial } = this.props

        this._setInitial(initial)

    }

    componentDidUpdate(prevProps) {
        if(this.props.initial !== prevProps.initial) {
            this._setInitial(this.props.initial)
        }
    }

    _setInitial = (data) => {
        this.setState({
            data: {
                id_workout: data.id_workout ? data.id_workout : '',
                name: data.name ? data.name : '',
                capacity: data.capacity ? data.capacity : '',
                valid_until: data.valid_until ? data.valid_until : new Date(),
                valid_from: data.valid_from ? data.valid_from : new Date(),
                description: data.description ? data.description : '',
                is_free: data.is_free ? data.is_free : 0,
                price_lecture: data.price_lecture ? data.price_lecture : '',
                price_points: data.price_points ? data.price_points : '',
                time: data.time ? data.time : '',
                id_lecturer: data.id_lecturer ? data.id_lecturer : '',
                venue_address: data.venue_address ? data.venue_address : '',
                event_color: data.event_color ? data.event_color: '',
                state: data.state ? data.state : 1,
                id_location: data.id_location ? data.id_location : null,
                id_location: data.lecturer_name ? data.lecturer_name : null
            }
        })
    }

    onChange = (e) => this.setState({
        data: {
            ...this.state.data,
            [e.target.name]: e.target.value
        }
    })

    onChangeSelect = (e, data) => this.setState({
        data: {
            ...this.state.data,
            [data.name]: data.value
        }
    })

    handleDayChange(day, modifiers, dayPickerInput) {

        const input = dayPickerInput.getInput();

        this.setState({ 
            data: {
                ...this.state.data,
                [input.name]: day
            } 
        });
    }

    submit = () => {
        this.props.submit(this.state.data)
    }

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };
    
    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };
    
    handleChange = (color) => {
        this.setState({ 
            data: {
                ...this.state.data,
                event_color: color.hex
            } 
        })
    };

    render() {

        const format = "DD/MM/YYYY";
        const { data } = this.state
        const { loading, loadingOpts, opts, isNew, locOpts, loadingLocs } = this.props

        return (
            <Form onSubmit={this.submit} loading={loading || loadingOpts || loadingLocs}>
                <Section>
                    <h4 className='heading_separator first'>Základní informace</h4>
                    <Form.Field>
                        <Form.Input
                            type='text'
                            name='name'
                            placeholder='Zadejte název lekce'
                            label='Název lekce'
                            value={data.name}
                            onChange={this.onChange}
                            required
                        />
                    </Form.Field>
                    <Row className='mb-3'>
                        <Col sm={4}>
                            <Form.Field>
                                <Form.Input 
                                    name='capacity'
                                    type='number'
                                    placeholder='Zadejte kapacitu jedné lekce'
                                    label='Kapacita jedné lekce'
                                    value={data.capacity}
                                    onChange={this.onChange}
                                    required
                                />
                            </Form.Field>
                        </Col>
                    </Row>
                    {isNew &&
                    <Row className='mb-3'>
                        <Col sm={4}>
                        <Form.Field>
                                <label>První den *</label>
                                <DayPickerInput 
                                    onDayChange={this.handleDayChange} 
                                    inputProps={{ 
                                        name: 'valid_from',
                                        locale: 'cs',
                                        localeUtils: MomentLocaleUtils,
                                    }}
                                    dayPickerProps={{ 
                                        locale: 'cs',
                                        localeUtils: MomentLocaleUtils,
                                        format: 'DD-MM-YYYY',
                                        formatDate: 'DD-MM-YYYY',
                                        parseDate: 'DD-MM-YYYY'
                                    }}
                                    format={'DD/MM/YYYY'}
                                    formatDate={formatDate}
                                    parseDate={parseDate}
                                    placeholder={new Date()}
                                    value={formatDate(this.state.data.valid_from, format)}
                                />
                            </Form.Field>
                        </Col>
                        <Col sm={4}>
                            <Form.Field>
                                <Form.Input
                                    type='time'
                                    name='time'
                                    value={data.time}
                                    onChange={this.onChange}
                                    label='Čas konání'
                                    required
                                />
                            </Form.Field>
                        </Col>
                        <Col sm={4}>
                            <Form.Field>
                                <label>Poslední den *</label>
                                <DayPickerInput 
                                    onDayChange={this.handleDayChange} 
                                    inputProps={{ 
                                        name: 'valid_until',
                                        locale: 'cs',
                                        localeUtils: MomentLocaleUtils,
                                    }}
                                    dayPickerProps={{ 
                                        locale: 'cs',
                                        localeUtils: MomentLocaleUtils,
                                        format: 'DD-MM-YYYY',
                                        formatDate: 'DD-MM-YYYY',
                                        parseDate: 'DD-MM-YYYY'
                                    }}
                                    format={'DD/MM/YYYY'}
                                    formatDate={formatDate}
                                    parseDate={parseDate}
                                    placeholder={new Date()}
                                    value={formatDate(this.state.data.valid_until, format)}
                                />
                            </Form.Field>
                        </Col>
                    </Row>
                    }
                    <Form.Field>
                        <Form.TextArea 
                            name='description'
                            label='Popis lekce'
                            placeholder='Napište popis této lekce'
                            value={data.description}
                            onChange={this.onChange}
                            required
                        />
                    </Form.Field>
                    <Row className='mb-3'>
                        <Col sm={8}>
                            {!loadingLocs &&
                            <Form.Field>
                                <label>Lokalita</label>
                                <Dropdown 
                                    name='id_location'
                                    placeholder='Vyberte lokalitu konání'
                                    options={locOpts}
                                    value={data.id_location}
                                    onChange={this.onChangeSelect}
                                    selection
                                    search
                                />
                            </Form.Field>
                            }
                        </Col>
                        <Col sm={4}>
                            <p className="form_desc small">Tato lokalita bude zobrazena na mapě a ve filtru. Pokud nemáte žádnou lokalitu vytvořenou, pokračujte zde</p>
                        </Col>
                    </Row>
                </Section>

                <Section>
                    <h4 className='heading_separator'>Platby za lekce</h4>
                    <Row className='mb-3'>
                        <Col sm={4}>
                            <Form.Field>
                                <Form.Input 
                                    type='number'
                                    name='price_lecture'
                                    placeholder='Zadejte cenu za jednu lekci'
                                    label='Cena za jednu lekci'
                                    value={data.price_lecture}
                                    onChange={this.onChange}
                                    required
                                />
                            </Form.Field>
                        </Col>
                        <Col sm={4}>
                            <Form.Field>
                                <Form.Input 
                                    type='number'
                                    name='price_points'
                                    placeholder='Zadejte počet bodů z permanentky'
                                    label='Permanentka od...'
                                    value={data.price_points}
                                    onChange={this.onChange}
                                    required
                                />
                            </Form.Field>
                        </Col>
                        <Col sm={4}>
                            <Form.Field>
                                <label>První lekce zdarma</label>
                                <Dropdown 
                                    name='is_free'
                                    placeholder='Vyberte možnost'
                                    options={isFreeOpts}
                                    value={data.is_free}
                                    onChange={this.onChangeSelect}
                                    selection
                                    className={'state-active-' + data.is_free}
                                />
                            </Form.Field>
                        </Col>
                    </Row>
                </Section>

                <Section>
                    <h4 className='heading_separator'>Lektor této lekce</h4>
                    <Row className='mb-3'>
                        <Col sm={8}>
                            {!loadingOpts &&
                            <Form.Field>
                                <label>Lektor *</label>
                                <Dropdown 
                                    name='id_lecturer'
                                    placeholder='Vyberte možnost'
                                    options={opts}
                                    value={data.id_lecturer}
                                    onChange={this.onChangeSelect}
                                    selection
                                />
                            </Form.Field>
                            }
                        </Col>
                        <Col sm={4}>
                            <p className="form_desc small">Tento lektor bude mít přístup ke správě přihlášených uživatelů na lekci</p>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col sm={8}>
                            <Form.Field>
                                <Form.Input 
                                    type='text'
                                    name='lecturer_name'
                                    placeholder='Zadejte jméno lektora'
                                    label='Lektor bez uživatele'
                                    value={data.lecturer_name}
                                    onChange={this.onChange}
                                />
                            </Form.Field>
                        </Col>
                        <Col sm={4}>
                            <p className="form_desc small">Pouze jméno lektora. Lekce nebudou navázaný na žádný účet</p>
                        </Col>
                    </Row>
                </Section>

                <Section>
                    <h4 className='heading_separator'>Zobrazení v kalendáři</h4>
                    <Row className='mb-3'>
                        <Col sm={8}>
                            <Form.Field>
                                <label>Barva lekce v kalendáři</label>
                                <ColorPicker
                                    displayColorPicker={this.state.displayColorPicker}
                                    handleClick={this.handleClick}
                                    handleClose={this.handleClose}
                                    event_color={this.state.data.event_color}
                                    handleChange={this.handleChange}
                                />
                            </Form.Field>
                        </Col>
                        <Col sm={4}>
                            <p className="form_desc small">Tato barva se bude zobrazovat v kalendáři a ve widgetech v klubu</p>
                        </Col>
                    </Row>
                </Section>
                    
                <FormButtonGroup>
                    <Button primary>Uložit lekci</Button>
                </FormButtonGroup>
            </Form>
        )
    }

}

export default CreateLectureForm