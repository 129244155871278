import React from 'react'
import { url } from "../config"

// Workouts
export function addToWatchlist(data, type) {
    return fetch(url.api + 'watchlist/add/' + type, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function getWatchlist(data, type) {
    return fetch(url.api + 'watchlist/get/all/' + type, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function deleteItem(data, id_item, type) {
    return fetch(url.api + 'watchlist/delete/' + id_item + '/' + type, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}