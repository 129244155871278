import React, { Component } from 'react'
import { dateFilterFrom, getDatesAdmin, getLocationsFilter, getLocationsFilterAdmin, getToken } from '../../api'
import EmptyTable from '../containers/EmptyTable'
import { CampsTable, DatesTable } from '../containers'
import { Card, CardControls, CardHeader, Button } from '../../styles'
import { Link } from 'react-router-dom'
import _ from 'loadsh'
import { Dropdown, Input, Dimmer, Loader } from 'semantic-ui-react'
import { connect } from 'react-redux'

const typeOptions = [
    {key: '1', value: null, text: 'Vše'},
    {key: '2', value: 6, text: 'Kidsjoy'},
    {key: '3', value: 2, text: 'Sportjoy'},
    {key: '4', value: 7, text: 'Babyjoy'}
]

const stateOptions = [
    {key: '1', value: null, text: 'Vše'},
    {key: '2', value: 1, text: 'Zaplněné'},
    {key: '3', value: 0, text: 'Volné'},
]

const dateOptions = [
    {key: '1', value: null, text: 'Vše'},
    {key: '2', value: '2020-07-06', text: '6.7. - 10.7.2020'},
    {key: '3', value: '2020-07-13', text: '13.7. - 17.7.2020'},
    {key: '4', value: '2020-07-20', text: '20.7. - 24.7.2020'},
    {key: '5', value: '2020-07-27', text: '27.7. - 31.7.2020'},
    {key: '6', value: '2020-08-03', text: '3.8. - 7.8.2020'},
    {key: '7', value: '2020-08-10', text: '10.8. - 14.8.2020'},
    {key: '8', value: '2020-08-17', text: '17.8. - 21.8.2020'},
    {key: '9', value: '2020-08-24', text: '24.8. - 28.8.2020'}
]

class DatesView extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            dates: [
                {
                    id_date: 0,
                    organization_name: 'Kidsjoy',
                    date_date: '2019-05-08',
                    date_capacity: 300,
                    location_name: 'Praha 6',
                    date_state: 1
                }
            ],
            baseData: [],
            org: [
                {key: '0', value: null, text: 'Vše'},
            ],
            locOptions: [
                {key: '0', value: null, text: 'Vše'},
            ],
            hasSome: true,
            column: 'id_date',
            direction: 'descending',
            filter: false,
            loading: true
        })
    }

    componentDidMount() {
        if(!this.props.loadingUser) {
            this._getLocationsFilter()
            this.getDatesHandler()
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.loadingUser !== this.props.loadingUser && !this.props.loadingUser) {
            this._getLocationsFilter()
            this.getDatesHandler()
        }
    }

    _getLocationsFilter = () => {

        const { user } = this.props

        getToken().then((token) => {
            const data = {
                token: token,
                id_org: user.id_organization
            }

            getLocationsFilterAdmin(data)
            .then((res) => {
                if(res.code === 1) {
                    this.setState({
                        locOptions: this.state.locOptions.concat(res.data)
                    })
                } else {
                    console.log(res.text)
                }
            })
        })
    }

    getDatesHandler = () => {

        const { user } = this.props

        getToken().then((token) => {
            const data = {
                token: token,
                id_org: user.id_organization,
                limit: 100,
                offset: 0
            }

            getDatesAdmin(data)
            .then((res) => {
                if(res.code === 1) {
                    if(_.keys(this.state.filters).length !== 0) {
                        this.setState({
                            dates: _.filter(res.data, this.state.filters),
                            baseData: res.data,
                            hasSome: true,
                            loading: false,
                        })
                    } else {
                        this.setState({
                            dates: res.data,
                            baseData: res.data,
                            hasSome: true,
                            loading: false,
                        })
                    }
                } else {
                    this.setState({
                        hasSome: false,
                        loading: false
                    })
                }
            })
        })
    }

    getFilteredHandler = (date) => {
        dateFilterFrom(date)
        .then((dates) => {
            if(dates.length !== 0) {
                if(_.keys(this.state.filters).length !== 0) {
                    this.setState({
                        dates: _.filter(dates, this.state.filters),
                        baseData: dates,
                        hasSome: true
                    })
                } else {
                    this.setState({
                        dates: dates,
                        baseData: dates,
                        hasSome: true
                    })
                }
            } else {
                this.setState({
                    hasSome: false
                })
            }
        })
    }

    onChangeSelect = (e, data) => {
        if(data.value !== null) {
            this.setState({
                filter: true,
                filters: {
                    ...this.state.filters,
                    [data.name]: data.value
                }
            }, () => {
                this.setState({
                    dates: _.filter(this.state.baseData, this.state.filters)
                })
            })
        } else {
            this.setState({
                filters: _.omit(this.state.filters, [data.name])
            }, () => {
                this.setState({
                    dates: _.keys(this.state.filters).length !== 0 ? _.filter(this.state.baseData, this.state.filters) : this.state.baseData,
                    filter: _.keys(this.state.filters).length !== 0 ? true : false
                })
            })
        }
    }

    onChangeDate = (e, data) => {
        this.setState({
            [data.name]: data.value
        }, () => {
            if(this.state.filter_date_from !== '' && this.state.filter_date_from !== null) {
                this.getFilteredHandler(this.state.filter_date_from)
            } else {
                this.getDatesHandler()
            }
        })
    }

    handleSort = (clickedColumn) => () => {
        const { column, dates, direction } = this.state
    
        if (column !== clickedColumn) {
          this.setState({
            column: clickedColumn,
            dates: _.sortBy(dates, [clickedColumn]),
            direction: 'ascending',
          })
    
          return
        }
    
        this.setState({
          data: dates.reverse(),
          direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    }

    render() {
        return (
            <div>
            {!this.state.hasSome ? 
                <EmptyTable section={"Termíny"} text={"Nemáte vytvořený žádný termín. Vytvořte nejdříve první záznam."} link={'/manage/dates/edit/new'}/>
                :
                <Card>
                    <CardHeader>
                        <div className='row'>
                            <div className='col-sm-9 text-left'>
                                <h2>Termíny</h2>
                            </div>
                            <div className='col-sm-3 text-right'>
                                <Link to={`${process.env.PUBLIC_URL}/admin/manage/dates/edit/new`}><Button icon="\efc2">Nový</Button></Link>
                            </div>
                        </div>
                    </CardHeader>
                    <CardControls>
                    <div className='row'>
                            <div className='col-sm-3 text-left'>
                                <Dropdown
                                    name='id_camp'
                                    options={typeOptions}
                                    placeholder='Vyberte tábor'
                                    selection
                                    search
                                    onChange={this.onChangeSelect}
                                    fluid
                                />
                            </div>
                            <div className='col-sm-3 text-left'>
                                <Dropdown
                                    name='id_location'
                                    options={this.state.locOptions}
                                    placeholder='Vyberte lokalitu'
                                    selection
                                    search
                                    onChange={this.onChangeSelect}
                                    fluid
                                />
                            </div>
                            <div className='col-sm-3 text-left'>
                                <Dropdown
                                    name='filter_date_from'
                                    options={dateOptions}
                                    placeholder='Vyberte termín'
                                    selection
                                    onChange={this.onChangeDate}
                                    fluid
                                />
                            </div>
                            <div className='col-sm-3 text-left'>
                                <Dropdown
                                    name='is_full'
                                    options={stateOptions}
                                    placeholder='Zaplněné / volné'
                                    selection
                                    onChange={this.onChangeSelect}
                                    fluid
                                />
                            </div>
                        </div>
                    </CardControls>
                    <DatesTable items={this.state.dates} refresh={this.getDatesHandler} sort={this.handleSort} column={this.state.column} direction={this.state.direction} />

                    <Dimmer active={this.state.loading}>
                        <Loader>Načítám data</Loader>
                    </Dimmer>
                </Card>
            }
            </div>
        )
    }

}

const mapStateToProps = state => ({
    user: state.user.user,
    loadingUser: state.user.loading
})
  
export default connect(mapStateToProps, {})(DatesView)