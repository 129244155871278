import styled from 'styled-components'

export const DashboardWidget = styled.div`
    width: 100%;
    background-color: ${props => props.bg};
    padding: 1.5rem;
    box-shadow: 0 0 2rem 0 rgba(136,152,170,.3);
    border-radius: calc(.375rem - 1px);
    margin-bottom: 20px;

    .ui.button {
        background: #fff;
        color: ${props => props.bg};
    }

    @media (max-width: 767px) {
        .mt-sm-3 {
            margin-top: 1rem;
        }
    } 
    
`
export const WidgetName = styled.h2`
    color: #fff;
    font-weight: 700;
    font-size: 18px;
`

export const WidgetDesc = styled.p`
    color: #fff;
    font-weight: 400;
`

export const WidgetDate = styled.p`
    color: #fff;
    font-weight: 700;

    @media (max-width: 767px) {
        text-align: left;
    }
`

export const WidgetType = styled.p`
    font-size: 80%;
    color: rgba(255,255,255,.7);
`