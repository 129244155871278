import React from 'react'
import { StuckContainer } from '../../styles'

const EmbedNavigation = ({utm, type, heading}) => {
    return (
        <StuckContainer>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-2'>
                        <div className="logo">
                            <div className="logo-image">
                                <a href="https://www.kidsjoy.cz/" title="Kidsjoy">
                                    <img className="logo-img" src={utm === 'kidsjoy' ? "https://www.kidsjoy.cz/images/kidsjoy-logo.png" : "https://www.kidsjoy.cz/images/sportjoy-web-logo.png"} alt="Kidsjoy" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-10'>
                        {type !== 'voucher' ?
                        <h1>{heading}</h1>
                        :
                        <h1>Zakoupení voucheru</h1>
                        }
                        
                    </div>
                </div>
            </div>
        </StuckContainer>
    )
}

export default EmbedNavigation