import React, { Component } from 'react'
import { Form, Button } from 'semantic-ui-react'
import firebase from 'firebase'
import { LoginFormWrapper, LoginLogo } from '../../styles'
import { Images } from '../../config'
import { connect } from 'react-redux'
import { setMessage } from '../../actions/messageActions'

class LoginView extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            email: '',
            passwd: '',
            loading: false
        })
    }

    onChange = (e) => this.setState({
        [e.target.name]: e.target.value
    })

    onSubmit = () => {

        this.setState({
            loading: true
        })

        firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.passwd)
        .then(() => {
            this.setState({
                loading: false
            })

            firebase.auth().currentUser.getIdTokenResult()
            .then((idTokenResult) => {
                if (!!idTokenResult.claims.isAdmin || idTokenResult.claims.isAdmin === undefined) {
                    this.props.setMessage({type: 'success', text: 'Jste úspěšně přihlášen/a'})
                    this.props.history.push('/admin/dashboard')
                } else {
                    this.props.setMessage({type: 'success', text: 'Jste úspěšně přihlášen/a'})
                    this.props.history.push('/klub/nastenka')
                }
            })
        })
        .catch((err) => {
            console.log(err.message)
            this.setState({
                loading: false
            })
        })
    }

    render() {
        return (
            <LoginFormWrapper>
                <LoginLogo src={Images.logo} />
                <h1>Přihlašte se prosím</h1>
                <Form onSubmit={this.onSubmit} loading={this.state.loading}>
                    <Form.Field>
                        <label>Email</label>
                        <input
                            type="email"
                            placeholder="Email"
                            name="email"
                            value={this.state.email}
                            onChange={this.onChange}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Heslo</label>
                        <input
                            type="password"
                            placeholder="Heslo"
                            name="passwd"
                            value={this.state.passwd}
                            onChange={this.onChange}
                        />
                    </Form.Field>
                    <Button primary>Přihlásit se</Button>
                </Form>
            </LoginFormWrapper>
        )
    }

}

const mapStateToProps = state => ({})
  
export default connect(mapStateToProps, { setMessage })(LoginView)