import React, { Component } from 'react'
import { Form, Button, TextArea } from 'semantic-ui-react'
import { FormButtonGroup, FormSection, FormSectionHead, FormSectionBody } from '../../../styles'
import moment from 'moment'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css';
import 'moment/locale/cs';
import { DateUtils } from 'react-day-picker';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
  } from 'react-day-picker/moment';

class KidsForm extends Component {

    constructor(props) {
        super(props)
        this.handleDayChange = this.handleDayChange.bind(this);
        this.state = ({
            buttonActive: true,
            isFilled: false,
            basicKid: [{
                label: 'Jméno',
                type: 'text',
                name: 'first_name',
                placeholder: 'Zadejte křestní jméno',
                id: 'first_name',
                required: true,
                width: 12
            },
            {
                label: 'Přijímení',
                type: 'text',
                name: 'last_name',
                placeholder: 'Zadejte přijímení',
                id: 'last_name',
                required: true,
                width: 12
            },
            {
                label: 'Datum narození ve tvaru (DD/MM/RRRR)',
                type: 'date',
                name: 'birthday',
                placeholder: 'Datum narození',
                id: 'birthday',
                required: true,
                width: 12
            },
            {
                label: 'Zdravotní omezení',
                type: 'textarea',
                name: 'health_issues',
                placeholder: 'Alergie na pyl, včelí bodnutí, skolióza, silné dipotrické brýle, ...',
                id: 'health_issues',
                required: false,
                width: 12
            }],
            formFields: [
                [{
                    label: 'Jméno',
                    type: 'text',
                    name: 'first_name',
                    placeholder: 'Zadejte křestní jméno',
                    id: 'first_name',
                    required: true,
                    width: 12
                },
                {
                    label: 'Přijímení',
                    type: 'text',
                    name: 'last_name',
                    placeholder: 'Zadejte přijímení',
                    id: 'last_name',
                    required: true,
                    width: 12
                },
                {
                    label: 'Datum narození ve tvaru (DD/MM/RRRR)',
                    type: 'date',
                    name: 'birthday',
                    placeholder: 'Datum narození',
                    id: 'birthday',
                    required: true,
                    width: 12
                },
                {
                    label: 'Zdravotní omezení',
                    type: 'textarea',
                    name: 'health_issues',
                    placeholder: 'Alergie na pyl, včelí bodnutí, skolióza, silné dipotrické brýle, ...',
                    id: 'health_issues',
                    required: false,
                    width: 12
                }]
            ],
            data: [
                {
                    first_name: '',
                    last_name: '',
                    birthday: formatDate(new Date()),
                    health_issues: ''
                }
            ]
        })
    }

    onChange = (e) => this.setState({
        data: {
            ...this.state.data,
            [e.target.name]: e.target.name === 'birthday' ? moment(e.target.value).format('YYYY-DD-MM') : e.target.value
        }
    })

    removeParent = () => {
        this.setState({
            data: this.state.data.filter(i => i !== this.state.data[1]),
            formFields: [
                ...this.state.formFields.filter(i => i !== this.state.formFields[1])
            ],
            buttonActive: true
        })
    }

    renderFields = () => {

        const format = "DD/MM/YYYY";

        return (
            this.state.formFields.map((item, index) => {
                return (
                    <FormSection key={index}>
                        <FormSectionBody>
                            {item.map((field) => {
                                return (
                                    <Form.Field key={field.id}>
                                        <label>{field.label} {field.required ? '*' : ''}</label>
                                        {(field.type !== 'textarea' && field.name !== 'birthday') ?
                                        <input 
                                            type={field.type}
                                            name={field.name + (index+1)}
                                            placeholder={field.placeholder}
                                            id={field.id}
                                            onChange={this.handleChange}
                                            className={field.name}
                                            required={field.required}
                                            label={field.label}
                                            data-id={index}
                                        />
                                        : (field.type === 'textarea') ?
                                        <TextArea
                                            name={field.name}
                                            placeholder={field.placeholder}
                                            id={field.id}
                                            data-id={index}
                                            onChange={this.handleChange}
                                            className={field.name}
                                            required={field.required}
                                        />
                                        :
                                        <DayPickerInput 
                                            onDayChange={this.handleDayChange} 
                                            inputProps={{ 
                                                id: index, 
                                                name: 'birthday',
                                                locale: 'cs',
                                                localeUtils: MomentLocaleUtils,
                                            }}
                                            dayPickerProps={{ 
                                                locale: 'cs',
                                                localeUtils: MomentLocaleUtils,
                                                format: 'DD-MM-YYYY',
                                                formatDate: 'DD-MM-YYYY',
                                                parseDate: 'DD-MM-YYYY'
                                            }}
                                            format={'DD/MM/YYYY'}
                                            formatDate={formatDate}
                                            parseDate={parseDate}
                                            placeholder={new Date()}
                                            value={formatDate(this.state.data[index][field.name], format)}
                                        />
                                        }
                                    </Form.Field>
                                )
                            })}
                        </FormSectionBody>
                    </FormSection>
                )
            })
        )
    }

    addKidHandler = () => {
        this.setState({
            buttonActive: false,
            formFields: [
                ...this.state.formFields,
                this.state.basicKid
            ],
            data: [
                ...this.state.data,
                {
                    first_name: '',
                    last_name: '',
                    birthday: formatDate(new Date()),
                    health_issues: ''
                }
            ]
        })
    }

    handleChange = (e) => {
        if (["first_name", "last_name", "birthday", "health_issues"].includes(e.target.className) ) {
          let parents = [...this.state.data]
          parents[e.target.dataset.id][e.target.className] = e.target.value
          this.setState({ data: parents })
        }
    }

    handleDayChange(value, modifiers, dayPickerInput) {
        const input = dayPickerInput.getInput();
        if (["first_name", "last_name", "birthday", "health_issues"].includes(input.name) ) {
            const kids = [...this.state.data]
            kids[input.id][input.name] = moment(value).format('YYYY-MM-DD')
            this.setState({ data: kids })
        }
    }

    _submit = () => {
        this.props.submit(this.state.data)
    }

    render() {

        const { loading } = this.props

        return (
            <Form onSubmit={this._submit} loading={loading}>
                <this.renderFields />
                <FormButtonGroup>
                    {/*<Button className='ghost btn_end' onClick={this.addKidHandler}>Přidat další dítě</Button>*/}
                    <Button className='btn_end' primary>Další</Button>
                </FormButtonGroup>
            </Form>
        )
    }

}

export default KidsForm