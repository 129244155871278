import React from 'react'
import { Form, Button, Dropdown } from 'semantic-ui-react'
import { Row, Col } from 'reactstrap'
import { FormButtonGroup } from '../../../styles'


const activeOpts = [
    {
        key: 0,
        value: 0,
        text: 'Neaktivní'
    },
    {
        key: 1,
        value: 1,
        text: 'Aktivní'
    }
]

const PrepaidDetailForm = ({ item, onChange, lecturesFilter, onChangeSelect, submit, loading }) => {
    return (
        <Form onSubmit={submit} loading={loading}>
            <Row className='mb-3'>
                <Col sm={12}>
                    <Form.Field>
                        <Form.Input 
                            name='num_of_lectures'
                            label='Zbývající počet lekcí'
                            value={item.num_of_lectures}
                            onChange={onChange}
                        />
                    </Form.Field>
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col sm={12}>
                    <Form.Field>
                        <label>Druh lekce</label>
                        <Dropdown 
                            options={lecturesFilter}
                            name='id_workout'
                            onChange={onChangeSelect}
                            selection
                            placeholder='Druh lekce'
                            fluid
                            value={item.id_workout}
                            search
                        />
                    </Form.Field>
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col sm={12}>
                    <Form.Field>
                        <label>Aktivní</label>
                        <Dropdown 
                            options={activeOpts}
                            name='active'
                            onChange={onChangeSelect}
                            selection
                            placeholder='Druh lekce'
                            fluid
                            value={item.active}
                            className={'state-active-' + item.active}
                        />
                    </Form.Field>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <FormButtonGroup>
                        <Button primary>Uložit změny</Button>
                    </FormButtonGroup>
                </Col>
            </Row>
        </Form>
    )
}

export default PrepaidDetailForm