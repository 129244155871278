import React from 'react'
import { url } from "../config"

export function signUpNewsletter(data) {
    return fetch(url.api + 'messages/sign-up-newsletter', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}