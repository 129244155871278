import React from 'react'
import { SignedIn, SignedInText } from '../../styles'
import { Button, Icon } from 'semantic-ui-react'
import firebase from 'firebase'

function logOut() {
    firebase.auth().signOut()
}

const SignedInAs = ({ email, name }) => {
    return (
        <SignedIn>
            <SignedInText>Přihlášen/a jako: {email}</SignedInText>
            {/*<Button icon onClick={logOut}><Icon name='sign out alternate' /></Button>*/}
        </SignedIn>
    )
}

export default SignedInAs