import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import TourReservations from '../../containers/club/TourReservations'
import EventsReservations from '../../containers/club/EventsReservations'

class ClubMyReservationsView extends Component {

    _isMounted = false;

    constructor(props) {
        super(props)
        this.state = ({})
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({
            isMounted: this._isMounted
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.setState({
            isMounted: this._isMounted
        })
    }

    render() {
            return (
                <Row>
                    {this.state.isMounted &&
                    <Col sm={12} className='mb-5'>
                        <TourReservations active={false} />
                    </Col>
                    }
                    {this.state.isMounted &&
                    <Col sm={12} className='mb-5'>
                        <EventsReservations type='moms' active={false} canChange={true} />
                    </Col>
                    }
                    {this.state.isMounted &&
                    <Col sm={12} className='mb-5'>
                        <EventsReservations type='kids' active={false} canChange={true} />
                    </Col>
                    }
                    {this.state.isMounted &&
                    <Col sm={12} className='mb-5'>
                        <EventsReservations type='hobby' active={false} canChange={false} />
                    </Col>
                    }
                </Row>
            )
        
    }

}

export default ClubMyReservationsView