import React, { Component } from 'react'
import { intToBool, toggleLocationState, shortText, getToken } from '../../../api'
import { Table, Icon, Button, Popup } from 'semantic-ui-react'
import _ from 'loadsh'
import moment from 'moment'

class SelectTourTable extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            showModal: false,
            idToDelete: 2,
            loadingToggle: 3
        })
    }

    render() {

        const { items, column, direction, sort, selected, select } = this.props

        return (
            <div>
                <Table sortable striped className='main-table select_table'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell
                                sorted={column === 'tour_name' ? direction : null}
                                onClick={sort('tour_name')}
                                width={3}
                            >
                                Název zájezdu
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'date_from' ? direction : null}
                                onClick={sort('date_from')}
                                width={2}
                            >
                                Datum od
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'date_to' ? direction : null}
                                onClick={sort('date_to')}
                                width={2}
                            >
                                Datum do
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'tour_decription' ? direction : null}
                                onClick={sort('tour_decription')}
                                width={6}
                            >
                                Popis
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'capacity' ? direction : null}
                                onClick={sort('capacity')}
                                width={1}
                            >
                                Kapacita
                            </Table.HeaderCell>

                            <Table.HeaderCell width={2}></Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                    {_.map(items, ({ id_tour, tour_name, tour_description, date_from, date_to, capacity, id_organization }, index) => (
                        <Table.Row key={id_tour}>
                            <Table.Cell>
                                {tour_name}
                            </Table.Cell>
                            <Table.Cell>
                                {moment(date_from).format('DD. MM. YYYY')}
                            </Table.Cell>
                            <Table.Cell>
                                {moment(date_to).format('DD. MM. YYYY')}
                            </Table.Cell>
                            <Table.Cell>
                                {shortText(tour_description, 80)}
                            </Table.Cell>

                            <Table.Cell>
                                {capacity}
                            </Table.Cell>
                            <Table.Cell className='text-right'>
                                <Button onClick={() => select(id_tour, id_organization)} primary disabled={selected === id_tour ? true : false}>{selected === id_tour ? 'Vybráno' : 'Přihlásit'}</Button>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                    </Table.Body>
                </Table>
            </div>
        )
    }
}

export default SelectTourTable