import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setMessage } from '../../actions/messageActions'
import { Card, CardControls, CardBody, CardHeader, CardHeaderSeparator, CardHeaderSeparatorLabel } from '../../styles'
import { getDate, getUsersOrganization, getToken, getLocationsValues, createDate, updateDate, getCampsValues, updateDateSecondary, getDateApplications, getDateSubsApplications } from '../../api'
import { DateSecondaryForm, EditDateForm } from '../forms'
import { Tab } from 'semantic-ui-react'
import { DateApplicationsTable } from '../containers'
import _ from 'loadsh'
import moment from 'moment'

class EditDatesView extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            loading: false,
            date: {
                date_state: 0
            },
            organizationsValues: [],
            locationsValues: [],
            campsValues: [],
            applications: [],
            subs: [],
            kids_sum: 0,
            avg_age: 0,
            total_price: 0,
            column: 'id_kids',
            direction: 'descending',
            user_admin: 0
        })
    }

    componentDidMount() {

        const { id } = this.props.match.params
        const { loadingUser } = this.props

        if(!loadingUser) {
            this.refresh()
        }

        getUsersOrganization()
            .then((data) => this.setState({ organizationsValues: data }))
    
            getLocationsValues()
            .then((data) => this.setState({ locationsValues: data }))

            getCampsValues(this.props.user.id_organization)
            .then((data) => this.setState({ campsValues: data }))

            getDateApplications(id)
            .then((data) => {
                this.setState({
                    applications: data.data,
                    kids_sum: data.kids_sum,
                    avg_age: data.avg_age,
                    total_price: data.total_price
                })
            })

            getDateSubsApplications(id)
            .then((data) => {
                this.setState({
                    subs: data
                })
            })

        this.setState({
            user_admin: this.props.user.user_admin !== undefined ? this.props.user.user_admin : 0
        }, () => {
            console.log(this.state.user_admin)
        })
        
    }

    componentDidUpdate(prevProps) {
        if(prevProps.loadingUser !== this.props.loadingUser && !this.props.loadingUser) {

            this.refresh()

            getCampsValues(this.props.user.id_organization)
            .then((data) => this.setState({ campsValues: data }))

            this.setState({
                user_admin: this.props.user.user_admin
            }, () => {
                console.log(this.state.user_admin)
            })
        }
    }

    refresh = () => {
        const { id } = this.props.match.params
        const { user } = this.props

        if(id !== 'new') {
            getToken().then((token) => {
                const data = {
                    token: token,
                    id_item: id,
                    id_org: user.id_organization
                }

                getDate(data)
                .then((res) => {
                    if(res.code === 1) {
                        this.setState({
                            date: res.data,
                            loading: false
                        })
                    } else {
                        this.setState({
                            loading: false,
                            hasSome: false
                        })
                    }
                })
            })

        } else {
            this.setState({
                loading: false
            })
        }

        getDateApplications(id)
            .then((data) => {
                this.setState({
                    applications: data
                })
            })

            getDateSubsApplications(id)
            .then((data) => {
                this.setState({
                    subs: data
                })
            })
    }

    onChange = (e) => this.setState({
        date: {
            ...this.state.date,
            [e.target.name]: e.target.value
        }
    })

    onChangeSelect = (e, data) => this.setState({
        date: {
            ...this.state.date,
            [data.name]: data.value
        }
    })

    submit = () => {
        const { id } = this.props.match.params
        const { date } = this.state

        if(id !== 'new') {

            this.setState({
                loading: true
            })

            getToken().then((token) => {

                const data = {
                    token: token,
                    date_from: moment(date.date_from).format('YYYY-MM-DD H:mm'),
                    date_to: moment(date.date_to).format('YYYY-MM-DD H:mm'),
                    date_capacity: date.date_capacity,
                    id_camp: date.id_camp,
                    id_location: date.id_location,
                    id_date: id
                }

                console.log(data)

                updateDate(data)
                .then((res) => {
                    this.props.setMessage(res)
                    this.refresh()
                })
            })
            
        } else {

            this.setState({
                loading: true
            })

            getToken().then((idToken) => {
                this.setState({
                    date: {
                        ...this.state.date,
                        token: idToken
                    }
                }, () => {
                    createDate(this.state.date)
                    .then((res) => {
                        this.setState({
                            loading: false
                        })
                        this.props.setMessage(res)
                    })
                    .then(() => {
                        this.props.history.push('/admin/manage/dates')
                    })
                    .catch((err) => {
                        this.props.setMessage(err)
                    })
                })
            })

        }
    }

    submitSecondary = () => {

        this.setState({
            loading: true
        })

        const { id } = this.props.match.params

        getToken().then((idToken) => {
            this.setState({
                date: {
                    ...this.state.date,
                    token: idToken
                }
            }, () => {
                updateDateSecondary(id, this.state.date)
                .then((res) => {
                    this.props.setMessage(res)
                    this.setState({
                        loading: false
                    }, () => {
                        this.refresh()
                    })
                })
            })
        })
    }

    handleSort = (clickedColumn) => () => {
        const { column, applications, direction } = this.state
    
        if (column !== clickedColumn) {
          this.setState({
            column: clickedColumn,
            applications: _.sortBy(applications, [clickedColumn]),
            direction: 'ascending',
          })
    
          return
        }
    
        this.setState({
          data: applications.reverse(),
          direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    }

    panes = [
        {
          menuItem: 'Seznam přihlášených',
          render: () => <Tab.Pane attached={false}><this.renderApplicationsView /></Tab.Pane>,
        },
        {
          menuItem: 'Upravit termín',
          render: () => <Tab.Pane attached={false}><this.renderEditView /></Tab.Pane>,
        },
    ]

    renderApplicationsView = () => {
        const { id } = this.props.match.params
        return (
            <div className='row'>
                <div className='col-sm-12'>
                    <Card>
                        <CardHeader>
                            <div className='row'>
                                <div className='col-sm-6 card_header_box text-left row pr-0'>
                                    
                                        <CardHeaderSeparator className='col-sm-4'>
                                            <CardHeaderSeparatorLabel>Termín</CardHeaderSeparatorLabel>
                                            <h2>{moment(this.state.date.date_from).format('DD.')} - {moment(this.state.date.date_to).format('DD. MM. YYYY')}</h2>
                                        </CardHeaderSeparator>
                                        <CardHeaderSeparator className='col-sm-3'>
                                            <CardHeaderSeparatorLabel>Tábor</CardHeaderSeparatorLabel>
                                            <h2>{this.state.date.camp_name}</h2>
                                        </CardHeaderSeparator>
                                        <CardHeaderSeparator className='col-sm-5'>
                                            <CardHeaderSeparatorLabel>Lokalita</CardHeaderSeparatorLabel>
                                            <h2>{this.state.date.location_name}</h2>
                                        </CardHeaderSeparator>
                                    
                                </div>
                                <div className='col-sm-6 card_header_box text-right row p-0'>
                                    
                                        <CardHeaderSeparator className='col-sm-4'>
                                            <CardHeaderSeparatorLabel>Počet dětí</CardHeaderSeparatorLabel>
                                            <h2>{this.state.kids_sum}/{this.state.date.date_capacity}</h2>
                                        </CardHeaderSeparator>
                                        <CardHeaderSeparator className='col-sm-4'>
                                            <CardHeaderSeparatorLabel>Průmerný věk</CardHeaderSeparatorLabel>
                                            <h2>{this.state.avg_age} let</h2>
                                        </CardHeaderSeparator>
                                        {this.state.user_admin === 1 &&
                                        <CardHeaderSeparator className='col-sm-4'>
                                            <CardHeaderSeparatorLabel>Celkem vybráno</CardHeaderSeparatorLabel>
                                            <h2>{this.state.total_price.toLocaleString()} Kč</h2>
                                        </CardHeaderSeparator>
                                        }
                                    
                                </div>
                            </div>
                        </CardHeader>
                        <DateApplicationsTable userAdmin={this.state.user_admin} items={this.state.applications} sub={false} id_date={this.state.date.id_date} date={this.state.date.date_from} sort={this.handleSort} column={this.state.column} direction={this.state.direction} />
                    </Card>
                </div>
                {this.state.subs.length !== 0 &&
                <div className='col-sm-12'>
                    <Card>
                        <CardHeader>
                            <div className='row'>
                                <div className='col-sm-12 text-left'>
                                    <h2>Náhradníci</h2>
                                </div>
                            </div>
                        </CardHeader>
                        <DateApplicationsTable userAdmin={this.state.user_admin} items={this.state.subs} id_date={id} sub={true} refresh={this.refresh} date={this.state.date.date_from} sort={this.handleSort} column={this.state.column} direction={this.state.direction} />
                    </Card>
                </div>
                }
            </div>
        )
    }

    renderEditView = () => {
        const { id } = this.props.match.params

        return (
            <div className='row'>
                <div className='col-sm-8'>
                    <Card>
                        <CardHeader>
                            <div className='row'>
                                <div className='col-sm-6 text-left'>
                                    <h2>Základní informace</h2>
                                </div>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <EditDateForm 
                                loading={this.state.loading}
                                date={this.state.date}
                                onChange={this.onChange}
                                onChangeSelect={this.onChangeSelect}
                                camps={this.state.campsValues}
                                locations={this.state.locationsValues}
                                submit={this.submit}
                            />
                        </CardBody>
                    </Card>
                </div>
                <div className='col-sm-4'>
                    <Card>
                        <CardHeader>
                            <div className='row'>
                                <div className='col-sm-12 text-left'>
                                    <h2>Další informace</h2>
                                </div>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <DateSecondaryForm 
                                date={this.state.date}
                                loading={this.state.loading}
                                isNew={id === 'new' ? true : false}
                                onChangeSelect={this.onChangeSelect}
                                submit={this.submitSecondary}
                            />
                        </CardBody>
                    </Card>
                </div>
            </div>
        )
    }

    render() {

        const { id } = this.props.match.params

        return (
            <Tab menu={{ secondary: true, pointing: true }} panes={this.panes} />
        )
    }

}


const mapStateToProps = state => ({
    user: state.user.user,
    loadingUser: state.user.loading
})
  
export default connect(mapStateToProps, { setMessage,  })(EditDatesView)