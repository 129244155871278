import React, { Component } from 'react'
import { Navbar, Button } from '../../styles'
import firebase from 'firebase'
import { withRouter } from 'react-router-dom'
import { setMessage } from '../../actions/messageActions'
import { connect } from 'react-redux'

class NavbarContainer extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            user: {}
        })
    }

    logOut = () => {
        firebase.auth().signOut()
        this.props.history.push('/login')
        this.props.setMessage({type: 'success', text: 'Jste úspěšně odhlášen/a'})
    }

    prihlaska = () => {
        this.props.history.push(`${process.env.PUBLIC_URL}/embed/signup`)
    }

    render() {
        return (
            <Navbar>
                <Button icon='\ef7c' outline onClick={this.logOut}>Odhlásit se</Button>
            </Navbar>
        )
    }

}

const mapStateToProps = state => ({})
  
export default connect(mapStateToProps, { setMessage })(withRouter(NavbarContainer))
