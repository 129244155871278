import React, { Component } from 'react'
import { Form, Button, Dropdown } from 'semantic-ui-react'
import 'react-day-picker/lib/style.css';
import 'moment/locale/cs';
import { FormButtonGroup } from '../../../styles';
import { Section } from '../../../styles/forms'

const stateOpts = [
    {
        key: 0,
        value: 0,
        text: 'Nezveřejněno'
    },
    {
        key: 1,
        value: 1,
        text: 'Zveřejněno'
    }
]

class CreateCategoryForm extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            data: {
                category_name: ''
            },
        })
    }

    componentDidMount() {

        const { initial } = this.props

        this._setInitial(initial)

    }

    componentDidUpdate(prevProps) {
        if(this.props.initial !== prevProps.initial) {
            this._setInitial(this.props.initial)
        }
    }

    _setInitial = (data) => {
        this.setState({
            data: {
                id_category: data.id_category ? data.id_category : '',
                category_name: data.category_name ? data.category_name : '',
                state: data.state ? data.state : ''
            }
        })
    }

    onChange = (e) => this.setState({
        data: {
            ...this.state.data,
            [e.target.name]: e.target.value
        }
    })

    onChangeSelect = (e, data) => this.setState({
        data: {
            ...this.state.data,
            [data.name]: data.value
        }
    })

    submit = () => {
        this.props.submit(this.state.data)
    }

    render() {

        const { data } = this.state
        const { loading } = this.props

        return (
            <Form onSubmit={this.submit} loading={loading}>
                <Section>
                    <Form.Field>
                        <Form.Input
                            type='text'
                            name='category_name'
                            placeholder='Zadejte název kategorie'
                            label='Název kategorie'
                            value={data.category_name}
                            onChange={this.onChange}
                            required
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Stav</label>
                        <Dropdown 
                            name='state'
                            placeholder='Vyberte možnost'
                            options={stateOpts}
                            value={data.state}
                            onChange={this.onChangeSelect}
                            selection
                            className={'state-active-' + data.state}
                        />
                    </Form.Field>
                </Section>
                    
                <FormButtonGroup>
                    <Button primary>Uložit kategorii</Button>
                </FormButtonGroup>
            </Form>
        )
    }

}

export default CreateCategoryForm