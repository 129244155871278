import React, { Component } from 'react'

class ClubUserDetailView extends Component {

    constructor(props) {
        super(props)

        this.state = ({})
    }

    render() {

        const { id } = this.props.match.params

        return (
            <p>User #{id}</p>
        )
    }

}

export default ClubUserDetailView