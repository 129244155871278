import React, { Component } from 'react'
import { Card, CardHeader, CardBody } from '../../../styles'
import Schedule from '../../containers/workouts/Schedule'
import { getCalendar, getCalendarItem } from '../../../api/workouts';
import moment from 'moment'
import { Row, Col } from 'reactstrap'
import { Dropdown } from 'semantic-ui-react'

const viewOpts = [
    {
        key: 0,
        value: 'moms',
        text: 'Cvičení pro maminky'
    },
    {
        key: 1,
        value: 'kids',
        text: 'Cvičení s dětmi'
    },
    {
        key: 2,
        value: 'hobby',
        text: 'Kroužky'
    }
]

class EventsSchedule extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            items: [],
            loadingData: [],
            header: 'Rorzvh lekcí'
        })
    }

    componentDidMount() {

        const { type } = this.props.match.params

        this.setCardHeader(type)

    }

    setCardHeader = (type) => {
        if(type === 'moms') {
            this.setState({
                header: 'Rozvrh cvičení pro maminky'
            })
        } else if(type === 'kids') {
            this.setState({
                header: 'Rozvrh cvičení s dětmi'
            })
        } else if(type === 'hobby') {
            this.setState({
                header: 'Rozvrh kroužků'
            })
        }
    }

    _getCalendarItems = (date) => {
        const { type } = this.props.match.params
        const formatDate = moment(date).format('YYYY-MM-DD')

        getCalendar(formatDate, type)
        .then((res) => {

            const newEvents = []
            res.map((item) => {
                const newItem = {
                    title: item.title,
                            start: moment(item.start).toDate(),
                            end: moment(item.end).toDate(),
                            id: item.id,
                            color: item.color,
                            is_full: item.is_full,
                            age_group: type !== 'moms' ? item.age_group : null,
                            scheduleCat: item.cat,
                            description: item.description,
                            id_location: item.id_location,
                            id_workout: item.id_workout,
                            group: item.group,
                            state: item.state
                }
                newEvents.push(newItem)
            })

            this.setState({
                items: newEvents
            })
        })
    }

    _getCalendarItem = (id) => {
        const { type } = this.props.match.params

        getCalendarItem(id, type)
        .then((res) => {
            if(res.code === 1) {
                this.setState({
                    selectedItem: res.data,
                    itemExist: true
                })
            } else {
                this.setState({
                    selectedItem: {},
                    itemExist: false
                })
            }
        })
    }

    onChangeSelect = (e, data) => {
        this.props.history.push(`${process.env.PUBLIC_URL}/klub/rozvrh/${data.value}`)
    }

    render() {

        const { items, header } = this.state
        const { type } = this.props.match.params

        return (
            <Card>
                <CardHeader>
                    <Row>
                        <Col sm={9}>
                            {header}
                        </Col>
                        <Col sm={3} className='text-right'>
                            <Dropdown 
                                options={viewOpts}
                                value={type}
                                onChange={this.onChangeSelect}
                                selection
                            />
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Schedule dates={items} getData={this._getCalendarItems} getItem={this._getCalendarItem} selectedItem={this.state.selectedItem} type={type} />
                </CardBody>
            </Card>
        )
    }

}

export default EventsSchedule