import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import { Card, CardHeader, CardBody } from '../../../styles'
import CreateLectureForm from '../../forms/mom-workouts/CreateLectureForm'
import LectureInfoForm from '../../forms/mom-workouts/LectureInfoForm'
import CreateLectureKidsForm from '../../forms/kids-workouts/CreateLectureKidsForm'
import { connect } from 'react-redux'
import { setMessage } from '../../../actions/messageActions'
import { getToken, getLecturersOpts, getLocationsValues } from '../../../api'
import { getInfo, addMoreDates, trash } from '../../../api/workouts'
import moment from 'moment'
import { createWorkout, updateWorkout, getWorkoutItem, getCategoriesOpts, getGroupOpts } from '../../../api/workouts'
import CreateLectureHobbyForm from '../../forms/hobby/CreateLectureHobbyForm'
import DatesTable from '../../tables/workouts/DatesTable'
import { Modal, Button } from 'semantic-ui-react'
import MoreDatesForm from '../../forms/mom-workouts/MoreDatesForm'

class EditMomWorkoutView extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            loading: true,
            formData: {},
            loadingOpts: true,
            loadingInfo: true,
            lecturers: [],
            loadingCatOpts: true,
            loadingLocs: true,
            catOpts: [],
            locOpts: []
        })
    }

    componentDidMount() {
        const { id, type } = this.props.match.params
        const { loadingUser } = this.props

        this._getLocationsOpts()
        this._getGroupOpts(type)

        if(id !== 'new') {
            if(!loadingUser) {
                this._getWorkoutItem()
                this._getLecturersOpts()

                if(type === 'kids') {
                    this._getCategoriesOpts()
                }
            }
        } else {
            if(!loadingUser) {
                this._getLecturersOpts()
                if(type === 'kids') {
                    this._getCategoriesOpts()
                }
            }
            this.setState({
                loading: false
            })
        }
    }

    componentDidUpdate(prevProps) {

        const { id, type } = this.props.match.params
        const { loadingUser } = this.props

        if(loadingUser !== prevProps.loadingUser && !loadingUser && id !== 'new') {
            this._getWorkoutItem()
            this._getLecturersOpts()
            if(type === 'kids') {
                this._getCategoriesOpts()
            }
        }

        if(loadingUser !== prevProps.loadingUser && !loadingUser && id === 'new') {
            this._getLecturersOpts()
            if(type === 'kids') {
                this._getCategoriesOpts()
            }
        }
    }

    _getLecturersOpts = () => {

        const { user } = this.props

        getToken().then((token) => {
            const data = {
                token: token,
                id_org: user.id_organization
            }

            getLecturersOpts(data)
            .then((res) => {
                if(res.code === 1) {
                    this.setState({
                        lecturers: res.data,
                        loadingOpts: false,
                        hasLecturers: true
                    })
                } else {
                    this.setState({
                        lecturers: [{
                            key: 0,
                            value: 0,
                            text: 'Nejdříve vytvořte lektora'
                        }],
                        loadingOpts: false,
                        hasLecturers: false
                    })
                }
            })
        })
    }

    _getCategoriesOpts = () => {

        const { user } = this.props
        const { type } = this.props.match.params

        getToken().then((token) => {
            const data = {
                token: token,
                id_org: user.id_organization
            }

            getCategoriesOpts(data, type)
            .then((res) => {
                if(res.code === 1) {
                    this.setState({
                        catOpts: res.data,
                        loadingCatOpts: false,
                        hasCats: true
                    })
                } else {
                    this.setState({
                        catOpts: [{
                            key: 0,
                            value: 0,
                            text: 'Nejdříve vytvořte kategorii'
                        }],
                        loadingCatOpts: false,
                        hasCats: false
                    })
                }
            })
        })
    }

    _getWorkoutItem = () => {

        const { user } = this.props
        const { id, type } = this.props.match.params

        this.setState({
            loading: true
        })

        getToken().then((token) => {
            const data = {
                token: token,
                id_org: user.id_organization,
                id_item: id
            }

            getWorkoutItem(data, type)
            .then((res) => {

                if(res.code === 1) {
                    this.setState({
                        formData: res.data,
                        isOwner: true,
                        loading: false
                    })

                    this._getInfo()

                } else {
                    this.setState({
                        isOwner: false,
                        formData: {},
                        loading: false
                    })
                }
            })
        })

    }

    _getLocationsOpts = () => {
        getLocationsValues()
        .then((res) => {
            this.setState({
                locOpts: res,
                loadingLocs: false
            })
        })
    }

    _getInfo = () => {

        const { user } = this.props
        const { id, type } = this.props.match.params

        this.setState({
            loadingInfo: true
        })

        getToken().then((token) => {
            const data = {
                token: token,
                id_org: user.id_organization,
                id_item: id
            }

            getInfo(data, type)
            .then((res) => {
                if(res.code === 1) {
                    this.setState({
                        info: res.data,
                        loadingInfo: false
                    })
                }
            })
        })
    }
    
    submitMoms = (data) => {

        const { id, type } = this.props.match.params

        this.setState({
            loading: true
        })

        const { user, setMessage } = this.props

        getToken().then((token) => {
            var send = {
                token: token,
                name: data.name,
                valid_until: moment(data.valid_until).format('YYYY-MM-DD'),
                valid_from: moment(data.valid_from).format('YYYY-MM-DD'),
                description: data.description,
                price_lecture: data.price_lecture,
                price_points: data.price_points,
                duration: data.duration,
                capacity: data.capacity,
                is_free: data.is_free,
                id_lecturer: data.id_lecturer,
                time: data.time,
                id_organization: user.id_organization,
                state: data.state,
                created_by: user.id_user,
                updated_by: user.id_user,
                event_color: data.event_color,
                venue_address: data.venue_address,
                id_location: data.id_location,
                lecturer_name: data.lecturer_name,
                type: type
            }

            if(id === 'new') {
                createWorkout(send, type)
                .then((res) => {
                    this.setState({
                        loading: false
                    })
                    setMessage(res)
                    this.props.history.push(`${process.env.PUBLIC_URL}/admin/manage/workouts/${type}/edit/${res.link}`)
                })
            } else {
                updateWorkout(send, id, type)
                .then((res) => {
                    this.setState({
                        loading: false
                    })
                    setMessage(res)
                    this._getWorkoutItem()
                })
            }
        })
        
    }

    submitKids = (data) => {

        const { id, type } = this.props.match.params

        this.setState({
            loading: true
        })

        const { user, setMessage } = this.props

        getToken().then((token) => {
            var send = {
                token: token,
                name: data.name,
                valid_until: moment(data.valid_until).format('YYYY-MM-DD'),
                valid_from: moment(data.valid_from).format('YYYY-MM-DD'),
                description: data.description,
                price_lecture: data.price_lecture,
                duration: data.duration,
                capacity: data.capacity,
                is_free: data.is_free,
                id_lecturer: data.id_lecturer,
                time: data.time,
                id_organization: user.id_organization,
                state: data.state,
                created_by: user.id_user,
                updated_by: user.id_user,
                event_color: data.event_color,
                venue_address: data.venue_address,
                age_group: data.age_group,
                id_location: data.id_location,
                group: data.group,
                lecturer_name: data.lecturer_name,
                type: type,
                num_of_paids: data.num_of_paids
            }

            if(id === 'new') {
                createWorkout(send, type)
                .then((res) => {
                    this.setState({
                        loading: false
                    })
                    setMessage(res)
                    this.props.history.push(`${process.env.PUBLIC_URL}/admin/manage/workouts/${type}/edit/${res.link}`)
                })
            } else {
                updateWorkout(send, id, type)
                .then((res) => {
                    this.setState({
                        loading: false
                    })
                    setMessage(res)
                    this._getWorkoutItem()
                })
            }
        })
        
    }

    submitHobby = (data) => {

        const { id, type } = this.props.match.params

        this.setState({
            loading: true
        })

        const { user, setMessage } = this.props

        getToken().then((token) => {
            var send = {
                token: token,
                name: data.name,
                valid_until: moment(data.valid_until).format('YYYY-MM-DD'),
                valid_from: moment(data.valid_from).format('YYYY-MM-DD'),
                description: data.description,
                price_lecture: data.price_lecture,
                capacity: data.capacity,
                duration: data.duration,
                is_free: data.is_free,
                id_lecturer: data.id_lecturer,
                time: data.time,
                id_organization: user.id_organization,
                state: data.state,
                created_by: user.id_user,
                updated_by: user.id_user,
                event_color: data.event_color,
                venue_address: data.venue_address,
                age_group: data.age_group,
                location_filter: data.location_filter,
                id_location: data.id_location,
                lecturer_name: data.lecturer_name,
                type: type,
                price_twice_week: data.price_twice_week
            }

            if(id === 'new') {
                createWorkout(send, type)
                .then((res) => {
                    this.setState({
                        loading: false
                    })
                    setMessage(res)
                    this.props.history.push(`${process.env.PUBLIC_URL}/admin/manage/workouts/${type}/edit/${res.link}`)
                })
            } else {
                updateWorkout(send, id, type)
                .then((res) => {
                    this.setState({
                        loading: false
                    })
                    setMessage(res)
                    this._getWorkoutItem()
                })
            }
        })
        .catch((err) => {
            this.props.setMessage({type: 'error', text: 'Nepodařilo se získat bezpečnostní token, aktualizuji stránku'})
            window.location.reload();
        })
        
    }

    _moreDatesModal = () => {
        return (
            <Modal trigger={<Button primary>Přidat termíny</Button>} className='o_visible'>
                <Modal.Header>
                    Přidat termíny
                </Modal.Header>
                <Modal.Content>
                    <MoreDatesForm submit={this._addMoreDates} />
                </Modal.Content>
            </Modal>
        )
    }

    _addMoreDates = (data) => {

        const { id, type } = this.props.match.params

        getToken().then((token) => {
            const send = {
                token: token,
                valid_until: moment(data.valid_until).format('YYYY-MM-DD'),
                valid_from: moment(data.valid_from).format('YYYY-MM-DD'),
                time: data.time
            }

            addMoreDates(send, id, type)
            .then((res) => {
                this.props.setMessage(res)
                this._getWorkoutItem()
            })


        })
    }

    _getGroupOpts = (type) => {

        this.setState({
            loadingGroups: true
        })

        getGroupOpts(type)
        .then((res) => {
            this.setState({
                groupOpts: res,
                loadingGroups: false
            })
        })
    } 

    _trash = (ids, action) => {

        const { type } = this.props.match.params

        getToken().then((token) => {
            const data = {
                token: token, 
                ids
            }

            trash(data, action, type)
            .then((res) => {
                this._getWorkoutItem()
                this.props.setMessage(res)
            })
        })
    }

    render() {

        const { id, type } = this.props.match.params

        return (
            <Row>
                <Col sm={8}>
                    <Card className='mb-5'>
                        <CardHeader>
                            {id === 'new' ? 'Vytvořit novou lekci' : 'Úprava lekce'}
                        </CardHeader>
                        <CardBody>
                            {type === 'moms' &&
                            <CreateLectureForm
                                isNew={id === 'new' ? true : false}
                                loading={this.state.loading}
                                loadingOpts={this.state.loadingOpts}
                                opts={this.state.lecturers}
                                submit={this.submitMoms}
                                initial={this.state.formData}
                                loadingLocs={this.state.loadingLocs}
                                locOpts={this.state.locOpts}
                            />
                            }
                            {type === 'kids' &&
                            <CreateLectureKidsForm
                                isNew={id === 'new' ? true : false}
                                loading={this.state.loading}
                                loadingOpts={this.state.loadingOpts}
                                loadingCatOpts={this.state.loadingCatOpts}
                                loadingGroupOpts={this.state.loadingGroups}
                                groupOpts={this.state.groupOpts}
                                catOpts={this.state.catOpts}
                                opts={this.state.lecturers}
                                submit={this.submitKids}
                                initial={this.state.formData}
                                loadingLocs={this.state.loadingLocs}
                                locOpts={this.state.locOpts}
                            />
                            }
                            {type === 'hobby' &&
                            <CreateLectureHobbyForm
                                isNew={id === 'new' ? true : false}
                                loading={this.state.loading}
                                loadingOpts={this.state.loadingOpts}
                                opts={this.state.lecturers}
                                submit={this.submitHobby}
                                initial={this.state.formData}
                                loadingLocs={this.state.loadingLocs}
                                locOpts={this.state.locOpts}
                            />
                            }
                        </CardBody>
                    </Card>

                    {id !== 'new' && !this.state.loading &&
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col sm={9} className='col-6'>
                                    Termíny lekcí
                                </Col>
                                <Col sm={3} className='text-right col-6'>
                                    <this._moreDatesModal />
                                </Col>
                            </Row>
                        </CardHeader>
                        <DatesTable items={this.state.formData.dates} type={type} setMessage={this.props.setMessage} refresh={this._getWorkoutItem} trash={this._trash} />
                    </Card>
                    }
                </Col>
                <Col sm={4}>
                    {id !== 'new' &&
                    <Card>
                        <CardHeader>
                            Informace o záznamu
                        </CardHeader>
                        <CardBody>
                            <LectureInfoForm data={this.state.info} loading={this.state.loadingInfo} />
                        </CardBody>
                    </Card>
                    }
                </Col>
            </Row>
        )
    }

}

const mapStateToProps = state => ({
    user: state.user.user,
    loadingUser: state.user.loading
})
  
export default connect(mapStateToProps, { setMessage })(EditMomWorkoutView)