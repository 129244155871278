import React, { Component } from 'react'
import { intToBool, toggleCampState, toggleVoucherPaid, getToken, deleteCoupon, deleteVoucher } from '../../api'
import { AreYouSureModal } from '../modals'
import { Link } from 'react-router-dom'
import { Table, Button, Icon, Modal } from 'semantic-ui-react'
import _ from 'loadsh'
import { setMessage } from '../../actions/messageActions'
import { connect } from 'react-redux'
import moment from 'moment'

class VouchersOrdersTable extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            loadingDelete: false
        })
    }

    deleteVoucherHandler = (id) => {

        this.setState({
            loadingDelete: true
        })

        getToken().then((token) => {
            const data = {
                token: token,
                id_voucher: id 
            }

            deleteVoucher(data)
            .then((res) => {
                this.props.setMessage(res)
                this.props.refresh()

                this.setState({
                    loadingDelete: false
                })
            })
        })
    }

    _renderModalDelete = (id) => {
        return (
            <Modal trigger={<Button icon className='ghost'><Icon name='trash'/></Button>}>
                <Modal.Header>Smazat voucher #{id}?</Modal.Header>
                <Modal.Content image>
                    <Modal.Description>
                        <p>Opravdu chcete smazat tento voucher? Zákazník ho již nebude moci použít.</p>
                        <p><strong>Tuto akci nelze vrátit</strong></p>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        positive
                        icon='checkmark'
                        labelPosition='right'
                        content="Ano, chci smazat"
                        loading={this.state.loadingDelete}
                        onClick={() => this.deleteVoucherHandler(id)}
                    />
                </Modal.Actions>
            </Modal>
        )
    }

    setPaidHandler = (id) => {

        this.props.setLoading()

        getToken().then((token) => {
            const data = {
                token: token,
                id_voucher: id
            }

            toggleVoucherPaid(data)
            .then((res) => {
                this.props.setMessage(res)
                this.props.refresh()
                this.props.unsetLoading()
            })
        })
    }

    render() {

        const { items, sort, column, direction } = this.props

        return (
            <div>
                <Table sortable striped className='main-table'>
                    <Table.Header>
                        <Table.Row>

                            <Table.HeaderCell
                                width={1}
                                sorted={column === 'id_voucher' ? direction : null}
                                onClick={sort('id_voucher')}
                            >
                                #
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'code' ? direction : null}
                                onClick={sort('code')}
                                width={2}
                            >
                                Kód voucheru
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'created' ? direction : null}
                                onClick={sort('created')}
                                width={3}
                            >
                                Vytvořeno
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'buyer_name' ? direction : null}
                                onClick={sort('buyer_name')}
                                width={3}
                            >
                                Jméno
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'buyer_phone' ? direction : null}
                                onClick={sort('buyer_phone')}
                                width={2}
                            >
                                Telefon
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'value' ? direction : null}
                                onClick={sort('value')}
                                width={2}
                            >
                                Hodnota
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={column === 'paid' ? direction : null}
                                onClick={sort('paid')}
                                width={1}
                            >
                                Zaplacen
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={column === 'used' ? direction : null}
                                onClick={sort('used')}
                                width={1}
                            >
                                Využit
                            </Table.HeaderCell>
                            <Table.HeaderCell width={1}></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                <Table.Body>
                {_.map(items, ({ id_voucher, value, code, buyer_name, buyer_phone, paid, used, created }) => (
                    <Table.Row key={id_voucher}>
                        <Table.Cell width={1}>
                            {id_voucher}
                        </Table.Cell>
                        <Table.Cell width={2}>
                            {code}
                        </Table.Cell>
                        <Table.Cell width={2}>
                            {moment(created).format('D. M. YYYY H:mm')}
                        </Table.Cell>
                        <Table.Cell>
                            {buyer_name}
                        </Table.Cell>
                        <Table.Cell>
                            {buyer_phone}
                        </Table.Cell>
                        <Table.Cell>
                            {value + ',- Kč'}
                        </Table.Cell>
                        <Table.Cell width={1}>
                            {intToBool(paid) ? <i class="icofont-check-circled icon-state" onClick={() => this.setPaidHandler(id_voucher)}></i> : <i class="icofont-close-circled icon-state" onClick={() => this.setPaidHandler(id_voucher)}></i>}
                        </Table.Cell>
                        <Table.Cell width={1}>
                            {intToBool(used) ? <i class="icofont-check-circled icon-state"></i> : <i class="icofont-close-circled icon-state"></i>}
                        </Table.Cell>
                        <Table.Cell>
                            {this._renderModalDelete(id_voucher)}
                        </Table.Cell>
                    </Table.Row>
                ))}
                </Table.Body>
            </Table>
            </div>
        )
    }
}

const mapStateToProps = state => ({})
  
export default connect(mapStateToProps, { setMessage })(VouchersOrdersTable)