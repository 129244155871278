import React, { Component } from 'react'
import { getApplications, disableApplication, getDisabled, enableApplication, searchApplications, countApplications, countKids, getToken, exportPhotoAgreement, openWindowWithPost } from '../../api'
import ApplicationsTable from '../containers/ApplicationsTable'
import { Card, CardControls, CardHeader } from '../../styles'
import { Button, Input, Dropdown, Modal, Dimmer, Loader } from 'semantic-ui-react'
import _ from 'loadsh'
import { connect } from 'react-redux'
import { setMessage } from '../../actions/messageActions'
import { KidsSearchTable } from '../containers'

const paymentOptions = [
    {key: '1', value: null, text: 'Vše'},
    {key: '2', value: 0, text: 'Nezaplaceno'},
    {key: '3', value: 1, text: 'Zaplaceno'}
]

const disabledOptions = [
    {key: '1', value: 1, text: 'Aktivní přihlášky'},
    {key: '2', value: 0, text: 'Stornované přihlášky'}
]

class ApplicationsView extends Component {

    constructor(props){
        super(props)
        this.state = ({
            applications: [],
            baseData: [],
            kids: [],
            column: 'id_application',
            direction: 'descending',
            filter: false,
            disableModalOpen: false,
            state: 1,
            loadingSearch: false,
            search_query: '',
            loading: true,
            total_applications: 0,
            total_kids: 0,
            emptyApplications: false,
            emptyKids: true,
            loadingExport: false,
            limit: 20,
            offset: 0
        })
    }

    componentDidMount() {
        {!this.props.loadingUser &&
            this.getApplicationsHandler()
        }
        this.countApplicationsHandler()
        this.countKidsHandler()
    }

    componentDidUpdate(prevProps) {
        if(prevProps.loadingUser !== this.props.loadingUser) {
            this.getApplicationsHandler()
        }
    }

    countApplicationsHandler() {
        countApplications()
        .then((data) => {
            this.setState({
                total_applications: data.total_applications
            })
        })
    }

    countKidsHandler() {
        countKids()
        .then((data) => {
            this.setState({
                total_kids: data.total_kids
            })
        })
    }

    getApplicationsHandler = () => {

        const { user } = this.props

        getToken().then((token) => {
            const data = {
                token: token,
                id_org: user.id_organization,
                limit: this.state.limit,
                offset: this.state.offset
            }

            getApplications(data)
            .then((res) => {
                if(res.code === 1) {
                    this.setState({
                        applications: res.data,
                        baseData: res.data,
                        hasSome: true,
                        loading: false
                    })
                } else {
                    this.setState({
                        hasSome: false,
                        loading: false
                    })
                }
                
            })
        })
        
    }

    getDisabledHandler = () => {

        const { user } = this.props

        this.setState({
            loading: true
        })

        getToken().then((token) => {
            const data = {
                token: token,
                id_org: user.id_organization
            }

            getDisabled(data)
            .then((res) => {
                if(res.code === 1) {
                    this.setState({
                        applications: res.data,
                        baseData: res.data,
                        hasSome: true,
                        loading: false
                    })
                } else {
                    this.setState({
                        hasSome: false,
                        loading: false
                    })
                }
            })

        })
    }

    handleSort = (clickedColumn) => () => {
        const { column, applications, direction } = this.state
    
        if (column !== clickedColumn) {
          this.setState({
            column: clickedColumn,
            applications: _.sortBy(applications, [clickedColumn]),
            direction: 'ascending',
          })
    
          return
        }
    
        this.setState({
          data: applications.reverse(),
          direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    }

    onChangeSelect = (e, data) => {
        if(data.value !== null) {
            this.setState({
                filter: true,
                filters: {
                    ...this.state.filters,
                    [data.name]: data.value
                }
            }, () => {
                this.setState({
                    applications: _.filter(this.state.baseData, this.state.filters)
                })
            })
        } else {
            this.setState({
                filters: _.omit(this.state.filters, [data.name])
            }, () => {
                this.setState({
                    applications: _.keys(this.state.filters).length !== 0 ? _.filter(this.state.baseData, this.state.filters) : this.state.baseData,
                    filter: _.keys(this.state.filters).length !== 0 ? true : false
                })
            })
        }
    }

    onChangeState = (e, data) => {

        this.setState({
            state: data.value
        })

        if(data.value === 1) {
            this.getApplicationsHandler()
        } else {
            this.getDisabledHandler()
        }
    }

    disableApplicationHandler = (id) => {
        disableApplication(id)
        .then((res) => {
            this.props.setMessage(res)
            this.handleCloseDisableModal()
            this.getApplicationsHandler()
        })
    }

    enableApplicationHandler = (id) => {
        enableApplication(id)
        .then((res) => {
            this.props.setMessage(res)

            if(this.state.state === 1) {
                this.getApplicationsHandler()
            } else {
                this.getDisabledHandler()
            }
        })
    }

    handleCloseDisableModal = () => {
        this.setState({
            toDisable: '',
            disableModalOpen: false
        })
    }

    handleOpenDisableModal = (id) => {
        this.setState({
            toDisable: id,
            disableModalOpen: true
        })
    }

    searchHandler = (q) => {
        

        this.setState({
            loadingSearch: true
        })

        if(q === '') {
            this.getApplicationsHandler()
            this.setState({
                loadingSearch: false,
                kids: [],
                emptyKids: true
            })
        }

        searchApplications(q, this.state.state)
        .then((data) => {
            if(_.keys(this.state.filters).length !== 0) {
                this.setState({
                    applications: _.filter(data.applications, this.state.filters),
                    baseData: data.applications,
                    loadingSearch: false,
                    emptyApplications: data.applications.length === 0 ? true : false,
                    kids: q !== '' ? data.kids : [],
                    emptyKids: data.kids.length === 0 || q === '' ? true : false,
                })
            } else {
                this.setState({
                    applications: data.applications,
                    baseData: data.applications,
                    loadingSearch: false,
                    emptyApplications: data.applications.length === 0 ? true : false,
                    kids: q !== '' ? data.kids : [],
                    emptyKids: data.kids.length === 0 || q === '' ? true : false
                })
            }
            
        })
    }

    onSearch = (e) => this.setState({
        [e.target.name]: e.target.value
    }, () => {
        this.searchHandler(this.state.search_query)
    })

    _renderDisableModal = () => {
        return (
            <Modal open={this.state.disableModalOpen} onClose={this.handleCloseDisableModal}>
                <Modal.Header>Smazat termín</Modal.Header>
                <Modal.Content image>
                    <Modal.Description>
                        <p>Jste si jistí, že chcete stornovat tuto přihlášku?</p>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='black' onClick={this.handleCloseDisableModal}>
                        Zpět
                    </Button>
                    <Button
                        positive
                        icon='checkmark'
                        labelPosition='right'
                        content="Ano, chci stornovat"
                        onClick={() => this.disableApplicationHandler(this.state.toDisable)}
                    />
                </Modal.Actions>
            </Modal>
        )
    }

    exportPhotoHandler = () => {
        this.setState({
            loadingExport: true
        })
        getToken().then((token) => {
            window.open('https://app.kidsjoy.cz/api/www/export/export-photo/' + token, "_blank")

            this.setState({
                loadingExport: false
            })
        })
    }

    exportKidsByLocationHandler = () => {
        this.setState({
            loadingExport: true
        })
        getToken().then((token) => {
            window.open('https://app.kidsjoy.cz/api/www/export/export-by-location/' + token, "_blank")

            this.setState({
                loadingExport: false
            })
        })
    }

    _renderExportDropdown = () => {
        return (
            <Dropdown
                text='Export'
                icon='download'
                floating
                labeled
                button
                className='icon'
                loading={this.state.loadingExport}
            >
                <Dropdown.Menu>
                    <Dropdown.Header icon='file outline' content='Typ exportu' />
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={this.exportPhotoHandler} icon='camera' text='Nesouhlas s focením' />
                    <Dropdown.Item onClick={this.exportKidsByLocationHandler} icon='users' text='Děti podle lokality' />
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    render() {
        return (
            <div>
                <Card>
                    <CardHeader className='display-flex align-items-center'>
                        <div className='row'>
                            <div className='col-sm-6 text-left'>
                                <h2>Správa přihlášek</h2>
                            </div>
                            <div className='col-sm-2 text-right card-stats'>
                                <p>Počet přihlášek</p>
                                <p>{this.state.total_applications}</p>
                            </div>
                            <div className='col-sm-1 text-right card-stats'>
                                <p>Počet dětí</p>
                                <p>{this.state.total_kids}</p>
                            </div>
                            <div className='col-sm-3 text-right'>
                                <this._renderExportDropdown />
                                <Button href='https://app.kidsjoy.cz/embed/signup/kidsjoy' target='_blank' icon="\efc2">Nový</Button>
                            </div>
                        </div>
                    </CardHeader>
                    <CardControls>
                        <div className='row'>
                            <div className='col-sm-6 text-left'>
                                <Input 
                                    placeholder='Hledat...'
                                    fluid
                                    icon='search'
                                    name='search_query'
                                    loading={this.state.loadingSearch}
                                    onChange={this.onSearch}
                                />
                            </div>
                            <div className='col-sm-3 text-left'>
                                <Dropdown
                                    name='paid'
                                    options={paymentOptions}
                                    placeholder='Zaplaceno / nezaplaceno'
                                    selection
                                    onChange={this.onChangeSelect}
                                    fluid
                                />
                            </div>
                            <div className='col-sm-3 text-left'>
                                <Dropdown
                                    name='state'
                                    options={disabledOptions}
                                    placeholder='Aktivní / stornované'
                                    selection
                                    onChange={this.onChangeState}
                                    fluid
                                    value={this.state.state}
                                />
                            </div>
                        </div>
                    </CardControls>
                    {!this.state.hasSome ? 
                        <p>Nemáte žádné přihlášky</p>
                    :
                    <div>
                    {!this.state.emptyApplications &&
                    <ApplicationsTable items={this.state.applications} disable={this.handleOpenDisableModal} enable={this.enableApplicationHandler} refresh={this.getApplicationsHandler} sort={this.handleSort} column={this.state.column} direction={this.state.direction} />
                    }
                    <this._renderDisableModal />
                    </div>
                    }
                    <Dimmer active={this.state.loading}>
                        <Loader>Načítám data</Loader>
                    </Dimmer>
                </Card>
                
                {!this.state.emptyKids &&
                <Card className='mt-5'>
                    <CardHeader className='display-flex align-items-center'>
                        <div className='row'>
                            <div className='col-sm-7 text-left'>
                                <h2>Výsledky vyhledávání - děti</h2>
                            </div>
                        </div>
                    </CardHeader>
                    <KidsSearchTable items={this.state.kids} />
                </Card>
                }
            
            </div>
        )
    }

}

const mapStateToProps = state => ({
    user: state.user.user,
    loadingUser: state.user.loading
})
  
export default connect(mapStateToProps, { setMessage })(ApplicationsView)