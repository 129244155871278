import React from 'react'
import { Form, Dropdown, Button } from 'semantic-ui-react'
import { FormButtonGroup } from '../../styles'

const options = [
    { key: 'no', value: 0, text: 'Nezveřejněno' },
    { key: 'yes', value: 1, text: 'Zveřejněno' },
]

const OrganizationSecondaryForm = ({ organization, onChange, loading, submit, onChangeSelect }) => {
    return (
        <Form loading={loading} onSubmit={submit}>
            <Form.Field>
                <Dropdown 
                    name='organization_state'
                    placeholder='Vyberte možnost'
                    options={options}
                    value={organization.organization_state}
                    onChange={onChangeSelect}
                    selection
                    className={'state-active-' + organization.organization_state}
                />
            </Form.Field>
            <Form.Field>
                <label>IČ organizace</label>
                <input
                    type='text'
                    name='organization_ic'
                    id='organization_ic'
                    value={organization.organization_ic}
                    onChange={onChange}  
                />
            </Form.Field>
            <Form.Field>
                <label>DIČ organizace</label>
                <input
                    type='text'
                    name='organization_vat_num'
                    id='organization_vat_num'
                    value={organization.organization_vat_num}
                    onChange={onChange}  
                />
            </Form.Field>
            <Form.Field>
                <label>Bankovní účet</label>
                <input
                    type='text'
                    name='organization_bank_acc'
                    id='organization_bank_acc'
                    value={organization.organization_bank_acc}
                    onChange={onChange}  
                />
            </Form.Field>
            <FormButtonGroup>
                <Button primary>Uložit změny</Button>
            </FormButtonGroup>
        </Form>
    )
}

export default OrganizationSecondaryForm