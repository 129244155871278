import { url } from "../config"
import axios from 'axios'

export function getClubUsers(token) {
    return fetch(url.api + 'users/get-club-users/' + token)
    .then((data) => { return data.json() })
}

export function updateUser(data, token) {
    return axios({
        method: 'post',
        url: url.api + 'users/update',
        data: data,
        headers: { Token: token },
        withCredentials: true
    })
}