import React from 'react'
import { Form, TextArea, Button } from 'semantic-ui-react'
import { FormButtonGroup } from '../../styles'

const EditLocation = ({ location, onChange, loading, submit }) => {
    return (
        <Form loading={loading} onSubmit={submit}>
            <Form.Field>
                <Form.Input
                    type='text'
                    name='location_name'
                    id='location_name'
                    label='Název lokality'
                    value={location.location_name}
                    onChange={onChange}
                    required
                />
            </Form.Field>
            <Form.Field>
                <Form.TextArea
                    name='location_description' 
                    value={location.location_description}
                    onChange={onChange}
                    label='Popis lokality'
                    required
                />
            </Form.Field>
            <Form.Field>
                <Form.Input
                    type='text'
                    name='location_address'
                    id='location_address'
                    value={location.location_address}
                    onChange={onChange} 
                    label='Adresa lokality'
                    required
                />
            </Form.Field>
            <FormButtonGroup>
                <Button primary>Uložit změny</Button>
            </FormButtonGroup>
        </Form>
    )
}

export default EditLocation