import React, { Component } from 'react'
import { FormButtonGroup, FormSection, FormSectionBody, FormSectionHead, FormSummary, FormConfirm } from '../../../styles'
import { Form, Button, Checkbox, Message, Popup } from 'semantic-ui-react'
import { Row, Col } from 'reactstrap'
import { formatMoney } from '../../../api'

class ApplicationSummary extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            loadingForm: false,
            price: 120
        })
    }

    renderPrice() {
        const { eventItem, data, coupon, coupon_value, type } = this.props
        if(!eventItem.is_full) {
            if(type === 'hobby') {
                if(data.event.times_per_week === 2) {
                    if(coupon) {
                        return formatMoney(eventItem.price_twice_week - coupon_value)
                    } else {
                        return formatMoney(eventItem.price_twice_week)
                    }
                } else {
                    if(coupon) {
                        return formatMoney(eventItem.price_lecture - coupon_value)
                    } else {
                        return formatMoney(eventItem.price_lecture)
                    }
                    
                }
            } else {
                if(coupon) {
                    return formatMoney(eventItem.price_lecture - coupon_value)
                } else {
                    return formatMoney(eventItem.price_lecture)
                }
            }
        } else {
            return 0
        }
    }

    _renderPrice = (is_free, is_full, all, coupon, coupon_value) => {
        const isAll = all === 'all' ? true : false
        if(is_free !== 1 && !is_full && !coupon) {
            return (
                <p>K zaplacení: {this.renderPrice()},- Kč</p>
            )
        } else if(is_free === 1 && !all) {
            return (
                <p className='text-center'>Zkušební lekce zdarma</p>
            )
        } else if(is_free === 1 && all && !coupon) {
            return (
                <p>K zaplacení: {this.renderPrice()},- Kč</p>
            )
        } else if(is_full) {
            return (
                <p className='text-center'>Přihlašujete se jako náhradník</p>
            )
        } else if(is_free !== 1 && !is_full && coupon) {
            return (
                <p>Sleva: {formatMoney(coupon_value)},- Kč</p>
            )
        } else if(is_free === 1 && is_full && all && coupon) {
            return (
                <p>Sleva: {formatMoney(coupon_value)},- Kč</p>
            )
        }
    }

    render() {

        const { first_name, last_name, email, phone, loading, terms, newsletter, handleCheckbox, submit, eventItem, gdpr, data, coupon, coupon_value, loadingSend, all } = this.props
        return (
            <FormSection sticky={false}>
                <FormSectionHead>
                    <h5>Dokončení objednávky</h5>
                </FormSectionHead>
                <FormSectionBody>
                    <Form loading={this.state.loadingForm} loading={loading} onSubmit={submit}>
                        <Form.Field>
                            <Form.Input
                                type='text'
                                name='customer_name'
                                label='Vaše jméno'
                                value={first_name ? `${first_name} ${last_name}` : ''}
                                placeholder='Bude automaticky vyplněno'
                                disabled
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input
                                type='email'
                                name='customer_email'
                                label='Váš email'
                                value={`${email}`}
                                placeholder='Bude automaticky vyplněno'
                                disabled
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input
                                type='text'
                                name='customer_phone'
                                label='Váš telefon'
                                value={`${phone}`}
                                placeholder='Bude automaticky vyplněno'
                                disabled
                            />
                        </Form.Field>
                        <FormSummary>
                            {this._renderPrice(eventItem.is_free, eventItem.is_full, all, coupon, coupon_value)}
                        </FormSummary>
                        <Form.Field>
                            <Checkbox checked={terms} onChange={handleCheckbox} name='terms' label={<label className="small"><a href='https://www.1fit.cz/vseobecne-podminky' target='_blank'>Souhlasím s všeobecnými podmínkami a platebními podmínkami *</a></label>} />
                        </Form.Field>
                        <Form.Field>
                            <Checkbox checked={gdpr} onChange={handleCheckbox} name='gdpr' label={<label className="small"><a href='https://www.1fit.cz/zasady-zpracovani-a-ochrany-osobnich-udaju' target='_blank'>Souhlasím se zpracováním osobních údajů *</a></label>} />
                        </Form.Field>
                        <Form.Field>
                            <Checkbox checked={newsletter} onChange={handleCheckbox} name='newsletter' label='Souhlasím se zasíláním newsletteru' />
                        </Form.Field>
                            {!terms || !gdpr || data.person.length === 0 || data.event === '' ?
                            <Popup 
                                content='Nemáte vyplněnou celou přihlášku. Projděte nejdříve všechny kroky.' 
                                trigger={<span><Button primary={eventItem.is_full ? false : true} fluid className={eventItem.is_full && 'red'} disabled={!terms || !gdpr || data.person.length === 0 || data.event === ''}>{eventItem.is_full ? 'Přihlásit jako náhradník' : 'Odeslat objednávku'}</Button></span>}
                            />
                            :
                            <Button primary={eventItem.is_full ? false : true} fluid className={eventItem.is_full && 'red'} loading={loadingSend} disabled={!terms || !gdpr || data.person.length === 0 || data.event === '' || loadingSend}>{eventItem.is_full ? 'Přihlásit jako náhradník' : 'Odeslat objednávku'}</Button>
                        }
                        {terms && gdpr && data.person.length !== 0 && data.event === '' && 
                            <Message negative>
                                Projděte nejdříve všechny kroky v přihlášce
                            </Message>
                        }
                    </Form>
                    {this.state.success_order &&
                        <FormConfirm>
                            <div>
                                <h2>Děkujeme za vaši objednávku.</h2>
                                <p>Věnujte prosím pozornost emailu, který vám byl odeslán.</p>
                                <Button as="a" target="_blank" primary href={this.state.payment_link}>Zaplatit přihlásku</Button>
                            </div>
                        </FormConfirm>
                    }
                </FormSectionBody>
            </FormSection>
        )
    }

}

export default ApplicationSummary