import React, { Component } from 'react'
import { getVouchersOrders, getToken, searchVouchers } from '../../api'
import { Card, CardControls, CardHeader, Button } from '../../styles'
import { Dropdown, Input, Dimmer, Loader } from 'semantic-ui-react'
import { VouchersOrdersTable } from '../containers'
import _ from 'loadsh'

const paidOptions = [
    {
        value: null,
        key: 2,
        text: 'Vše'
    },
    {
        value: 0,
        key: 0,
        text: 'Nezaplaceno'
    },
    {
        value: 1,
        key: 1,
        text: 'Zaplaceno'
    }
]

const usedOptions = [
    {
        value: null,
        key: 2,
        text: 'Vše'
    },
    {
        value: 0,
        key: 0,
        text: 'Nevyužito'
    },
    {
        value: 1,
        key: 1,
        text: 'Využito'
    }
]

class VouchersOrdersView extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            orders: [],
            baseData: [],
            loadingOrders: true,
            filter: false,
            filters: {},
            column: 'id_voucher',
            direction: 'descending',
            loadingSearch: false,
            search_query: ''
        })
    }

    componentDidMount() {
        this.getOrdersHandler()
    }

    getOrdersHandler = () => {

        this.setState({
            loadingOrders: true
        })

        getToken().then((token) => {
            const data = {
                token: token
            }

            getVouchersOrders(data)
            .then((res) => {
                console.log(res.data)
                if(res.data !== 0) {
                    if(_.keys(this.state.filters).length !== 0) {
                        this.setState({
                            orders: _.filter(res.data, this.state.filters),
                            baseData: res.data,
                            hasSome: true,
                            loadingOrders: false,
                        })
                    } else {
                        this.setState({
                            orders: res.data,
                            baseData: res.data,
                            hasSome: true,
                            loadingOrders: false,
                        })
                    }
                } else {
                    this.setState({
                        hasSome: false,
                        loadingOrders: false
                    })
                }
            })
        })
        
    }

    handleSort = (clickedColumn) => () => {
        const { column, orders, direction } = this.state
    
        if (column !== clickedColumn) {
            this.setState({
                column: clickedColumn,
                orders: _.sortBy(orders, [clickedColumn]),
                direction: 'ascending',
            })
    
            return
        }
    
        this.setState({
            orders: orders.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    }

    onChangeSelect = (e, data) => {
        if(data.value !== null) {
            this.setState({
                filter: true,
                filters: {
                    ...this.state.filters,
                    [data.name]: data.value
                }
            }, () => {
                this.setState({
                    orders: _.filter(this.state.baseData, this.state.filters)
                })
            })
        } else {
            this.setState({
                filters: _.omit(this.state.filters, [data.name])
            }, () => {
                this.setState({
                    orders: _.keys(this.state.filters).length !== 0 ? _.filter(this.state.baseData, this.state.filters) : this.state.baseData,
                    filter: _.keys(this.state.filters).length !== 0 ? true : false
                })
            })
        }
    }

    onSearch = (e) => this.setState({
        [e.target.name]: e.target.value
    }, () => {
        this.searchHandler(this.state.search_query)
    })

    searchHandler = (q) => {
        

        this.setState({
            loadingSearch: true
        })

        if(q === '') {
            this.getOrdersHandler()
            this.setState({
                loadingSearch: false,
                orders: []
            })
        } else {
            searchVouchers(q)
            .then((data) => {
                if(_.keys(this.state.filters).length !== 0) {
                    this.setState({
                        orders: _.filter(data, this.state.filters),
                        baseData: data,
                        loadingSearch: false,
                    })
                } else {
                    this.setState({
                        orders: data,
                        baseData: data,
                        loadingSearch: false,
                    })
                }
                
            })
        }
    }

    setLoading = () => this.setState({ loadingOrders: true })
    unsetLoading = () => this.setState({ loadingOrders: false })

    render() {
        return (
            <Card>
                <CardHeader>
                    <div className='row'>
                        <div className='col-sm-9 text-left'>
                            <h2>Objednávky voucherů</h2>
                        </div>
                    </div>
                </CardHeader>
                <CardControls>
                    <div className='row'>
                        <div className='col-sm-6 text-left'>
                            <Input 
                                placeholder='Hledat...'
                                fluid
                                icon='search'
                                name='search_query'
                                loading={this.state.loadingSearch}
                                onChange={this.onSearch}
                            />
                        </div>
                        <div className='col-sm-3 text-left'>
                            <Dropdown
                                name='paid'
                                options={paidOptions}
                                placeholder='Zaplaceno / nezaplaceno'
                                selection
                                onChange={this.onChangeSelect}
                                fluid
                            />
                        </div>
                        <div className='col-sm-3 text-left'>
                            <Dropdown
                                name='used'
                                options={usedOptions}
                                placeholder='Využito / nevyužito'
                                selection
                                onChange={this.onChangeSelect}
                                fluid
                            />
                        </div>
                    </div>
                </CardControls>
                <VouchersOrdersTable items={this.state.orders} setLoading={this.setLoading} unsetLoading={this.unsetLoading} refresh={this.getOrdersHandler} sort={this.handleSort} column={this.state.column} direction={this.state.direction} />
                <Dimmer active={this.state.loadingOrders}>
                    <Loader>Načítám data</Loader>
                </Dimmer>
            </Card>
        )
    }

}

export default VouchersOrdersView