import React, { Component } from 'react'
import { intToBool, togglePaid, disableApplication, toggleFlag, getToken, sendMultipleReminders, sendMultipleConfirms, multipleDisable, toggleInvoice } from '../../api'
import { AreYouSureModal } from '../modals'
import { Link } from 'react-router-dom'
import { Table, Button, Icon, Modal, Checkbox } from 'semantic-ui-react'
import _ from 'loadsh'
import moment from 'moment'
import { setMessage } from '../../actions/messageActions'
import { connect } from 'react-redux'
import { MultipleActions } from '../../styles'

class ApplicationsTable extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            showModal: false,
            idToDelete: 2,
            toChangePayment: '',
            modalPayment: false,
            selectedIds: [],
            loading: {
                reminder: false,
                confirm: false,
                disable: false
            },
            loadingToggle: false
        })
    }

    setPaidHandler = (id) => {
        this.setState({
            toChangePayment: id,
            modalPayment: true
        })
    }

    confirmSetPaid = () => {

        if(this.state.toChangePayment !== '') {
            togglePaid(this.state.toChangePayment)
            .then(() => {
                this.props.refresh()
                this.setState({
                    toChangePayment: ''
                })
                this.handleCloseModalPayment()
            })
        }
        
    }

    toggleModal = (id) => {
        this.setState({
            showModal: !this.state.showModal,
            idToDelete: !this.state.showModal ? id : ''
        })
    }

    toggleFlagHandler = (id) => {
        toggleFlag(id)
        .then((res) => {
            this.props.setMessage(res)
            this.props.refresh()
        })
    }

    toggleInvoiceHanlder = (id) => {

        this.setState({
            loadingToggle: true
        })

        getToken().then((token) => {
            const data = {
                token: token,
                id: id
            }

            toggleInvoice(data)
            .then((res) => {
                this.props.setMessage(res)
                this.props.refresh()
                this.setState({
                    loadingToggle: false
                })
            })
        })
    }

    handleMultipleSelect = (event, data, id) => {

        if(data.checked) {
            this.setState({
                selectedIds: [
                    ...this.state.selectedIds,
                    id
                ]
            })
        } else {
            this.setState({
                selectedIds: this.state.selectedIds.filter((i) => i !== id)
            })
        }
        
    }

    handleCloseModalPayment = () => this.setState({ modalPayment: false })

    _renderModalPayment = () => {
        return (
            <Modal open={this.state.modalPayment} onClose={this.handleCloseModalPayment}>
                <Modal.Header>Změnit stav platby</Modal.Header>
                <Modal.Content image>
                    <Modal.Description>
                        <p>Opravdu chcete změnit stav platby za tuto přihlášku?</p>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='black' onClick={this.handleCloseModalPayment}>
                        Zpět
                    </Button>
                    <Button
                        positive
                        icon='checkmark'
                        labelPosition='right'
                        content="Ano, chci změnit"
                        onClick={() => this.confirmSetPaid()}
                    />
                </Modal.Actions>
            </Modal>
        )
    }

    _renderMultipleSelectActions = () => {
        return (
            <MultipleActions>
                <div className='row'>
                    <div className='col-sm-1'>
                        <p>Vybráno: {this.state.selectedIds.length}</p>
                    </div>
                    <div className='col-sm-9'>
                        <Button color='red' onClick={() => this.multipleRemindersHandler('reminder')} loading={this.state.loading.reminder}>Odeslat upomínku</Button>
                        <Button color='green' onClick={() => this.multipleRemindersHandler('confirm')} loading={this.state.loading.confirm}>Odeslat potvrzení</Button>
                        <Button className='ghost' onClick={() => this.multipleRemindersHandler('disable')} loading={this.state.loading.disable}>Stornovat přihlášky</Button>
                    </div>
                    <div className='col-sm-2 text-right'>
                        <Button onClick={this.clearSelected} className='ghost'>Vyprázdnit</Button>
                    </div>
                </div>
            </MultipleActions>
        )
    }

    multipleRemindersHandler = (type) => {

        this.setState({
            loading: {
                ...this.state.loading,
                [type]: true
            }
        })

        getToken().then((token) => {
            const data = {
                token: token,
                ids: this.state.selectedIds
            }

            if(type === 'reminder') {
                sendMultipleReminders(data)
                .then((res) => {
                    this.props.setMessage(res)

                    this.setState({
                        loading: {
                            ...this.state.loading,
                            [type]: false
                        }
                    })
                })
            } else if(type === 'confirm') {
                sendMultipleConfirms(data)
                .then((res) => {
                    this.props.setMessage(res)

                    this.setState({
                        loading: {
                            ...this.state.loading,
                            [type]: false
                        }
                    })
                })
            } else if(type === 'disable') {
                multipleDisable(data)
                .then((res) => {
                    this.props.setMessage(res)
                    this.props.refresh()

                    this.setState({
                        loading: {
                            ...this.state.loading,
                            [type]: false
                        }
                    })
                })
            }

        })
    }

    clearSelected = () => this.setState({ selectedIds: [] })

    render() {

        const { items, sort, column, direction, disable, enable } = this.props

        return (
            <div>
                <Table sortable striped className='main-table'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'id_application' ? direction : null}
                                onClick={sort('id_application')}
                            >
                                #
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'parent_name' ? direction : null}
                                onClick={sort('parent_name')}
                            >
                                Jméno rodiče
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'parent_email' ? direction : null}
                                onClick={sort('parent_email')}
                            >
                                Kontakt
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'created' ? direction : null}
                                onClick={sort('created')}
                            >
                                Vytvořeno
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'num_of_dates' ? direction : null}
                                onClick={sort('num_of_dates')}
                            >
                                Termíny
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'paid' ? direction : null}
                                onClick={sort('paid')}
                            >
                                Zaplaceno
                            </Table.HeaderCell>

                            <Table.HeaderCell></Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>
                <Table.Body>
                {_.map(items, ({ id_application, parent_name, state, parent_email, has_sub, parent_phone, created, priority, num_of_dates, paid, invoice_sent }) => (
                    <Table.Row key={id_application} className={has_sub ? 'has_sub' : 'no_sub'}>
                        <Table.Cell>
                            <Checkbox checked={this.state.selectedIds.includes(id_application) ? true : false} onChange={(event, data) => this.handleMultipleSelect(event, data, id_application)} />
                        </Table.Cell>
                        <Table.Cell>
                            {Number(id_application)}
                        </Table.Cell>
                        <Table.Cell>
                            {parent_name}
                        </Table.Cell>
                        <Table.Cell>
                            {parent_phone}<br />{this.props.user.user_admin === 1 && parent_email}
                        </Table.Cell>
                        <Table.Cell>
                            {moment(created).format('D/M/YYYY')}
                        </Table.Cell>
                        <Table.Cell>
                            {num_of_dates}
                        </Table.Cell>
                        <Table.Cell>
                            {intToBool(paid) ? <i class="icofont-check-circled icon-state" onClick={() => this.setPaidHandler(id_application)}></i> : <i class="icofont-close-circled icon-state" onClick={() => this.setPaidHandler(id_application)}></i>}
                        </Table.Cell>
                        <Table.Cell>
                            <Button icon className={invoice_sent === 0 ? 'ghost' : 'invoice-sent'} onClick={() => this.toggleInvoiceHanlder(id_application)} loading={this.state.loadingToggle} ><Icon name='circle'/></Button>
                            <Button icon className={priority === 0 ? 'ghost' : 'priority-flag'} onClick={() => this.toggleFlagHandler(id_application)} ><Icon name='flag'/></Button>
                            {state === 1 && <Button icon className='ghost' onClick={() => disable(id_application)} ><Icon name='trash'/></Button>}
                            {state === 0 && <Button icon className='ghost' onClick={() => enable(id_application)} ><Icon name='check'/></Button>}
                            <Button icon href={`${process.env.PUBLIC_URL}/admin/manage/applications/detail/` + id_application}><Icon name='pencil alternate' /></Button>
                        </Table.Cell>
                    </Table.Row>
                ))}
                </Table.Body>
            </Table>
            <this._renderModalPayment />
            {this.state.selectedIds.length !== 0 &&
                <this._renderMultipleSelectActions />
            }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.user.user
})
  
export default connect(mapStateToProps, { setMessage })(ApplicationsTable)