import React, { Component } from 'react'
import { Form, Button, Dropdown } from 'semantic-ui-react'
import { Row, Col } from 'reactstrap'
//import { FieldCounter } from '../../Styles'

class DefaultForm extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            data: {},
            loadingData: true
        })
    }

    onChange = (e) => this.setState({
        data: {
            ...this.state.data,
            [e.target.name]: e.target.value
        }
    })

    onChangeSelect = (e, data) => this.setState({
        data: {
            ...this.state.data,
            [data.name]: data.value
        }
    })

    componentDidMount() {

        const { data } = this.props

        this.setState({
            data: data,
            loadingData: false
        })
    }

    componentDidUpdate(prevProps) {
        if(prevProps.data !== this.props.data) {
            const { data } = this.props

            this.setState({
                data: data,
                loadingData: false
            })
        }
    }

    onSubmit = () => {
        const { submit } = this.props

        submit(this.state.data)
    }

    render() {

        const { fields, loading } = this.props

        return (
            <Form onSubmit={this.onSubmit} loading={loading}>
                {
                    fields.map((field, index) => {

                        const length = field.countLength && this.state.data[field.name] && this.state.data[field.name].length

                        return (
                            <Row key={index} className='mb-3'>
                                <Col sm={8}>
                                    <Form.Field>
                                        {field.type !== 'textarea' && field.type !== 'select' &&
                                        <Form.Input 
                                            name={field.name}
                                            type={field.type}
                                            label={field.label}
                                            required={field.required}
                                            placeholder={field.placeholder}
                                            onChange={this.onChange}
                                            value={this.state.data[field.name] || ''}
                                            disabled={field.disabled}
                                            icon={field.showIcon ? field.icon : ''}
                                            iconPosition={field.iconPosition}
                                        />
                                        }

                                        {field.type === 'textarea' &&
                                        <Form.TextArea 
                                            name={field.name}
                                            type={field.type}
                                            label={field.label}
                                            required={field.required}
                                            placeholder={field.placeholder}
                                            onChange={this.onChange}
                                            value={this.state.data[field.name] || ''}
                                            disabled={field.disabled}
                                        />
                                        }

                                        {field.type === 'select' &&
                                            <>
                                            <label>{field.label}</label>
                                            <Dropdown
                                                name={field.name}
                                                placeholder={field.placeholder}
                                                options={field.options}
                                                value={this.state.data[field.name] || ''}
                                                fluid
                                                selection
                                                onChange={this.onChangeSelect}
                                            />
                                            </>
                                        }

                                        {/*field.countLength && this.state.data[field.name] &&
                                            <FieldCounter
                                                bad={length < field.range.min || length > field.range.max ? true : false}
                                            >
                                                {length}
                                            </FieldCounter>
                                        */}
                                    </Form.Field>
                                </Col>
                                <Col sm={4}>
                                    <p className="form_desc small">{field.desc}</p>
                                </Col>
                            </Row>
                        )
                    })
                }
                <Row>
                    <Col sm={8} className='text-center'>
                        <Form.Field>
                            <Button primary>Uložit změny</Button>
                        </Form.Field>
                    </Col>
                </Row>
            </Form>
        )
    }

}

export default DefaultForm