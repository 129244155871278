import React, { Component } from 'react'
import { Form, Button, Dropdown } from 'semantic-ui-react'
import moment from 'moment'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css';
import 'moment/locale/cs';
import { DateUtils } from 'react-day-picker';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import { Row, Col } from 'reactstrap';
import { FormButtonGroup } from '../../../styles';
import { Section } from '../../../styles/forms'
import ColorPicker from './ColorPicker'

const monthsOpts = [
    {
        key: 0,
        value: 1,
        text: '1 měsíc'
    },
    {
        key: 1,
        value: 2,
        text: '2 měsíce'
    },
    {
        key: 2,
        value: 3,
        text: '3 měsíce'
    },
    {
        key: 3,
        value: 4,
        text: '4 měsíce'
    },
    {
        key: 4,
        value: 5,
        text: '5 měsíců'
    },
    {
        key: 5,
        value: 6,
        text: '6 měsíců'
    },
    {
        key: 6,
        value: 7,
        text: '7 měsíců'
    },
    {
        key: 7,
        value: 8,
        text: '8 měsíců'
    },
    {
        key: 8,
        value: 9,
        text: '9 měsíců'
    },
    {
        key: 9,
        value: 10,
        text: '10 měsíců'
    },
    {
        key: 10,
        value: 11,
        text: '11 měsíců'
    },
    {
        key: 11,
        value: 12,
        text: '12 měsíců'
    },
]

class EditPrepaidForm extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            data: {
                id_workout: '',
                num_of_lectures: '',
                price: '',
                valid_months: '',
                state: '',
                name: ''
            },
            displayColorPicker: false
        })

        this.handleDayChange = this.handleDayChange.bind(this);
    }

    componentDidMount() {

        const { initial } = this.props

        this._setInitial(initial)

    }

    componentDidUpdate(prevProps) {
        if(this.props.initial !== prevProps.initial) {
            this._setInitial(this.props.initial)
        }
    }

    _setInitial = (data) => {
        this.setState({
            data: {
                id_workout: data.id_workout ? data.id_workout : '',
                num_of_lectures: data.num_of_lectures ? data.num_of_lectures : '',
                price_per_lecture: data.price_per_lecture ? data.price_per_lecture : '',
                valid_months: data.valid_months ? data.valid_months : '',
                state: data.state ? data.state : '',
                name: data.name ? data.name : '',
            }
        })
    }

    onChange = (e) => this.setState({
        data: {
            ...this.state.data,
            [e.target.name]: e.target.value
        }
    })

    onChangeSelect = (e, data) => this.setState({
        data: {
            ...this.state.data,
            [data.name]: data.value
        }
    })

    handleDayChange(day, modifiers, dayPickerInput) {

        const input = dayPickerInput.getInput();

        this.setState({ 
            data: {
                ...this.state.data,
                [input.name]: day
            } 
        });
    }

    submit = () => {
        this.props.submit(this.state.data)
    }

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };
    
    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };
    
    handleChange = (color) => {
        this.setState({ 
            data: {
                ...this.state.data,
                event_color: color.hex
            } 
        })
    };

    render() {

        const format = "DD/MM/YYYY";
        const { data } = this.state
        const { loading, loadingOpts, opts, isNew } = this.props

        return (
            <Form onSubmit={this.submit} loading={loading || loadingOpts}>
                <Section>
                    <h4 className='heading_separator first'>Základní informace</h4>
                    <Form.Field>
                        <Form.Input
                            type='text'
                            name='name'
                            placeholder='Zadejte název permanentky'
                            label='Název permanentky'
                            value={data.name}
                            onChange={this.onChange}
                            required
                        />
                    </Form.Field>
                    <Row className='mb-3'>
                        <Col sm={4}>
                            <Form.Field>
                                <Form.Input 
                                    name='num_of_lectures'
                                    type='number'
                                    placeholder='Počet lekcí při koupi'
                                    label='Počet lekcí po nákupu'
                                    value={data.num_of_lectures}
                                    onChange={this.onChange}
                                    required
                                />
                            </Form.Field>
                        </Col>
                        <Col sm={4}>
                            <Form.Field>
                                <label>Platnost v měsících</label>
                                <Dropdown 
                                    name='valid_months'
                                    placeholder='Vyberte možnost'
                                    options={monthsOpts}
                                    value={data.valid_months}
                                    onChange={this.onChangeSelect}
                                    selection
                                />
                            </Form.Field>
                        </Col>
                    </Row>
                </Section>

                <Section>
                    <h4 className='heading_separator'>Cena</h4>
                    <Row className='mb-3'>
                        <Col sm={4}>
                            <Form.Field>
                                <Form.Input 
                                    type='number'
                                    name='price_per_lecture'
                                    placeholder='Zadejte cenu za jednu lekci'
                                    label='Cena za jednu lekci'
                                    value={data.price_per_lecture}
                                    onChange={this.onChange}
                                    required
                                />
                            </Form.Field>
                        </Col>
                        <Col sm={4}>
                            <Form.Field>
                                <Form.Input 
                                    type='number'
                                    name='total_price'
                                    placeholder='Cena za permanentku'
                                    label='Cena za permanentku'
                                    value={data.price_per_lecture * data.num_of_lectures}
                                    onChange={this.onChange}
                                    disabled
                                />
                            </Form.Field>
                        </Col>
                        <Col sm={4}>
                            <Form.Field>
                                <label>Permanentka pro lekce</label>
                                <Dropdown 
                                    name='id_workout'
                                    placeholder='Vyberte možnost'
                                    options={opts}
                                    value={data.id_workout}
                                    onChange={this.onChangeSelect}
                                    selection
                                />
                            </Form.Field>
                        </Col>
                    </Row>
                </Section>
                    
                <FormButtonGroup>
                    <Button primary>Uložit permanentku</Button>
                </FormButtonGroup>
            </Form>
        )
    }

}

export default EditPrepaidForm