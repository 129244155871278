import styled from 'styled-components'

export const ConfirmContainer = styled.div`

`

export const ConfirmHeading = styled.h1`
    text-align: center;
    font-weight: 700;
    @media (min-width: 768px) {
        font-size: 40px;
    }
`

export const ServiceBlock = styled.div`
    text-align: center;
    padding: 2rem;
    box-shadow: 0 0 2rem 0 rgba(136,152,170,.15);
    background: #fff;
    border-radius: 4px;
    margin-bottom: 30px;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: relative;

    h3 {
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 700;
        color: #212529 !important;
    }

    img {
        width: 120px;
        height: auto;
        margin: 0 auto;
        margin-bottom: 20px;
    }

    :hover::before {
        opacity: 1;
    }

    a {
        color: #212529 !important;
    }

    ::before {
        content: '${props => props.actionText}';
        display: flex;
        justify-content: center;
        align-items: center;
        background: #4CC7BE;
        color: #fff;
        font-weight: 700;
        position: absolute;
        top: 0; left: 0; right: 0; bottom: 0;
        font-size: 20px;
        opacity: 0;
        transition: all .3s ease-out;
        font-family: 'Barlow Condensed', sans-serif !important;
    }
`

export const Header = styled.div`
    padding-top: 0rem;
    padding-bottom: 0 !important;
    background: #4CC7BE;

    img.app_logo {
        width: 100px;
    }

    a {
        color: #fff !important;
        margin-right: 20px;
        font-family: 'Barlow Condensed', sans-serif !important;
        font-size: 16px !important;
        font-weight: 700;
        line-height: 1;
    }
`

export const HeaderRoute = styled.h1`
    display: inline-block;
    padding-top: .3125rem;
    padding-bottom: .3125rem;
    margin: 0;
    font-size: 20px;
    line-height: inherit;
    white-space: nowrap;
    position: relative;

    ::before {
        content: '';
        width: 1px;
        height: 30px;
        background: #fff;
        position: absolute;
        left: -20px;
    }
`