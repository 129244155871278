import React, { Component } from 'react'
import { Modal, Form, Button } from 'semantic-ui-react'
import { getToken } from '../../api'
import { url } from '../../config'

class ExportDayByDayModal extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            date: ''
        })
    }

    onChange = (e) => this.setState({
        [e.target.name]: e.target.value
    })

    exportDayByDay = () => {

        const { type } = this.props

        this.setState({
            loadingExport: true
        })

        const exportUrl = 'export/export-day-by-day/'
        getToken().then((token) => {
            //$id, $type, $date, $workout
            window.open(url.api + exportUrl + token + '/' + type + '/' + this.state.date, "_blank")

            this.setState({
                loadingExport: false
            })
        })
    }

    render() {
        return (
            <Modal trigger={<Button>Export podle dne</Button>}>
                <Modal.Header>
                    Zvolte den pro export
                </Modal.Header>
                <Modal.Content>
                    <Form onSubmit={this.exportDayByDay}>
                        <Form.Field>
                            <Form.Input 
                                type='date'
                                name='date'
                                value={this.state.date}
                                onChange={this.onChange}
                            />
                        </Form.Field>
                        <Button primary loading={this.state.loadingExport}>Exportovat</Button>
                    </Form>
                </Modal.Content>
            </Modal>
        )
        
    }

}

export default ExportDayByDayModal