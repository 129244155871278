import React, { Component } from 'react'
import { getToken } from '../../../api'
import { getLecturesList } from '../../../api/workouts'
import { connect } from 'react-redux'
import WorkoutsTable from '../../tables/workouts/WorkoutsTable'
import Loading from '../../containers/Loading'
import NoReservations from '../../containers/club/NoReservations'
import { Card, CardBody, CardHeader, CardControls } from '../../../styles'
import _ from 'loadsh'
import { Row, Col } from 'reactstrap'
import { Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import DatesListTable from '../../tables/workouts/DatesListTable'

class DatesListView extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            items: [],
            loadingData: true,
            hasData: false,
            column: 'id_workout',
            direction: 'descending',
        })
    }

    setCardHeader = (type) => {
        switch (type) {
            case 'moms':
                return 'Seznam termínů (Cvičení pro maminky)'
            
            case 'kids':
                return 'Seznam termínů (Cvičení s dětmi)'

            case 'hobby':
                return 'Seznam termínů kroužků'
    
            default: 
                return 'Seznam termínů'
        }
    }

    componentDidMount() {
        const { loadingUser } = this.props

        if(!loadingUser) {
            this._getList()
        }
    }

    componentDidUpdate(prevProps) {

        const { loadingUser } = this.props

        if(prevProps.loadingUser !== loadingUser && !loadingUser) {
            this._getList()
        }
    }

    _getList = () => {

        const { user } = this.props
        const { type, id } = this.props.match.params

        getToken().then((token) => {
            const data = {
                token: token,
                id_org: user.id_organization,
                id_workout: id
            }

            getLecturesList(data, type)
            .then((res) => {
                if(res.code === 1) {
                    this.setState({
                        items: res.data,
                        hasData: true,
                        loadingData: false
                    })
                } else {
                    this.setState({
                        hasData: false,
                        loadingData: false
                    })
                }
            })
        })
        .catch((err) => {
            this.props.setMessage({type: 'error', text: 'Nepodařilo se získat bezpečnostní token, aktualizuji stránku'})
            window.location.reload();
        })
    }

    handleSort = (clickedColumn) => () => {
        const { column, items, direction } = this.state
    
        if (column !== clickedColumn) {
          this.setState({
            column: clickedColumn,
            items: _.sortBy(items, [clickedColumn]),
            direction: 'ascending',
          })
    
          return
        }
    
        this.setState({
            items: items.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    }

    content = () => {

        const { type } = this.props.match.params
        const { loadingUser } = this.props

        return (
            <Card>
                <CardHeader>
                    <Row>
                        <Col sm={9} className='col-6'>
                            {this.setCardHeader(type)}
                        </Col>
                        <Col sm={3} className='text-right col-6'>
                            <Link to={`${process.env.PUBLIC_URL}/admin/manage/workouts/${type}/edit/new`}><Button icon="\efc2">Nový</Button></Link>
                        </Col>
                    </Row>
                </CardHeader>
                {/*<CardControls>
                    <Row>
                        <Col>
                            <Dropdown 
                                options={groupFilter}
                                name='group'
                                onChange={this.onChangeFilter}
                                selection
                                placeholder='Kategorie'
                                fluid
                                value={parsed.group ? Number(parsed.group) : null}
                            />
                        </Col>
                    </Row>
                </CardControls>*/}
                {!loadingUser &&
                <DatesListTable items={this.state.items} sort={this.handleSort} column={this.state.column} direction={this.state.direction} type={type} isLecturer={this.props.user.is_lecturer} />
                }
            </Card>
        )
    }

    render() {
        const { loadingData, hasData } = this.state

        if(loadingData) {
            return (
                <Loading />
            )
        } else if(!loadingData && !hasData) {
            return (
                <NoReservations text='Nemáte vytvořené žádné termíny' />
            )  
        } else {
            return (
                <>
                {this.content()}
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    user: state.user.user,
    loadingUser: state.user.loading
})
  
export default connect(mapStateToProps, {})(DatesListView)