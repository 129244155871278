import React, { Component } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { getCalendar, getCalendarItem } from '../../../api/workouts';
import { Event } from '../../../styles/calendar';
import Schedule from '../../containers/workouts/Schedule'
import { Card, CardHeader, CardBody } from '../../../styles'
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom'
import { Button } from 'semantic-ui-react'

class MomWorkoutScheduleView extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            dates: [],
            selectedItem: {},
            title: ''
        })
    }

    componentDidMount() {

        const { type } = this.props.match.params

        const date = moment()
        this._getCalendar(date)
        this._setScheduleTitle(type)
    }

    _setScheduleTitle = (type) => {
        if(type === 'moms') {
            this.setState({
                title: 'Rozvrh cvičení pro maminky'
            })
        } else if(type === 'kids') {
            this.setState({
                title: 'Rozvrh cvičení s dětmi'
            })
        }
    }

    _getCalendar = (date) => {

        const { type } = this.props.match.params
        const formatDate = moment(date).format('YYYY-MM-DD')

        getCalendar(formatDate, type)
        .then((res) => {
            const newEvents = []
            res.map((item) => {
                const newItem = {
                    title: item.title,
                            start: moment(item.start).toDate(),
                            end: moment(item.end).toDate(),
                            id: item.id,
                            color: item.color,
                            is_full: item.is_full,
                            age_group: type !== 'moms' ? item.age_group : null,
                            scheduleCat: item.cat,
                            description: item.description,
                            id_location: item.id_location,
                            id_workout: item.id_workout,
                            group: item.group,
                            state: item.state
                }
                newEvents.push(newItem)
            })

            this.setState({
                dates: newEvents
            }, () => {
                console.log(newEvents)
            })
        })
    }

    _getCalendarItem = (id) => {
        const { type } = this.props.match.params

        getCalendarItem(id, type)
        .then((res) => {
            if(res.code === 1) {
                this.setState({
                    selectedItem: res.data,
                    itemExist: true
                })
            } else {
                this.setState({
                    selectedItem: {},
                    itemExist: false
                })
            }
        })
    }

    render() {

        const { type } = this.props.match.params

        return (
            <Card>
                <CardHeader>
                    <Row>
                        <Col sm={9}>
                            {this.state.title}
                        </Col>
                        <Col sm={3} className='text-right'>
                            <Link to={`${process.env.PUBLIC_URL}/admin/manage/workouts/moms/edit/new`}><Button icon="\efc2">Přidat lekci</Button></Link>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Schedule getData={this._getCalendar} dates={this.state.dates} getItem={this._getCalendarItem} selectedItem={this.state.selectedItem} type={type} />
                </CardBody>
            </Card>
        )
    }

}

export default MomWorkoutScheduleView