import React, { Component } from 'react'
import { intToBool, toggleLocationState, shortText, getToken } from '../../../api'
import { Table, Icon, Button, Popup } from 'semantic-ui-react'
import _ from 'loadsh'
import moment from 'moment'
import { StateIcon } from '../../../styles'
import { Link } from 'react-router-dom'

class ClubUsersTable extends Component {

    constructor(props) {
        super(props)
        this.state = ({})
    }

    render() {

        const { items, column, direction, sort } = this.props

        return (
            <div>
                <Table sortable striped className='main-table select_table'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell
                                sorted={column === 'id_user' ? direction : null}
                                onClick={sort('id_user')}
                                width={1}
                            >
                                #
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'first_name' ? direction : null}
                                onClick={sort('first_name')}
                                width={3}
                            >
                                Jméno
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'last_name' ? direction : null}
                                onClick={sort('last_name')}
                                width={2}
                            >
                                Příjmení
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'email' ? direction : null}
                                onClick={sort('email')}
                                width={2}
                            >
                                Email
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'phone' ? direction : null}
                                onClick={sort('phone')}
                                width={5}
                            >
                                Telefon
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'newsletter' ? direction : null}
                                onClick={sort('newsletter')}
                                width={1}
                            >
                                Newsletter
                            </Table.HeaderCell>

                            <Table.HeaderCell width={2}></Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                    {_.map(items, ({ id_user, first_name, last_name, email, phone, newsletter }, index) => (
                        <Table.Row key={id_user}>
                            <Table.Cell>
                                {id_user}
                            </Table.Cell>
                            <Table.Cell>
                                {first_name}
                            </Table.Cell>
                            <Table.Cell>
                                {last_name}
                            </Table.Cell>
                            <Table.Cell>
                                {email}
                            </Table.Cell>
                            <Table.Cell>
                                {phone}
                            </Table.Cell>

                            <Table.Cell className='text-center'>
                                <StateIcon state={newsletter} />
                            </Table.Cell>
                            <Table.Cell className='text-right'>
                                <Link to={`/admin/manage/club-users/detail/${id_user}`}><Button icon><Icon name='edit' /></Button></Link>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                    </Table.Body>
                </Table>
            </div>
        )
    }
}

export default ClubUsersTable