import React, { Component } from 'react'
import LoginForm from '../../forms/auth/LoginForm'
import SignupForm from '../../forms/auth/SignupForm'
import { Row, Col } from 'reactstrap'
import { createClubUser, forgotPassword } from '../../../api/club'
import { connect } from 'react-redux'
import { setMessage } from '../../../actions/messageActions'
import { saveClubUser, saveUser } from '../../../actions/userActions'
import firebase from 'firebase'
import { Images } from '../../../config'
import ReactPixel from 'react-facebook-pixel';
import ForgotPasswordForm from '../../forms/auth/ForgotPasswordForm'

class ClubSignUp extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            loginForm: true,
            signupForm: false,
            loading: false,
            login: {
                email: '',
                passwd: '',
            },
            forgot: {
                email: ''
            },
            signup: {
                first_name: '',
                last_name: '',
                email: '',
                passwd: '',
                passwd_check: '',
                gdpr: false,
                newsletter: false,
                terms: false
            },
            errorsSignup: {
                passwd_check: ''
            },
        })
    }

    componentDidMount() {

        firebase.auth().onAuthStateChanged((user) => {
            if(user) {
                this.props.history.push(`${process.env.PUBLIC_URL}/`)
            }
        })
    }

    onChangeLogin = (e) => this.setState({
        login: {
            ...this.state.login,
            [e.target.name]: e.target.value
        }
    })

    onChangeForgot = (e) => this.setState({
        forgot: {
            ...this.state.forgot,
            [e.target.name]: e.target.value
        }
    })

    onChangeSignup = (e) => this.setState({
        signup: {
            ...this.state.signup,
            [e.target.name]: e.target.value
        }
    })

    onChangeCheckbox = (e, data) => {
        this.setState({
            signup: {
                ...this.state.signup,
                [data.name]: data.checked
            }
        })
    }

    submitSignup = () => {
        const { signup } = this.state

        this.setState({
            loading: true
        })

        if(signup.passwd !== signup.passwd_check) {
            this.setState({
                loading: false,
                errorMessage: 'Hesla se neshodují',
                hasError: true,
                loading: false
            })
        } else {
            firebase
            .auth()
            .fetchSignInMethodsForEmail(signup.email)
            .then((result) => {
                if(result.length === 0) {
                    createClubUser(signup)
                    .then((res) => {
                        this.setState({
                            loading: false
                        })
                        this.props.setMessage(res)
                        this.props.history.push(`${process.env.PUBLIC_URL}/klub/auth/prihlaseni`)
                        ReactPixel.track('track', 'CompleteRegistration');
                    })
                } else {
                    this.setState({
                        loading: false,
                        errorMessage: window.location.origin !== 'https://klub.tabory-krouzky.cz' ? 'Tento email je již zaregistrovaný' : 'Tento email je již zaregistrován ve 1FIT, použijte stejné přihlašování',
                        hasError: true,
                        loading: false
                    })
                }
            });
        }
    }

    submitLogin = () => {

        const { login } = this.state

        this.setState({
            loading: true
        })

        firebase.auth().signInWithEmailAndPassword(login.email, login.passwd)
        .then((user) => {
            if(user) {
                firebase.auth().currentUser.getIdTokenResult()
                .then((idTokenResult) => {
                    if (!!idTokenResult.claims.isAdmin || idTokenResult.claims.isAdmin === undefined) {
                        this.props.history.push('/admin/dashboard')
                        this.props.saveUser(idTokenResult.claims.sub)
                    } else {
                        this.props.history.push('/klub/nastenka')
                        this.props.saveClubUser(idTokenResult.token)
                    }
                })
            }
        })
        .catch((err) => {

            var message = ''

            if(err.code === 'auth/user-not-found') {
                message = 'Tento uživatel neexistuje. Nejdříve se prosím zaregistrujte'
            } else if(err.code === 'auth/wrong-password') {
                message = 'Zadané heslo není správné'
            } else if(err.code === 'auth/user-disabled') {
                message = 'Tento účet byl pozastaven'
            }

            this.setState({
                errorMessage: message,
                hasError: true,
                loading: false
            })
        })
            
    }

    submitForgot = () => {

        const { forgot } = this.state

        this.setState({
            loading: true
        })

        firebase
        .auth()
        .fetchSignInMethodsForEmail(forgot.email)
        .then((result) => {
            if(result.length === 0) {
                this.setState({
                    loading: false,
                    errorMessage: 'Účet s tímto emailem neexistuje',
                    hasError: true,
                    loading: false
                })
            } else {
                forgotPassword(forgot.email)
                .then((res) => {
                    this.setState({
                        loading: false
                    })
                    this.props.setMessage(res)
                    this.props.history.push(`${process.env.PUBLIC_URL}/klub/auth/prihlaseni`)
                })
            }
        });
            
    }

    render() {

        const { type } = this.props.match.params

        return (
            <>
            <Row className={window.location.origin === 'https://klub.tabory-krouzky.cz' ? 'd-flex align-items-center justify-content-center auth_row no_bg' : 'd-flex align-items-center justify-content-center auth_row'}>
                <Col lg={type === 'prihlaseni' ? 3 : 4} md={6} className={`login_box login_box_${type} p-5`}>
                    <a href={window.location.origin === 'https://klub.tabory-krouzky.cz' ? "https://www.tabory-krouzky.cz" : "https://www.1fit.cz"}><img className={window.location.origin === 'https://klub.tabory-krouzky.cz' ? 'tabory login_logo' : 'login_logo'} src={Images.logo_c} /></a>
                    {type === 'prihlaseni' && <LoginForm loading={this.state.loading} hasError={this.state.hasError} errorMessage={this.state.errorMessage} onChange={this.onChangeLogin} data={this.state.login} submit={this.submitLogin} /> }
                    {type === 'registrace' && <SignupForm loading={this.state.loading} hasError={this.state.hasError} errorMessage={this.state.errorMessage} data={this.state.signup} checkbox={this.onChangeCheckbox} onChange={this.onChangeSignup} submit={this.submitSignup} />}
                    {type === 'zapomenute-heslo' && <ForgotPasswordForm loading={this.state.loading} hasError={this.state.hasError} errorMessage={this.state.errorMessage} data={this.state.forgot} onChange={this.onChangeForgot} submit={this.submitForgot} />}
                </Col>
            </Row>
                
            </>
        )
    }

}

const mapStateToProps = state => ({
    loadingUser: state.user.loading,
    user: state.user.user
})
  
export default connect(mapStateToProps, { setMessage, saveClubUser, saveUser })(ClubSignUp)