import React from 'react'
import { DashboardWidget, WidgetName, WidgetDesc, WidgetDate, WidgetType } from '../../../styles/club'
import { Row, Col } from 'reactstrap'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { shortText } from '../../../api'

const setType = (type) => {
    switch(type) {
        case 'moms':
            return 'Cvičení pro maminky'

        case 'kids':
            return 'Cvičení s dětmi'

        case 'hobby':
            return 'Kroužky'

        default:
            return 'Lekce'
    }
}

const EmptyWidget = ({ }) => {
    return (
        <DashboardWidget bg={'#e11616'}>
            <Row>
                <Col sm={12} className='mb-4'>
                    <WidgetName>Nemáte zakoupeny žádné lekce</WidgetName>
                </Col>
            </Row>
            
        </DashboardWidget>
       
    )
}

export default EmptyWidget