import React, { Component } from 'react'
import { Icon, Step, Button } from 'semantic-ui-react'
import { ParentForm, KidsForm, TermsForm, ConfirmForm } from '../forms'
import { StepCard, StuckContainer, UserId } from '../../styles'
import { createApplication, requestPaymentLink } from '../../api'
import { EmbedNavigation } from '../containers'
import ReactGA from 'react-ga'
import firebase from 'firebase'

class EmbedView extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            steps: {
                parent: true,
                kids: false,
                term: false,
                confirm: false
            },
            completed: {
                parent: false,
                kids: false,
                term: false,
                confirm: false
            },
            disabled: {
                parent: false,
                kids: true,
                term: true,
                confirm: true
            },
            data: {
                parents: [],
                kids: [],
                term: ''
            },
            res: {
                type: '',
                message: '',
                id: ''
            },
            paymentLink: '',
            loading: false,
            logged: false
        })
    }

    componentDidMount() {

        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
              this.setState({
                logged: true
              })
            } else {
                this.setState({
                    logged: false
                })
            }
        });

        const { utm } = this.props.match.params

        document.body.classList.add(utm)
        document.body.classList.add('body-application')

        ReactGA.initialize('UA-155260757-1', {
            debug: true,
            cookieDomain: 'kidsjoy.cz'
        });

        ReactGA.pageview('/prihlaska');
    }

    setParent = (data) => {
        this.setState({
            steps: {
                ...this.state.steps,
                parent: false,
                kids: true,
            },
            completed: {
                ...this.state.completed,
                parent: true
            },
            disabled: {
                ...this.state.disabled,
                kids: false
            },
            data: {
                ...this.state.data,
                parents: this.state.data.parents.length === 0 ? this.state.data.parents.concat(data) : data
            }
        })
        ReactGA.event({
            category: 'User',
            action: 'Vyplněný rodič'
        });
    }

    setKids = (data) => {
        this.setState({
            steps: {
                ...this.state.steps,
                kids: false,
                term: true,
            },
            completed: {
                ...this.state.completed,
                kids: true
            },
            disabled: {
                ...this.state.disabled,
                term: false
            },
            data: {
                ...this.state.data,
                kids: this.state.data.kids.length === 0 ? this.state.data.kids.concat(data) : data
            }
        })
        ReactGA.event({
            category: 'User',
            action: 'Vyplněné dítě'
        });
    }

    setTerm = (data) => {
        this.setState({
            steps: {
                ...this.state.steps,
                term: false,
                confirm: true,
            },
            completed: {
                ...this.state.completed,
                term: true
            },
            disabled: {
                ...this.state.disabled,
                confirm: false
            },
            data: {
                ...this.state.data,
                term: data
            }
        })
        ReactGA.event({
            category: 'User',
            action: 'Vybrané termíny'
        });
    }

    setConfirm = (data, price) => {
        let parents = [...this.state.data.parents]
        parents[0] = {...parents[0], additional: data}

        this.setState({
            data: {
                ...this.state.data,
                parents: parents
            },
            price: price
        }, () => {
            this.submitHandler()
        })

        ReactGA.event({
            category: 'User',
            action: 'Pokus o přihlášení'
        });
    }

    submitHandler = () => {

        const { utm } = this.props.match.params

        this.setState({
            loading: true
        })

        console.log(this.state.data)

        createApplication(this.state.data)
        .then((res) => {
            this.setState({
                res: {
                    type: res.type,
                    message: res.message,
                    id: res.id
                },
                loading: false
            })

            const data = {
                order_num: res.id,
                amount: this.state.price,
                utm: utm
            }

            requestPaymentLink(data)
            .then((res) => {
                this.setState({
                    paymentLink: res.link
                })
            })

            ReactGA.event({
                category: 'User',
                action: 'Úspěšné přihlášení'
            });
        })
    }

    showParent = () => {
        this.setState({
            steps: {
                parent: true,
                kids: false,
                term: false,
                confirm: false,
            },
        })
    }

    showKids = () => {
        this.setState({
            steps: {
                parent: false,
                kids: true,
                term: false,
                confirm: false,
            },
        })
    }

    showTerms = () => {
        this.setState({
            steps: {
                parent: false,
                kids: false,
                term: true,
                confirm: false,
            },
        })
    }

    showConfirm = () => {
        this.setState({
            steps: {
                parent: false,
                kids: false,
                term: false,
                confirm: true,
            },
        })
    }

    _renderSteps = () => {

        const { utm } = this.props.match.params

        return (
            <div className='push_from_nav application'>
                <Step.Group>
                    <Step active={this.state.steps.parent} completed={this.state.completed.parent} disabled={this.state.disabled.parent} onClick={this.showParent}>
                        <Icon name='user' />
                        <Step.Content>
                            <Step.Title>Rodiče</Step.Title>
                            <Step.Description>Zadejte informace o rodičích</Step.Description>
                        </Step.Content>
                    </Step>

                    <Step active={this.state.steps.kids} completed={this.state.completed.kids} disabled={this.state.disabled.kids} onClick={this.showKids}>
                        <Icon name='child' />
                        <Step.Content>
                            <Step.Title>Dítě</Step.Title>
                            <Step.Description>Zadejte informace o dítěti</Step.Description>
                        </Step.Content>
                    </Step>

                    <Step active={this.state.steps.term} completed={this.state.completed.term} disabled={this.state.disabled.term} onClick={this.showTerms}>
                        <Icon name='calendar alternate outline' />
                        <Step.Content>
                            <Step.Title>Termín</Step.Title>
                            <Step.Description>Vyberte termín kempu</Step.Description>
                        </Step.Content>
                    </Step>

                    <Step active={this.state.steps.confirm} onClick={this.showConfirm} disabled={this.state.disabled.confirm}>
                        <Icon name='check' />
                        <Step.Content>
                            <Step.Title>Dokončení přihlášky</Step.Title>
                        </Step.Content>
                    </Step>
                </Step.Group>
                
                <StepCard active={this.state.steps.parent}>
                    <ParentForm setParent={this.setParent} />
                </StepCard>

                <StepCard active={this.state.steps.kids}>
                    <KidsForm setKids={this.setKids} />
                </StepCard>

                {this.state.data.kids.length !== 0 &&
                <StepCard active={this.state.steps.term}>
                    <TermsForm setTerm={this.setTerm} kids={this.state.data.kids} campType={utm} />
                </StepCard>
                }

                {this.state.data.kids.length !== 0 && this.state.data.kids[0].kids_camps.length !== 0 &&
                <StepCard active={this.state.steps.confirm}>
                    <ConfirmForm setConfirm={this.setConfirm} data={this.state.data} loading={this.state.loading} />
                </StepCard>
                }
            </div>
        )
    }

    _renderMessage = ({type, message, id}) => {

        const { utm } = this.props.match.params

        return (
            <div className='push_from_nav application application_message'>
                <Icon className='application_icon' name={type === 'success' ? 'thumbs up' : 'thumbs down'} />
                <h1>{message}</h1>
                <div className='application_success_info'>
                    <p className='text-center'>Vaše ID</p>
                    <UserId>
                        {id}
                    </UserId>

                    <p>Věnujte prosím pozornost informacím, které dorazí na Vámi uvedený email v přihlášce.</p>
                    <p>Platbu můžete uhradit platební bránou nebo převodem na účet.</p>
                    <p>Platbu je nutné uhradit nejpozději do 7 pracovních dnů a to převodem na účet (viz níže)</p>

                    <div className='row card-payment'>
                        <div className='col-sm-6'>
                            <h2>{this.state.price},- Kč</h2>
                        </div>
                        <div className='col-sm-6 text-center'>
                            <Button size='massive' color='green' href={this.state.paymentLink}>Zaplatit kartou</Button>
                        </div>
                    </div>
                    
                    <div className='row banktransfer-payment'>
                        <div className='col-sm-6'>
                            <h3>Platba převodem</h3>
                            <p>Cena: <strong>{this.state.price},- Kč</strong></p>
                            <p>Platbu prosím zašlete na číslo účtu: <strong>5669472379/0800</strong></p>
                            <p>Variabilní symbol: <strong>{id}</strong></p>
                        </div>
                        <div className='col-sm-6 qr'>
                            <img src={'https://api.paylibo.com/paylibo/generator/czech/image?accountNumber=5669472379&bankCode=0800&amount='+this.state.price+'&currency=CZK&vs='+ id} className='qr_payment img-fluid' />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <Button href={utm === 'kidsjoy' ? 'https://www.kidsjoy.cz' : 'https://www.sportjoy.cz'} target='_parent'>Zpět na {utm === 'kidsjoy' ? 'Kidsjoy' : 'Sportjoy'}</Button>
                        </div>
                    </div>
                    
                </div>
            </div>
        )
    }

    render() {

        const { utm } = this.props.match.params
        
        return (
            <div className='embed' id='iFrameSizer'>
                <EmbedNavigation utm={utm} />
                <div className='container'>
                    {this.state.res.type === '' ?
                        <this._renderSteps />
                        :
                        <this._renderMessage type={this.state.res.type} message={this.state.res.message} id={this.state.res.id} />
                    }
                </div>

            </div>
        )
    }

}

export default EmbedView