import React, { Component } from 'react'
import { intToBool, toggleLocationState, shortText, getToken } from '../../../api'
import { Table, Icon, Button, Dropdown, Popup } from 'semantic-ui-react'
import _ from 'loadsh'
import moment from 'moment'
import { StateIcon } from '../../../styles'
import { Link } from 'react-router-dom'
import { url } from '../../../config'

class DatesListTable extends Component {

    constructor(props) {
        super(props)
        this.state = ({})
    }

    exportApplications = (id_date, id_workout, type) => {

        const { isLecturer } = this.props

        this.setState({
            loadingExport: true
        })
        const exportUrl = isLecturer === 1 ? 'export/export-applications-from-date-lecturer/' : 'export/export-applications-from-date/'
        getToken().then((token) => {
            //$id, $type, $date, $workout
            window.open(url.api + exportUrl + token + '/' + type + '/' + id_date + '/' + id_workout, "_blank")

            this.setState({
                loadingExport: false
            })
        })
    }

    exportAttended = (id_date, type) => {

        const { isLecturer } = this.props

        this.setState({
            loadingExport: true
        })
        const exportUrl = isLecturer === 1 ? 'export/export-attended-by-lecture-lecturer/' : 'export/export-attended-by-lecture/'
        getToken().then((token) => {
            //$id, $type, $date, $workout
            window.open(url.api + exportUrl + token + '/' + type + '/' + id_date, "_blank")

            this.setState({
                loadingExport: false
            })
        })
    }

    render() {

        const { items, column, direction, sort, type } = this.props

        return (
            <div className='admin_table_wrapper'>
                <Table sortable striped className='main-table select_table' unstackable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell
                                sorted={column === 'id_date' ? direction : null}
                                onClick={sort('id_date')}
                                width={1}
                            >
                                #
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'date' ? direction : null}
                                onClick={sort('date')}
                                width={3}
                                className='mw_200'
                            >
                                Termín
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'name' ? direction : null}
                                onClick={sort('name')}
                                width={5}
                                className='mw_300'
                            >
                                Cvičení
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'capacity' ? direction : null}
                                onClick={sort('capacity')}
                                width={2}
                                className='mw_100'
                            >
                                Kapacita
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'signed' ? direction : null}
                                onClick={sort('signed')}
                                width={2}
                            >
                                Přihlášeno
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'state' ? direction : null}
                                onClick={sort('state')}
                                width={1}
                            >
                                Stav
                            </Table.HeaderCell>

                            <Table.HeaderCell width={2} className='mw_150'></Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                    {_.map(items, ({ id_date, date,  name, capacity, state, signed, id_workout }, index) => (
                        <Table.Row key={id_date}>
                            <Table.Cell>
                                {id_date}
                            </Table.Cell>
                            <Table.Cell>
                                {moment(date).format('DD. MM. YYYY H:ss')}
                            </Table.Cell>
                            <Table.Cell>
                                {name}
                            </Table.Cell>
                            <Table.Cell>
                                {capacity}
                            </Table.Cell>
                            <Table.Cell>
                                {signed}
                            </Table.Cell>
                            <Table.Cell className='text-center'>
                                <StateIcon state={state} />
                            </Table.Cell>
                            <Table.Cell className='text-right'>
                                <Popup content='Export dat' position='top right' trigger={
                                    <Dropdown text='' icon='ellipsis vertical' className='mr-3' inline pointing='top right'>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => this.exportApplications(id_date, id_workout, type)} icon='list' text='Přihlášky z této lekce' />
                                            <Dropdown.Item onClick={() => this.exportAttended(id_date, type)} icon='list' text='Docházka' />
                                        </Dropdown.Menu>
                                    </Dropdown>
                                } />
                                <Link to={'/admin/manage/workouts/'+ type +'/dates/list/' + id_date + '/detail'}><Button icon><Icon name='edit' /></Button></Link>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                    </Table.Body>
                </Table>
            </div>
        )
    }
}

export default DatesListTable