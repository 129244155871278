import React, { Component } from 'react'
import EmbedNavigation from '../../containers/EmbedNavigation'
import { Container, Row, Col } from 'reactstrap'
import { ConfirmHeading } from '../../../styles/applications'
import { Icon, Step, Button } from 'semantic-ui-react'
import { StepCard, StuckContainer, UserId } from '../../../styles'
import { confirmData } from '../../../api/workouts'
import HeaderApplications from '../../containers/club/HeaderApplications'
import Loading from '../../containers/Loading'

class TourApplicationConfirm extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            bank_acc: '',
            total_price: 0,
            loading: true
        })
    }

    componentDidMount() {
        const { id } = this.props.match.params
        const type = 'tour'

        this._confirmData(id, type)
        document.body.classList.add('application')
    }

    _confirmData = (id, type) => {
        confirmData(id, type)
        .then((res) => {
            if(res.code === 1) {
                this.setState({
                    bank_acc: res.data.organization_bank_acc,
                    total_price: res.data.total_deposit,
                    is_free: res.data.is_free === 1 ? true : false,
                    gateway: res.data.gateway,
                    loading: false
                })
            }
        })
    }

    setCode = (id) => {
        return '50' + id
    }

    _renderMessage = ({type, message}) => {

        const { utm, id } = this.props.match.params

        return (
            <div className='application_message'>
                <Icon className='application_icon' style={{color: '#4CC7BE'}} name={'thumbs up'} />
                <h1>Odeslání rezervace zájezdu proběhlo v pořádku!</h1>
                <div className='application_success_info'>
                    <p className='text-center'>Vaše ID</p>
                    <UserId>
                        {this.setCode(id)}
                    </UserId>

                    <p style={{fontSize: 22}}>Věnujte prosím pozornost informacím, které dorazí na Vámi uvedený email v rezervaci.</p>
                    <p style={{fontSize: 22}}>Na uvedený email Vám přijde odkaz do Vaši administrace FIT KLUB, kde můžete spravovat své přihlášky, rezervace a cvičební lekce. Nejdříve si vytvořte heslo a použijte stejný email, jako v rezervaci)přihlášce.</p>
                    <p style={{fontSize: 22}}>Platbu můžete uhradit platební bránou nebo převodem na účet.</p>
                    <p style={{color: 'red', fontSize: 22}}>Platbu doporučujeme uhradit, co nejdříve, aby Vaše rezervovaná služba byla potvrzena.</p>

                    
                    <>
                    <div className='row card-payment'>
                        <div className='col-sm-6'>
                            <h2>{this.state.total_price},- Kč</h2>
                        </div>
                        <div className='col-sm-6 text-center'>
                            <Button size='massive' color='green' href={this.state.gateway}>Zaplatit kartou</Button>
                        </div>
                    </div>
                    
                    <div className='row banktransfer-payment'>
                        <div className='col-sm-6'>
                            <h3>Platba převodem</h3>
                            <p>Cena: <strong>{this.state.total_price},- Kč</strong></p>
                            <p>Platbu prosím zašlete na číslo účtu: <strong>{this.state.bank_acc}</strong></p>
                            <p>Variabilní symbol: <strong>{this.setCode(id)}</strong></p>
                        </div>
                        <div className='col-sm-6 qr'>
                            <img src={'https://api.paylibo.com/paylibo/generator/czech/image?accountNumber=5669472379&bankCode=0800&amount='+this.state.total_price+'&currency=CZK&vs='+ this.setCode(id)} className='qr_payment img-fluid' />
                        </div>
                    </div>
                    </>
                    
                    <div className='row'>
                        <div className='col-sm-12 text-center'>
                            <Button size='massive' color='green' href='https://www.1fit.cz'>Zpět na 1FIT</Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {

        const { utm } = this.props.match.params
        const { loading } = this.state

        return (
            <>
            <HeaderApplications hasRoute={true} route={'Potvrzení odeslání rezervace'} backlink={'/prihlaska'}  />
                <Container className='mt-5 mb-5'>
                    {!loading ?
                    <this._renderMessage />
                    :
                    <Loading inEmbed={true} />
                    }
                </Container>
            </>
        )
    }

}

export default TourApplicationConfirm