import React, { Component } from 'react'
import { getDates, selectCamp } from '../../../api'
import { DateItem, DateHead, DateSelected, EmbedMessage, FormButtonGroup, TermModal, FormSection, FormSectionHead, FormSectionBody, ModalHead } from '../../../styles'
import moment from 'moment'
import { Button, Modal, Message } from 'semantic-ui-react'

class TermsForm extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            dates: [],
            datesByKids: [],
            kids: [],
            selected: '',
            selectedItems: [],
            selectLoading: false,
            isSelected: false,
            alternate: false,
            canSelect: false,
            modals: {}
        })
    }

    componentDidMount() {
        getDates()
        .then((data) => {
            this.setState({
                dates: data
            }, () => {
                this.setDatesData()
            })
        })

        this.setState({
            kids: this.props.kids
        }, () => {
            this.checkSelected()
            this.setDatesData()
        })
    }

    componentDidUpdate(prevProps) {
        if(this.props.kids !== prevProps.kids) {
            this.setState({
                kids: this.props.kids
            }, () => {
                this.checkSelected()
                this.setDatesData()
            })
        }
    }

    selectHandler = (date, index) => {
        this.setState({
            selectLoading: true
        })

        let kids = [...this.state.kids]
        kids[index]['kids_camps'] = kids[index]['kids_camps'].concat(date)
        kids[index]['kids_can_select'] = kids[index]['kids_can_select'].filter(i => i.id_date !== date.id_date)

        this.setState({
            selected: date,
            kids: kids
        }, () => {
            this.toggleModal(index)
            this.setState({
                selectLoading: false
            })
            this.checkSelected()
        })
    }

    checkSelected = () => {

        for (var i = 0; i < this.state.kids.length; i++) {
            if (this.state.kids[i]['kids_camps'].length === 0) {
                this.setState({
                    isSelected: false
                })
              break;
            } else {
                this.setState({
                    isSelected: true
                })
            }
        }

    }

    toggleModal = (index) => {
        this.setState({
            modals: {
                ['modal-' + index]: this.state.modals['modal-' + index] !== undefined ? !this.state.modals['modal-' + index] : false
            }
        })
    }

    closeModal = ({index}) => {
        this.setState({
            modals: {
                ['modal-' + index]: this.state.modals['modal-' + index] !== undefined && false
            }
        })
    }

    setDatesData = () => {
        let kids = [...this.state.kids]
        var MAXIMUM_BABYJOY = moment().subtract(4, 'year');
        var MINIMUM_SPORTJOY = moment().subtract(6, 'year');
        var MAXIMUM_BOTH = moment().subtract(5, 'year');

        this.state.kids.map((item, index) => {
            
            var diff = moment(kids[index]['kids_birthday']).diff('2020-07-06', 'years', true);
            var interDiff = -diff

            /*if(interDiff > 6) {
                kids[index]['kids_can_select'] = this.state.dates.filter(i => i.id_camp !== 7)
            } else if(interDiff < 4 && interDiff < 5) {
                kids[index]['kids_can_select'] = this.state.dates.filter(i => i.id_camp === 7)
            } else {
                kids[index]['kids_can_select'] = this.state.dates
            }*/

            if(interDiff > 3 && interDiff < 5) {
                kids[index]['kids_can_select'] = this.state.dates.filter(i => i.id_camp === 7)
            } else if(interDiff > 5 && interDiff < 6) {
                kids[index]['kids_can_select'] = this.state.dates
            } else if(interDiff > 6) {
                kids[index]['kids_can_select'] = this.state.dates.filter(i => i.id_camp !== 7)
            }
        })

        this.setState({
            kids: kids,
            canSelect: true
        })
    }

    renderKids = () => {

        return this.state.kids.map((kid, index) => {

            return (
                <FormSection>
                    <FormSectionHead>
                        <h5>{kid.kids_name}</h5>
                    </FormSectionHead>

                    <FormSectionBody>
                        {kid.kids_camps.length === 0 &&
                            <div>
                                <this.selectedItem index={index} />
                            </div>
                        }
                        {kid.kids_camps.map((camp, idx) => {
                            return (
                                <this.selectedItem index={index} camp={camp} />
                            )
                        })}

                        <Button onClick={() => this.toggleModal(index)}>Přidat termín</Button>
                    </FormSectionBody>
                    
                    <this.datesList index={index} show={this.state.modals['modal-' + index] !== undefined ? !this.state.modals['modal-' + index] : false} />
                </FormSection>
            )
        })
    }

    removeHandler = (index, item) => {
        const itemToDelete = item
        let kids = [...this.state.kids]
        
        kids[index]['kids_camps'] = kids[index]['kids_camps'].filter(i => i !== itemToDelete)
        kids[index]['kids_can_select'] = kids[index]['kids_can_select'].concat(itemToDelete)

        this.setState({
            kids: kids
        }, () => {
            this.checkSelected()
        })
    }

    nextHandler = () => {
        this.props.setTerm(this.state.selected)
    }

    renderDates = ({index}) => {

        const { campType } = this.props

        if(this.state.canSelect) {
            return this.state.kids[index].kids_can_select.map((date) => {
                return (
                    <DateItem className={date.date_capacity - date.date_signed <= 0 ? 'row full_date' : 'row'} camp={campType}>
                        <div className='col-sm-2 camp_name_uppercase'>
                        <img className='img-fluid' alt={date.camp_name} src={'https://app.kidsjoy.cz/api/www/media/upload/camp/' + date.id_camp + '/' + date.camp_logo} />
                        </div>
                        <div className='col-sm-2'>
                            {moment(date.date_from).format('D. M.')} - {moment(date.date_to).format('D. M. YYYY')}
                        </div>
                        <div className='col-sm-4'>
                            {date.location_address}
                        </div>
                        <div className='col-sm-2'>
                            volné {date.date_capacity - date.date_signed <= 0 ? '0/' + date.date_capacity : date.date_capacity - date.date_signed+'/'+date.date_capacity}
                        </div>
                        <div className='col-sm-2'>
                            <Button onClick={() => this.selectHandler(date, index)}>{date.date_capacity - date.date_signed <= 0 ? 'Jako náhradník' : 'Vybrat termín'}</Button>
                        </div>
                    </DateItem>
                )
            })
        } else {
            return (
                <p>Načítám...</p>
            )
        }
    }

    selectedItem = ({camp, index}) => {
        return (
            <div>
            <DateSelected loading={this.state.selectLoading} full={this.state.kids[index].kids_camps.length !== 0 && camp.date_capacity - camp.date_signed <= 0}>
                {this.state.kids[index].kids_camps.length === 0 ? <p>Přidejte prosím termín tlačítkem níže.</p> 
                :
                <div className='row'>
                    <div className='col-sm-1 camp_name_uppercase'>
                        {camp.camp_name}
                    </div>
                    <div className='col-sm-3'>
                        {moment(camp.date_from).format('D. M.')} - {moment(camp.date_to).format('D. M. YYYY')}
                    </div>
                    <div className='col-sm-4'>
                        {camp.location_address}
                    </div>
                    <div className='col-sm-2'>
                        volné {camp.date_capacity - camp.date_signed <= 0 ? '0/' + camp.date_capacity : camp.date_capacity - camp.date_signed+'/'+camp.date_capacity}
                    </div>
                    <div className='col-sm-2'>
                        <Button onClick={() => this.removeHandler(index, camp)}>Odebrat</Button>
                    </div>
                </div>
                }
            </DateSelected>
            {this.state.kids[index].kids_camps.length !== 0 && camp.date_capacity - camp.date_signed <= 0 &&
                <EmbedMessage type='error'><p>Tento termín je již plný, vaše dítě bude přihlášeno jako náhradník. Můžete vybrat jiný termín.</p></EmbedMessage>
            }
            </div>
        )
    }

    datesList = ({index, show}) => {
        return (
            <div>
                {/*<ModalHead>
                    <h5>Vyberte termín</h5>
                </ModalHead>
                <DateHead className='row'>
                    <div className='col-sm-1'>
                        Tábor
                    </div>
                    <div className='col-sm-3'>
                        Datum
                    </div>
                    <div className='col-sm-4'>
                        Místo konání
                    </div>
                    <div className='col-sm-2'>
                        Kapacita
                    </div>
                    <div className='col-sm-2'>
                            
                    </div>
                </DateHead>
                <this.renderDates index={index} />*/}
                <Modal open={show} onClose={() => this.closeModal(index)} size={'fullscreen'} closeIcon>
                    <Modal.Header>Vyberte termín</Modal.Header>
                    <Modal.Content>
                        <this.renderDates index={index} />
                    </Modal.Content>
                </Modal>
            </div>
        )
    }

    render() {
        return (
            <div>
                <Message
                    color='red'
                    icon='warning sign'
                    header='Tábory můžete kombinovat'
                    content='V jedné přihlášce můžete kombinovat tábory SPORTJOY a KIDSJOY. Tábory se Vám nabídnou na základě věku dítěte.'
                />
                <this.renderKids />
                <FormButtonGroup>
                    {!this.state.isSelected &&
                    <p>Vyberte ke každému dítěti alespoň jeden termín</p>}
                    <Button className='btn_end' onClick={this.nextHandler} disabled={!this.state.isSelected}>Další</Button>
                </FormButtonGroup>
            </div>
            
        )
    }

}

export default TermsForm