import React, { Component } from 'react'
import { Form, Button, Dropdown } from 'semantic-ui-react'
import moment from 'moment'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css';
import 'moment/locale/cs';
import { DateUtils } from 'react-day-picker';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import { Row, Col } from 'reactstrap';
import { FormButtonGroup } from '../../../styles';
import { Section } from '../../../styles/forms'
import FilterItem from '../../containers/tours/FilterItem';

const externalOpts = [
    {
        key: 0,
        value: 0,
        text: 'Ne'
    },
    {
        key: 1,
        value: 1,
        text: 'Ano'
    }
]

class CreateTourForm extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            data: {
                date_from: new Date(),
                date_to: new Date(),
                tour_name: '',
                tour_description: '',
                tour_payment_deposit: '',
                tour_payment_final: '',
                external_link: '',
                is_external: 0,
                state: 1,
                id_location_type: null,
                id_category: null
            }
        })

        this.handleDayChange = this.handleDayChange.bind(this);
    }

    componentDidMount() {

        const { initial } = this.props

        this._setInitial(initial)

    }

    componentDidUpdate(prevProps) {
        if(this.props.initial !== prevProps.initial) {
            this._setInitial(this.props.initial)
        }
    }

    _setInitial = (data) => {
        this.setState({
            data: {
                id_tour: data.id_tour ? data.id_tour : '',
                capacity: data.capacity ? data.capacity : '',
                tour_name: data.tour_name ? data.tour_name : '',
                date_from: data.date_from ? data.date_from : new Date(),
                date_to: data.date_to ? data.date_to : new Date(),
                tour_description: data.tour_description ? data.tour_description : '',
                tour_payment_deposit_kids: data.tour_payment_deposit_kids ? data.tour_payment_deposit_kids : '',
                tour_payment_final_kids: data.tour_payment_final_kids ? data.tour_payment_final_kids : '',
                tour_payment_deposit_adults: data.tour_payment_deposit_adults ? data.tour_payment_deposit_adults : '',
                tour_payment_final_adults: data.tour_payment_final_adults ? data.tour_payment_final_adults : '',
                external_link: data.external_link ? data.external_link : '',
                is_external: data.is_external ? data.is_external : 0,
                state: data.state ? data.state : 1,
                id_category: data.id_category,
                id_location_type: data.id_location_type
            }
        })
    }

    onChange = (e) => this.setState({
        data: {
            ...this.state.data,
            [e.target.name]: e.target.value
        }
    })

    onChangeSelect = (e, data) => this.setState({
        data: {
            ...this.state.data,
            [data.name]: data.value
        }
    })

    handleDayChange(day, modifiers, dayPickerInput) {

        const input = dayPickerInput.getInput();

        this.setState({ 
            data: {
                ...this.state.data,
                [input.name]: day
            } 
        });
    }

    submit = () => {
        this.props.submit(this.state.data)
    }

    render() {

        const format = "DD/MM/YYYY";
        const { data } = this.state
        const { loading } = this.props

        return (
            <Form onSubmit={this.submit} loading={loading}>
                <Section>
                    <h4 className='heading_separator first'>Základní informace</h4>
                    <Form.Field>
                        <Form.Input
                            type='text'
                            name='tour_name'
                            placeholder='Zadejte název zájezdu'
                            label='Název zájezdu'
                            value={data.tour_name}
                            onChange={this.onChange}
                            required
                        />
                    </Form.Field>
                    <Row className='mb-3'>
                        <Col sm={4}>
                            <Form.Field>
                                <label>Začátek zájezdu</label>
                                <DayPickerInput 
                                    onDayChange={this.handleDayChange} 
                                    inputProps={{ 
                                        name: 'date_from',
                                        locale: 'cs',
                                        localeUtils: MomentLocaleUtils,
                                    }}
                                    dayPickerProps={{ 
                                        locale: 'cs',
                                        localeUtils: MomentLocaleUtils,
                                        format: 'DD-MM-YYYY',
                                        formatDate: 'DD-MM-YYYY',
                                        parseDate: 'DD-MM-YYYY'
                                    }}
                                    format={'DD/MM/YYYY'}
                                    formatDate={formatDate}
                                    parseDate={parseDate}
                                    placeholder={new Date()}
                                    value={formatDate(this.state.data.date_from, format)}
                                />
                            </Form.Field>
                        </Col>
                        <Col sm={4}>
                            <Form.Field>
                                <label>Konec zájezdu</label>
                                <DayPickerInput 
                                    onDayChange={this.handleDayChange} 
                                    inputProps={{ 
                                        name: 'date_to',
                                        locale: 'cs',
                                        localeUtils: MomentLocaleUtils,
                                    }}
                                    dayPickerProps={{ 
                                        locale: 'cs',
                                        localeUtils: MomentLocaleUtils,
                                        format: 'DD-MM-YYYY',
                                        formatDate: 'DD-MM-YYYY',
                                        parseDate: 'DD-MM-YYYY'
                                    }}
                                    format={'DD/MM/YYYY'}
                                    formatDate={formatDate}
                                    parseDate={parseDate}
                                    placeholder={new Date()}
                                    value={formatDate(this.state.data.date_to, format)}
                                />
                            </Form.Field>
                        </Col>
                    </Row>
                    <Form.Field>
                        <Form.TextArea 
                            name='tour_description'
                            label='Popis zájezdu'
                            placeholder='Napište popis tohoto zájezdu'
                            value={data.tour_description}
                            onChange={this.onChange}
                            required
                        />
                    </Form.Field>
                    <Form.Field>
                        <Form.Input 
                            type='number'
                            label='Kapacita zájazdu'
                            placeholder='Zadejte kapacitu zájezdu'
                            name='capacity'
                            value={data.capacity}
                            onChange={this.onChange}
                            required
                        />
                    </Form.Field>
                </Section>

                <Section>
                    <h4 className='heading_separator'>Zařazení do kategorií</h4>
                    <Row className='mb-3'>
                        <Col sm={6}>
                            <Form.Field>
                                <label>Kategorie zájedu</label>
                                <FilterItem 
                                    name='id_category'
                                    placeholder='Vyberte kategorii'
                                    search={false}
                                    type='categories'
                                    onChange={this.onChangeSelect}
                                />
                                </Form.Field>
                        </Col>
                        <Col sm={6}>
                            <Form.Field>
                                <label>ČR / Zahraničí</label>
                                <FilterItem 
                                    name='id_location_type'
                                    placeholder='Vyberte kategorii'
                                    search={false}
                                    type='location-type'
                                    onChange={this.onChangeSelect}
                                />
                            </Form.Field>
                        </Col>
                    </Row>
                </Section>

                <Section>
                    <h4 className='heading_separator'>Platby za zájezd</h4>
                    <Row className='mb-3'>
                        <Col sm={4}>
                            <Form.Field>
                                <Form.Input 
                                    type='number'
                                    name='tour_payment_deposit_kids'
                                    placeholder='Zadejte zálohu'
                                    label='Záloha - dítě'
                                    value={data.tour_payment_deposit_kids}
                                    onChange={this.onChange}
                                    required
                                />
                            </Form.Field>
                        </Col>
                        <Col sm={4}>
                            <Form.Field>
                                <Form.Input 
                                    type='number'
                                    name='tour_payment_final_kids'
                                    placeholder='Zadejte doplatek'
                                    label='Doplatek - dítě'
                                    value={data.tour_payment_final_kids}
                                    onChange={this.onChange}
                                    required
                                />
                            </Form.Field>
                        </Col>
                        <Col sm={4}>
                            <Form.Field>
                                <Form.Input 
                                    type='number'
                                    name='total_price'
                                    placeholder='Celková cena za dítě'
                                    label='Celková cena'
                                    value={Number(data.tour_payment_final_kids) + Number(data.tour_payment_deposit_kids)}
                                    disabled
                                />
                            </Form.Field>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                            <Form.Field>
                                <Form.Input 
                                    type='number'
                                    name='tour_payment_deposit_adults'
                                    placeholder='Zadejte zálohu'
                                    label='Záloha - dospělý'
                                    value={data.tour_payment_deposit_adults}
                                    onChange={this.onChange}
                                    required
                                />
                            </Form.Field>
                        </Col>
                        <Col sm={4}>
                            <Form.Field>
                                <Form.Input 
                                    type='number'
                                    name='tour_payment_final_adults'
                                    placeholder='Zadejte doplatek'
                                    label='Doplatek - dospělý'
                                    value={data.tour_payment_final_adults}
                                    onChange={this.onChange}
                                    required
                                />
                            </Form.Field>
                        </Col>
                        <Col sm={4}>
                            <Form.Field>
                                <Form.Input 
                                    type='number'
                                    name='total_price'
                                    placeholder='Celková cena za dospělého'
                                    label='Celková cena'
                                    value={Number(data.tour_payment_final_adults) + Number(data.tour_payment_deposit_adults)}
                                    disabled
                                />
                            </Form.Field>
                        </Col>
                    </Row>
                </Section>

                <Section>
                    <h4 className='heading_separator'>Externí přihláška</h4>
                    <Row className='mb-3'>
                        <Col sm={4}>
                            <Form.Field>
                                <label>Je přihláška na jiném webu?</label>
                                <Dropdown 
                                    name='is_external'
                                    placeholder='Vyberte možnost'
                                    options={externalOpts}
                                    value={data.is_external}
                                    onChange={this.onChangeSelect}
                                    selection
                                    className={'state-active-' + data.is_external}
                                />
                            </Form.Field>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Form.Field>
                                <Form.Input 
                                    type='url'
                                    name='external_link'
                                    placeholder='Zadejte odkaz na přihlášku'
                                    label='Odkaz na přihlášku'
                                    onChange={this.onChange}
                                    value={this.state.data.external_link}
                                    required={data.is_external === 1 ? true : false}
                                />
                            </Form.Field>
                        </Col>
                    </Row>
                </Section>
                    
                <FormButtonGroup>
                    <Button primary>Uložit zájezd</Button>
                </FormButtonGroup>
            </Form>
        )
    }

}

export default CreateTourForm