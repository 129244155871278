import React, { Component } from 'react'
import { FormButtonGroup, FormSection, FormSectionBody, FormSectionHead, VoucherType } from '../../../styles'
import { getWorkoutsPrepaids } from '../../../api/workouts'
import { Row, Col } from 'reactstrap'

class PrepaidSelectForm extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            items: [],
            item: '',
            loadingData: true
        })
    }

    componentDidMount() {
        this._getPrepaids()
    }

    _getPrepaids = () => {

        const { id, type } = this.props

        getWorkoutsPrepaids(id, type)
        .then((res) => {

            if(res.code === 1) {
                this.setState({
                    items: res.data,
                    loadingData: false
                })
            }
        })
    }

    selectPrepaid = (id) => {
        this.setState({
            selected: id
        }, () => {
            const data = this.state.items.filter(i => i.id_prepaid === id)
            this.props.selectPrepaid(data)
        })
    }

    _renderVouchers = () => {
        return this.state.items.map((item, index) => {
            return (
                <VoucherType key={index} selected={this.state.selected === item.id_prepaid ? true : false} onClick={() => this.selectPrepaid(item.id_prepaid)}>
                    <Row>
                        <Col sm={8}>
                            <h2><span className="voucher_value">Permanentka</span>{item.name},- Kč</h2>
                            <p>{item.description}</p>
                        </Col>
                        <Col sm={4} className='voucher_price_container'>
                            <h2><span className="voucher_value">Cena</span>{item.price_per_lecture * item.num_of_lectures},- Kč</h2>
                        </Col>
                    </Row>
                    
                </VoucherType>
            )
        })
    }

    render() {
        return (
            <>
                {!this.state.loadingData && this._renderVouchers()}
            </>
        )
    }

}

export default PrepaidSelectForm