import React from 'react'
import { Modal, Button, Divider, Icon } from 'semantic-ui-react'
import { Row, Col } from 'reactstrap'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { FullTag } from '../../../styles/events'

const InfoModalHobby = ({ close, closed, item, type, addToWatchlist, loadingWatchlist }) => {

    return (
        <Modal open={!closed} onClose={close}>
            <Modal.Header>{item.name} {item.is_full && <FullTag>Tato lekce je plná</FullTag>}</Modal.Header>
            <Modal.Content>
                <Row>
                    <Col sm={8}>
                        <p>{item.description}</p>
                        <Row>
                            <Col sm={6}>
                                <p><strong>Lektor/ka:</strong><br />{item.user_first_name} {item.user_last_name}</p>
                            </Col>
                            <Col sm={6}>
                                <p><strong>Adresa:</strong><br />{item.venue_address}</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={4}>
                        <p><strong>Čas konání:</strong><br />{moment(item.date).format('dddd v h:mm')}</p>
                        <p><strong>Doba trvání lekce:</strong><br />{moment(item.duration).format('hh:mm')} h</p>
                        <Divider />
                        <p><strong>Cena za pololetí:</strong><br />{item.price_lecture},- Kč</p>
                    </Col>
                </Row>
            </Modal.Content>
            <Modal.Actions className='justify-content-end'>
                <div>
                <Link target='_blank' to={`${process.env.PUBLIC_URL}/prihlaska/cviceni/${type}/${item.id_date}`}><Button primary={!item.is_full} className={item.is_full && 'red'}>{item.is_full ? 'Přihlásit jako náhradník' : 'Přihlásit se'}</Button></Link>
                {item.is_full && 
                    <Button
                        className='ml-2'
                        onClick={() => addToWatchlist(item.id_workout, item.id_date, type)}
                        icon
                        labelPosition='left'
                        loading={loadingWatchlist}
                    >
                        <Icon name='bell' />
                        Hlídat uvolění lekce
                    </Button>}
                </div>
            </Modal.Actions>
        </Modal>
    )
}

export default InfoModalHobby