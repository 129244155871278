import React, { Component } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { getCalendar } from '../../../api/workouts';
import { Event } from '../../../styles/calendar';
import InfoChangeModal from './InfoChangeModal';
import { getToken } from '../../../api';
import { addToWatchlist } from '../../../api/watchlist';
import { connect } from 'react-redux'
import { setMessage } from '../../../actions/messageActions'
import { Popup } from 'semantic-ui-react'

const localizer = momentLocalizer(moment)

class ChangeLectureSchedule extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            dates: [],
            closedModal: true,
            selectedItem: {},
            loadingWatchlist: false,
            loading: true
        })

        this.childRef = React.createRef();
        this.calendarRef = React.createRef();
        this.eventsRefs = [];
    }

    componentDidMount() {

        const { type, is_free } = this.props

        /*var d = new Date();
        if(Number(moment().format('e')) > 4) {
            d.setDate(d.getDate() + (1 + 7 - d.getDay()) % 7);
        } else if(Number(moment().format('e')) === 0) {
            d = new Date();
        } else {
            d.setDate(d.getDate() - (d.getDay() + 6) % 7)
        }*/

        var d = moment('2021-01-18')

        this._getCalendar(d, type)
        this.setState({
            dates: this.props.dates
        }, () => window.addEventListener('scroll', this.checkIfVisible))
    }

    componentDidUpdate(prevProps) {
        if(prevProps.dates !== this.props.dates) {
            this.eventsRefs = []
            this.setState({
                dates: this.props.dates
            }, () => {
                this.setEarliestEvent()
                this.setState({loading: false})
            })
        }

        if(prevProps.selectedItem !== this.props.selectedItem) {
            this.setState({
                selectedItem: this.props.selectedItem
            })
        }
    }

    _getCalendar = (date, type) => {
        this.setState({
            loading: true
        })
        if(type !== 'kids') {
            this.props.getData(date, type)
        } else {

            const { ageGroup, is_free } = this.props

            this.props.getData(date, type, ageGroup, is_free)
        }
        
    }

    myEvent = (props) => {
        return (
            <Popup on='click' content={this._popupContent} key={props.event.id} trigger={
                <Event color={props.event.color} state={props.event.state} ref={(eventsRefs) => { this.eventsRefs[props.event.id] = eventsRefs }}>
                    <p>{props.title}</p>
                </Event>
            } wide />
            
        )
    }

    setEventsTop = () => {

        var lowest = 0

        this.eventsRefs.map((event) => {
            if(event !== null) {
                var position = event.parentElement.parentElement.offsetTop
                var height = document.getElementsByClassName('rbc-events-container')[0].clientHeight;
                var eventHeight = document.getElementsByClassName('rbc-event')[0].clientHeight;
                var pixels = eventHeight;
                var percentage = (pixels / height) * 100;
                var multiply = event.parentElement.parentElement.classList[2]
                var item = event.parentElement.parentElement
                var newHeight = event.parentElement.parentElement.style.top.replace('%', '')
                var newHeightNum = Number(newHeight) + (percentage * multiply)
    
                item.style.top = newHeightNum + '%'

                if(position > lowest) {
                    lowest = position

                    this.setState({
                        lowest: lowest
                    }, () => this.checkIfVisible())
                }

            }
        })
    }

    setEarliestEvent = () => {
        var earliest = moment('2021-12-31 23:59:59').hours()
        var firstItem = {}

        this.props.dates.map((item, index) => {
            var newTime = moment(item.start).hours()
            if(newTime < earliest) {
                earliest = moment(item.start).hours()
                firstItem = item.start
            }
        })

        this.setState({
            scroll: firstItem
        }, () => {
            this.setEventsTop()
        })
    }

    _popupContent = () => {
        const { type } = this.props
        const { loadingChange } = this.state

        return (
            <InfoChangeModal item={this.state.selectedItem} type={type} setToChange={this._setToChange} loadingChange={loadingChange} />
        )
    }

     checkIfVisible = () => {

        var scroll = window.scrollY

        if(this.state.lowest > scroll) {
            this.setState({
                showArrow: true,
                loadingArrow: false
            })
        } else {
            this.setState({
                showArrow: false,
                loadingArrow: false
            })
        }
    }

    eventStyleGetter = (event, start, end, isSelected) => {
        var style = {
            backgroundColor: event.color,
            opacity: event.is_full ? 0.3 : 1,
            borderRadius: '0.25rem',
            color: '#fff',
            border: '0px'
        };
        return {
            style: style,
            className: event.state === 0 ? 'not-active' : 'active ' + event.scheduleCat
        };
    }

    selectEvent = (event) => {
        this.props.getItem(event.id)
        this.showModal()
    }

    _setToChange = (id, id_workout) => {
        this.props.setToChange(id, id_workout)
    }

    showModal = () => this.setState({ closedModal: false })
    hideModal = () => this.setState({ closedModal: true })

    render() {

        let components = {
            event: this.myEvent
        }

        var min_time = new Date('2020-09-07T08:00:00');

        const { type, loadingChange, is_free, ageGroup } = this.props

        /*var d = new Date();
        if(Number(moment().format('e')) > 4) {
            d.setDate(d.getDate() + (1 + 7 - d.getDay()) % 7);
        } else if(Number(moment().format('e')) === 0) {
            d = new Date();
        } else {
            d.setDate(d.getDate() - (d.getDay() + 6) % 7)
        }*/

        var d = moment('2021-01-18')

        return (
            <>
            <Calendar
                localizer={localizer}
                events={!this.state.loading ? this.state.dates : []}
                startAccessor="start"
                endAccessor="end"
                eventPropGetter={this.eventStyleGetter}
                style={{
                    minHeight: 800
                }}
                onNavigate={(date) => this._getCalendar(date, type, ageGroup, is_free)}
                components={components}
                min={moment(this.state.scroll).toDate()}
                defaultDate={d}
                messages={{
                    month: 'Měsíc',
                    day: 'Den',
                    today: 'Dnes',
                    previous: 'Zpět',
                    next: 'Další',
                    week: 'Týden',
                    date: 'Datum',
                    time: 'Čas',
                    event: 'Lekce',
                    showMore: total => `+ Zobrazit další (${total})`
                }}
                onSelectEvent={this.selectEvent}
                defaultView='week'
                views={{ month: true, week: true }}
                scrollToTime={moment(this.state.scroll).toDate()}
                ref={this.calendarRef}
            />
            </>
        )
    }

}

const mapStateToProps = state => ({})
  
export default connect(mapStateToProps, { setMessage })(ChangeLectureSchedule)