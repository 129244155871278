import React from 'react'
import { NoDataContainer } from '../../../styles'
import { Loader } from 'semantic-ui-react'

const Loading = () => {
    return (
        <NoDataContainer>
            <Loader active size='small' />
        </NoDataContainer>
    )
}

export default Loading