import React, { Component } from 'react'
import { Modal, Button, Icon } from 'semantic-ui-react'
import ChangeLectureSchedule from '../containers/workouts/ChangeLectureSchedule'
import { getCalendar, getCalendarItem, changeLecture } from '../../api/workouts'
import { getToken } from '../../api'
import moment from 'moment'
import { connect } from 'react-redux'

class ChangeLectureModal extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            dates: [],
            loadingDates: true
        })
    }

    componentDidMount() {
        const { type, is_free } = this.props
        const formatDate = moment().format('YYYY-MM-DD')
        if(type !== 'kids') {
            this._getCalendarItems(formatDate, type, 0, is_free)
        } else {
            const { ageGroup, is_free, loadingUser } = this.props
            if(!loadingUser) {
                this._getCalendarItemsKids(formatDate, type, ageGroup, is_free)
            }
        }
        
    }

    componentDidUpdate(prevProps) {
        const { type, is_free, ageGroup } = this.props
        const formatDate = moment().format('YYYY-MM-DD')
        if(prevProps.loadingUser !== this.props.loadingUser && !this.props.loadingUser) {
            this._getCalendarItemsKids(formatDate, type, ageGroup, is_free)
        }
    }

    _getCalendarItems = (date, type, ageGroup, is_free = 0) => {
        const formatDate = moment(date).format('YYYY-MM-DD')

        this.setState({
            dates: [],
            loadingDates: true
        })

        getCalendar(formatDate, type)
        .then((res) => {

            const newEvents = []
            res.map((item) => {
                if(!item.is_full) {
                    if(moment(item.start).isAfter()) {
                        if(item.can_change !== 0) {
                            const newItem = {
                                title: item.title,
                                start: moment(item.start).toDate(),
                                end: moment(item.end).toDate(),
                                id: item.id,
                                color: item.color,
                                is_full: item.is_full,
                                scheduleCat: item.cat,
                                description: item.description,
                                id_location: item.id_location,
                                id_workout: item.id_workout,
                                group: item.group,
                                state: item.state
                            }
                            newEvents.push(newItem)
                        }
                    }
                }
            })

            this.setState({
                dates: newEvents,
                loadingDates: false
            })
        })
    }

    _getCalendarItemsKids = (date, type, ageGroup, is_free) => {

        const { user, loadingUser } = this.props

        this.setState({
            dates: [],
            loadingDates: true
        })

        const formatDate = moment(date).format('YYYY-MM-DD')
        getCalendar(formatDate, type)
        .then((res) => {
            const newEvents = []
            res.map((item) => {
                if(!item.is_full) {
                    if(moment(item.start).isAfter()) {
                        if(user.user_admin === 1) {
                            const newItem = {
                                title: item.title,
                                start: moment(item.start).toDate(),
                                end: moment(item.end).toDate(),
                                id: item.id,
                                color: item.color,
                                is_full: item.is_full,
                                age_group: type !== 'moms' ? item.age_group : null,
                                scheduleCat: item.cat,
                                description: item.description,
                                id_location: item.id_location,
                                id_workout: item.id_workout,
                                group: item.group,
                                state: item.state
                            }
                            newEvents.push(newItem)
                        } else {
                            if(item.age_group === ageGroup) {
                                if(item.can_change !== 0) {
                                    if(item.is_free === is_free) {
                                        const newItem = {
                                            title: item.title,
                                            start: moment(item.start).toDate(),
                                            end: moment(item.end).toDate(),
                                            id: item.id,
                                            color: item.color,
                                            is_full: item.is_full,
                                            age_group: type !== 'moms' ? item.age_group : null,
                                            scheduleCat: item.cat,
                                            description: item.description,
                                            id_location: item.id_location,
                                            id_workout: item.id_workout,
                                            group: item.group,
                                            state: item.state
                                        }
                                        newEvents.push(newItem)
                                    }
                                }
                            }
                        }
                    }
                }
            })

            this.setState({
                dates: newEvents
            })
        })
    }

    _getCalendarItem = (id) => {
        const { type } = this.props

        getCalendarItem(id, type)
        .then((res) => {
            if(res.code === 1) {
                this.setState({
                    selectedItem: res.data,
                    itemExist: true
                })
            } else {
                this.setState({
                    selectedItem: {},
                    itemExist: false
                })
            }
        })
    }

    setToChange = (newId, workoutId) => {

        this.setState({
            loadingChange: true
        })

        const { id, type, refresh, setMessage } = this.props

        getToken().then((token) => {
            const data = {
                token: token,
                newId: newId,
                workoutId: workoutId,
                signedId: id
            }

            changeLecture(data, type)
            .then((res) => {
                refresh()
                setMessage(res)

                this.setState({
                    loadingChange: false
                })
            })
        })
    }

    render() {

        const { type, is_free } = this.props

        return (
            <Modal size='fullscreen' trigger={<Button icon primary>Nahradit</Button>} closeIcon>
                <Modal.Header>
                    Vyberte novou lekci
                </Modal.Header>
                <Modal.Content>
                    <ChangeLectureSchedule 
                        loadingChange={this.state.loadingDates} 
                        getData={type !== 'kids' ? this._getCalendarItems : this._getCalendarItemsKids} 
                        setToChange={this.setToChange} 
                        dates={this.state.dates} 
                        getItem={this._getCalendarItem} 
                        selectedItem={this.state.selectedItem} 
                        type={type}
                        is_free={is_free}
                        ageGroup={type !== 'kids' ? undefined : this.props.ageGroup}
                    />
                </Modal.Content>
            </Modal>
        )
    }

}

const mapStateToProps = state => ({
    user: state.user.user,
    loadingUser: state.user.loading
})
  
export default connect(mapStateToProps, { })(ChangeLectureModal)