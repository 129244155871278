import styled from 'styled-components'

export const EventItem = styled.div`
    width: 100%;
    padding: 1.5rem;
    border-radius: 3px;
    border: 1px solid #ececec;
    margin-bottom: 3rem;

    p {
        margin-bottom: 0;
    }

    @media (max-width: 767px) {
        div[class*="col-sm-"] {
            margin-bottom: 1.5rem;
        }
    }

    h2 {
        font-size: 16px;
    }

    ${props => props.full && 'border-color: #f5365c; background: rgba(245, 54, 92, 0.05); p { color: #f5365c; } h2 { color: #f5365c; } '}
`

export const FullTag = styled.span`
    font-size: 12px;
    background: #f5365c; 
    color: #fff;
    padding: 5px 10px;
    border-radius: 500rem;
    margin-left: 10px;
`

export const FreeTag = styled.span`
    font-size: 12px;
    background: #2dce89; 
    color: #fff;
    padding: 5px 10px;
    border-radius: 500rem;
    margin-left: 10px;
`