import React from 'react'
import { Modal, Button, Divider, Icon } from 'semantic-ui-react'
import { Row, Col } from 'reactstrap'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { FullTag, FreeTag } from '../../../styles/events'

const InfoChangeModal = ({ close, closed, item, type, setToChange, loadingChange }) => {

    return (
        <div>
            <div>
                <Row>
                    <Col sm={12}>
                        <Row>
                            <Col sm={6} className='mb-3'>
                                <h3 className="h5">{item.name}</h3>
                            </Col>
                            <Col sm={6} className='mb-3'>
                                {item.is_full ? <FullTag>Tato lekce je plná</FullTag> :  <FreeTag>Tato lekce je volná</FreeTag>}
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={12} className='mb-3'>
                                <p>{item.description}</p>
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col sm={type === 'kids' ? 6 : 12} className='mb-3'>
                                <p><strong>Lektor/ka:</strong><br />{item.user_first_name} {item.user_last_name}</p>
                            </Col>
                            {type === 'kids' &&
                            <Col sm={6} className='mb-3'>
                                <p><strong>Věková kategorie:</strong><br />{item.age_group}</p>
                            </Col>
                            }
                            <Col sm={12} className='mb-3'>
                                <p><strong>Adresa:</strong><br />{item.venue_address}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6} className='mb-3'>
                                <p><strong>Volná místa:</strong><br />{item.capacity - item.num_of_signed}/{item.capacity}</p>
                            </Col>
                            <Col sm={6} className='mb-3'>
                                <p><strong>Čas konání:</strong><br />{moment(item.date).format('dddd v h:mm')}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6} className='mb-3'>
                                <p><strong>Cena za jednu lekci:</strong><br />{item.price_lecture},- Kč</p>
                            </Col>
                            <Col sm={6} className='mb-3'>
                                <p><strong>Permanentky od:</strong><br />{item.price_points}</p>
                            </Col>
                        </Row>
                        <div className="popup_actions">
                        <Row>
                            <Col sm={12} className='mb-2'>
                                {item.can_change === 1 && <Button primary onClick={() => setToChange(item.id_date, item.id_workout)} fluid loading={loadingChange} disabled={loadingChange}>Vybrat lekci</Button>}
                            </Col>
                        </Row>
                        
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default InfoChangeModal