import React, { Component } from 'react'
import { Modal, Button } from 'semantic-ui-react'
import TourListItem from '../../containers/tours/TourListItem'
import SelectTourTable from '../../tables/tours/SelectTourTable'
import _ from 'loadsh'
import { FormButtonGroup } from '../../../styles'

class SelectTourForm extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            column: 'id_tour',
            direction: 'descending',
            tours: [],
            selected: 0
        })
    }

    componentDidMount() {
        this.setState({
            tours: this.props.toursList
        })
    }

    componentDidUpdate(prevProps) {
        if(prevProps.toursList !== this.props.toursList) {
            this.setState({
                tours: this.props.toursList
            })
        }
    }

    _ToursList = () => {

        const { toursList } = this.props

        return (
            toursList.map((item) => {
                return <TourListItem item={item} />
            })
        )
    }

    handleSort = (clickedColumn) => () => {
        const { column, tours, direction } = this.state
    
        if (column !== clickedColumn) {
          this.setState({
            column: clickedColumn,
            tours: _.sortBy(tours, [clickedColumn]),
            direction: 'ascending',
          })
    
          return
        }
    
        this.setState({
          data: tours.reverse(),
          direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    }

    selectTour = (id, id_organization) => {
        this.props.submit(id, id_organization)
        this.setState({
            selected: id,
            id_organization: id_organization
        })
    }

    ToursModal = () => {
        return (
            <Modal trigger={<Button>Vybrat jiný termín</Button>} size="fullscreen">
                <Modal.Header>
                    Vyberte zájezd
                </Modal.Header>
                <Modal.Content className='p-0'>
                    <SelectTourTable items={this.state.tours} sort={this.handleSort} column={this.state.column} direction={this.state.direction} selected={this.state.selected} select={this.selectTour} />
                </Modal.Content>
            </Modal>
        )
    }

    render() {

        const { toursList, selectedItem, isSelected, setButton } = this.props

        return (
            <>
                {isSelected &&
                <TourListItem item={selectedItem} />
                }

                <FormButtonGroup>
                    <this.ToursModal />
                    <Button className='btn_end' onClick={setButton} disabled={!isSelected}>Další</Button>
                </FormButtonGroup>
            </>
        )
    }

}

export default SelectTourForm