import * as types from '../actions/types'

let userState = { user: {}, loading: true }

const user = (state = userState, action) => {
    switch (action.type) {
        case types.USER_LOGGING_IN:
            return { 
                ...state,
                loading: true
            }

        case types.USER_LOGGED_IN:
            return { 
                user: action.user,
                loading: false
            }

        case types.USER_LOGGED_OUT:
            return { 
                user: {},
                loading: true
            }

        default: 
            return state
    }
}

export default user