import React, { Component } from 'react'
import { intToBool, toggleLocationState, shortText, getToken } from '../../../api'
import { Table, Icon, Button, Popup, Modal } from 'semantic-ui-react'
import _ from 'loadsh'
import moment from 'moment'
import { StateIcon } from '../../../styles'
import { Link } from 'react-router-dom'
import { toggleWorkoutState } from '../../../api/workouts'
import WorkoutsTableRow from './WorkoutsTableRow'

class WorkoutsTable extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            token: '',
            loadingToken: true
        })
    }

    componentDidMount() {
        getToken().then((token) => {
            this.setState({
                token: token,
                loadingToken: false
            })
        })
    }

    _trash = (id) => {
        this.props.trash(id)
    }


    deleteModal = (id_item) => {
        return (
            <Modal trigger={<Button className='red' icon><Icon name='trash alternate' /></Button>} onClose={this.hideModal}>
                <Modal.Header>
                    Smazat záznam této lekce?
                </Modal.Header>
                <Modal.Content>
                    <p>Lekce bude vymazána včetně jejích termínů v kalendáři. Informace o přihláškách zůstanou uložené v databázi.</p>
                    <p><strong style={{color: '#f5365c'}}>Tento krok nelze vrátit zpět!</strong></p>
                </Modal.Content>
                <Modal.Actions className='justify-content-end'>
                    <Button className='red' onClick={() => this._trash(id_item)} loading={this.state.loading} disabled={this.state.loading}>Smazat záznam</Button>
                </Modal.Actions>
            </Modal>
        )
    }

    _toggleWorkoutState = (id, type) => {
        getToken().then((token) => {
            const data = {
                token: token
            }

            toggleWorkoutState(data, id, type)
            .then((res) => {
                this.props.setMessage(res)
                this.props.refresh()
            })
            .catch((err) => {
                this.props.setMessage({type: 'error', text: 'Něco se pokazilo, zkuste to prosím později'})
            })
        })
    }

    render() {

        const { items, column, direction, sort, type } = this.props

        return (
            <div className='admin_table_wrapper'>
                <Table sortable striped className='main-table select_table' unstackable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell
                                sorted={column === 'id_workout' ? direction : null}
                                onClick={sort('id_workout')}
                                width={1}
                            >
                                #
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'name' ? direction : null}
                                onClick={sort('name')}
                                width={3}
                                className='mw_300'
                            >
                                Název
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'description' ? direction : null}
                                onClick={sort('description')}
                                width={3}
                                className='mw_200'
                            >
                                Termín/y
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'location_name' ? direction : null}
                                onClick={sort('location_name')}
                                width={4}
                                className='mw_300'
                            >
                                Lokalita
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'date_to' ? direction : null}
                                onClick={sort('date_to')}
                                width={1}
                                className='mw_100'
                            >
                                Kapacita
                            </Table.HeaderCell>

                            {/*<Table.HeaderCell
                                sorted={column === 'tour_decription' ? direction : null}
                                onClick={sort('tour_decription')}
                                width={3}
                            >
                                Vytvořeno
                            </Table.HeaderCell>*/}

                            <Table.HeaderCell
                                sorted={column === 'paid_deposit' ? direction : null}
                                onClick={sort('paid_deposit')}
                                width={1}
                            >
                                Stav
                            </Table.HeaderCell>

                            <Table.HeaderCell width={3} className='mw_200'></Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                    {!this.state.loadingToken && items.map((item) => {
                        return (
                            <WorkoutsTableRow item={item} type={type} token={this.state.token} toggleWorkoutState={this._toggleWorkoutState} deleteModal={this.deleteModal} />
                        )
                    })}
                    </Table.Body>
                </Table>
            </div>
        )
    }
}

export default WorkoutsTable