import React from 'react'
import { OffersWidgetItem, OfferName, OfferBrand, OfferImg, OfferContent } from '../../../styles/offers'
import { Col } from 'reactstrap'
import { url } from '../../../config'
import { Link } from 'react-router-dom'
import moment from 'moment'

const OffersItem = ({item, width, index}) => {
    return (
        <Col sm={width} key={index}>
            <Link to={`/klub/nabidka/${item.id_offer}`}>
            <OffersWidgetItem valid={moment(item.valid_to).isAfter()}>
                <OfferImg img={url.images + '/offers/' + item.id_offer + '/' + item.image}></OfferImg>
                <OfferContent>
                    <OfferName>
                        {item.name}
                    </OfferName>
                    <OfferBrand href={item.link} target='_blank'>{item.brand}</OfferBrand>
                </OfferContent>
            </OffersWidgetItem>
            </Link>
        </Col>
    )
}

export default OffersItem