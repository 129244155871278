import React, { Component } from 'react'
import SignupNewsletterDefault from '../../forms/newsletter/SignupNewsletterDefault'
import { signUpNewsletter } from '../../../api/newsletter'
import queryString from 'query-string'
import SignupNewsletterPostCode from '../../forms/newsletter/SignupNewsletterPostCode'

class SignupNewsletterView extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            data: {
                email: ''
            },
            loading: false,
            isSubmitted: false,
            message: '',
            type: ''
        })
    }

    componentDidMount() {
        document.body.classList.add('application', 'transparent', 'offers')
    }

    chooseForm = () => {
        const { type } = this.props.match.params
        const parsed = queryString.parse(this.props.location.search);

        switch(type) {
            case 'default':
                return  <SignupNewsletterDefault 
                            onChange={this.onChange} 
                            submit={this.submit} 
                            loading={this.state.loading} 
                            isSubmitted={this.state.isSubmitted} 
                            message={this.state.message} 
                            btn_bg={parsed.btnbg}
                            btn_color={parsed.btncl}
                        />

            case 'postcode':
                return <SignupNewsletterPostCode 
                            onChange={this.onChange} 
                            submit={this.submit} 
                            loading={this.state.loading} 
                            isSubmitted={this.state.isSubmitted} 
                            message={this.state.message} 
                            btn_bg={parsed.btnbg}
                            btn_color={parsed.btncl}
                        />
        }
    }

    submit = () => {
        this.setState({
            loading: true
        })

        this._signUpNewsletter()
    }

    _signUpNewsletter = () => {
        signUpNewsletter(this.state.data)
        .then((res) => {
            this.setState({
                isSubmitted: true,
                message: res.message,
                type: res.type,
                loading: false
            })
        })
        .catch((err) => {
            this.setState({
                loading: false,
                message: 'Něco se pokazilo, zkuste to prosím později',
                type: 'error',
                isSubmitted: true
            })
        })
    }

    onChange = (e) => this.setState({
        data: {
            ...this.state.data,
            [e.target.name]: e.target.value
        }
    })

    render() {
        return (
            <>
            {this.chooseForm()}
            </>
        )
    }

}

export default SignupNewsletterView