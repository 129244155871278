import React from 'react'
import { Form, Button } from 'semantic-ui-react'
import { Row, Col } from 'reactstrap'

const SignupNewsletterDefault = ({ onChange, submit, loading, isSubmitted, message, btn_bg, btn_color }) => {
    return (
        <Form onSubmit={submit}>
            {!isSubmitted ?
            <Row>
                <Col sm={9} className='mb-sm-3'>
                    <Form.Field>
                        <Form.Input 
                            type='email'
                            name='email'
                            placeholder='Zadejte svou emailovou adresu'
                            onChange={onChange}
                            required
                        />
                    </Form.Field>
                </Col>
                <Col sm={3}>
                    <Button style={{backgroundColor: '#' + btn_bg, color: '#' + btn_color}} fluid loading={loading} disabled={loading}>Přihlásit k odběru</Button>
                </Col>
            </Row>
            :
            <Row>
                <Col sm={8} className='offset-sm-2'>
                    <h2 className='text-center'>{message}</h2>
                </Col>
            </Row>
            }
            
        </Form>
    )
}

export default SignupNewsletterDefault