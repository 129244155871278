import React, { Component } from 'react'
import moment from 'moment'
import { Form, Checkbox, Button, Popup } from 'semantic-ui-react'
import { FormButtonGroup, FormSection, FormSectionHead, EmbedMessage, FormSectionBody, DateSelected } from '../../../styles'
import { getCoupon, getRecommend } from '../../../api'

class ConfirmForm extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            numberOfTerms: 0,
            data: {
                parent_tos: false,
                parent_gdpr: false,
                parent_photos: false,
                parent_newsletter: false,
                coupon: false,
                coupon_value: 0,
                id_coupon: null,
                recommend: '',
                id_recommended: 0,
                application_note: '',
                additional: 0,
                numberOfSubs: 0
            }
        })
    }

    componentDidMount() {
        this.setNumOfTerms()
    }

    componentDidUpdate(prevProps) {
        if(this.props.data !== prevProps.data) {
            this.setNumOfTerms()
        }
    }

    submitHandler = () => {
        this.props.setConfirm(this.state.data, this.countPrice(this.state.numberOfTerms, this.props.data.kids.length, this.state.data.additional))
    }

    parentsBlock = () => {
        const { data } = this.props
        return (
            data.parents.map((item) => {
                return (
                    <div className='col-sm-6'>
                        <p>Jméno</p>
                        <p>{item.parent_name}</p>
                        <p>Email</p>
                        <p>{item.parent_email}</p>
                        <p>Telefon</p>
                        <p>{item.parent_phone}</p>
                    </div>
                )
            })
        )
    }

    kidsBlock = () => {
        const { data } = this.props
        return (
            data.kids.map((item) => {
                return (
                    <div className='col-sm-6'>
                        <p>Jméno</p>
                        <p>{item.kids_name}</p>
                        <p>Datum narození</p>
                        <p>{moment(item.kids_birthday).format('D. M. YYYY')}</p>
                        <p>Zdravotní omezení</p>
                        <p>{item.kids_health_issues === '' ? 'Nemá' : item.kids_health_issues}</p>
                        <p>Chce být ve skupině s:</p>
                        <p>{item.kids_pairing}</p>
                        <this.renderTerms items={item.kids_camps} />
                    </div>
                )
            })
        )
    }

    setNumOfTerms = () => {
        const { data } = this.props
        let num = 0
        let subs = 0
        data.kids.map((item) => {
            num = num + item.kids_camps.length

            item.kids_camps.map((item) => {
                num = num - item.is_full
                subs = subs + item.is_full
            })
        })

        this.setState({
            numberOfTerms: num,
            numberOfSubs: subs
        })
        
    }

    setNumOfSubs = () => {
        const { data } = this.props

        let num = 0

        data.kids.map((item) => {
            item.kids_camps.map((item) => {
                num = num + item.is_full
            })
        })

        this.setState({
            numberOfSubs: num
        })

    }

    onChangeCoupon = (e) => {
        getCoupon(e.target.value)
        .then((res) => {
            if(res.isCoupon) {
                this.setState({
                    data: {
                        ...this.state.data,
                        coupon: true,
                        coupon_value: res.coupon_value,
                        id_coupon: res.id_coupon
                    }
                })
            } else {
                this.setState({
                    data: {
                        ...this.state.data,
                        coupon: false,
                        coupon_value: '',
                        id_coupon: null
                    }
                })
            }
        })
    }

    onChangeRecommendId = (e) => {

        this.setState({
            data: {
                ...this.state.data,
                id_recommended: Number(e.target.value)
            }
        })

        getRecommend(e.target.value)
        .then((res) => {
            if(res.exists) {
                this.setState({
                    data: {
                        ...this.state.data,
                        additional: 0
                    }
                    
                })
            } else {
                this.setState({
                    data: {
                        ...this.state.data,
                        additional: 0,
                        id_recommended: 0
                    }
                })
            }
        })
    }

    onChangeRecommend = (e) => {
        this.setState({
            data: {
                ...this.state.data,
                recommend: e.target.value
            }
        })
    }

    countPrice = (dates, num_of_kids, add) => {

        const NORMAL_PRICE = 2950
        const LOWER_PRICE = 2750

        if(num_of_kids === 1 && !this.state.data.coupon) {
            return (dates * NORMAL_PRICE) - add
        } else if(num_of_kids === 1 && this.state.data.coupon) {
            return (dates * (NORMAL_PRICE - this.state.data.coupon_value)) - add
        } else if(num_of_kids > 1 && !this.state.data.coupon) {
            return (dates * LOWER_PRICE) - add
        } else if(num_of_kids > 1 && this.state.data.coupon) {
            return (dates * (LOWER_PRICE - this.state.data.coupon_value)) - add
        } else if (dates === 0) {
            return 0
        }

    }

    renderTerms = ({items}) => {
        return items.map((term) => {
            return (
                <div>
                <DateSelected full={term.is_full === 1}>
                    <div className='row'>
                        <div className='col-sm-2'>
                            {term.camp_name}
                        </div>
                        <div className='col-sm-4'>
                            {moment(term.date_from).format('D. M.')} - {moment(term.date_to).format('D. M. YYYY')}
                        </div>
                        <div className='col-sm-6'>
                            {term.location_address}
                        </div>
                    </div>
                </DateSelected>
                {term.is_full === 1 &&
                    <EmbedMessage type='error'><p>Na tento termín se přihlašujete jako náhradník</p></EmbedMessage>
                }
                </div>
            )
        })
    }

    renderPrice = () => {
        const { data } = this.props
        const NORMAL_PRICE = 2950
        const LOWER_PRICE = 2750

        return (
            <div>
                <label>Termíny</label>
                {
                    data.kids.map((item) => {
                        return (
                            <div>
                            <this.renderTerms items={item.kids_camps} />
                            </div>
                        )
                    })
                }
                <Form>
                    <Form.Field>
                        <label>Počet termínů</label>
                        <input type='text' value={this.state.numberOfTerms} disabled />
                    </Form.Field>
                    <Form.Field>
                        <label>Počet dětí</label>
                        <input type='text' value={data.kids.length} disabled />
                    </Form.Field>
                    <Form.Field>
                        <label>Poznámka k přihlášce</label>
                        <textarea 
                            value={this.state.data.note}
                            name='application_note'
                            onChange={this.onChange}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Slevový kód</label>
                        <input 
                            type='text'
                            name='coupon'
                            id='coupon'
                            placeholder='Slevový kód'
                            onChange={this.onChangeCoupon}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Cena</label>
                        <label className='danger'>{this.state.numberOfSubs !== 0 && 'Ohledně termínu, na který jste přihlášeni jako náhradník, se Vám ještě ozveme.'}</label>
                        <h3>{this.countPrice(this.state.numberOfTerms, data.kids.length, this.state.data.additional)},- Kč</h3>
                    </Form.Field>
                </Form>
            </div>
        )
    }

    lastItems = () => {
        return (
            <Form>
                <Form.Field>
                    <label>Doporučuji</label>
                    <input 
                        type='text'
                        name='recommend'
                        id='recommend'
                        placeholder='Jméno příjmení - věk'
                        onChange={this.onChangeRecommend}
                    />
                    <p className='form-desc'>Získejte další slevu. Pokud na tábor doporučíte další účastníky, účastník se přihlásí a uhradí platbu za tábor, získáváte 100 Kč slevu za každého nově přihlášené dítě. Částka bude automaticky zaslána na Váš účet. - příklad celé jméno „Jméno příjmení - věk“</p>
                </Form.Field>
                <Form.Field>
                    <label>Doporučil/a mě</label>
                    <input 
                        type='text'
                        name='id_recommended'
                        id='id_recommended'
                        placeholder='ID účastníka'
                        onChange={this.onChangeRecommendId}
                    />
                </Form.Field>
                <Form.Field>
                    <Checkbox onChange={this.handleCheckbox} name='parent_tos' label={<label><a href='https://www.kidsjoy.cz/cz/vseobecne-podminky' target='_blank'>Souhlasím s všeobecnými podmínkami a platebními podmínkami *</a></label>} />
                </Form.Field>
                <Form.Field>
                    <Checkbox onChange={this.handleCheckbox} name='parent_gdpr' label={<label><a href='https://www.kidsjoy.cz/cz/gdpr' target='_blank'>Souhlasím se zpracováním osobních údajů *</a></label>} />
                </Form.Field>
                <Form.Field>
                <Popup trigger={<Checkbox onChange={this.handleCheckbox} name='parent_photos' label={<label>Souhlasím s pořizováním fotografií v průběhu tábora</label>} />} wide>
                    <span className='form-desc'>V průběhu tábora pořižujeme fotografie a videa, jak děti sportují a radují se. Fotografie dáváme každý den na Facebook a po ukončení léta Vám je pošleme na email.</span>
                </Popup>
                </Form.Field>
                <Form.Field>
                <Popup trigger={<Checkbox onChange={this.handleCheckbox} name='parent_newsletter' label='Souhlasím s odběrem newsletteru' />} wide>
                    <span className='form-desc'>Hromady emailů se nemusíte bát. Občas pošleme slevu :) Občas pošleme nějakou informaci o novém táboře, akci, cvičení nebo dovolené. Občas pošleme žádost o zpětnou vazbu a tak. Z odběru se budete moci později odhlásit.</span>
                </Popup>
                    
                </Form.Field>
            </Form>
        )
    }

    onChange = (e) => this.setState({
        data: {
            ...this.state.data,
            [e.target.name]: e.target.value
        }
    })

    handleCheckbox = (e, data) => {
        this.setState({
            data: {
                ...this.state.data,
                [data.name]: data.checked
            }
        })
    }

    render() {

        const { loading } = this.props

        return (
            <div>
                <FormSection>
                    <FormSectionHead>
                    <h5>Kontaktdní údaje rodičů</h5>
                    </FormSectionHead>
                    <FormSectionBody>
                        <div className='row'>
                            <this.parentsBlock />
                        </div>
                    </FormSectionBody>
                </FormSection>

                <FormSection>
                    <FormSectionHead>
                        <h5>Přihlášené děti</h5>
                    </FormSectionHead>
                    <FormSectionBody>
                        <div className='row'>
                            <this.kidsBlock />
                        </div>
                    </FormSectionBody>
                </FormSection>

                <FormSection>
                    <FormSectionHead>
                        <h5>Souhrn termínů a cena</h5>
                    </FormSectionHead>
                    <FormSectionBody>
                        <this.renderPrice />
                    </FormSectionBody>
                </FormSection>

                <FormSection>
                    <FormSectionHead>
                        <h5>Závěr</h5>
                    </FormSectionHead>
                    <FormSectionBody>
                        <this.lastItems />
                    </FormSectionBody>
                </FormSection>
                
                <FormButtonGroup>
                    <Button className='btn_end' loading={loading} disabled={!this.state.data.parent_gdpr || !this.state.data.parent_tos ? true : false} onClick={this.submitHandler}>Odeslat přihlášku</Button>
                </FormButtonGroup>
            </div>
        )
    }
}

export default ConfirmForm