import { url } from "../config"

export function checkPrepaids(id_workout, id_user) {
    return fetch(url.api + 'prepaids/check/' + id_workout + '/' + id_user)
    .then((data) => { return data.json() })
}

export function useWorkoutPrepaid(data) {
    return fetch(url.api + 'workouts/use-prepaid', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function setPaid(data) {
    return fetch(url.api + 'prepaids/set-paid/', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function getApplication(data) {
    return fetch(url.api + 'prepaids/get/application/', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function updatePrepaidApplication(data) {
    return fetch(url.api + 'prepaids/update-application/', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}