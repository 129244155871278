import React, { Component } from 'react'
import { toggleDateState } from '../../api'
import { Table, Button, Popup } from 'semantic-ui-react'
import _ from 'loadsh'
import moment from 'moment'

class DateItems extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            idToDelete: 2,
            show: props.type === 'homepage' ? false : true,
            items: [],
            baseItems: []
        })
    }

    toggleStateHandler = (id) => {
        toggleDateState(id)
        .then(() => {
            this.props.refresh()
        })
    }

    toggleModal = (id) => {
        this.setState({
            showModal: !this.state.showModal,
            idToDelete: !this.state.showModal ? id : ''
        })
    }

    handleShow = () => {
        this.setState({ 
            show: !this.state.show,
        })
    }

    render() {

        const { items, sort, column, direction } = this.props

        return (
            <div className='relative'>
                <div className={this.state.show ? 'collapsable-table show' : 'collapsable-table hidden'}>
                <Table sortable striped className='embed-table'>
                    <Table.Header>
                        <Table.Row>

                            <Table.HeaderCell
                                sorted={column === 'id_date' ? direction : null}
                                onClick={sort('id_date')}
                            >
                                #
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'camp_name' ? direction : null}
                                onClick={sort('camp_name')}
                            >
                                Tábor
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'date_from' ? direction : null}
                                onClick={sort('date_from')}
                            >
                                Od
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'date_to' ? direction : null}
                                onClick={sort('date_to')}
                            >
                                Do
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'camp_age' ? direction : null}
                                onClick={sort('camp_age')}
                            >
                                Věk
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'location_name' ? direction : null}
                                onClick={sort('location_name')}
                            >
                                Lokalita
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'date_capacity' ? direction : null}
                                onClick={sort('date_capacity')}
                            >
                                Volná místa
                            </Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                <Table.Body>
                {_.map(items, ({ id_date, camp_name, camp_logo, camp_age, camp_description, id_camp, date_from, date_to, date_signed, location_name, date_capacity, is_full, location_address }) => (
                    <Table.Row key={id_date} className={is_full === 1 && 'full_date'}>
                        <Table.Cell>
                            {id_date}
                        </Table.Cell>
                        <Table.Cell width='2'>
                            <Popup trigger={<img className='img-fluid' alt={camp_name} src={'https://app.kidsjoy.cz/api/www/media/upload/camp/' + id_camp + '/' + camp_logo} />}>
                                {camp_description}
                            </Popup>
                        </Table.Cell>
                        <Table.Cell className='date date_from'>
                            <span className='only-phone display-block'>Od: </span>{moment(date_from).format("DD/MM/YYYY")}
                        </Table.Cell>
                        <Table.Cell className='date date_to'>
                            <span className='only-phone display-block'>Do: </span>{moment(date_to).format("DD/MM/YYYY")}
                        </Table.Cell>
                        <Table.Cell className='date date_to'>
                            <span className='only-phone display-block'>Věk: </span>{camp_age} let
                        </Table.Cell>
                        <Table.Cell>
                            <span className='only-phone display-block'>Adresa: </span><p><strong>{location_name}</strong><br />{location_address}</p>
                        </Table.Cell>
                        <Table.Cell className='capacity'>
                            <span className='only-phone display-block'>Volná místa: </span> {(date_capacity - date_signed) <= 0 ? '0/' + date_capacity : (date_capacity - date_signed) + '/' + date_capacity}
                        </Table.Cell>
                        <Table.Cell className='application_button'>
                            <Button className="btn" href={`${process.env.PUBLIC_URL}/embed/signup/kidsjoy`} target="_blank">{is_full === 0 ? 'Přihláška' : 'Jako náhradník'}</Button>
                        </Table.Cell>
                    </Table.Row>
                ))}
                </Table.Body>
            </Table>
            {this.props.type === 'homepage' &&
            <div className='table-buttons'>
                <Button onClick={this.handleShow}>{!this.state.show ? 'Ukázat vše' : 'Schovat'}</Button>
            </div>
            }
            </div>
            </div>
        )
    }
}

export default DateItems