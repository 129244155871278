import React, { useState, useEffect } from 'react'
import { Form, Button } from 'semantic-ui-react'
import { Row, Col } from 'reactstrap'
import { useDispatch } from "react-redux";
import { updateNote } from '../../../api/workouts';
import { getToken } from '../../../api';
import { jsonToFormData } from '../../../tools';
import { setMessage } from '../../../actions/messageActions'

const ApplicationNoteForm = ({ user, token, id, type }) => {

    const dispatch = useDispatch();
    const [data, setData] = useState({});
    const [loadingData, setLoadingData] = useState(true)

    useEffect(() => {
        setData(user)
        setLoadingData(false)
    }, [user])

    const onChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    function submit() {
        setLoadingData(true)
        const prepData = {
            id_app: id,
            type: type,
            note: data.note
        }
        console.log(prepData)
        const newData = jsonToFormData(prepData)

        updateNote(newData, token)
        .then((res) => {
            dispatch(setMessage(res.data))
            setLoadingData(false)
        })
        .catch((err) => {
            setLoadingData(false)
            dispatch(setMessage({type: 'error', text: 'Něco se pokazilo, zkuste to prosím později'}))
        })
        
    }

    return (
        <Form loading={loadingData} onSubmit={submit}>
            <Row className='mb-3'>
                <Col sm={12}>
                    <Form.Field>
                        <Form.TextArea 
                            name='note'
                            label='Poznámka uživatele'
                            value={data.note}
                            onChange={onChange}
                        />
                    </Form.Field>
                </Col>
            </Row>
            <Row className='d-flex justify-content-center'>
                <Button primary>Uložit změny</Button>
            </Row>
        </Form>
    )
}

export default ApplicationNoteForm