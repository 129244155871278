import React from 'react'
import { Form, Button, Dropdown, Input } from 'semantic-ui-react'
import { FormButtonGroup } from '../../styles'

const isAdmin = [
    {key: 'no', value: 0, text: 'Ne'},
    {key: 'yes', value: 1, text: 'Ano'}
]

const EditUserForm = ({ user, errors, submit, onChange, values, onChangeSelect, isNew, loading }) => {
    return(
        <Form onSubmit={submit} loading={loading}>
            <Form.Field>
                <label>E-mail</label>
                <Input
                    type="email"
                    name="user_email"
                    id="user_email"
                    value={user.user_email}
                    onChange={onChange}
                    placeholder="E-mail"
                    error={errors.user_email}
                />
            </Form.Field>
            {isNew &&
            <Form.Field>
                <label>Heslo</label>
                <Input
                    type="password"
                    name="user_passwd"
                    id="user_passwd"
                    value={user.user_passwd}
                    onChange={onChange}
                    placeholder="Heslo"
                    error={errors.user_passwd}
                />
            </Form.Field>
            }
            {isNew &&
            <Form.Field>
                <label>Heslo znovu</label>
                <Input
                    type="password"
                    name="user_passwd_check"
                    id="user_passwd_check"
                    value={user.user_passwd_check}
                    onChange={onChange}
                    placeholder="Heslo znovu"
                    error={errors.user_passwd_check}
                />
            </Form.Field>
            }
            <Form.Field>
                <label>Jméno</label>
                <Input
                    type="text"
                    name="user_first_name"
                    id="user_first_name"
                    placeholder="Jméno"
                    value={user.user_first_name}
                    onChange={onChange}
                    error={errors.user_first_name}
                />
            </Form.Field>
            <Form.Field>
                <label>Příjmení</label>
                <Input
                    type="text"
                    name="user_last_name"
                    id="user_last_name"
                    placeholder="Příjmení"
                    value={user.user_last_name}
                    onChange={onChange}
                    error={errors.user_last_name}
                />
            </Form.Field>
            <Form.Field>
                <label>Organizace</label>
                <Dropdown 
                    name='user_organization'
                    placeholder='Vyberte možnost'
                    options={values}
                    onChange={onChangeSelect}
                    value={user.user_organization}
                    selection
                    search
                    error={errors.user_organization}
                />
            </Form.Field>
            <Form.Field>
                <label>Uživatel je administrátor</label>
                <Dropdown 
                    name='user_admin'
                    placeholder='Vyberte možnost'
                    options={isAdmin}
                    onChange={onChangeSelect}
                    selection
                    value={user.user_admin}
                    className={'state-active-' + user.user_admin}
                    error={errors.user_admin}
                />
            </Form.Field>
            <Form.Field>
                <label>Uživatel je lektor</label>
                <Dropdown 
                    name='is_lecturer'
                    placeholder='Vyberte možnost'
                    options={isAdmin}
                    onChange={onChangeSelect}
                    selection
                    value={user.is_lecturer}
                    className={'state-active-' + user.is_lecturer}
                    error={errors.is_lecturer}
                />
            </Form.Field>
            {isNew &&
            <FormButtonGroup>
                <Button primary>Vytvořit uživatele</Button>
            </FormButtonGroup>
            }
        </Form>
    )
}

export default EditUserForm