import React, { Component } from 'react'
import { intToBool, toggleLocationState, shortText, getToken } from '../../../api'
import { Table, Icon, Button, Popup } from 'semantic-ui-react'
import _ from 'loadsh'
import moment from 'moment'
import { StateIcon } from '../../../styles'
import { Link } from 'react-router-dom'

class ReservationsTable extends Component {

    constructor(props) {
        super(props)
        this.state = ({})
    }

    render() {

        const { items, column, direction, sort, type } = this.props

        return (
            <div>
                <Table sortable striped className='main-table select_table'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell
                                sorted={column === 'id_application' ? direction : null}
                                onClick={sort('id_application')}
                                width={1}
                            >
                                #
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'workout_name' ? direction : null}
                                onClick={sort('workout_name')}
                                width={3}
                            >
                                Název lekce
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'date' ? direction : null}
                                onClick={sort('date')}
                                width={3}
                            >
                                Datum a čas
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'total_price' ? direction : null}
                                onClick={sort('total_price')}
                                width={1}
                            >
                                Cena
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'created' ? direction : null}
                                onClick={sort('created')}
                                width={2}
                            >
                                Vytvořeno
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'paid' ? direction : null}
                                onClick={sort('paid')}
                                width={1}
                            >
                                Zaplaceno
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'state' ? direction : null}
                                onClick={sort('state')}
                                width={1}
                            >
                                Status
                            </Table.HeaderCell>

                            <Table.HeaderCell width={2}></Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                    {_.map(items, ({ id_application, id_workout, date, total_price, paid, state, created, workout_name }, index) => (
                        <Table.Row key={id_application}>
                            <Table.Cell>
                                {id_application}
                            </Table.Cell>
                            <Table.Cell>
                                {workout_name}
                            </Table.Cell>
                            <Table.Cell>
                                {moment(date).format('DD. MM. YYYY h:ss')}
                            </Table.Cell>
                           
                            <Table.Cell>
                                {total_price}
                            </Table.Cell>

                            <Table.Cell>
                                {moment(created).format('DD. MM. YYYY')}
                            </Table.Cell>

                            <Table.Cell className='text-center'>
                                <StateIcon state={paid} />
                            </Table.Cell>

                            <Table.Cell className='text-center'>
                                <StateIcon state={state} />
                            </Table.Cell>

                            <Table.Cell className='text-right'>
                                <Button icon><Icon name='edit' /></Button>
                            </Table.Cell>

                        </Table.Row>
                    ))}
                    </Table.Body>
                </Table>
            </div>
        )
    }
}

export default ReservationsTable