import React, { Component } from 'react'
import { StatsCard } from '../containers'
import { countApplications, countKids, todayApplications, todayKids, lastFiveApplications, getToken, getCouponsStats, paidUnpaid, getNumberPaid } from '../../api'
import { LastTenApplicationsTable, CouponsStatsTable } from '../containers'
import { Card, CardHeader } from '../../styles'
import { Link } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import { connect } from 'react-redux'

class Dashboard extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            total_applications: 0,
            total_kids: 0,
            today_applications: 0,
            applications_difference: 0,
            today_kids: 0,
            kids_difference: 0,
            paidUnpaid: {
                paid: 0,
                unpaid: 0,
                percentage: 0
            },
            numberPaid: {
                paid: 0,
                total: 0,
                avg: 0
            },
            lastFiveApplications: [],
            couponsStats: []
        })
    }

    componentDidMount() {
        document.body.classList.remove('application')
        this.countApplicationsHandler()
        this.countKidsHandler()
        this.todayApplicationsHandler()
        this.todayKidsHandler()
        this.couponsStatsHandler()
        this.paidUnpaidHandler()
        this.numberPaidHandler()

        if(!this.props.loadingUser) {
            this.lastFiveApplicationsHandler()
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.loadingUser !== this.props.loadingUser) {
            this.lastFiveApplicationsHandler()
        }
    }

    countApplicationsHandler = () => {
        getToken().then((token) => {
            countApplications(token)
            .then((data) => {
                this.setState({
                    total_applications: data.total_applications
                })
            })
        })
        
    }

    countKidsHandler = () => {
        getToken().then((token) => {
            countKids(token)
            .then((data) => {
                this.setState({
                    total_kids: data
                })
            })
        })
    }

    todayApplicationsHandler = () => {
        getToken().then((token) => {
            todayApplications(token)
            .then((data) => {
                this.setState({
                    today_applications: data.today_applications,
                    applications_difference: data.difference
                })
            })
        })
    }

    todayKidsHandler = () => {
        getToken().then((token) => {
            todayKids(token)
            .then((data) => {
                this.setState({
                    today_kids: data.today_kids,
                    kids_difference: data.difference
                })
            })
        })
    }

    paidUnpaidHandler = () => {
        getToken().then((token) => {
            paidUnpaid(token)
            .then((data) => {
                this.setState({
                    paidUnpaid: data,
                })
            })
        })
    }

    lastFiveApplicationsHandler = () => {

        const { user } = this.props

        getToken().then((token) => {
            const data = {
                token: token,
                id_org: user.id_organization
            }

            lastFiveApplications(data)
            .then((res) => {
                if(res.code === 1) {
                    this.setState({
                        lastFiveApplications: res.data,
                        hasLastFive: true,
                        loading: false
                    })
                } else {
                    this.setState({
                        hasLastFive: false,
                        loading: false
                    })
                }
            })
        })
    }

    couponsStatsHandler = () => {
        getCouponsStats()
        .then((data) => {
            this.setState({
                couponsStats: data
            })
        })
    }

    numberPaidHandler = () => {
        getToken().then((token) => {
            getNumberPaid(token)
            .then((data) => {
                this.setState({
                    numberPaid: data
                })
            })
        })
        
    }

    render() {

        const { user } = this.props

        return (
            <div>
                <div className='row dashboard-row'>
                    <div className='col-sm-3'>
                        <StatsCard type='today-applications' value={this.state.today_applications} diff={this.state.applications_difference} />
                    </div>
                    <div className='col-sm-3'>
                        <StatsCard type='today-kids' value={this.state.today_kids} diff={this.state.kids_difference} />
                    </div>
                    <div className='col-sm-3'>
                        <StatsCard type='total-applications' value={this.state.total_applications} />
                    </div>
                    <div className='col-sm-3'>
                        <StatsCard type='total-kids' value={this.state.total_kids} />
                    </div>
                </div>
                <div className='row dashboard-row'>
                    <div className={'col-sm-8'}>
                        <Card>
                            <CardHeader>
                                <div className='row'>
                                    <div className='col-sm-9 text-left'>
                                        <h2>Posledních 5 přihlášek</h2>
                                    </div>
                                    <div className='col-sm-3 text-right'>
                                        <Link to={`${process.env.PUBLIC_URL}/admin/manage/applications`}><Button>Přejít na přihlášky</Button></Link>
                                    </div>
                                </div>
                            </CardHeader>
                            <LastTenApplicationsTable items={this.state.lastFiveApplications} />
                        </Card>
                    </div>
                    {user.user_admin &&
                    <div className='col-sm-4'>
                        <StatsCard type='paid-unpaid' value={this.state.paidUnpaid} />
                        <StatsCard type='number-paid' value={this.state.numberPaid} />
                        {/*<Card>
                            <CardHeader>
                                <div className='row'>
                                    <div className='col-sm-6 text-left'>
                                        <h2>Statistiky kuponů</h2>
                                    </div>
                                    <div className='col-sm-6 text-right'>
                                        <Link to={`${process.env.PUBLIC_URL}/settings/coupons`}><Button>Přejít na kupony</Button></Link>
                                    </div>
                                </div>
                            </CardHeader>
                            <CouponsStatsTable items={this.state.couponsStats} />
                        </Card>*/}
                    </div>
                    }
                </div>
            </div>
        )
    }

}

const mapStateToProps = state => ({
    user: state.user.user,
    loadingUser: state.user.loading
})
  
export default connect(mapStateToProps, {})(Dashboard)