import React, { Component } from 'react'
import { Form, Input, Button } from 'semantic-ui-react'
import { FormButtonGroup } from '../../../styles'
import { updateParents } from '../../../api'
import { setMessage } from '../../../actions/messageActions'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom/cjs/react-router-dom'

class ParentsForm extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            id: '',
            parent_name: '',
            parent_email: '',
            parent_phone: '',
            parent_second: '',
            recommend: '',
            id_recommend: '',
            note: ''
        })
    }

    componentDidMount() {

        const { parents } = this.props

        this.setState({
            id: parents.id_parents,
            parent_name: parents.parent_name,
            parent_email: parents.parent_email,
            parent_phone: parents.parent_phone,
            recommend: parents.recommend,
            id_recommend: parents.id_recommend !== 0 ? parents.id_recommend : '',
            parent_second: parents.parent_second,
            note: parents.note
        })
    }

    componentDidUpdate(prevProps) {
        if(this.props.parents !== prevProps.parents) {
            const { parents } = this.props

            this.setState({
                id: parents.id_parents,
                parent_name: parents.parent_name,
                parent_email: parents.parent_email,
                parent_phone: parents.parent_phone,
                parent_second: parents.parent_second,
                note: parents.note
            })
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    updateParentsHandler = () => {

        this.setState({
            loading: true
        })

        updateParents(this.state)
        .then((res) => {
            this.props.setMessage(res)
            this.props.refresh()
            this.setState({
                loading: false
            })
        })
    }

    render() {
        const {parents, loading, onChange, isAdmin} = this.props

        return (
            <Form loading={loading || this.state.loading} onSubmit={this.updateParentsHandler}>
                <Form.Field>
                    <label>Jméno rodiče</label>
                    <Input 
                        type='text'
                        name='parent_name'
                        value={this.state.parent_name}
                        onChange={this.onChange}
                    />
                </Form.Field>
                {isAdmin &&
                <Form.Field>
                    <label>Email rodiče</label>
                    <Input 
                        type='text'
                        name='parent_email'
                        value={this.state.parent_email}
                        onChange={this.onChange}
                    />
                </Form.Field>
                }
                <Form.Field>
                    <label>Telefon rodiče</label>
                    <Input 
                        type='text'
                        name='parent_phone'
                        value={this.state.parent_phone}
                        onChange={this.onChange}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Poznámka k přihlášce</label>
                    <textarea 
                        name='note'
                        value={this.state.note}
                        disabled
                    ></textarea>
                </Form.Field>
                <div className="row">
                    <div className="col-sm-6">
                        <Form.Field>
                            <label>Doporučil/a mě</label>
                            <Input 
                                type='text'
                                name='id_recommend'
                                value={this.state.id_recommend}
                                onChange={this.onChange}
                                label={this.state.id_recommend !== '' && <Link className="ui button" to={`${process.env.PUBLIC_URL}/manage/applications/detail/${this.state.id_recommend}`}>Ukázat přihlášku</Link>}
                                labelPosition='right'
                            />
                        </Form.Field>
                    </div>
                    <div className="col-sm-6">
                        <Form.Field>
                            <label>Doporučuji</label>
                            <Input 
                                type='text'
                                name='recommend'
                                value={this.state.recommend}
                                onChange={this.onChange}
                            />
                        </Form.Field>
                    </div>
                </div>
                <FormButtonGroup>
                    <Button>Uložit změny</Button>
                </FormButtonGroup>
            </Form>
        )
    }

}

const mapStateToProps = state => ({})
  
export default connect(mapStateToProps, { setMessage })(ParentsForm)