import React, { Component } from 'react'
import { Form, Input, Button, TextArea } from 'semantic-ui-react'
import { FormButtonGroup, FormSection, FormSectionHead, FormSectionBody } from '../../../styles'

class ParentsForm extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            buttonActive: true,
            isFilled: false,
            basicParent: [{
                label: 'Jméno',
                type: 'text',
                name: 'first_name',
                placeholder: 'Zadejte křestní jméno',
                id: 'first_name',
                required: true,
                width: 12
            },
            {
                label: 'Přijímení',
                type: 'text',
                name: 'last_name',
                placeholder: 'Zadejte přijímení',
                id: 'last_name',
                required: true,
                width: 12
            },
            {
                label: 'Email',
                type: 'email',
                name: 'email',
                placeholder: 'Zadejte váš email',
                id: 'email',
                required: true,
                width: 12
            },
            {
                label: 'Telefon',
                type: 'tel',
                name: 'phone',
                placeholder: 'Zadejte váš telefon',
                id: 'phone',
                required: true,
                width: 12
            },
            {
                label: 'Adresa',
                type: 'text',
                name: 'address',
                placeholder: 'Zadejte adresu bydliště',
                id: 'address',
                required: true,
                width: 12
            },
            {
                label: 'Město',
                type: 'text',
                name: 'city',
                placeholder: 'Zadejte město',
                id: 'city',
                required: true,
                width: 6
            },
            {
                label: 'PSČ',
                type: 'text',
                name: 'post_code',
                placeholder: 'Zadejte PSČ',
                id: 'post_code',
                required: true,
                width: 6
            }],
            formFields: [
                [{
                    label: 'Jméno',
                    type: 'text',
                    name: 'first_name',
                    placeholder: 'Zadejte křestní jméno',
                    id: 'first_name',
                    required: true,
                    width: 12
                },
                {
                    label: 'Přijímení',
                    type: 'text',
                    name: 'last_name',
                    placeholder: 'Zadejte přijímení',
                    id: 'last_name',
                    required: true,
                    width: 12
                },
                {
                    label: 'Email',
                    type: 'email',
                    name: 'email',
                    placeholder: 'Zadejte váš email',
                    id: 'email',
                    required: true,
                    width: 12
                },
                {
                    label: 'Telefon',
                    type: 'tel',
                    name: 'phone',
                    placeholder: 'Zadejte váš telefon',
                    id: 'phone',
                    required: true,
                    width: 12
                },
                {
                    label: 'Adresa',
                    type: 'text',
                    name: 'address',
                    placeholder: 'Zadejte adresu bydliště',
                    id: 'address',
                    required: true,
                    width: 12
                },
                {
                    label: 'Město',
                    type: 'text',
                    name: 'city',
                    placeholder: 'Zadejte město',
                    id: 'city',
                    required: true,
                    width: 6
                },
                {
                    label: 'PSČ',
                    type: 'text',
                    name: 'post_code',
                    placeholder: 'Zadejte PSČ',
                    id: 'post_code',
                    required: true,
                    width: 6
                }]
            ],
            data: [
                {
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone: '',
                    address: '',
                    city: '',
                    post_code: ''
                }
            ],
            errors: [
                {
                    parent_name: '',
                    parent_email: '',
                    parent_phone: ''
                },
                {
                    parent_name: '',
                    parent_email: '',
                    parent_phone: ''
                }
            ]
        })
    }

    componentDidMount() {
        const { isLogged } = this.props

        if(isLogged) {
            this.setUser()
        }
    }

    setUser = () => {

        const { user } = this.props

        const loggedParent = [
            {
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
                phone: user.phone,
                address: user.address,
                city: user.city,
                post_code: user.post_code
            }
        ]

        this.setState({
            data: loggedParent,
            loading: false
        })
    }

    onChange = (e) => this.setState({
        data: {
            ...this.state.data,
            [e.target.name]: e.target.value
        }
    })

    removeParent = () => {
        this.setState({
            data: this.state.data.filter(i => i !== this.state.data[1]),
            formFields: [
                ...this.state.formFields.filter(i => i !== this.state.formFields[1])
            ],
            buttonActive: true
        })
    }

    renderFields = () => {
        return (
            this.state.formFields.map((item, index) => {
                return (
                    <FormSection key={index}>
                        <FormSectionHead>
                            <h5>Zákonný zástupce #{index + 1}</h5>
                            {index !== 0 && <Button className='button_card_head' onClick={this.removeParent}>X</Button>}
                        </FormSectionHead>
                        <FormSectionBody>
                            {item.map((field) => {
                                return (
                                    <Form.Field key={field.id}>
                                        {field.type !== 'textarea' ?
                                        <>
                                        <label>{field.label}</label>
                                        <input 
                                            type={field.type}
                                            name={field.name + (index+1)}
                                            placeholder={field.placeholder}
                                            id={field.id}
                                            onChange={this.handleChange}
                                            className={field.name}
                                            required={field.required}
                                            label={field.label}
                                            data-id={index}
                                            value={this.state.data[index][field.name]}
                                        />
                                        </>
                                        :
                                        <TextArea
                                            name={field.name}
                                            placeholder={field.placeholder}
                                            id={field.id}
                                            data-id={index}
                                            onChange={this.handleChange}
                                            className={field.name}
                                            value={this.state.data[index][field.name]}
                                        />
                                        }
                                        <p className='form-error-text'>{this.state.errors[index][field.name]}</p>
                                    </Form.Field>
                                )
                            })}
                        </FormSectionBody>
                    </FormSection>
                )
            })
        )
    }

    addParentHandler = () => {
        this.setState({
            buttonActive: false,
            formFields: [
                ...this.state.formFields,
                this.state.basicParent
            ],
            data: [
                ...this.state.data,
                {
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone: '',
                    address: '',
                    city: '',
                    post_code: ''
                }
            ]
        })
    }

    handleChange = (e) => {
        if (["first_name", "last_name", "phone", "address", "post_code", "city", "email"].includes(e.target.className) ) {
          let parents = [...this.state.data]
          parents[e.target.dataset.id][e.target.className] = e.target.value
          this.setState({ data: parents })
        }
    }

    _submit = () => {
        this.props.submit(this.state.data)
    }

    render() {

        const { loading, submit } = this.props

        return (
            <Form onSubmit={this._submit} loading={loading}>
                <this.renderFields />
                <FormButtonGroup>
                    {this.state.buttonActive &&
                        <Button className='ghost btn_end' onClick={this.addParentHandler}>Přidat druhého zástupce</Button>
                    }
                    <Button className='btn_end'>Další</Button>
                </FormButtonGroup>
            </Form>
        )
    }

}

export default ParentsForm