
import React, { Component } from 'react'
import { Card, CardHeader, CardBody } from '../../../styles'
import { getToken } from '../../../api'
import { getWorkoutsReservations, getEventsList } from '../../../api/club'
import NoReservations from './NoReservations'
import Loading from './Loading'
import ReservationsTable from '../../tables/workouts/ReservationsTable'
import _ from 'loadsh'
import { Row, Col } from 'reactstrap'
import { Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import WatchlistTable from '../../tables/workouts/WatchlistTable'
import { getWatchlist } from '../../../api/watchlist'
import { connect } from 'react-redux'
import { setMessage } from '../../../actions/messageActions'
import { logOutUser } from '../../../actions/userActions'
import firebase from 'firebase'

class WatchlistEvents extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            loadingData: true,
            data: [],
            column: 'id_tour_application',
            direction: 'descending',
            filter: false,
        })
    }

    componentDidMount() {
        this._getWatchlist()
    }

    setCardHeader = (type) => {
        switch (type) {
            case 'moms':
                return 'Hlídané lekce cvičení pro maminky'
            
            case 'kids':
                return 'Hlídané lekce cvičení s dětmi'

            case 'hobby':
                return 'Hlídané lekce kroužků'
    
            default: 
                return 'Moje rezervace'
        }
    }

    _getWatchlist = () => {

        const { type, active } = this.props

        this.setState({
            loadingData: true,
            hasData: false
        })

        getToken().then((token) => {
            const data = {
                limit: 20,
                offset: 0,
                token: token
            }

            getWatchlist(data, type)
            .then((res) => {
                if(res.code === 1) {
                    this.setState({
                        hasData: true,
                        data: res.data,
                        loadingData: false
                    })
                } else {
                    this.setState({
                        hasData: false,
                        loadingData: false
                    })
                }
            })
            .catch((err) => {
                this.setState({
                    loadingData: false,
                    hasData: false
                })
                this.props.setMessage({type: 'error', text: 'Nepodařilo se získat data, zkuste to prosím později'})
            })
            
        })
        .catch((err) => {
            this.props.setMessage({type: 'error', text: 'Nepodařilo se získat bezpečnostní token, budete odhlášeni'})
            firebase.auth().signOut()
            window.location.reload();
            this.props.logOutUser()
        })
    }

    handleSort = (clickedColumn) => () => {
        const { column, data, direction } = this.state
    
        if (column !== clickedColumn) {
          this.setState({
            column: clickedColumn,
            data: _.sortBy(data, [clickedColumn]),
            direction: 'ascending',
          })
    
          return
        }
    
        this.setState({
          data: data.reverse(),
          direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    }

    render() {

        const { hasData, loadingData, data, column, direction } = this.state
        const { type, active, setMessage } = this.props

        return (
            <Card>
                <CardHeader>
                    <Row>
                        <Col sm={9}>
                            {this.setCardHeader(type)}
                        </Col>
                    </Row>
                    
                </CardHeader>
                {hasData && !loadingData &&
                    <WatchlistTable items={data} type={type} sort={this.handleSort} column={column} direction={direction} refresh={this._getWatchlist} setMessage={setMessage} />
                }
                {!hasData && !loadingData &&
                    <CardBody>
                        <NoReservations text='Nemáte žádné hlídané lekce' />
                    </CardBody>
                }
                {loadingData && !hasData &&
                    <CardBody>
                        <Loading />
                    </CardBody>
                }
            </Card>
        )
    }

}

const mapStateToProps = state => ({})
  
export default connect(mapStateToProps, { setMessage, logOutUser })(WatchlistEvents)