

import React, { Component } from 'react'
import { Card, CardHeader, CardBody } from '../../../styles'
import { getToken } from '../../../api'
import { getWorkoutsReservations, getEventsList } from '../../../api/club'
import NoReservations from '../club/NoReservations'
import Loading from '../Loading'
import _ from 'loadsh'
import { Row, Col } from 'reactstrap'
import { Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { setMessage } from '../../../actions/messageActions'
import { getNextLectures } from '../../../api/lecturers'
import NextLecturesTable from '../../tables/lecturers/NextLecturesTable'

class NextLectures extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            loadingData: true,
            data: [],
            column: 'id_tour_application',
            direction: 'descending',
            filter: false,
        })
    }

    componentDidMount() {
        const { type } = this.props
        this._getNextLectures(type)
    }

    setCardHeader = (type) => {
        switch (type) {
            case 'moms':
                return 'Cvičení pro maminky'
            
            case 'kids':
                return 'Cvičení pro děti'

            case 'hobby':
                return 'Kroužky'
    
            default: 
                return 'Moje rezervace'
        }
    }

    _getNextLectures = (type) => {
        getToken().then((token) => {
            getNextLectures(token, type)
            .then((res) => {
                if(res.code === 1) {
                    this.setState({
                        data: res.data,
                        hasData: true,
                        loading: false
                    })
                } else {
                    this.setState({
                        hasData: false,
                        loading: false
                    })
                }
            })
        })
    }

    handleSort = (clickedColumn) => () => {
        const { column, data, direction } = this.state
    
        if (column !== clickedColumn) {
          this.setState({
            column: clickedColumn,
            data: _.sortBy(data, [clickedColumn]),
            direction: 'ascending',
          })
    
          return
        }
    
        this.setState({
          data: data.reverse(),
          direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    }

    render() {

        const { hasData, loading, data, column, direction } = this.state
        const { type, active, setMessage, canChange } = this.props

        return (
            <Card className='mb-5'>
                <CardHeader>
                    <Row>
                        <Col sm={9}>
                            {this.setCardHeader(type)}
                        </Col>
                        <Col sm={3} className='text-right'>
                            {active && <Link to={`${process.env.PUBLIC_URL}/klub/lekce/rozvrh/${type}`}><Button>Zobrazit kalendář</Button></Link>}
                        </Col>
                    </Row>
                    
                </CardHeader>
                {loading &&
                    <CardBody>
                        <Loading />
                    </CardBody>
                }
                {hasData && !loading &&
                    <NextLecturesTable items={data} canChange={canChange} sort={this.handleSort} column={column} direction={direction} type={type} refresh={this._getUserTourReservations} setMessage={setMessage} />
                }
                {!hasData && !loading &&
                    <CardBody>
                        <NoReservations text='V této kategorii nemáte přiřazené lekce' />
                    </CardBody>
                }
            </Card>
        )
    }

}

const mapStateToProps = state => ({})
  
export default connect(mapStateToProps, { setMessage })(NextLectures)