import React, { Component } from 'react'
import { Dropdown } from 'semantic-ui-react'
import { getFilter } from '../../../api/tours'

class FilterItem extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            opts: [],
            loadingOpts: true
        })
    }

    componentDidMount() {
        this._getFilter()
    }

    _getFilter = () => {
        const { type } = this.props
        getFilter(type)
        .then((res) => {
            this.setState({
                opts: res.data,
                loadingOpts: false
            })
        })
    }


    render() {

        const { name, placeholder, search, onChange, value } = this.props

        return (
            <Dropdown
                name={name}
                options={this.state.opts}
                placeholder={placeholder}
                selection
                search={search}
                onChange={onChange}
                fluid
                value={value}
                loading={this.state.loadingOpts}
            />
        )
    }

}

export default FilterItem