import * as types from './types'

export function setMessage(item) {

    const id = Math.floor(Math.random() * 101);

    let newItems = Object.assign({}, item, { id: id })

    return (dispatch, getState) => {
        dispatch({type: types.SET_MESSAGE, items: newItems})

        setTimeout(() => {
            dispatch({type: types.UNSET_MESSAGE, id: id})
        }, 4000);
    }
}