import React, { Component } from 'react'
import { intToBool, toggleLocationState, shortText, getToken } from '../../../api'
import { unsetSub, setAttended } from '../../../api/workouts'
import { Table, Icon, Button, Popup, Modal } from 'semantic-ui-react'
import _ from 'loadsh'
import moment from 'moment'
import { StateIcon } from '../../../styles'
import { Link } from 'react-router-dom'
import { deleteItem } from '../../../api/workouts'
import ChangeLectureModal from '../../modals/ChangeLectureModal'

class DateParticipantsTable extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            showModal: false,
            loading: false,
            loadingUnset: false
        })
    }

    hideModal = () => this.setState({ showModal: false })
    openModal = () => this.setState({ showModal: true })

    _deleteItem = (id) => {

        this.setState({
            loading: true
        })

        const { setMessage, refresh, type } = this.props

        getToken().then((token) => {
            const data = {
                token: token
            }

            deleteItem(data, id, type)
            .then((res) => {
                setMessage(res)
                refresh()

                this.setState({
                    loading: false
                })
            })

        })
    }

    deleteModal = (id_item, type) => {
        return (
            <Modal trigger={<Button icon>Omluvit</Button>} onClose={this.hideModal}>
                <Modal.Header>
                    Omluvit se z této lekce
                </Modal.Header>
                <Modal.Content>
                    {type !== 'hobby' && <p>Po omluvení budete z lekce vyřazeni a budete si ji moci nahradit jindy.</p>}
                    {type === 'hobby' && <p>Po omluvení budete z lekce vyřazeni a <strong>nebudete si ji moci nahradit jindy.</strong></p>}
                </Modal.Content>
                <Modal.Actions className='justify-content-end'>
                    <Button className='red' onClick={() => this._deleteItem(id_item)} loading={this.state.loading} disabled={this.state.loading}>Omluvit lekci</Button>
                </Modal.Actions>
            </Modal>
        )
    }

    _changeLectureModal = (type, id_signed, age_group = 0, is_free = 0) => {

        const { setMessage, refresh } = this.props
        return (
            <ChangeLectureModal type={type} id={id_signed} refresh={refresh} setMessage={setMessage} ageGroup={age_group} is_free={is_free} />
        )
    }

    _unsetSub = (id_signed) => {

        this.setState({
            loadingUnset: true
        })

        const { type, setMessage, refresh } = this.props

        getToken().then((token) => {
            const data = {
                token: token,
                id_signed: id_signed
            }

            unsetSub(data, type)
            .then((res) => {
                this.setState({
                    loadingUnset: false
                })
                setMessage(res)
                refresh()
            })
        })
    }

    _setAttended = (id_signed) => {
        this.setState({
            loadingAttended: true
        })

        const { type, setMessage, refresh } = this.props

        getToken().then((token) => {
            const data = {
                token: token,
                id_signed: id_signed
            }

            setAttended(data, type)
            .then((res) => {
                this.setState({
                    loadingAttended: false
                })
                setMessage(res)
                refresh()
            })
        })
    }

    render() {

        const { items, column, direction, sort, type, canChange, isLecturer, isSub } = this.props
        return (
            <div className='admin_table_wrapper'>
                <Table sortable striped className='main-table' unstackable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell
                                sorted={column === 'id_application' ? direction : null}
                                onClick={sort('id_application')}
                                width={1}
                            >
                                #
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'workout_name' ? direction : null}
                                onClick={sort('workout_name')}
                                width={6}
                            >
                                Jméno
                            </Table.HeaderCell>

                            {!isLecturer &&
                            <Table.HeaderCell
                                sorted={column === 'date' ? direction : null}
                                onClick={sort('date')}
                                width={3}
                            >
                                Email
                            </Table.HeaderCell>
                            }

                            <Table.HeaderCell
                                sorted={column === 'total_price' ? direction : null}
                                onClick={sort('total_price')}
                                width={1}
                            >
                                Telefon
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'paid' ? direction : null}
                                onClick={sort('paid')}
                                width={1}
                            >
                                Zaplaceno
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'state' ? direction : null}
                                onClick={sort('state')}
                                width={1}
                            >
                                Status
                            </Table.HeaderCell>

                            <Table.HeaderCell width={3}></Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                    {_.map(items, ({ id_application, id_signed, first_name, last_name, attended, id_workout, date, email, phone, paid, state, created, workout_name, can_change, age_group, is_free }, index) => (
                        <Table.Row key={id_application}>
                            <Table.Cell>
                                {id_application}
                            </Table.Cell>
                            <Table.Cell>
                                {first_name} {last_name}
                            </Table.Cell>
                           
                            {!isLecturer &&
                            <Table.Cell>
                                {email}
                            </Table.Cell>
                            }

                            <Table.Cell>
                                {phone}
                            </Table.Cell>

                            <Table.Cell className='text-center'>
                                <StateIcon state={paid} />
                            </Table.Cell>

                            <Table.Cell className='text-center'>
                                {state !== 2 && <StateIcon state={state} />}
                                {state === 2 && <p className='bubble'>Omluveno</p>}
                                {state === 3 && <p className='bubble'>Nahrazeno</p>}
                            </Table.Cell>

                            {!isLecturer ?
                            <Table.Cell className='text-right'>
                                {!isSub ?
                                <>
                                {can_change && state === 2 && this._changeLectureModal(type, id_signed, age_group, is_free)}
                                {state === 1 && this.deleteModal(id_signed, type)}
                                {state === 3 && <Button disabled icon>Žádné akce</Button>}
                                </>
                                :
                                <>
                                <Button icon loading={this.state.loadingUnset} onClick={() => this._unsetSub(id_signed)}>Změnit na účastníka</Button>
                                </>
                                }
                            </Table.Cell>
                            :
                                <Table.Cell className='text-right'>
                                    <Button icon primary={attended === 0 || attended === null ? true : false} loading={this.state.loadingAttended} onClick={() => this._setAttended(id_signed)}>{attended === 0 || attended === null ? 'Potvrdit účast' : 'Zrušit účast'}</Button>
                                </Table.Cell>
                            }

                        </Table.Row>
                    ))}
                    </Table.Body>
                </Table>
            </div>
        )
    }
}

export default DateParticipantsTable