import React from 'react'
import { Form, Button, Message } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

const ForgotPasswordForm = ({onChange, data, submit, loading, hasError, errorMessage}) => {

    document.title = "Zapomenuté heslo | 1FIT Klub"

    return (
        <div>
            <div className="login_intro">
                <h1>Zapomenuté heslo</h1>
                <p>Odkaz pro reset hesla Vám bude odeslán emailem.</p>
            </div>
            <Form loading={loading} onSubmit={submit}>
                <Form.Field>
                    <Form.Input 
                        type="email"
                        name='email'
                        label='E-mail'
                        placeholder='Zadejte e-mail'
                        value={data.email}
                        onChange={onChange}
                        required
                    />
                </Form.Field>
                {hasError &&
                <Message negative>
                    {errorMessage}
                </Message>
                }
                <Button primary fluid>Vytvořit heslo</Button>
            </Form>
        </div>
    )
}

export default ForgotPasswordForm