import React, { Component } from 'react'
import EmbedNavigation from '../../containers/EmbedNavigation'
import { Container, Row, Col } from 'reactstrap'
import { ConfirmHeading } from '../../../styles/applications'
import { Icon, Step, Button } from 'semantic-ui-react'
import { StepCard, StuckContainer, UserId } from '../../../styles'
import { confirmData } from '../../../api/workouts'
import HeaderApplications from '../../containers/club/HeaderApplications'
import Loading from '../../containers/Loading'
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module'

class ApplicationConfirm extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            bank_acc: '',
            total_price: 0,
            loading: true
        })
    }

    componentDidMount() {
        const { id, type } = this.props.match.params

        this._confirmData(id, type)
        document.body.classList.add('application')
    }

    _confirmData = (id, type) => {
        confirmData(id, type)
        .then((res) => {
            if(res.code === 1) {
                
                ReactPixel.track('Purchase', { value: res.data.total_price, currency: 'CZK' });

                const tagManagerArgs = {
                    gtmId: 'GTM-582S55N',
                    events: {
                        event: 'confirm',
                        convValue: res.data.total_price,
                        currency: 'czk',
                        convId: 760604231,
                        convLabel: 'aJroCPem6NsBEMfM1-oC'
                    }
                }

                TagManager.initialize(tagManagerArgs)

                this.setState({
                    bank_acc: res.data.bank_acc,
                    bank_code: res.data.bank_code,
                    total_price: res.data.total_price,
                    gateway: res.data.gateway,
                    is_free: res.data.is_free === 1 ? true : false,
                    is_sub: res.data.is_sub === 1 ? true : false,
                    loading: false
                })
            }
        })
        .catch((err) => {
            this.setState({
                loading: false
            })
        })
    }

    setCode = (type, id) => {
        switch(type) {
            case 'moms':
                return '10' + id
            case 'kids':
                return '20' + id
            case 'hobby':
                return '30' + id
        }
    }

    _renderText = () => {
        const { type, id, all } = this.props.match.params
        const isAll = all === 'all' ? true : false
        const { is_free, is_sub } = this.state

        if(!is_free && !is_sub) {
            return (
                <>
                <p style={{fontSize: 22}}>Na uvedený email Vám přijde odkaz do Vaši administrace FIT KLUB, kde můžete spravovat své přihlášky, rezervace a cvičební lekce. Nejdříve si vytvořte heslo a použijte stejný email, jako v rezervaci/přihlášce.</p>
                <p style={{fontSize: 22}}>Platbu můžete uhradit platební bránou nebo převodem na účet.</p>
                <p style={{color: 'red', fontSize: 22}}>Platbu doporučujeme uhradit, co nejdříve, aby Vaše rezervovaná služba byla potvrzena.</p>
                </>
            )
        } else if(is_free && isAll && !is_sub) {
            return (
                <>
                <p style={{fontSize: 22}}>Na uvedený email Vám přijde odkaz do Vaši administrace FIT KLUB, kde můžete spravovat své přihlášky, rezervace a cvičební lekce. Nejdříve si vytvořte heslo a použijte stejný email, jako v rezervaci/přihlášce.</p>
                <p style={{fontSize: 22}}>Platbu můžete uhradit platební bránou nebo převodem na účet.</p>
                <p style={{color: 'red', fontSize: 22}}>Platbu doporučujeme uhradit, co nejdříve, aby Vaše rezervovaná služba byla potvrzena.</p>
                </>
            )
        } else if(is_free && !isAll && !is_sub) {
            return (
                <>
                <p style={{fontSize: 22}}>Na uvedený email Vám přijde odkaz do Vaši administrace FIT KLUB, kde můžete spravovat své přihlášky, rezervace a cvičební lekce. Nejdříve si vytvořte heslo a použijte stejný email, jako v rezervaci/přihlášce.</p>
                <p style={{fontSize: 22}}>Lekce je zdarma, takže nic hradit nemusíte.</p>
                <p style={{color: 'red', fontSize: 22}}>Upozornění:  Zkušební jednorázová lekce nezaručuje místo v kurzu.</p>
                <p style={{fontSize: 22}}>Těšíme se na Vás</p>
                </>
            )
        } else if(is_sub) {
            return (
                <>
                <p style={{fontSize: 22}}>Na uvedený email Vám přijde odkaz do Vaši administrace FIT KLUB, kde můžete spravovat své přihlášky, rezervace a cvičební lekce. Nejdříve si vytvořte heslo a použijte stejný email, jako v rezervaci/přihlášce.</p>
                <p style={{color: 'red', fontSize: 22}}>Upozornění:  Kurz je nyní zaplněn. Jakmile se místo uvolní, budeme vás neprodleně informovat.</p>
                <p style={{fontSize: 22}}>Jakmile obdržíte potvrzovací email a sms, že se místo v kroužku uvolnilo, tak pošleme platební údaje a služba bude ve stavu rezervována.</p>
                <p style={{fontSize: 22}}>Po potvrzení platbu můžete uhradit platební bránou nebo převodem na účet.</p>
                <p style={{fontSize: 22}}>Platbu doporučujeme poté uhradit, co nejdříve, aby Vaše rezervovaná služba byla potvrzena.</p>
                </>
            )
        }
    }

    _renderPaymentInfo = () => {
        const { type, id, all } = this.props.match.params
        const isAll = all === 'all' ? true : false
        const { is_free, is_sub } = this.state

        if(!is_free && !is_sub) {
            return (
                <>
                    <div className='row card-payment'>
                        <div className='col-sm-6'>
                            <h2>{this.state.total_price},- Kč</h2>
                        </div>
                        <div className='col-sm-6 text-center'>
                            <Button size='massive' color='green' href={this.state.gateway}>Zaplatit kartou</Button>
                        </div>
                    </div>
                    
                    <div className='row banktransfer-payment'>
                        <div className='col-sm-6'>
                            <h3>Platba převodem</h3>
                            <p>Cena: <strong>{this.state.total_price},- Kč</strong></p>
                            <p>Platbu prosím zašlete na číslo účtu: <strong>{this.state.bank_acc}/{this.state.bank_code}</strong></p>
                            <p>Variabilní symbol: <strong>{this.setCode(type, id)}</strong></p>
                        </div>
                        <div className='col-sm-6 qr'>
                            <img src={'https://api.paylibo.com/paylibo/generator/czech/image?accountNumber='+this.state.bank_acc+'&bankCode='+this.state.bank_code+'&amount='+this.state.total_price+'&currency=CZK&vs='+ this.setCode(type, id)} className='qr_payment img-fluid' />
                        </div>
                    </div>
                </>
            )
        } else if(is_free && isAll && !is_sub) {
            return (
                <>
                    <div className='row card-payment'>
                        <div className='col-sm-6'>
                            <h2>{this.state.total_price},- Kč</h2>
                        </div>
                        <div className='col-sm-6 text-center'>
                            <Button size='massive' color='green' href={this.state.gateway}>Zaplatit kartou</Button>
                        </div>
                    </div>
                    
                    <div className='row banktransfer-payment'>
                        <div className='col-sm-6'>
                            <h3>Platba převodem</h3>
                            <p>Cena: <strong>{this.state.total_price},- Kč</strong></p>
                            <p>Platbu prosím zašlete na číslo účtu: <strong>{this.state.bank_acc}/{this.state.bank_code}</strong></p>
                            <p>Variabilní symbol: <strong>{this.setCode(type, id)}</strong></p>
                        </div>
                        <div className='col-sm-6 qr'>
                            <img src={'https://api.paylibo.com/paylibo/generator/czech/image?accountNumber='+this.state.bank_acc+'&bankCode='+this.state.bank_code+'&amount='+this.state.total_price+'&currency=CZK&vs='+ this.setCode(type, id)} className='qr_payment img-fluid' />
                        </div>
                    </div>
                </>
            )
        } else {
            return (<></>)
        }
    }

    _renderMessage = ({message}) => {

        const { type, id, all } = this.props.match.params

        const isAll = all === 'all' ? true : false

        return (
            <div className='application_message'>
                <Icon className='application_icon' style={{color: '#4CC7BE'}} name={'thumbs up'} />
                {!this.state.is_free || isAll ?
                <h1>Odeslání rezervace lekce proběhlo v pořádku!</h1>
                :
                <h1>Odeslání rezervace na jednorázovou zkušební lekci proběhlo v pořádku!</h1>
                }
                <div className='application_success_info'>
                    <p className='text-center'>Vaše ID</p>
                    <UserId>
                        {this.setCode(type, id)}
                    </UserId>

                    {this._renderText()}

                    {this._renderPaymentInfo()}
                    <div className='row'>
                        <div className='col-sm-12 text-center'>
                            <Button size='massive' color='green' href='https://www.1fit.cz'>Zpět na 1FIT</Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {

        const { utm } = this.props.match.params
        const { loading } = this.state

        return (
            <>
            <HeaderApplications hasRoute={true} route={'Potvrzení odeslání rezervace'} backlink={'/prihlaska'}  />
                <Container className='mt-5 mb-5'>
                    {!loading ?
                    <this._renderMessage />
                    :
                    <Loading inEmbed={true} />
                    }
                </Container>
            </>
        )
    }

}

export default ApplicationConfirm