import React from 'react'
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color' 

const ColorPicker = ({ displayColorPicker, handleClick, handleClose, event_color, handleChange }) => {

    const styles = reactCSS({
        'default': {
          color: {
            width: '100%',
            height: '14px',
            borderRadius: '2px',
            background: `${ event_color }`,
          },
          swatch: {
            padding: '5px',
            background: '#fff',
            borderRadius: '1px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer',
            width: '100%'
          },
          popover: {
            position: 'absolute',
            zIndex: '2',
          },
          cover: {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
          },
        },
    });

    return (
        <div>
            <div style={ styles.swatch } onClick={ handleClick }>
                <div style={ styles.color } />
            </div>
            { displayColorPicker ? <div style={ styles.popover }>
            <div style={ styles.cover } onClick={ handleClose }/>
                <SketchPicker color={ event_color } onChange={ handleChange } />
            </div> : null }
        </div>
    )
}

export default ColorPicker