
import React, { Component } from 'react'
import { Card, CardHeader, CardBody } from '../../../styles'
import { getToken } from '../../../api'
import { getWorkoutsReservations, getEventsList } from '../../../api/club'
import NoReservations from './NoReservations'
import Loading from './Loading'
import UsersEventsTable from '../../tables/workouts/UsersEventsTable'
import _ from 'loadsh'
import { Row, Col } from 'reactstrap'
import { Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { setMessage } from '../../../actions/messageActions'
import { logOutUser } from '../../../actions/userActions'
import firebase from 'firebase'

class MyEvents extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            loadingData: true,
            data: [],
            column: 'id_tour_application',
            direction: 'descending',
            filter: false,
            limit: 5
        })
    }

    componentDidMount() {
        this._getUserTourReservations()
    }

    setCardHeader = (type) => {
        switch (type) {
            case 'moms':
                return 'Cvičení pro maminky'
            
            case 'kids':
                return 'Cvičení pro děti'

            case 'hobby':
                return 'Kroužky'
    
            default: 
                return 'Moje rezervace'
        }
    }

    _getUserTourReservations = () => {

        const { type, active, token } = this.props

        this.setState({
            loadingData: true,
            hasData: false
        })

        const data = {
            limit: this.state.limit,
            offset: 0,
            token: token
        }

        if(active) {
            getEventsList(data, type)
            .then((res) => {
                if(res.code === 1) {
                    this.setState({
                        hasData: true,
                        data: res.data.data,
                        count: res.data.count,
                        loadingData: false
                    })
                } else {
                    this.setState({
                        hasData: false,
                        loadingData: false
                    })
                }
            })
            .catch((err) => {
                this.setState({
                    hasData: false,
                    loadingData: false
                })
            })
        } else {
            getWorkoutsReservations(data, type)
            .then((res) => {
                if(res.code === 1) {
                    this.setState({
                        hasData: true,
                        data: res.data.data,
                        loadingData: false
                    })
                } else {
                    this.setState({
                        hasData: false,
                        loadingData: false
                    })
                }
            })
            .catch((err) => {
                this.setState({
                    hasData: false,
                    loadingData: false
                })
            })
        }

        /*getToken().then((token) => {
            
        })
        .catch((err) => {
            this.props.setMessage({type: 'error', text: 'Nepodařilo se získat bezpečnostní token, budete odhlášeni'})
            firebase.auth().signOut()
            window.location.reload();
            this.props.logOutUser()
        })*/
    }

    handleSort = (clickedColumn) => () => {
        const { column, data, direction } = this.state
    
        if (column !== clickedColumn) {
          this.setState({
            column: clickedColumn,
            data: _.sortBy(data, [clickedColumn]),
            direction: 'ascending',
          })
    
          return
        }
    
        this.setState({
          data: data.reverse(),
          direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    }

    showAll = () => {
        this.setState({
            limit: 10000
        })
        this._getUserTourReservations()
    }

    showMore = () => {
        this.setState({
            limit: this.state.limit + 10
        }, () => {
            this._getUserTourReservations()
        })
    }

    showMoreButtons = () => {

        const { count, limit } = this.state

        if(count > limit) {
            return (
                <Row className='py-3 text-center'>
                    <Col>
                        <Button onClick={this.showMore}>Načíst dalších 10</Button>
                        <Button basic onClick={this.showAll}>Načíst vše</Button>
                    </Col>
                </Row>
            )
        }
    }

    render() {

        const { hasData, loadingData, data, column, direction } = this.state
        const { type, active, setMessage, canChange } = this.props

        return (
            <Card>
                <CardHeader>
                    <Row>
                        <Col sm={9}>
                            {this.setCardHeader(type)}
                        </Col>
                        <Col sm={3} className='text-right'>
                            {active && <Link to={`${process.env.PUBLIC_URL}/klub/lekce/rozvrh/${type}`}><Button>Zobrazit kalendář</Button></Link>}
                        </Col>
                    </Row>
                    
                </CardHeader>
                {hasData && !loadingData &&
                    <>
                    <UsersEventsTable items={data} canChange={canChange} sort={this.handleSort} column={column} direction={direction} type={type} refresh={this._getUserTourReservations} setMessage={setMessage} />
                    {this.showMoreButtons()}
                    </>
                }
                {!hasData && !loadingData &&
                    <CardBody>
                        <NoReservations text='V této kategorii nejste nikam přihlášeni' />
                    </CardBody>
                }
                {loadingData && !hasData &&
                    <CardBody>
                        <Loading />
                    </CardBody>
                }
            </Card>
        )
    }

}

const mapStateToProps = state => ({})
  
export default connect(mapStateToProps, { setMessage, logOutUser })(MyEvents)