import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Table, Popup, Button, Icon, Loader } from 'semantic-ui-react'
import { StateIcon } from '../../../styles'
import { Link } from 'react-router-dom'
import { getSignedNumbers } from '../../../api/workouts'
import axios from 'axios'

function WorkoutsTableRow({item, type, toggleWorkoutState, deleteModal, token}) {

    const [signedNumbers, setSignedNumbers] = useState({})
    const [loadingNumbers, setLoadingNumbers] = useState(true)
    useEffect(() => {
        const cancelTokenSource = axios.CancelToken.source();

            getSignedNumbers(item.id_workout, type, token, cancelTokenSource)
            .then((res) => {
                setSignedNumbers(res.data)
                setLoadingNumbers(false)
            })
            .catch((err) => {
                console.log(err)
                setLoadingNumbers(false)
            })
        
        
        return () => {
            cancelTokenSource.cancel();
        };
    }, [])

    return (
        <Table.Row>
            <Table.Cell>
                {item.id_workout}
            </Table.Cell>
            <Table.Cell>
                {item.name}
            </Table.Cell>
            <Table.Cell>
                {item.dates.map((date) => {
                    return (
                        <p><strong>{moment(date.date).format('dddd')}</strong> ({moment(date.date).format('H:ss')})</p>
                    )
                })}
            </Table.Cell>
            <Table.Cell>
                {item.location_name}
            </Table.Cell>
            <Table.Cell>
                {!loadingNumbers ?
                <>
                <Popup 
                    content='Aktivní přihlášky'
                    trigger={<span>{signedNumbers.countSigned}</span>}
                />
                /
                <Popup 
                    content='Rezervace'
                    trigger={<span>{signedNumbers.countReservations}</span>}
                />/
                <Popup 
                    content='Kapacita'
                    trigger={<strong><span>{item.capacity}</span></strong>}
                /> <br />
                (
                <Popup 
                    content='Příhlášení na následující zkušební lekci'
                    trigger={<span>{signedNumbers.countSignedFree}/{item.capacity}</span>}
                />
                )
                </>
                : 
                <i className="icofont-check-circled icon-state loading"></i>
                }
                
            </Table.Cell>
            {/*
            <Table.Cell>
                {moment(created).format('DD. MM. YYYY')}
            </Table.Cell>
            */}
            <Table.Cell className='text-center'>
                <StateIcon state={item.state} toggle={true} onClick={() => toggleWorkoutState(item.id_workout, type)} />
            </Table.Cell>
            <Table.Cell className='text-right'>
                <Popup 
                    content='Smazat tuto lekci'
                    position='top right'
                    trigger={
                        deleteModal(item.id_workout)
                    }
                />
                <Popup 
                    content='Seznam jednotlivých termínů'
                    position='top right' 
                    trigger={
                        <Link to={'/admin/manage/workouts/'+ type +'/dates/list/' + item.id_workout}><Button icon><Icon name='list' /></Button></Link>
                    } 
                />
                <Popup 
                    content='Upravit záznam cvičení'
                    position='top right' 
                    trigger={
                        <Link to={'/admin/manage/workouts/'+ type +'/edit/' + item.id_workout}><Button icon><Icon name='edit' /></Button></Link>
                    } 
                />
                
            </Table.Cell>
        </Table.Row>
    )
}

export default WorkoutsTableRow