import React from 'react'
import { LoadingContainer } from '../../styles'
import { Loader } from 'semantic-ui-react'

const Loading = () => {
    return (
        <LoadingContainer>
            <Loader active />
        </LoadingContainer>
    )
}

export default Loading