const Images = {
    logo: require('../assets/img/kidsjoy-logo.png'),
    krouzky_ill: require('../assets/img/services/krouzky.svg'),
    tabory_ill: require('../assets/img/services/tabory.svg'),
    mamafit_ill: require('../assets/img/services/mamafit.svg'),
    minifit_ill: require('../assets/img/services/minifit.svg'),
    minigym_ill: require('../assets/img/services/minigym@2x.jpg'),
    travelfit_ill: require('../assets/img/services/travelfit.svg'),
    logo_w: require('../assets/img/logo/1fit_logo_white.svg'),
    logo_c: window.location.origin === 'https://klub.tabory-krouzky.cz' ? require('../assets/img/logo/taborykrouzky_logo_color.svg') : require('../assets/img/logo/1fit_logo_color.svg'),
    favicon: window.location.origin === 'https://klub.tabory-krouzky.cz' && require('../assets/img/favicon/tabory_krouzky_fav.ico')
}

export default Images