import React, { Component } from 'react'
import { Form, Input, Tab, Button, Icon, Modal, Popup } from 'semantic-ui-react'
import moment from 'moment'
import { DateItemAdmin, FormButtonGroup } from '../../../styles'
import { cancelDate, getToken, updateKids, unsetSub } from '../../../api'
import { connect } from 'react-redux'
import { setMessage } from '../../../actions/messageActions'

class KidsDetailForm extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            data: {
                kids_note: '',
                kids_name: '',
                kids_birthday: '',
                kids_address: '',
                kids_city: '',
                kids_post_code: '',
                kids_insurance: '',
                kids_pairings: '',
                kids_eating_issues: '',
                kids_health_issues: ''
            },
            delete: {
                id_date: '',
                id_kids: '',
                token: ''
            },
            modalOpen: false,
            idToDelete: '',
            loading: false
        })
    }

    componentDidMount() {

        const { kid } = this.props

        this.setState({
            loading: true
        })

        this.setState({
            data: {
                id: kid.id_kids,
                kids_note: kid.kids_note,
                kids_name: kid.kids_name,
                kids_birthday: kid.kids_birthday,
                kids_address: kid.kids_address,
                kids_city: kid.kids_city,
                kids_post_code: kid.kids_post_code,
                kids_insurance: kid.kids_insurance,
                kids_pairings: kid.kids_pairings === undefined ? '' : kid.kids_pairings,
                kids_eating_issues: kid.kids_eating_issues,
                kids_health_issues: kid.kids_health_issues
            }
        }, () => {
            this.setState({
                loading: false
            })
        })
    }

    componentDidUpdate(prevProps) {
        if(this.props.kid !== prevProps.kid) {
            const { kid } = this.props

            this.setState({
                data: {
                    id: kid.id_kids,
                    kids_note: kid.kids_note,
                    kids_name: kid.kids_name,
                    kids_birthday: kid.kids_birthday,
                    kids_address: kid.kids_address,
                    kids_city: kid.kids_city,
                    kids_post_code: kid.kids_post_code,
                    kids_insurance: kid.kids_insurance,
                    kids_pairings: kid.kids_pairings === undefined ? '' : kid.kids_pairings,
                    kids_eating_issues: kid.kids_eating_issues,
                    kids_health_issues: kid.kids_health_issues
                }
            })
        }
    }

    updateKidsHandler = () => {

        this.setState({
            loading: true
        })

        updateKids(this.state.data)
        .then((res) => {
            this.props.setMessage(res)
            this.props.refresh()
            this.setState({
                loading: false
            })
        })
    }

    onChange = (e) => {
        this.setState({
            data: {
                ...this.state.data,
                [e.target.name]: e.target.value
            }
        })
    }

    unsetSubHandler = (id_kids, id_date) => {
        getToken().then((token) => {

            const data = {
                token: token,
                id_kids: id_kids,
                id_date: id_date
            }

            unsetSub(data)
            .then((res) => {
                this.props.setMessage(res)
                this.props.refresh()
            })

        })
    }

    _renderKidsInfo = () => {

        const { loading } = this.props
        const { data } = this.state

        return (
            <Form loading={loading || this.state.loading} onSubmit={this.updateKidsHandler}>
                <Form.Field>
                    <label>Interní poznámka</label>
                    <textarea 
                        value={data.kids_note}
                        name='kids_note'
                        onChange={this.onChange}
                    />
                </Form.Field>
    
                <Form.Field>
                    <label>Jméno a příjmení</label>
                    <Input 
                        type='text'
                        value={data.kids_name}
                        onChange={this.onChange}
                        name='kids_name'
                    />
                </Form.Field>
                <div className='row mb-3'>
                    <div className='col-sm-6'>
                        <Form.Field>
                            <label>Datum narození</label>
                            <input 
                                type='date'
                                value={moment(data.kids_birthday).format('YYYY-MM-DD')}
                                onChange={this.onChange}
                                name='kids_birthday'
                            />
                        </Form.Field>
                    </div>
                    <div className='col-sm-6'>
                        <Form.Field>
                            <label>Věk</label>
                            <Input 
                                type='text'
                                value={Math.abs(moment(data.kids_birthday).diff(moment(), 'years', true)).toFixed(1)}
                                onChange={this.onChange}
                                name='kids_birthday'
                                disabled
                            />
                        </Form.Field>
                    </div>
                </div>
                
                <div className='row mb-3'>
                    <div className='col-sm-4'>
                        <Form.Field>
                            <label>Adresa</label>
                            <Input 
                                type='text'
                                value={data.kids_address}
                                onChange={this.onChange}
                                name='kids_address'
                            />
                        </Form.Field>
                    </div>
                    <div className='col-sm-4'>
                        <Form.Field>
                            <label>Město</label>
                            <Input 
                                type='text'
                                value={data.kids_city}
                                onChange={this.onChange}
                                name='kids_city'
                            />
                        </Form.Field>
                    </div>
                    <div className='col-sm-4'>
                        <Form.Field>
                            <label>PSČ</label>
                            <Input 
                                type='number'
                                value={data.kids_post_code}
                                onChange={this.onChange}
                                name='kids_post_code'
                            />
                        </Form.Field>
                    </div>
                </div>
                <Form.Field>
                    <label>Pojišťovna</label>
                    <Input 
                        type='text'
                        value={data.kids_insurance}
                        onChange={this.onChange}
                        name='kids_insurance'
                    />
                </Form.Field>
    
                <Form.Field>
                    <label>Chce být ve skupině s...</label>
                    <Input 
                        type='text'
                        value={data.kids_pairings}
                        onChange={this.onChange}
                        name='kids_pairings'
                    />
                </Form.Field>
    
                <Form.Field>
                    <label>Stravovací omezení</label>
                    <textarea 
                        value={data.kids_eating_issues}
                        onChange={this.onChange}
                        name='kids_eating_issues'
                    />
                </Form.Field>
    
                <Form.Field>
                    <label>Zdravotní omezení</label>
                    <textarea 
                        value={data.kids_health_issues}
                        onChange={this.onChange}
                        name='kids_health_issues'
                    />
                </Form.Field>
                <FormButtonGroup>
                    <Button>Uložit změny</Button>
                </FormButtonGroup>
            </Form>
        )
    }

    _renderKidsDates = () => {
        const { dates } = this.props.kid
        const { changeDate, addDate, kid, loading } = this.props

        return (
            <div>
                {
                dates.map((date) => {
                    return (
                        <Form loading={loading}>
                            <DateItemAdmin className={date.is_sub ? 'row mb-2 full_date' : 'row mb-2'} key={date.id_date}>
                                <div className='col-sm-2 camp_name_uppercase'>
                                    {date.camp_name}
                                </div>
                                <div className='col-sm-3'>
                                    {moment(date.date_from).format('D. M.')} - {moment(date.date_to).format('D. M. YYYY')}
                                </div>
                                <div className='col-sm-5'>
                                    {date.location_address}
                                </div>
                                <div className='col-sm-2 text-right'>
                                    <Popup content='Smazat termín' position='top center' size='mini' trigger={<Button onClick={() => this.handleOpenModal(date.id_date)} icon><Icon name='trash alternate' /></Button>} />
                                    <Popup content='Změnit termín' position='top center' size='mini' trigger={<Button onClick={() => changeDate(date.id_date, kid.id_kids)} icon><Icon name='exchange' /></Button>} />
                                    {date.is_sub ? <Popup content='Zrušit náhradníka' position='top center' size='mini' trigger={<Button onClick={() => this.unsetSubHandler(kid.id_kids, date.id_date)} icon><Icon name='angle up' /></Button>} /> : ''}
                                </div>
                            </DateItemAdmin>
                        </Form>
                        )
                    })
                }
                <FormButtonGroup>
                    <Button onClick={() => addDate(kid.id_kids)}>Přidat termín</Button>
                </FormButtonGroup>
            </div>
        )
    }

    handleOpenModal = (id) => {
        this.setState({
            modalOpen: true,
            idToDelete: id
        })
    }

    handleCloseModal = () => {
        this.setState({
            modalOpen: false,
            idToDelete: ''
        })
    }

    cancelDateHandler = (id_date) => {

        const { kid, setMessage, refresh, email } = this.props

        getToken().then((idToken) => {
            this.setState({
                delete: {
                    id_date: id_date,
                    id_kids: kid.id_kids,
                    email: email,
                    token: idToken
                }
            }, () => {
                cancelDate(this.state.delete)
                .then((res) => {
                    setMessage(res)
                    refresh()
                })
            })
        })
    }

    panes = [
        {
          menuItem: 'Informace o dítěti',
          render: () => <Tab.Pane attached={false}><this._renderKidsInfo /></Tab.Pane>,
        },
        {
          menuItem: 'Termíny',
          render: () => <Tab.Pane attached={false}><this._renderKidsDates /></Tab.Pane>,
        },
    ]

    render() {
        return (
            <div>
                <Tab menu={{ secondary: true, pointing: true }} panes={this.panes} />
                <Modal open={this.state.modalOpen} onClose={this.handleCloseModal}>
                    <Modal.Header>Smazat termín</Modal.Header>
                    <Modal.Content image>
                        <Modal.Description>
                            <p>Jste si jistí, že chcete smazat tento termín?</p>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='black' onClick={this.handleCloseModal}>
                        Zpět
                        </Button>
                        <Button
                            positive
                            icon='checkmark'
                            labelPosition='right'
                            content="Ano, chci smazat"
                            onClick={() => this.cancelDateHandler(this.state.idToDelete)}
                        />
                    </Modal.Actions>
                </Modal>
            </div>
        )
    }

}

const mapStateToProps = state => ({})
  
export default connect(mapStateToProps, { setMessage,  })(KidsDetailForm)