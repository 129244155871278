import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import { Form, Button } from 'semantic-ui-react'
import moment from 'moment'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css';
import 'moment/locale/cs';
import { DateUtils } from 'react-day-picker';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import { FormButtonGroup } from '../../../styles';
import { Section } from '../../../styles/forms'

class MoreDatesForm extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            data: {
                valid_until: new Date(),
                name: '',
                description: '',
                capacity: '',
                is_free: 0,
                price_lecture: 0,
                price_points: 0,
                state: 1,
                venue_address: '',
                time: '',
                id_lecturer: '',
                id_location: null
            },
            displayColorPicker: false
        })

        this.handleDayChange = this.handleDayChange.bind(this);
    }

    handleDayChange(day, modifiers, dayPickerInput) {

        const input = dayPickerInput.getInput();

        this.setState({ 
            data: {
                ...this.state.data,
                [input.name]: day
            } 
        });
    }

    onChange = (e) => this.setState({
        data: {
            ...this.state.data,
            [e.target.name]: e.target.value
        }
    })

    submit = () => {

        const { data } = this.state

        this.props.submit(data)
    }

    render() {

        const format = "DD/MM/YYYY";
        const { data } = this.state

        return (
            <Form onSubmit={this.submit}>
            <Row className='mb-3'>
                <Col sm={4}>
                    <Form.Field>
                            <label>První den *</label>
                            <DayPickerInput 
                                onDayChange={this.handleDayChange} 
                                inputProps={{ 
                                    name: 'valid_from',
                                    locale: 'cs',
                                    localeUtils: MomentLocaleUtils,
                                }}
                                dayPickerProps={{ 
                                    locale: 'cs',
                                    localeUtils: MomentLocaleUtils,
                                    format: 'DD-MM-YYYY',
                                    formatDate: 'DD-MM-YYYY',
                                    parseDate: 'DD-MM-YYYY'
                                }}
                                format={'DD/MM/YYYY'}
                                formatDate={formatDate}
                                parseDate={parseDate}
                                placeholder={new Date()}
                                value={formatDate(this.state.data.valid_from, format)}
                            />
                    </Form.Field>
                            </Col>
                            <Col sm={4}>
                                <Form.Field>
                                    <Form.Input
                                        type='time'
                                        name='time'
                                        value={data.time}
                                        onChange={this.onChange}
                                        label='Čas konání'
                                        required
                                    />
                                </Form.Field>
                            </Col>
                            <Col sm={4}>
                                <Form.Field>
                                    <label>Poslední den *</label>
                                    <DayPickerInput 
                                        onDayChange={this.handleDayChange} 
                                        inputProps={{ 
                                            name: 'valid_until',
                                            locale: 'cs',
                                            localeUtils: MomentLocaleUtils,
                                        }}
                                        dayPickerProps={{ 
                                            locale: 'cs',
                                            localeUtils: MomentLocaleUtils,
                                            format: 'DD-MM-YYYY',
                                            formatDate: 'DD-MM-YYYY',
                                            parseDate: 'DD-MM-YYYY'
                                        }}
                                        format={'DD/MM/YYYY'}
                                        formatDate={formatDate}
                                        parseDate={parseDate}
                                        placeholder={new Date()}
                                        value={formatDate(this.state.data.valid_until, format)}
                                    />
                                </Form.Field>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormButtonGroup>
                                    <Button primary>Přidat termíny</Button>
                                </FormButtonGroup>
                            </Col>
                        </Row>
            </Form>
        )
    }
}
export default MoreDatesForm