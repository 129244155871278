import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { ServiceBlock } from '../../../styles/applications'
import { Images } from '../../../config'
import { Link } from 'react-router-dom'

const ServicesBlocks = () => {
    return (
        <>
    <Row>
        <Col sm={10} className='offset-sm-1'>
            <Row>
                <Col sm={4} className='mb-4'>
                    <Link to={`${process.env.PUBLIC_URL}/prihlaska/nabidka/cviceni/kids`}>
                        <ServiceBlock actionText='Nabídka cvičení'>
                            <div>
                                <img src={Images.minifit_ill} />
                                <h3>Cvičení pro děti od 4&nbsp;měs.&nbsp;do&nbsp;3&nbsp;let</h3>
                            </div>
                        </ServiceBlock>
                    </Link>
                </Col>
                <Col sm={4} className='mb-4'>
                    <Link to={`${process.env.PUBLIC_URL}/prihlaska/nabidka/cviceni/moms`}>
                        <ServiceBlock actionText='Nabídka cvičení'>
                            <div>
                                <img src={Images.mamafit_ill} />
                                <h3>Cvičení pro maminky s dětmi</h3>
                            </div>
                        </ServiceBlock>
                    </Link>
                </Col>
                <Col sm={4} className='mb-4'>
                    <Link to={`${process.env.PUBLIC_URL}/prihlaska/nabidka/cviceni/kids?group=2`}>
                        <ServiceBlock actionText='Zobrazit termíny'>
                            <div>
                                <img src={Images.minigym_ill} />
                                <h3>Cvičení pro děti od 2,5 do 6 let</h3>
                            </div>
                        </ServiceBlock>
                    </Link>
                </Col>
            </Row>
            <Row>
                {/*
                <Col sm={4} className='mb-4'>
                    <Link to={`${process.env.PUBLIC_URL}/prihlaska/nabidka/cviceni/hobby`}>
                        <ServiceBlock actionText='Nabídka kroužků'>
                            <div>
                                <img src={Images.krouzky_ill} />
                                <h3>Sportovní kroužky</h3>
                            </div>
                        </ServiceBlock>
                    </Link>
                </Col>
                */}
                {/*<Col sm={4} className='mb-4'>
                    <Link to={`${process.env.PUBLIC_URL}/prihlaska/nabidka/tabory`}>
                        <ServiceBlock actionText='Zobrazit termíny'>
                            <div>
                                <img src={Images.tabory_ill} />
                                <h3>Příměstské tábory</h3>
                            </div>
                        </ServiceBlock>
                    </Link>
                </Col>*/}
                <Col sm={4} className='mb-4'>
                    <Link to={`${process.env.PUBLIC_URL}/prihlaska/nabidka/zajezdy`}>
                        <ServiceBlock actionText='Zobrazit termíny'>
                            <div>
                                <img src={Images.travelfit_ill} />
                                <h3>Sportovní zájezdy</h3>
                            </div>
                        </ServiceBlock>
                    </Link>
                </Col>
            </Row>
        </Col>
    </Row>
    <Row>
        <Col sm={12} className='text-center mt-3 d-flex justify-content-center align-items-center'>
            <a href="https://www.1fit.cz" style={{fontSize: 20, color: '#000', lineHeight: 1}}>Zpět na</a>
            <a href="https://www.1fit.cz"><img style={{height: 20, marginLeft: 10}} src={Images.logo_c} /></a>
        </Col>
    </Row>
    </>
    )
}

export default ServicesBlocks