import React from 'react'
import { Form, Button, Checkbox, Divider, Message } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { Row, Col } from 'reactstrap'

const SignupForm = ({onChange, checkbox, data, submit, loading, hasError, errorMessage}) => {

    document.title = "Registrace do klubu | 1FIT Klub"

    return (
        <div>
            <div className="login_intro">
                <h1>Zaregistrujte se</h1>
                <p>Už máte svůj účet? <Link to={`/klub/auth/prihlaseni/`}>Přihlaste se</Link></p>
            </div>
            <Form loading={loading} onSubmit={submit}>
                <Row className='mb-3'>
                    <Col sm={6}>
                        <Form.Field>
                            <Form.Input 
                                type="text"
                                name='first_name'
                                label='Jméno'
                                placeholder='Zadejte vaše křestní jméno'
                                value={data.first_name}
                                onChange={onChange}
                                required
                            />
                        </Form.Field>
                    </Col>
                    <Col sm={6}>
                        <Form.Field>
                            <Form.Input 
                                type="text"
                                name='last_name'
                                label='Příjmení'
                                placeholder='Zadejte vaše příjmení'
                                value={data.last_name}
                                onChange={onChange}
                                required
                            />
                        </Form.Field>
                    </Col>
                </Row>
                
                <Form.Field>
                    <Form.Input 
                        type="email"
                        name='email'
                        label='E-mail'
                        placeholder='Zadejte e-mail'
                        value={data.email}
                        onChange={onChange}
                        required
                    />
                </Form.Field>

                <Form.Field>
                    <Form.Input 
                        type="password"
                        name='passwd'
                        label='Heslo'
                        placeholder='Zadejte heslo'
                        value={data.passwd}
                        onChange={onChange}
                        required
                    />
                </Form.Field>
                <Form.Field>
                    <Form.Input 
                        type="password"
                        name='passwd_check'
                        label='Heslo znovu'
                        placeholder='Zadejte heslo znovu'
                        value={data.passwd_check}
                        onChange={onChange}
                        required
                    />
                </Form.Field>
                <Divider />
                <Form.Field>
                    <Checkbox onChange={checkbox} name='gdpr' label='Souhlasím se zásadami zpracování osobních údajů' />
                </Form.Field>
                <Form.Field>
                    <Checkbox onChange={checkbox} name='newsletter' label='Souhlasím se zasíláním newsletteru' />
                </Form.Field>
                <Form.Field>
                    <Checkbox onChange={checkbox} name='terms' label='Souhlasím s všeobecnými obchodními podmínkami' />
                </Form.Field>
                {hasError &&
                <Message negative>
                    {errorMessage}
                </Message>
                }
                <Button primary fluid disabled={!data.gdpr || !data.terms}>Vytvořit účet</Button>
            </Form>
        </div>
    )
}

export default SignupForm