import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap'
import HeaderApplications from '../../containers/club/HeaderApplications'
import { ServiceBlock } from '../../../styles/applications'
import { Images } from '../../../config'
import { Card, CardHeader, CardBody } from '../../../styles'
import Schedule from '../../containers/workouts/Schedule'
import { getCalendar, getCalendarItem, getLecturesFilter, getGroupOpts, getLocationsOpts } from '../../../api/workouts';
import moment from 'moment'
import { Dropdown, Button } from 'semantic-ui-react'
import _ from 'loadsh'
import queryString from 'query-string'
import { getLocationsFilter } from '../../../api';
import { ScrollTo } from "react-scroll-to"
import ApplicationsSuspended from '../../containers/applications/ApplicationsSuspended'

const viewOpts = [
    {
        key: 0,
        value: 'moms',
        text: 'Cvičení pro maminky'
    },
    {
        key: 1,
        value: 'kids',
        text: 'Cvičení pro děti'
    },
]

/* 
{
        key: 2,
        value: 'hobby',
        text: 'Kroužky'
    }
*/

const isFullOpts = [
    {
        key: 0,
        value: null,
        text: 'Vše'
    },
    {
        key: 1,
        value: false,
        text: 'Volno'
    },
    {
        key: 2,
        value: true,
        text: 'Plno'
    }
]

const ageOpts = [
    {
        key: 0,
        value: null,
        text: 'Vše'
    },
    {
        key: 1,
        value: 6,
        text: '4 - 6 měsíců'
    },
    {
        key: 2,
        value: 7,
        text: '7 - 10 měsíců'
    },
    {
        key: 3,
        value: 8,
        text: '11 - 17 měsíců'
    }
    ,
    {
        key: 4,
        value: 9,
        text: '1,5 - 2,5 roky'
    }
]

const miniGymAgeOpts = [
    {
        key: 0,
        value: null,
        text: 'Vše'
    },
    {
        key: 1,
        value: 10,
        text: '2,5 - 4 roky'
    },
    {
        key: 2,
        value: 11,
        text: '4 - 6 let'
    }
]

const hobbyAgeOpts = [
    {
        key: 2,
        value: null,
        text: 'Vše'
    },
    {
        key: 0,
        value: 0,
        text: '6-9 let'
    },
    {
        key: 1,
        value: 1,
        text: '10-15 let'
    }
]

class ApplicationWorkoutsView extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            items: [],
            baseData: [],
            loadingData: [],
            header: 'Rorzvh lekcí',
            filter: false,
            filters: [],
            loadingLocs: true
        })
        this.eventsRefs = [];
    }

    componentDidMount() {

        const { type } = this.props.match.params
        this.setTitle(type)
        this.setCardHeader(type)

        this._getLocOpts()
        this._lecturesFilter()
        this._groupFilter()

        document.body.classList.add('application')

        const parsed = queryString.parse(this.props.location.search);

        var filters = []
        if(parsed.group) {
            const data = {
                name: 'group',
                value: Number(parsed.group)
            }
            filters.push(data)
            
        }

        if(parsed.id_location) {
            const data = {
                name: 'id_location',
                value: Number(parsed.id_location)
            }
            filters.push(data)
            
        }

        if(parsed.id_workout) {
            const data = {
                name: 'id_workout',
                value: Number(parsed.id_workout)
            }
            filters.push(data)
           
        }

        if(parsed.age_group) {
            const data = {
                name: 'age_group',
                value: Number(parsed.age_group)
            }
            filters.push(data)
            
        }

        Object.keys(parsed).forEach(function(el){
            parsed[el] = parseInt(parsed[el])
        })

        this.filterByQuery(parsed)

    }

    _lecturesFilter = () => {
        const { type } = this.props.match.params
        const parsed = queryString.parse(this.props.location.search);

        var group = null;
        if(type === 'kids') {
            if(parsed.group) {
                group = Number(parsed.group)
            }
        }
        getLecturesFilter(type, group)
        .then((res) => {
            this.setState({
                lecturesFilter: res,
                loadingLecturesFilter: false
            })
        })
    }

    _groupFilter = () => {
        const { type } = this.props.match.params

        this.setState({
            loadingGroupFilter: true
        })

        getGroupOpts(type)
        .then((res) => {
            this.setState({
                groupFilter: res,
                lodingGroupFilter: false
            })
        })
    }

    setTitle = (type) => {
        switch(type) {
            case 'moms':
                return document.title = "Termíny cvičení pro maminky | 1FIT Klub"

            case 'kids':
                return document.title = "Termíny cvičení pro děti | 1FIT Klub"

            case 'hobby':
                return document.title = "Termíny kroužků | 1FIT Klub"

            default: 
                return document.title = "Termíny | 1FIT Klub"
        }
    }

    setCardHeader = (type) => {
        if(type === 'moms') {
            this.setState({
                header: 'Rozvrh cvičení pro maminky'
            })
        } else if(type === 'kids') {
            this.setState({
                header: 'Rozvrh cvičení pro děti'
            })
        } else if(type === 'hobby') {
            this.setState({
                header: 'Rozvrh kroužků'
            })
        }
    }

    _getLocOpts = () => {

        const { type } = this.props.match.params

        getLocationsOpts(type)
        .then((res) => {
            this.setState({
                locOpts: res,
                loadingLocs: false
            })
        })
    }

    _getCalendarItems = (date) => {
        const { type } = this.props.match.params
        const formatDate = moment(date).format('YYYY-MM-DD')

        getCalendar(formatDate, type)
        .then((res) => {
            if(res.length !== 0) {
                if(_.keys(this.state.filters).length !== 0) {

                    const newEvents = []
                    res.map((item) => {
                        const newItem = {
                            title: item.title,
                            start: moment(item.start).toDate(),
                            end: moment(item.end).toDate(),
                            id: item.id,
                            color: item.color,
                            is_full: item.is_full,
                            age_group: type !== 'moms' ? item.age_group : null,
                            scheduleCat: item.cat,
                            description: item.description,
                            id_location: item.id_location,
                            id_workout: item.id_workout,
                            group: item.group,
                            state: item.state
                        }
                        newEvents.push(newItem)
                    })

                    this.setState({
                        items: _.filter(newEvents, this.state.filters),
                        baseData: newEvents,
                        hasSome: true
                    })

                } else {

                    const newEvents = []
                    res.map((item) => {
                        const newItem = {
                            title: item.title,
                            start: moment(item.start).toDate(),
                            end: moment(item.end).toDate(),
                            id: item.id,
                            color: item.color,
                            is_full: item.is_full,
                            age_group: type !== 'moms' ? item.age_group : null,
                            scheduleCat: item.cat,
                            description: item.description,
                            id_location: item.id_location,
                            id_workout: item.id_workout,
                            group: item.group,
                            state: item.state
                        }
                        newEvents.push(newItem)
                    })

                    this.setState({
                        items: newEvents,
                        baseData: newEvents,
                        hasSome: true
                    })
                }
            } else {
                this.setState({
                    hasSome: false
                })
            }
        })
    }

    _getCalendarItem = (id) => {
        const { type } = this.props.match.params

        getCalendarItem(id, type)
        .then((res) => {
            if(res.code === 1) {
                this.setState({
                    selectedItem: res.data,
                    itemExist: true
                })
            } else {
                this.setState({
                    selectedItem: {},
                    itemExist: false
                })
            }
        })
    }

    onChangeSelect = (e, data) => {
        this.props.history.push(`${process.env.PUBLIC_URL}/prihlaska/nabidka/cviceni/${data.value}`)
    }

    filterByQuery = (data) => {
        if(data.length !== 0) {
            this.setState({
                filter: true,
                filters: data
            }, () => {
                this.setState({
                    dates: _.filter(this.state.baseData, this.state.filters)
                })
            })
        } else {
            this.setState({
                filters: _.omit(this.state.filters, [data.name])
            }, () => {
                this.setState({
                    dates: _.keys(this.state.filters).length !== 0 ? _.filter(this.state.baseData, this.state.filters) : this.state.baseData,
                    filter: _.keys(this.state.filters).length !== 0 ? true : false
                })
            })
        }
    }

    handleRef = r => {
        this.setState({ bodyHeight: r.clientHeight})
        this.childRef.current = r;
    };

    onChangeFilter = (e, data) => {

        const { type } = this.props.match.params

        const parsed = queryString.parse(this.props.location.search);

        const params = {
            ...parsed,
            [data.name]: [data.value] 
        }

        const url = queryString.stringify(params, {
            skipNull: true
        });

        this.props.history.push(`${process.env.PUBLIC_URL}/prihlaska/nabidka/cviceni/${type}?${url}`)

    }
    
    filtersBarHobby = () => {

        const { items, header, locOpts, lecturesFilter, groupFilter } = this.state
        const { type } = this.props.match.params
        const parsed = queryString.parse(this.props.location.search);

        return (
            <>
            <Col sm={2} className='text-right'>
                <Dropdown 
                    options={lecturesFilter}
                    name='id_workout'
                    onChange={this.onChangeFilter}
                    selection
                    placeholder='Druh lekce'
                    fluid
                    value={parsed.id_workout ? Number(parsed.id_workout) : null}
                    search
                />
            </Col>
            <Col sm={2} className='text-right'>
                <Dropdown 
                    options={type === 'hobby' ? hobbyAgeOpts : (type === 'kids' && Number(parsed.group) !== 2 ? ageOpts : miniGymAgeOpts)}
                    name='age_group'
                    onChange={this.onChangeFilter}
                    selection
                    placeholder='Věk'
                    fluid
                    value={parsed.age_group ? Number(parsed.age_group) : null}
                    search
                />
            </Col>
            <Col sm={2} className='text-right'>
                <Dropdown 
                    options={locOpts}
                    onChange={this.onChangeFilter}
                    name="id_location"
                    placeholder="Lokalita"
                    selection
                    fluid
                    value={parsed.id_location ? Number(parsed.id_location) : null}
                    search
                />
            </Col>
            <Col sm={2} className='text-right'></Col>
            </>
        )
    }

    filtersBarKids = () => {

        const { items, header, locOpts, lecturesFilter, groupFilter } = this.state
        const { type } = this.props.match.params
        const parsed = queryString.parse(this.props.location.search);

        return (
            <>
            <Col sm={2} className='text-right'>
                <Dropdown 
                    options={groupFilter}
                    name='group'
                    onChange={this.onChangeFilter}
                    selection
                    placeholder='Kategorie'
                    fluid
                    value={parsed.group ? Number(parsed.group) : 1}
                    search
                />
            </Col>
            {/*<Col sm={2} className='text-right'>
                <Dropdown 
                    options={lecturesFilter}
                    name='id_workout'
                    onChange={this.onChangeFilter}
                    selection
                    placeholder='Druh lekce'
                    fluid
                    value={parsed.id_workout ? Number(parsed.id_workout) : null}
                    search
                />
            </Col>*/}
            <Col sm={2} className='text-right'>
                <Dropdown 
                    options={type === 'hobby' ? hobbyAgeOpts : (type === 'kids' && Number(parsed.group) !== 2 ? ageOpts : miniGymAgeOpts)}
                    name='age_group'
                    onChange={this.onChangeFilter}
                    selection
                    placeholder='Věk'
                    fluid
                    value={parsed.age_group ? Number(parsed.age_group) : null}
                    search
                />
            </Col>
            <Col sm={2} className='text-right'>
                <Dropdown 
                    options={locOpts}
                    onChange={this.onChangeFilter}
                    name="id_location"
                    placeholder="Lokalita"
                    selection
                    fluid
                    value={parsed.id_location ? Number(parsed.id_location) : null}
                    search
                />
            </Col>
            <Col sm={2} className='text-right'></Col>
            </>
        )
    }

    filtersBarMoms = () => {

        const { locOpts, lecturesFilter } = this.state
        const parsed = queryString.parse(this.props.location.search);

        return (
            <>
            <Col sm={2} className='text-right'>
                <Dropdown 
                    options={lecturesFilter}
                    name='id_workout'
                    onChange={this.onChangeFilter}
                    selection
                    placeholder='Druh lekce'
                    fluid
                    value={parsed.id_workout ? Number(parsed.id_workout) : null}
                    search
                />
            </Col>
            <Col sm={2} className='text-right'>
                <Dropdown 
                    options={locOpts}
                    onChange={this.onChangeFilter}
                    name="id_location"
                    placeholder="Lokalita"
                    selection
                    fluid
                    value={parsed.id_location ? Number(parsed.id_location) : null}
                    search
                />
            </Col>
            <Col sm={2} className='text-right'></Col>
            <Col sm={2} className='text-right'></Col>
            </>
        )
    }

    render() {

        const { items, header } = this.state
        const { type } = this.props.match.params

        return (
            <>
            <HeaderApplications hasRoute={true} route={header} backlink={'/prihlaska'} />
                {/*
                <Card>
                    <CardHeader>
                        <Row>
                            <Col sm={2} className='text-right'>
                                <Dropdown 
                                    options={viewOpts}
                                    value={type}
                                    onChange={this.onChangeSelect}
                                    selection
                                    fluid
                                    search
                                />
                            </Col>
                            {type === 'hobby' && this.filtersBarHobby()}
                            {type === 'kids' && this.filtersBarKids() }
                            {type === 'moms' && this.filtersBarMoms()}
                            <Col sm={2}>
                                <Button fluid onClick={() => this.props.history.push(`${process.env.PUBLIC_URL}/prihlaska/nabidka/cviceni/${type}`)}>Vymazat filtr</Button>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Schedule dates={items} getData={this._getCalendarItems} getItem={this._getCalendarItem} selectedItem={this.state.selectedItem} type={type} />
                    </CardBody>
                </Card>
                */}
                <ApplicationsSuspended />
            </>
        )
    }

}

export default ApplicationWorkoutsView