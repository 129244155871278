
import React, { Component } from 'react'
import EmbedNavigation from '../../containers/EmbedNavigation'
import { Container, Row, Col } from 'reactstrap'
import { Accordion, Icon } from 'semantic-ui-react'
import MomsWorkoutsApplicationAccordion from '../../containers/workouts/MomsWorkoutsApplicationAccordion'
import KidsWorkoutsApplicationAccordion from '../../containers/workouts/KidsWorkoutsApplicationAccordion'
import { createApplication, getWorkoutsPrepaids, createPrepaidApplication } from '../../../api/workouts'
import ApplicationSummary from './ApplicationSummary'
import firebase from 'firebase'
import { connect } from 'react-redux'
import { saveClubUser } from '../../../actions/userActions'
import { setMessage } from '../../../actions/messageActions'
import Loading from '../../containers/Loading'
import { getCalendarItem } from '../../../api/workouts'
import PrepaidsAccordion from '../../containers/prepaids/PrepaidsAccordion'
import HeaderApplications from '../../containers/club/HeaderApplications'

class PrepaidApplicationView extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            toursList: [],
            saved: {
                person: [
                    {
                        first_name: '',
                        last_name: '',
                        email: '',
                        phone: '',
                        address: '',
                        city: '',
                        post_code: ''
                    }
                ],
                id_organization: '',
                event: ''
            },
            userInfo: {
                terms: false,
                newsletter: false,
                gdpr: false,
                note: '',
            },
            item: {},
            isLogged: false,
            loading: true,
            saveSelect: {}
        })
    }

    componentDidMount = () => {

        const { loadingUser } = this.props
        const { selected } = this.props.match.params

        this.checkUser()
        this._getPrepaids()

        if(this.state.isLogged && !loadingUser) {
            this.setUser()
        }

        document.body.classList.add('application')
    }

    componentDidUpdate(prevProps) {
        const { loadingUser } = this.props

        if(prevProps.loadingUser !== loadingUser && !loadingUser && this.state.isLogged) {
            this.setUser()
        }
    }

    checkUser = () => {
        firebase.auth().onAuthStateChanged((user) => {
            if(user) {
                firebase.auth().currentUser.getIdTokenResult()
                .then((idTokenResult) => {
                    if (!idTokenResult.claims.isAdmin && idTokenResult.claims.isAdmin !== undefined) {
                        this.setState({
                            isLogged: true
                        })
                    } else {
                        this.setState({
                            loading: false
                        })
                    }
                })
            } else {
                this.setState({
                    loading: false
                })
            }
        })
    }

    setUser = () => {

        const { user } = this.props

        const loggedPerson = [
            {
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
                phone: user.phone,
                address: user.address,
                city: user.city,
                post_code: user.post_code
            }
        ]

        this.setState({
            saved: {
                ...this.state.saved,
                person: loggedPerson
            },
            loading: false
        })
    }

    _getPrepaids = () => {

        const { selected, type } = this.props.match.params

        getWorkoutsPrepaids(selected, type)
        .then((res) => {

            if(res.code === 1) {
                this.setState({
                    items: res.data
                })
            }
        })
    }

    saveSelect = (data) => {
        this.setState({
            saveSelect: data[0]
        })
    }

    saveData = (data) => {
        this.setState({
            saved: data
        })
    }

    handleCheckbox = (e, data) => {
        this.setState({
            userInfo: {
                ...this.state.userInfo,
                [data.name]: data.checked
            } 
        })
    }

    submit = () => {

        const { type, selected } = this.props.match.params
        const { saved, userInfo } = this.state

        this.setState({
            loadingData: true
        })

        const data = {
            person: saved.person,
            event: saved.event,
            gdpr: userInfo.gdpr,
            terms: userInfo.terms,
            newsletter: userInfo.newsletter,
            id_prepaid: this.state.saveSelect.id_prepaid,
            total_price: this.state.saveSelect.price_per_lecture * this.state.saveSelect.num_of_lectures,
            num_of_lectures: this.state.saveSelect.num_of_lectures
        }

        createPrepaidApplication(data, selected, type)
        .then((res) => {
            this.props.history.push(`${process.env.PUBLIC_URL}/prihlaska/permanentka/done/potvrzeni/${res.link}`)
        })
    }

    momsWorkoutsContent = () => {

        const { selected, type } = this.props.match.params
        const { isLogged, loading, saved, item } = this.state

        return (
            <Row>
                <Col sm={8}>
                    {!loading &&
                    <PrepaidsAccordion 
                        toursList={this.state.toursList} 
                        type={type}
                        saveData={this.saveData} 
                        selectPrepaid={this.selectPrepaid}
                        saveSelected={this.saveSelect} 
                        isLogged={isLogged} 
                        user={isLogged ? this.props.user : null} 
                        loading={loading}
                        eventItem={item}
                        id={selected}
                    />
                    }
                </Col>
                <Col sm={4}>
                    <ApplicationSummary 
                        email={saved.person[0].email} 
                        first_name={saved.person[0].first_name} 
                        last_name={saved.person[0].last_name}
                        phone={saved.person[0].phone}
                        loading={loading}
                        handleCheckbox={this.handleCheckbox}
                        terms={this.state.userInfo.terms}
                        gdpr={this.state.userInfo.gdpr}
                        newsletter={this.state.userInfo.newsletter}
                        submit={this.submit}
                        eventItem={item}
                        selected={this.state.saveSelect}
                    />
                </Col>
            </Row>
        )
    }

    accordion = (type) => {
        if(type === 'moms') {
            return <this.momsWorkoutsContent />
        }
    }

    render() {

        const { utm, type } = this.props.match.params
        const { loading } = this.state

        return (
            <>
            <HeaderApplications hasRoute={true} route={'Permanentky'} backlink={'/prihlaska'} />
                <Container className='mt-5 mb-5'>
                    {!loading ?
                    this.accordion(type)
                    :
                    <Loading inEmbed={true} />
                    }
                </Container>
            </>
        )
    }

}

const mapStateToProps = state => ({
    user: state.user.user,
    loadingUser: state.user.loading
})
  
export default connect(mapStateToProps, { setMessage, saveClubUser })(PrepaidApplicationView)