import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import firebase from 'firebase'
import { firebaseConfigData, Images } from './config';
import { Route, withRouter } from 'react-router-dom'
import { Dashboard, LoadingView, ApplicationsView, AddressBookView, VouchersStoreView, NewsletterView, PaymentFinish, UnsubscribeView, RecommendationsView, CouponsView, ApplicationDetailView, LoginView, LocationsView, EditLocationView, OrganizationsView, EditOrganizationView, UsersView, EditUserView, CampsView, EditCampView, DatesView, EditDatesView, EmbedView, DatesEmbedView, VouchersOrdersView } from './components/views';
import { LoginWrapper, Wrapper } from './styles';
import { SidebarContainer, MessagesContainer, NavbarContainer } from './components/containers';
import { connect } from 'react-redux'
import { saveUser, saveClubUser } from './actions/userActions'
import { getToken } from './api';
//import * as Sentry from '@sentry/react';
import {
  BrowserView,
  isMobile,
  MobileView
} from "react-device-detect";
import ReactPixel from 'react-facebook-pixel';
import ToursView from './components/views/tours/ToursView';
import EditTourView from './components/views/tours/EditTourView';
import TourAppliationView from './components/views/tours/TourApplicationView'
import ClubSignUp from './components/views/club/ClubSignUp'
import SidebarClub from './components/containers/club/SidebarClub';
import UserProfileView from './components/views/club/UserProfileView'
import SignedInAs from './components/containers/SignedInAs';
import ClubDashboardView from './components/views/club/ClubDashboardView'
import ClubMyReservationsView from './components/views/club/ClubMyReservationsView'
import TourOrgApplicationsView from './components/views/tours/TourOrgApplicationsView'
import EditMomWorkoutView from './components/views/mom-workouts/EditMomWorkoutView'
import MomWorkoutScheduleView from './components/views/mom-workouts/MomWorkoutScheduleView'
import ListMomsWorkouts from './components/views/mom-workouts/ListMomsWorkouts';
import EventsSchedule from './components/views/club/EventsSchedule';
import EventApplicationView from './components/views/mom-workouts/EventApplicationView'
import ApplicationsListView from './components/views/mom-workouts/ApplicationsListView'
import ScheduleView from './components/views/club/ScheduleView';
import ClubMyEventsList from './components/views/club/ClubMyEventsList';
import WatchlistView from './components/views/club/WatchlistView'
import ApplicationConfirm from './components/views/mom-workouts/ApplicationConfirm';
import WorkoutApplicationDetailView from './components/views/mom-workouts/WorkoutApplicationDetailView'
import EmbedSchedule from './components/views/embeds/EmbedSchedule';
import ListCategoriesView from './components/views/kids-workouts/ListCategoriesView';
import EditCategoryView from './components/views/kids-workouts/EditCategoryView';
import DatesListView from './components/views/mom-workouts/DatesListView';
import DateDetailView from './components/views/mom-workouts/DateDetailView';
import HeaderContainer from './components/containers/club/HeaderContainer'
import EditPrepaidView from './components/views/mom-workouts/EditPrepaidView';
import PrepaidApplicationView from './components/views/prepaids/PrepaidApplicationView';
import ApplicationMainView from './components/views/application/ApplicationMainView';
import ApplicationWorkoutsView from './components/views/application/ApplicationWorkoutsView';
import ApplicationCampsView from './components/views/application/ApplicationCampsView';
import EmbedOffers from './components/views/embeds/EmbedOffers';
import ResetPasswordView from './components/views/club/ResetPasswordView';
import PrepaidsListView from './components/views/prepaids/PrepaidsListView';
import PrepaidsOrdersView from './components/views/prepaids/PrepaidsOrdersView';
import ClubMyPrepaidsView from './components/views/club/ClubMyPrepaidsView';
import OfferView from './components/views/offers/OfferView';
import PrepaidOrderDetailView from './components/views/prepaids/PrepaidOrderDetailView';
import PrepaidApplicationConfirm from './components/views/prepaids/PrepaidApplicationConfirm'
import ApplicationToursView from './components/views/application/ApplicationToursView';
import TourApplicationConfirm from './components/views/tours/TourApplicationConfirm';
import PaymentConfirm from './components/views/application/PaymentConfirm';
import DashboardLecturersView from './components/views/lecturers/DashboardLecturersView';
import SidebarLecturers from './components/containers/lecturers/SidebarLecturers';
import ListWorkouts from './components/views/lecturers/ListWorkouts';
import ClubOffersView from './components/views/club/ClubOffersView';
import { Container } from 'reactstrap';
import SignupNewsletterView from './components/views/club/SIgnupNewsletterView';
import TagManager from 'react-gtm-module'
import MobileSidebar from './components/containers/club/MobileSidebar';
import SidebarLecturersMobile from './components/containers/lecturers/SidebarLecturersMobile';
import ClubUsersListView from './components/views/users/ClubUsersListView';
import ClubUserDetailView from './components/views/users/ClubUserDetailView';
import NavbarMobile from './components/containers/sidebar-mobile/NavbarMobile';

//Sentry.init({dsn: "https://af8a010e2e7241bbbac1887e53f67868@o345484.ingest.sentry.io/5385474"});

const tagManagerArgs = {
  gtmId: 'GTM-582S55N'
}

TagManager.initialize(tagManagerArgs)

class App extends Component {

  constructor() {
    super()
    firebase.initializeApp(firebaseConfigData)
    this.state = ({
      logged: false,
      loggedClub: false,
      loggedLecturer: false,
      embedView: false,
      user_name: '',
      user_email: '',
      loading: true
    })
  }

  componentDidMount() {

    if(isMobile) {
      document.body.classList.add('mobile')
    }

    if(window.location.origin === 'https://klub.tabory-krouzky.cz') {
      this.changeFavicon()
    }

    this.initPixel()

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase.auth().currentUser.getIdTokenResult()
        .then((idTokenResult) => {
          console.log(idTokenResult.claims)
          if (!!idTokenResult.claims.isAdmin || idTokenResult.claims.isAdmin === undefined) {
            this.props.saveUser(idTokenResult.claims.sub)
            this.setState({
              logged: idTokenResult.claims.isLecturer !== true ? true : false,
              loggedClub: false,
              loggedLecturer: idTokenResult.claims.isLecturer === true ? true : false,
              loading: false,
              embedView: this.props.location.pathname.substring(0,7) === '/admin/' || this.props.location.pathname.substring(0,10) === '/lecturer/' || this.props.location.pathname.substring(0,6) === '/klub/' && this.props.location.pathname.substring(0,10) !== '/klub/auth' ? false : true
            }, () => {
              console.log(this.state.embedView)
            })
          } else {
            this.props.saveClubUser(idTokenResult.token)
            this.setState({
              logged: false,
              loggedClub: true,
              user_name: user.displayName,
              user_email: user.email,
              loading: false,
              embedView: this.props.location.pathname.substring(0,7) === '/admin/' || this.props.location.pathname.substring(0,10) === '/lecturer/' || this.props.location.pathname.substring(0,6) === '/klub/' && this.props.location.pathname.substring(0,10) !== '/klub/auth' ? false : true
            })
          }
        })
      } else {
         if(this.props.location.pathname.substring(0,10) !== '/klub/auth' && this.props.location.pathname.substring(0,10) !== '/prihlaska' && this.props.location.pathname.substring(0,12) !== '/newsletter/' && this.props.location.pathname.substring(0,9) !== '/payment/' && this.props.location.pathname.substring(0,7) !== '/embed/') {
          this.setState({
            loading: false,
            embedView: this.props.location.pathname.substring(0,7) === '/admin/' || this.props.location.pathname.substring(0,10) === '/lecturer/' || this.props.location.pathname.substring(0,6) === '/klub/' && this.props.location.pathname.substring(0,10) !== '/klub/auth' ? false : true
          })
          this.props.history.push(`${process.env.PUBLIC_URL}/klub/auth/prihlaseni`)
         } else {
           this.setState({
             loading: false,
             embedView: this.props.location.pathname.substring(0,7) === '/admin/' || this.props.location.pathname.substring(0,10) === '/lecturer/' || this.props.location.pathname.substring(0,6) === '/klub/' && this.props.location.pathname.substring(0,10) !== '/klub/auth' ? false : true
           })
         }
        
      }
    });

  }

  changeFavicon = () => {
    let link = document.querySelector('link[rel="shortcut icon"]') ||
    document.querySelector('link[rel="icon"]');

    if (!link) {
        link = document.createElement('link');
        link.id = 'favicon';
        link.rel = 'shortcut icon';
        document.head.appendChild(link);
    }

    link.href = Images.favicon;
  }

  initPixel = () => {
    const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    const options = {
      autoConfig: true, // set pixel's autoConfig
      debug: false, // enable logs
    };
    ReactPixel.init('770710593468864', advancedMatching, options);
  }

  componentDidUpdate(prevProps) {
    if(prevProps.location !== this.props.location) {
      this.setState({
        loading: false,
        embedView: this.props.location.pathname.substring(0,7) === '/admin/' || this.props.location.pathname.substring(0,6) === '/klub/' && this.props.location.pathname.substring(0,10) !== '/klub/auth' ? false : true
      })
    }
  }

  render() {


    return (
      <div className="App">
        <Route path='/' exact component={withRouter(LoadingView)} />
        {this.state.logged && !this.state.embedView && !this.state.loading &&
          <div>
            <BrowserView>
              <SidebarContainer isMobile={isMobile} />
              <HeaderContainer />
            </BrowserView>
            <MobileView>
              <NavbarMobile />
            </MobileView>
            <Wrapper isMobile={isMobile}>
              <Route path={`${process.env.PUBLIC_URL}/admin/dashboard`} exact component={withRouter(Dashboard)} />

              <Route path={`${process.env.PUBLIC_URL}/admin/manage/applications`} exact component={withRouter(ApplicationsView)} />
              <Route path={`${process.env.PUBLIC_URL}/admin/manage/applications/detail/:id`} exact component={withRouter(ApplicationDetailView)} />

              <Route path={`${process.env.PUBLIC_URL}/admin/user/contacts`} exact component={withRouter(AddressBookView)} />
              <Route path={`${process.env.PUBLIC_URL}/admin/user/contacts/newsletter`} exact component={withRouter(NewsletterView)} />

              <Route path={`${process.env.PUBLIC_URL}/admin/manage/locations`} exact component={withRouter(LocationsView)} />
              <Route path={`${process.env.PUBLIC_URL}/admin/manage/locations/edit/:id`} exact component={withRouter(EditLocationView)} />

              <Route path={`${process.env.PUBLIC_URL}/admin/manage/organizations`} exact component={withRouter(OrganizationsView)} />
              <Route path={`${process.env.PUBLIC_URL}/admin/manage/organizations/edit/:id`} exact component={withRouter(EditOrganizationView)} />

              <Route path={`${process.env.PUBLIC_URL}/admin/manage/users`} exact component={withRouter(UsersView)} />
              <Route path={`${process.env.PUBLIC_URL}/admin/manage/users/edit/:id`} exact component={withRouter(EditUserView)} />

              <Route path={`${process.env.PUBLIC_URL}/admin/manage/camps`} exact component={withRouter(CampsView)} />
              <Route path={`${process.env.PUBLIC_URL}/admin/manage/camps/edit/:id`} exact component={withRouter(EditCampView)} />

              <Route path={`${process.env.PUBLIC_URL}/admin/manage/dates`} exact component={withRouter(DatesView)} />
              <Route path={`${process.env.PUBLIC_URL}/admin/manage/dates/edit/:id`} exact component={withRouter(EditDatesView)} />

              <Route path={`${process.env.PUBLIC_URL}/admin/manage/tours/list`} exact component={withRouter(ToursView)} />
              <Route path={`${process.env.PUBLIC_URL}/admin/manage/tours/edit/:id`} exact component={withRouter(EditTourView)} />
              <Route path={`${process.env.PUBLIC_URL}/admin/manage/tours/applications`} exact component={withRouter(TourOrgApplicationsView)} />

              <Route path={`${process.env.PUBLIC_URL}/admin/manage/workouts/:type/list/`} exact component={withRouter(ListMomsWorkouts)} />
              <Route path={`${process.env.PUBLIC_URL}/admin/manage/workouts/:type/schedule/`} exact component={withRouter(MomWorkoutScheduleView)} />
              <Route path={`${process.env.PUBLIC_URL}/admin/manage/workouts/:type/edit/:id`} exact component={withRouter(EditMomWorkoutView)} />
              <Route path={`${process.env.PUBLIC_URL}/admin/manage/workouts/:type/applications/list/`} exact component={withRouter(ApplicationsListView)} />
              <Route path={`${process.env.PUBLIC_URL}/admin/manage/workouts/:type/applications/detail/:id`} exact component={withRouter(WorkoutApplicationDetailView)} />
              <Route path={`${process.env.PUBLIC_URL}/admin/manage/workouts/:type/categories/list/`} exact component={withRouter(ListCategoriesView)} />
              <Route path={`${process.env.PUBLIC_URL}/admin/manage/workouts/:type/categories/edit/:id`} exact component={withRouter(EditCategoryView)} />
              <Route path={`${process.env.PUBLIC_URL}/admin/manage/workouts/:type/dates/list/:id`} exact component={withRouter(DatesListView)} />
              <Route path={`${process.env.PUBLIC_URL}/admin/manage/workouts/:type/dates/list/:id/detail`} exact component={withRouter(DateDetailView)} />

              <Route path={`${process.env.PUBLIC_URL}/admin/manage/club-users/`} exact component={withRouter(ClubUsersListView)} />
              <Route path={`${process.env.PUBLIC_URL}/admin/manage/club-users/detail/:id`} exact component={withRouter(ClubUserDetailView)} />

              <Route path={`${process.env.PUBLIC_URL}/admin/manage/workouts/:type/prepaids/edit/:id`} exact component={withRouter(EditPrepaidView)} />
              <Route path={`${process.env.PUBLIC_URL}/admin/manage/workouts/:type/prepaids/list`} exact component={withRouter(PrepaidsListView)} />
              <Route path={`${process.env.PUBLIC_URL}/admin/manage/workouts/:type/prepaids/orders`} exact component={withRouter(PrepaidsOrdersView)} />
              <Route path={`${process.env.PUBLIC_URL}/admin/manage/workouts/:type/prepaids/orders/detail/:id`} exact component={withRouter(PrepaidOrderDetailView)} />

              <Route path={`${process.env.PUBLIC_URL}/admin/manage/vouchers`} exact component={withRouter(VouchersOrdersView)} />

              <Route path={`${process.env.PUBLIC_URL}/embed/signup`} exact component={withRouter(EmbedView)} />

              <Route path={`${process.env.PUBLIC_URL}/admin/settings/coupons`} exact component={withRouter(CouponsView)} />
              <Route path={`${process.env.PUBLIC_URL}/admin/settings/recommendations`} exact component={withRouter(RecommendationsView)} />
            </Wrapper>
          </div>
        }

        {!this.state.logged && !this.state.embedView && this.state.loggedClub && !this.state.loading &&
          <div>
            {window.location.origin !== 'https://klub.tabory-krouzky.cz' ?
            <>
            {!isMobile ?
            <>
            <HeaderContainer />
            <SidebarClub isMobile={isMobile} />
            </>
            :
            <MobileSidebar />
            }
            <Wrapper isMobile={isMobile}>
              <Route path={`${process.env.PUBLIC_URL}/klub/nastenka/:type?`} exact component={withRouter(ClubDashboardView)} />
              <Route path={`${process.env.PUBLIC_URL}/klub/rezervace`} exact component={withRouter(ClubMyReservationsView)} />
              <Route path={`${process.env.PUBLIC_URL}/klub/permanentky`} exact component={withRouter(ClubMyPrepaidsView)} />
              <Route path={`${process.env.PUBLIC_URL}/klub/rozvrh/:type`} exact component={withRouter(EventsSchedule)} />
              <Route path={`${process.env.PUBLIC_URL}/klub/lekce/list`} exact component={withRouter(ClubMyEventsList)} />
              <Route path={`${process.env.PUBLIC_URL}/klub/lekce/watchlist`} exact component={withRouter(WatchlistView)} />
              <Route path={`${process.env.PUBLIC_URL}/klub/lekce/rozvrh/:type`} exact component={withRouter(ScheduleView)} />
              <Route path={`${process.env.PUBLIC_URL}/klub/muj-ucet`} exact component={withRouter(UserProfileView)} />
              <Route path={`${process.env.PUBLIC_URL}/klub/nabidka/:id`} exact component={withRouter(OfferView)} />
              <Route path={`${process.env.PUBLIC_URL}/klub/nabidky`} exact component={withRouter(ClubOffersView)} />
            </Wrapper>
            </>
            :
            <>
            {!isMobile ?
            <>
            <HeaderContainer bg={'#56CAC0'} />
            <SidebarClub isMobile={isMobile} />
            </>
            :
            <MobileSidebar />
            }
            <Wrapper isMobile={isMobile}>
                <Route path={`${process.env.PUBLIC_URL}/klub/nastenka/:type?`} exact component={withRouter(ClubDashboardView)} />
                <Route path={`${process.env.PUBLIC_URL}/klub/nabidka/:id`} exact component={withRouter(OfferView)} />
                <Route path={`${process.env.PUBLIC_URL}/klub/nabidky`} exact component={withRouter(ClubOffersView)} />
                <Route path={`${process.env.PUBLIC_URL}/klub/muj-ucet`} exact component={withRouter(UserProfileView)} />
            </Wrapper>
            </>
            }
          </div>
        }

        {this.state.loggedLecturer && !this.state.embedView && !this.state.loading &&
          <div>
            {!isMobile ?
            <>
            <SidebarLecturers isMobile={isMobile} />
            <HeaderContainer />
            </>
            :
            <SidebarLecturersMobile />
            }
            <Wrapper isMobile={isMobile}>
              <Route path={`${process.env.PUBLIC_URL}/admin/lecturer/dashboard`} exact component={withRouter(DashboardLecturersView)} />
              <Route path={`${process.env.PUBLIC_URL}/admin/manage/workouts/:type/dates/list/:id`} exact component={withRouter(DatesListView)} />
              <Route path={`${process.env.PUBLIC_URL}/admin/manage/workouts/:type/dates/list/:id/detail`} exact component={withRouter(DateDetailView)} />
              <Route path={`${process.env.PUBLIC_URL}/admin/manage/workouts/:type/list/`} exact component={withRouter(ListWorkouts)} />
            </Wrapper>
          </div>
        }

        {!this.state.logged && !this.state.embedView && !this.state.loggedClub && !this.state.loading &&
          <Route path={`${process.env.PUBLIC_URL}/klub/auth/:type`} exact component={withRouter(ClubSignUp)} />
        }

        {this.state.embedView && !this.state.loading &&
          <div>
          <Route path={`${process.env.PUBLIC_URL}/embed/signup/:utm`} exact component={withRouter(EmbedView)} />
          <Route path={`${process.env.PUBLIC_URL}/embed/vouchers/:utm?`} exact component={withRouter(VouchersStoreView)} />
          <Route path={`${process.env.PUBLIC_URL}/embed/dates/:type/:utm?`} exact component={withRouter(DatesEmbedView)} />
          <Route path={`${process.env.PUBLIC_URL}/embed/rozvrh/:type`} exact component={withRouter(EmbedSchedule)} />
          <Route path={`${process.env.PUBLIC_URL}/embed/offers/`} exact component={withRouter(EmbedOffers)} />
          <Route path={`${process.env.PUBLIC_URL}/embed/newsletter/:type`} exact component={withRouter(SignupNewsletterView)} />

          <Route path={`${process.env.PUBLIC_URL}/prihlaska/zajezdy/:selected?`} exact component={withRouter(TourAppliationView)} />
          <Route path={`${process.env.PUBLIC_URL}/prihlaska`} exact component={withRouter(ApplicationMainView)} />
          <Route path={`${process.env.PUBLIC_URL}/prihlaska/nabidka/cviceni/:type`} exact component={withRouter(ApplicationWorkoutsView)} />
          <Route path={`${process.env.PUBLIC_URL}/prihlaska/nabidka/tabory`} exact component={withRouter(ApplicationCampsView)} />
          <Route path={`${process.env.PUBLIC_URL}/prihlaska/nabidka/zajezdy`} exact component={withRouter(ApplicationToursView)} />

          <Route path={`${process.env.PUBLIC_URL}/prihlaska/cviceni/:type/:selected/:all?`} exact component={withRouter(EventApplicationView)} />
          <Route path={`${process.env.PUBLIC_URL}/prihlaska/cviceni/:type/done/potvrzeni/:id/:all?`} exact component={withRouter(ApplicationConfirm)} />
          <Route path={`${process.env.PUBLIC_URL}/prihlaska/permanentka/done/potvrzeni/:id`} exact component={withRouter(PrepaidApplicationConfirm)} />
          <Route path={`${process.env.PUBLIC_URL}/prihlaska/zajezdy/done/potvrzeni/:id`} exact component={withRouter(TourApplicationConfirm)} />
          <Route path={`${process.env.PUBLIC_URL}/prihlaska/permanentky/:type/:selected`} exact component={withRouter(PrepaidApplicationView)} />
          <Route path={`${process.env.PUBLIC_URL}/prihlaska/platba/:id/:type/:code/:workoutType?`} exact component={withRouter(PaymentConfirm)} />

          <Route path={`${process.env.PUBLIC_URL}/newsletter/unsubscribe/:id/:email`} exact component={withRouter(UnsubscribeView)} />
          <Route path={`${process.env.PUBLIC_URL}/payment/:id/:type/:utm/:code/:confirm?`} exact component={withRouter(PaymentFinish)} />
          </div>
        }

        <Route path={`${process.env.PUBLIC_URL}/klub/auth/:type`} exact component={withRouter(ClubSignUp)} />
        <Route path={`${process.env.PUBLIC_URL}/klub/auth/prihlaseni/reset/:email/:code`} exact component={withRouter(ResetPasswordView)} />

        {this.state.loggedClub && !this.state.embedView &&
          <SignedInAs email={this.state.user_email} name={this.state.user_name} />
        }
        <MessagesContainer />
      </div>
    );
  }

  
}

const mapStateToProps = state => ({})

export default connect(mapStateToProps, { saveUser, saveClubUser })(App)
