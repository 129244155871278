import React from 'react'
import { url } from "../config"

// Club
export function createClubUser(data) {
    return fetch(url.api + 'users/create-club-user', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function updateClubUser(data) {
    return fetch(url.api + 'users/update-club-user', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function getClubUser(token) {
    return fetch(url.api + 'users/get-club-user/' + token + '/0/')
    .then((data) => { return data.json() })
}

export function forgotPassword(email) {
    return fetch(url.api + 'users/forgot-password/' + email)
    .then((data) => { return data.json() })
}

// Reservations
export function getTourReservations(data) {
    return fetch(url.api + 'tours/get/reservations', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function getWorkoutsReservations(data, type) {
    return fetch(url.api + 'workouts/get/reservations/' + type, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function getEventsList(data, type) {
    return fetch(url.api + 'workouts/get/events-list/' + type, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function getSignedDashboard(data, date) {
    return fetch(url.api + 'workouts/get-signed-dashboard/' + date, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function getUserPrepaid(data, type) {
    return fetch(url.api + 'workouts/get/user-prepaids/' + type, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}