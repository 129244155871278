import React from 'react'
import { OffersWidgetItem, OfferName, OfferBrand, OfferImg, OfferContent } from '../../../styles/offers'
import { Col } from 'reactstrap'
import { url } from '../../../config'
import { Link } from 'react-router-dom'
import { Divider } from 'semantic-ui-react'
import moment from 'moment'

const OfferInfoCard = ({item}) => {
    return (
        <OffersWidgetItem valid={moment(item.valid_to).isAfter()}>
            <OfferImg img={url.images + '/offers/' + item.id_offer + '/' + item.image} style={{minHeight: 250}}></OfferImg>
            <OfferContent>
                <p className='mb-0'>Značka</p>
                <h5>
                    {item.brand}
                </h5>
                <Divider />
                <p className='mb-0'>Kategorie</p>
                <h5>
                    {item.category}
                </h5>
                <Divider />
                <p className='mb-0'>Platnost</p>
                <h5>
                    {moment(item.valid_from).format('DD. MM. YYYY')} - {moment(item.valid_to).format('DD. MM. YYYY')}
                </h5>
                <Divider />
                <p className='mb-0'>Slevový kód</p>
                <h5>
                    {item.coupon}
                </h5>
                <Divider />
                <p className='mb-0'>Web výhody</p>
                <h5>
                <a href={item.link} target='_blank'>{item.link}</a>
                </h5>
            </OfferContent>
        </OffersWidgetItem>
    )
}

export default OfferInfoCard