import React from 'react'
import { Modal, Button, Divider, Icon } from 'semantic-ui-react'
import { Row, Col } from 'reactstrap'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { FullTag, FreeTag } from '../../../styles/events'

const Actions = ( type, item ) => {
    if(type === 'hobby' && !item.is_full && item.is_free === 0) {
        return (
            <Link target='_blank' to={`${process.env.PUBLIC_URL}/prihlaska/cviceni/${type}/${item.id_date}`}>
                <Button fluid primary>{'Zapsat do kroužku'}</Button>
            </Link>
        )
    } else if (type === 'hobby' && item.is_full && item.is_free === 0) {
        return (
            <Link target='_blank' to={`${process.env.PUBLIC_URL}/prihlaska/cviceni/${type}/${item.id_date}`}>
                <Button fluid className='red'>Přihlásit jako náhradník</Button>
            </Link>
        )
    } else if (type === 'moms' && !item.is_full && item.is_free === 0) {
        return (
            <Link target='_blank' to={`${process.env.PUBLIC_URL}/prihlaska/cviceni/${type}/${item.id_date}`}>
                <Button fluid>Rezervace lekce</Button>
            </Link>
        )
    } else if (type === 'moms' && item.is_full && item.is_free === 0) {
        return (
            <Link target='_blank' to={`${process.env.PUBLIC_URL}/prihlaska/cviceni/${type}/${item.id_date}`}>
                <Button fluid className='red'>Přihlásit jako náhradník</Button>
            </Link>
        )
    } else if (type === 'kids' && !item.is_full && item.is_free === 0) {
        return (
            <Link target='_blank' to={`${process.env.PUBLIC_URL}/prihlaska/cviceni/${type}/${item.id_date}`}>
                <Button fluid primary>Rezervace kurzu</Button>
            </Link>
        )
    } else if (type === 'kids' && item.is_full && item.is_free === 0) {
        return (
            <Link target='_blank' to={`${process.env.PUBLIC_URL}/prihlaska/cviceni/${type}/${item.id_date}`}>
                <Button fluid primary>Přihlásit jako náhradník</Button>
            </Link>
        )
    } else if (item.is_free === 1) {
        return (
            <>
            <Link target='_blank' to={`${process.env.PUBLIC_URL}/prihlaska/cviceni/${type}/${item.id_date}`}>
                <Button fluid className='mb-1'>Přihlásit se na zkušební lekci zdarma</Button>
            </Link>
            <Link target='_blank' to={`${process.env.PUBLIC_URL}/prihlaska/cviceni/${type}/${item.buy_all}`}>
                <Button fluid primary>Rezervace kurzu</Button>
            </Link>
            </>
        )
    }
}

const pricing = (type, item) => {
    if(type === 'hobby') {
        return (
            <p><strong>Cena za pololetí:</strong><br />{item.price_lecture},- Kč</p>
        )
    } else if (type === 'kids') {
        return (
            <p><strong>Cena za pololetí:</strong><br />{item.price_lecture},- Kč</p>
        )
    } else if (type === 'moms') {
        return (
            <p><strong>Cena za lekci:</strong><br />{item.price_lecture},- Kč</p>
        )
    }
}

const InfoModal = ({ close, closed, item, type, addToWatchlist, loadingWatchlist, ageGroup, logged, loadingPrepaids, hasPrepaids, workoutPrepaid }) => {

    return (
        <div>
            <div>
                <Row>
                    <Col sm={12}>
                        <Row>
                            <Col sm={6} className='mb-3'>
                                <h3 className="h5">{item.name}</h3>
                            </Col>
                            <Col sm={6} className='mb-3'>
                                {item.is_full ? <FullTag>Tato lekce je plná</FullTag> :  <FreeTag>Tato lekce je volná</FreeTag>}
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={12} className='mb-3'>
                                <p>{item.description}</p>
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col sm={type === 'kids' ? 6 : 12} className='mb-3'>
                                <p><strong>Lektor/ka:</strong><br />{item.user_first_name} {item.user_last_name}</p>
                            </Col>
                            {type === 'kids' &&
                            <Col sm={6} className='mb-3'>
                                <p><strong>Věková kategorie:</strong><br />{item.age_group}</p>
                            </Col>
                            }
                            <Col sm={12} className='mb-3'>
                                <p><strong>Adresa:</strong><br />{item.venue_address}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6} className='mb-3'>
                                <p><strong>Volná místa:</strong><br />{item.is_full ? 'Plno' :  'Volno'}</p>
                            </Col>
                            <Col sm={6} className='mb-3'>
                                <p><strong>Čas konání:</strong><br />{moment(item.date).format('dddd v H:mm')}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6} className='mb-3'>
                                {pricing(type, item)}
                            </Col>
                            {type === 'moms' &&
                            <Col sm={6} className='mb-3'>
                                <p><strong>Permanentky od:</strong><br />{item.price_points}</p>
                            </Col>
                            }
                        </Row>
                        <div className="popup_actions">
                        {item.state === 1 ?
                        <Row>
                            <Col sm={12} className='mb-2'>
                                {Actions(type, item)}
                            </Col>
                        </Row>
                        :
                        <Row>
                            <Col sm={12} className='mb-2'>
                                <Button disabled fluid>Tato lekce má vypnuté přihlašování</Button>
                            </Col>
                        </Row>
                        }
                        {item.prepaids && !hasPrepaids &&
                        <Row>
                            <Col sm={12} className='mb-2'>
                                <Link target='_blank' to={`${process.env.PUBLIC_URL}/prihlaska/permanentky/${type}/${item.id_workout}`}><Button primary fluid>Koupit permanentku</Button></Link>
                            </Col>
                        </Row>
                        }
                        {item.is_full && logged &&
                        <Row>
                            <Col sm={12}>
                                <Button
                                    onClick={() => addToWatchlist(item.id_workout, item.id_date, type)}
                                    icon
                                    labelPosition='left'
                                    loading={loadingWatchlist}
                                    fluid
                                >
                                    <Icon name='bell' />
                                    Hlídat uvolění lekce
                                </Button>
                            </Col>
                        </Row>
                        }
                        {!loadingPrepaids && hasPrepaids &&
                            <Row>
                                <Col sm={12}>
                                    <Button
                                        onClick={() => workoutPrepaid(item.id_workout, item.id_date)}
                                        primary
                                        loading={loadingWatchlist}
                                        fluid
                                    >
                                        Využít permanentku
                                    </Button>
                                </Col>
                            </Row>
                        }
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default InfoModal