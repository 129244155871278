import React, { Component } from 'react'
import { Card, CardControls, CardHeader } from '../../styles'
import { Link } from 'react-router-dom'
import _ from 'loadsh'
import { Dropdown, Input, Modal, Form, Button } from 'semantic-ui-react'
import { getCouponsList, getToken, createCoupon } from '../../api'
import { CouponsTable } from '../containers'
import { connect } from 'react-redux'
import { setMessage } from '../../actions/messageActions'

class CouponsView extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            data: [],
            column: 'id_coupon',
            direction: 'descending',
            modal: false,
            new: {
                coupon_text: '',
                coupon_value: 0
            },
            newLoading: false
        })
    }

    componentDidMount() {
        this.getCouponsHandler()
    }

    getCouponsHandler = () => {

        this.setState({
            loadingCoupons: true
        })

        getCouponsList()
        .then((data) => {
            this.setState({
                loadingCoupons: false,
                data: data
            })
        })
    }

    handleSort = (clickedColumn) => () => {
        const { column, data, direction } = this.state
    
        if (column !== clickedColumn) {
          this.setState({
            column: clickedColumn,
            data: _.sortBy(data, [clickedColumn]),
            direction: 'ascending',
          })
    
          return
        }
    
        this.setState({
          data: data.reverse(),
          direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    }

    onChange = (e) => this.setState({
        new: {
            ...this.state.new,
            [e.target.name]: e.target.value
        }
    })

    submitCoupon = () => {

        this.setState({
            newLoading: true
        })


        getToken().then((token) => {

            const data = {
                token: token,
                coupon_text: this.state.new.coupon_text,
                coupon_value: this.state.new.coupon_value
            }

            createCoupon(data)
            .then((res) => {
                this.props.setMessage(res)
                this.handleHideModal()
                this.getCouponsHandler()

                this.setState({
                    newLoading: false
                })
            })

        })

    }

    handleHideModal = () => this.setState({ modal: false, new: { coupon_text: '', coupon_value: 0 } })
    handleShowModal = () => this.setState({ modal: true })

    _renderModal = () => {
        return (
            <Modal open={this.state.modal} onClose={() => this.handleHideModal()} closeIcon size='large'>
                <Modal.Header>Přidat nový kupon</Modal.Header>
                <Modal.Content>
                    <this.newCouponForm />
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.handleHideModal}>Zavřít</Button>
                    <Button positive onClick={this.submitCoupon} loading={this.state.newLoading}>Vytvořit kupon</Button>
                </Modal.Actions>
            </Modal>
        )
    }

    newCouponForm = () => {
        return (
            <Form>
                <Form.Group widths='equal'>
                    <Form.Field>
                        <label>Kód kuponu</label>
                        <Input 
                            type='text'
                            name='coupon_text'
                            value={this.state.new.coupon_text}
                            onChange={this.onChange}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Hodnota kuponu</label>
                        <Input 
                            type='number'
                            name='coupon_value'
                            value={this.state.new.coupon_value}
                            onChange={this.onChange}
                        />
                    </Form.Field>
                </Form.Group>
            </Form>
        )
    }

    render() {
        return (
            <div>
                <Card>
                    <CardHeader>
                        <div className='row'>
                            <div className='col-sm-9 text-left'>
                                <h2>Správa slevových kuponů</h2>
                            </div>
                            <div className='col-sm-3 text-right'>
                                {<Button positive onClick={this.handleShowModal}>Nový kupon</Button>}
                            </div>
                        </div>
                    </CardHeader>
                    <CouponsTable items={this.state.data} refresh={this.getCouponsHandler} sort={this.handleSort} column={this.state.column} direction={this.state.direction} />
                </Card>
                <this._renderModal />
            </div>
        )
    }

}

const mapStateToProps = state => ({})
  
export default connect(mapStateToProps, { setMessage })(CouponsView)