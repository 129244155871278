import React, { Component } from 'react'
import { intToBool, toggleLocationState, shortText, getToken } from '../../../api'
import { Table, Icon, Button, Popup, Modal } from 'semantic-ui-react'
import _ from 'loadsh'
import moment from 'moment'
import { StateIcon } from '../../../styles'
import { Link } from 'react-router-dom'
import { deleteItem } from '../../../api/watchlist'

class WatchlistTable extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            showModal: false,
            loading: false
        })
    }

    hideModal = () => this.setState({ showModal: false })
    openModal = () => this.setState({ showModal: true })

    _deleteItem = (id) => {

        this.setState({
            loading: true
        })

        const { setMessage, refresh, type } = this.props

        getToken().then((token) => {
            const data = {
                token: token
            }

            deleteItem(data, id, type)
            .then((res) => {
                setMessage(res)
                refresh()

                this.setState({
                    loading: false
                })
            })

        })
    }

    deleteModal = (id_item) => {
        return (
            <Modal trigger={<Button icon><Icon name='trash' /></Button>} onClose={this.hideModal}>
                <Modal.Header>
                    Zrušit hlídání lekce?
                </Modal.Header>
                <Modal.Content>
                    <p>Po zrušení hlídání již nebudete upozorněni na uvlonení kapacity,</p>
                </Modal.Content>
                <Modal.Actions className='justify-content-end'>
                    <Button className='red' onClick={() => this._deleteItem(id_item)} loading={this.state.loading} disabled={this.state.loading}>Zrušit hlídání</Button>
                </Modal.Actions>
            </Modal>
        )
    }

    render() {

        const { items, column, direction, sort, type } = this.props

        return (
            <div>
                <Table sortable striped className='main-table select_table'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell
                                sorted={column === 'id_watchlist' ? direction : null}
                                onClick={sort('id_watchlist')}
                                width={1}
                            >
                                #
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'workout_name' ? direction : null}
                                onClick={sort('workout_name')}
                                width={3}
                            >
                                Název lekce
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'description' ? direction : null}
                                onClick={sort('description')}
                                width={3}
                            >
                                Popis
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'date' ? direction : null}
                                onClick={sort('date')}
                                width={3}
                            >
                                Datum a čas
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'total_price' ? direction : null}
                                onClick={sort('total_price')}
                                width={1}
                            >
                                Cena za lekci
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'created' ? direction : null}
                                onClick={sort('created')}
                                width={2}
                            >
                                Vytvořeno
                            </Table.HeaderCell>

                            <Table.HeaderCell width={2}></Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                    {_.map(items, ({ id_watchlist, description, date, price_lecture, created, workout_name }, index) => (
                        <Table.Row key={id_watchlist}>
                            <Table.Cell>
                                {id_watchlist}
                            </Table.Cell>
                            <Table.Cell>
                                {workout_name}
                            </Table.Cell>
                            <Table.Cell>
                                <Popup wide='very' content={description} trigger={<span>{shortText(description, 80)}</span>} />
                            </Table.Cell>
                            <Table.Cell>
                                {moment(date).format('DD. MM. YYYY h:ss')}
                            </Table.Cell>
                           
                            <Table.Cell>
                                {price_lecture}
                            </Table.Cell>

                            <Table.Cell>
                                {moment(created).format('DD. MM. YYYY')}
                            </Table.Cell>

                            <Table.Cell className='text-right'>
                                {this.deleteModal(id_watchlist)}
                            </Table.Cell>

                        </Table.Row>
                    ))}
                    </Table.Body>
                </Table>
            </div>
        )
    }
}

export default WatchlistTable