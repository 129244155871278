import React, { Component, ReactDOM } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { getCalendar } from '../../../api/workouts';
import { Event, MoreArrow } from '../../../styles/calendar';
import InfoModal from './InfoModal';
import InfoModalHobby from './InfoModalHobby'
import { getToken } from '../../../api';
import { addToWatchlist } from '../../../api/watchlist';
import { connect } from 'react-redux'
import { setMessage } from '../../../actions/messageActions'
import { Popup } from 'semantic-ui-react';
import { checkPrepaids, useWorkoutPrepaid } from '../../../api/prepaids';
import VizSensor from 'react-visibility-sensor';

const localizer = momentLocalizer(moment)

class Schedule extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            dates: [],
            closedModal: true,
            selectedItem: {},
            loadingWatchlist: false,
            loadingPrepaids: false,
            scroll: new Date(),
            visible: {},
            loadingArrow: true,
            showArrow: true
        })

        this.childRef = React.createRef();
        this.calendarRef = React.createRef();
        this.eventsRefs = [];
    }

    componentDidMount() {
        /*var d = new Date();
        if(Number(moment().format('e')) > 4) {
            d.setDate(d.getDate() + (1 + 7 - d.getDay()) % 7);
        } else if(Number(moment().format('e')) === 0) {
            d = new Date();
        } else {
            d.setDate(d.getDate() - (d.getDay() + 6) % 7)
        }*/

        var d = moment('2021-01-18')

        this._getCalendar(d)

        this.setState({
            dates: this.props.dates
        }, () => window.addEventListener('scroll', this.checkIfVisible))

        
    }

    componentDidUpdate(prevProps) {
        if(prevProps.dates !== this.props.dates) {
            this.setState({
                dates: this.props.dates
            }, () => {
                this.setEarliestEvent()
            })
        }

        if(prevProps.selectedItem !== this.props.selectedItem) {
            this.setState({
                selectedItem: this.props.selectedItem
            })
        }
    }

    _getCalendar = (date) => {
        this.setState({
            dates: []
        }, () => this.props.getData(date))
        
    }

    myEvent = (props) => {
        return (
            <Popup on='click' content={this._popupContent} trigger={
                <Event color={props.event.color} state={props.event.state} ref={(eventsRefs) => { this.eventsRefs[props.event.id] = eventsRefs }}>
                    <p>{props.title}</p>
                </Event>
            } wide />
        )
    }

    setEventsTop = () => {

            var lowest = 0

            this.eventsRefs.map((event) => {
                if(event !== null) {
                    var position = event.parentElement.parentElement.offsetTop
                    var height = document.getElementsByClassName('rbc-events-container')[0].clientHeight;
                    var eventHeight = document.getElementsByClassName('rbc-event')[0].clientHeight;
                    var pixels = eventHeight;
                    var percentage = (pixels / height) * 100;
                    var multiply = event.parentElement.parentElement.classList[2]
                    var item = event.parentElement.parentElement
                    var newHeight = event.parentElement.parentElement.style.top.replace('%', '')
                    var newHeightNum = Number(newHeight) + (percentage * multiply)
        
                    item.style.top = newHeightNum + '%'

                    if(position > lowest) {
                        lowest = position

                        this.setState({
                            lowest: lowest
                        }, () => this.checkIfVisible())
                    }

                }
            })
    }

    checkIfVisible = () => {

        var scroll = window.scrollY

        /*
        this.eventsRefs.map((event, index) => {
            if(event !== null) {
                var position = event.parentElement.parentElement.offsetTop
                this.setState({
                    visible: {
                        ...this.state.visible,
                        [index]: position < scroll
                    }
                })
            }
        })

        if(Object.keys(this.state.visible).every((i) => this.state.visible[i])) {
            this.setState({
                showArrow: false,
                loadingArrow: false
            })
        } else {
            this.setState({
                showArrow: true,
                loadingArrow: false
            })
        }*/

        if(this.state.lowest > scroll) {
            this.setState({
                showArrow: true,
                loadingArrow: false
            })
        } else {
            this.setState({
                showArrow: false,
                loadingArrow: false
            })
        }
    }

    setEarliestEvent = () => {
        var earliest = moment('2021-12-31 23:59:59').hours()
        var firstItem = {}

        this.props.dates.map((item, index) => {
            var newTime = moment(item.start).hours()
            if(newTime < earliest) {
                earliest = moment(item.start).hours()
                firstItem = item.start
            }
        })

        this.setState({
            scroll: firstItem
        }, () => {
            this.setEventsTop()
        })
    }

    _popupContent = () => {
        const { user, type } = this.props

        return (
            <InfoModal item={this.state.selectedItem} type={type} logged={user.email !== undefined} loadingPrepaids={this.state.loadingPrepaids} hasPrepaids={this.state.hasPrepaids} workoutPrepaid={this._usePrepaid} />
        )
    }

    eventStyleGetter = (event, start, end, isSelected) => {
        var style = {
            backgroundColor: event.color,
            opacity: event.is_full ? 0.3 : 1,
            borderRadius: '0.25rem',
            color: '#fff',
            border: '0px'
        };
        return {
            style: style,
            className: event.state === 0 ? 'not-active' : 'active ' + event.scheduleCat
        };
    }

    selectEvent = (event) => {
        const { user } = this.props

        if(user.email !== undefined) {
            this._checkPrepaids(event.id_workout, user.id_user)
        }

        this.props.getItem(event.id)
    }

    _checkPrepaids = (id_workout, id_user) => {

        this.setState({
            loadingPrepaids: true
        })

        checkPrepaids(id_workout, id_user)
        .then((res) => {
            this.setState({
                loadingPrepaids: false,
                hasPrepaids: res.prepaid
            })
        })
    }

    showModal = () => this.setState({ closedModal: false })
    hideModal = () => this.setState({ closedModal: true })

    _addToWatchlist = (id_workout, id_date, type) => {

        this.setState({
            loadingWatchlist: true
        })

        getToken().then((token) => {
            const data = {
                token: token,
                id_workout: id_workout,
                id_date: id_date
            }
            
            addToWatchlist(data, type)
            .then((res) => {
                this.props.setMessage(res)
                this.hideModal()
                this.setState({
                    loadingWatchlist: false
                })
            })
        })
    }

    _usePrepaid = (id_workout, id_date) => {
        this.setState({
            loadingUsePrepaid: true
        })

        getToken().then((token) => {
            const data = {
                token: token,
                id_workout: id_workout,
                id_date: id_date
            }
            
            useWorkoutPrepaid(data)
            .then((res) => {
                this.props.setMessage(res)
                this.setState({
                    loadingUsePrepaid: false
                })
            })
        })
    }

    render() {

        const { type } = this.props

        let components = {
            event: this.myEvent
        }

        if(type === 'hobby') {
            var min_time = new Date();
                min_time.setHours(14);
                min_time.setMinutes(0);
                min_time.setMilliseconds(0);
        } else {
            var min_time = new Date();
                min_time.setHours(9);
                min_time.setMinutes(0);
                min_time.setMilliseconds(0);
        }

        if(type === 'kids') {
            var max_time = new Date();
                max_time.setHours(12);
                max_time.setMinutes(0);
                max_time.setMilliseconds(0);
        } else {
            var max_time = new Date();
                max_time.setHours(21);
                max_time.setMinutes(0);
                max_time.setMilliseconds(0);
        }

        var d = new Date();
        if(Number(moment().format('e')) > 4) {
            d.setDate(d.getDate() + (1 + 7 - d.getDay()) % 7);
        } else if(Number(moment().format('e')) === 0) {
            d = new Date();
        } else {
            d.setDate(d.getDate() - (d.getDay() + 6) % 7)
        }

        d = moment('2021-01-18')
        
        return (
            <>
            <Calendar
                localizer={localizer}
                events={this.state.dates}
                startAccessor="start"
                endAccessor="end"
                eventPropGetter={this.eventStyleGetter}
                style={{
                    minHeight: 800
                }}
                onNavigate={(date) => this._getCalendar(date)}
                components={components}
                min={moment(this.state.scroll).toDate()}
                max={max_time}
                defaultDate={d}
                messages={{
                    month: 'Měsíc',
                    day: 'Den',
                    today: 'Dnes',
                    previous: 'Zpět',
                    next: 'Další',
                    week: 'Týden',
                    date: 'Datum',
                    time: 'Čas',
                    event: 'Lekce',
                    showMore: total => `+ Zobrazit další (${total})`
                }}
                onSelectEvent={this.selectEvent}
                defaultView='week'
                views={{ month: true, week: true }}
                scrollToTime={moment(this.state.scroll).toDate()}
                ref={this.calendarRef}
            />
            {type === 'hobby' &&
            <InfoModalHobby 
                item={this.state.selectedItem}
                close={this.hideModal}
                open={this.showModal}
                closed={this.state.closedModal}
                type={type}
                addToWatchlist={this._addToWatchlist}
                loadingWatchlist={this.state.loadingWatchlist}
            />
            }

            <MoreArrow loading={this.state.loadingArrow} visible={this.state.showArrow} onClick={() => window.scrollTo(0, this.state.lowest)} />
            </>
        )
    }

}

const mapStateToProps = state => ({
    user: state.user.user
})
  
export default connect(mapStateToProps, { setMessage })(Schedule)