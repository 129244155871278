import React, { Component } from 'react'
import { getDates, getLocationsFilter, dateFilterFrom } from '../../api'
import { DateItems } from '../containers'
import { Dropdown, Form } from 'semantic-ui-react'
import _ from 'loadsh'

const stateOptions = [
    {key: '1', value: null, text: 'Vše'},
    {key: '2', value: 6, text: 'Kidsjoy'},
    {key: '3', value: 2, text: 'Sportjoy'},
    {key: '4', value: 7, text: 'Babyjoy'}
]

const dateOptions = [
    {key: '1', value: null, text: 'Vše'},
    {key: '2', value: '2020-07-06', text: '6.7. - 10.7.2020'},
    {key: '3', value: '2020-07-13', text: '13.7. - 17.7.2020'},
    {key: '4', value: '2020-07-20', text: '20.7. - 24.7.2020'},
    {key: '5', value: '2020-07-27', text: '27.7. - 31.7.2020'},
    {key: '6', value: '2020-08-03', text: '3.8. - 7.8.2020'},
    {key: '7', value: '2020-08-10', text: '10.8. - 14.8.2020'},
    {key: '8', value: '2020-08-17', text: '17.8. - 21.8.2020'},
    {key: '9', value: '2020-08-24', text: '24.8. - 28.8.2020'}
]

class DatesEmbedView extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            dates: [],
            baseData: [],
            column: 'id_date',
            direction: 'descending',
            locOptions: [
                {key: '0', value: null, text: 'Vše'},
            ],
            filter: false,
            filter_date_from: '', 
            utm: ''
        })
    }

    handleSort = (clickedColumn) => () => {
        const { column, dates, direction } = this.state
    
        if (column !== clickedColumn) {
          this.setState({
            column: clickedColumn,
            dates: _.sortBy(dates, [clickedColumn]),
            direction: 'ascending',
          })
    
          return
        }
    
        this.setState({
          data: dates.reverse(),
          direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    }

    onChangeSelect = (e, data) => {
        if(data.value !== null) {
            this.setState({
                filter: true,
                filters: {
                    ...this.state.filters,
                    [data.name]: data.value
                }
            }, () => {
                this.setState({
                    dates: _.filter(this.state.baseData, this.state.filters)
                })
            })
        } else {
            this.setState({
                filters: _.omit(this.state.filters, [data.name])
            }, () => {
                this.setState({
                    dates: _.keys(this.state.filters).length !== 0 ? _.filter(this.state.baseData, this.state.filters) : this.state.baseData,
                    filter: _.keys(this.state.filters).length !== 0 ? true : false
                })
            })
        }
    }

    onChangeDate = (e, data) => {
        this.setState({
            [data.name]: data.value
        }, () => {
            if(this.state.filter_date_from !== '' && this.state.filter_date_from !== null) {
                this.getFilteredHandler(this.state.filter_date_from)
            } else {
                this.getDatesHandler()
            }
        })
    }

    /*onChangeDate = (e) => this.setState({
        [e.target.name]: e.target.value
    }, () => {
        if(this.state.filter_date_from !== '') {
            this.getFilteredHandler(this.state.filter_date_from)
        } else {
            this.getDatesHandler()
        }
        
    })*/

    getFilteredHandler = (date) => {
        dateFilterFrom(date)
        .then((dates) => {
            if(dates.length !== 0) {
                if(_.keys(this.state.filters).length !== 0) {
                    this.setState({
                        dates: _.filter(dates, this.state.filters),
                        baseData: dates,
                        hasSome: true
                    })
                } else {
                    this.setState({
                        dates: dates,
                        baseData: dates,
                        hasSome: true
                    })
                }
            } else {
                this.setState({
                    hasSome: false
                })
            }
        })
    }

    componentDidMount() {

        const { utm } = this.props.match.params

        this.setState({
            utm: utm !== undefined ? utm : ''
        })

        document.body.classList.add('dates')

        getLocationsFilter()
        .then((data) => {
            this.setState({
                locOptions: this.state.locOptions.concat(data)
            })
        })

        this.getDatesHandler()
    }

    getDatesHandler = () => {
        getDates()
        .then((dates) => {
            if(dates.length !== 0) {
                if(_.keys(this.state.filters).length !== 0) {
                    this.setState({
                        dates: _.filter(dates, this.state.filters),
                        baseData: dates,
                        hasSome: true
                    })
                } else {
                    this.setState({
                        dates: dates,
                        baseData: dates,
                        hasSome: true
                    })
                }
            } else {
                this.setState({
                    hasSome: false
                })
            }
        })
    }

    render() {

        const { type } = this.props.match.params

        return (
            <div className={this.state.utm + ' dates-container'}>
                <div className='row dates-filters'>
                    <div className='col-sm-4'>
                        <label>Typ táboru</label>
                        <Dropdown
                            name='id_camp'
                            options={stateOptions}
                            placeholder='Vyberte tábor'
                            selection
                            search
                            onChange={this.onChangeSelect}
                            fluid
                        />
                    </div>
                    <div className='col-sm-4'>
                        <label>Lokalita</label>
                        <Dropdown
                            name='id_location'
                            options={this.state.locOptions}
                            placeholder='Vyberte lokalitu'
                            selection
                            search
                            onChange={this.onChangeSelect}
                            fluid
                        />
                    </div>
                    <div className='col-sm-4'>
                        <label>Termín</label>
                        <Dropdown
                            name='filter_date_from'
                            options={dateOptions}
                            placeholder='Vyberte termín'
                            selection
                            onChange={this.onChangeDate}
                            fluid
                        />
                    </div>
                </div>
                
                <DateItems items={this.state.dates} refresh={this.getDatesHandler} sort={this.handleSort} column={this.state.column} direction={this.state.direction} type={type} />
            </div>
        )
    }

}

export default DatesEmbedView