import React, { Component } from 'react'
import { intToBool, toggleLocationState, shortText, getToken } from '../../../api'
import { Table, Icon, Button, Popup, Modal } from 'semantic-ui-react'
import _ from 'loadsh'
import moment from 'moment'
import { StateIcon } from '../../../styles'
import { Link } from 'react-router-dom'
import { deleteItem } from '../../../api/workouts'
import ChangeLectureModal from '../../modals/ChangeLectureModal'

class UsersEventsTable extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            showModal: false,
            loading: false
        })
    }

    hideModal = () => this.setState({ showModal: false })
    openModal = () => this.setState({ showModal: true })

    _deleteItem = (id) => {

        this.setState({
            loading: true
        })

        const { setMessage, refresh, type } = this.props

        getToken().then((token) => {
            const data = {
                token: token
            }

            deleteItem(data, id, type)
            .then((res) => {
                setMessage(res)
                refresh()

                this.setState({
                    loading: false
                })
            })

        })
    }

    deleteModal = (id_item, type) => {
        return (
            <Modal trigger={<Button className='red' icon>Omluvit</Button>} onClose={this.hideModal}>
                <Modal.Header>
                    Omluvit se z této lekce
                </Modal.Header>
                <Modal.Content>
                    {type !== 'hobby' && <p>Po omluvení budete z lekce vyřazeni a budete si ji moci nahradit jindy.</p>}
                    {type === 'hobby' && <p>Po omluvení budete z lekce vyřazeni a <strong>nebudete si ji moci nahradit jindy.</strong></p>}
                </Modal.Content>
                <Modal.Actions className='justify-content-end'>
                    <Button className='red' onClick={() => this._deleteItem(id_item)} loading={this.state.loading} disabled={this.state.loading}>Omluvit lekci</Button>
                </Modal.Actions>
            </Modal>
        )
    }

    _changeLectureModal = (type, id_signed, age_group, is_free) => {

        const { setMessage, refresh } = this.props

        return (
            <ChangeLectureModal type={type} id={id_signed} refresh={refresh} setMessage={setMessage} ageGroup={age_group} is_free={is_free} />
        )
    }   

    render() {

        const { items, column, direction, sort, type, canChange } = this.props

        return (
            <div>
                <Table sortable striped className='main-table'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell
                                sorted={column === 'id_application' ? direction : null}
                                onClick={sort('id_application')}
                                width={1}
                            >
                                #
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'workout_name' ? direction : null}
                                onClick={sort('workout_name')}
                                width={3}
                            >
                                Název lekce
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'date' ? direction : null}
                                onClick={sort('date')}
                                width={3}
                            >
                                Datum a čas
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'total_price' ? direction : null}
                                onClick={sort('total_price')}
                                width={1}
                            >
                                Cena
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'created' ? direction : null}
                                onClick={sort('created')}
                                width={2}
                            >
                                Vytvořeno
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'paid' ? direction : null}
                                onClick={sort('paid')}
                                width={1}
                            >
                                Zaplaceno
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'state' ? direction : null}
                                onClick={sort('state')}
                                width={1}
                            >
                                Status
                            </Table.HeaderCell>

                            <Table.HeaderCell width={2}></Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                    {_.map(items, ({ id_application, id_signed, id_workout, date, total_price, paid, state, created, workout_name, age_group, is_free }, index) => (
                        <Table.Row key={id_application}>
                            <Table.Cell>
                                {id_application}
                            </Table.Cell>
                            <Table.Cell>
                                {workout_name}
                            </Table.Cell>
                            <Table.Cell>
                                {moment(date).format('DD. MM. YYYY H:mm')}
                            </Table.Cell>
                           
                            <Table.Cell>
                                {!is_free ? total_price : <span className='bubble'>Zkušební</span>}
                            </Table.Cell>

                            <Table.Cell>
                                {moment(created).format('DD. MM. YYYY')}
                            </Table.Cell>

                            <Table.Cell className='text-center'>
                                <StateIcon state={paid} />
                            </Table.Cell>

                            <Table.Cell className='text-center'>
                                {state !== 2 && <StateIcon state={state} />}
                                {state === 2 && <p className='bubble'>Omluveno</p>}
                                {state === 3 && <p className='bubble grey'>Nahrazeno</p>}
                            </Table.Cell>

                            <Table.Cell className='text-right'>
                                {canChange && state === 2 && this._changeLectureModal(type, id_signed, age_group, is_free)}
                                {state === 1 && this.deleteModal(id_signed, type)}
                                {state === 3 && <Button disabled icon>Žádné akce</Button>}
                            </Table.Cell>

                        </Table.Row>
                    ))}
                    </Table.Body>
                </Table>
            </div>
        )
    }
}

export default UsersEventsTable