
import React, { Component } from 'react'
import EmbedNavigation from '../../containers/EmbedNavigation'
import { Container, Row, Col } from 'reactstrap'
import { Accordion, Icon } from 'semantic-ui-react'
import ApplicationAccordion from '../../containers/tours/ApplicationAccordion'
import { getPublicToursList, createApplication } from '../../../api/tours'
import ApplicationSummary from '../../containers/tours/ApplicationSummary'
import firebase from 'firebase'
import { connect } from 'react-redux'
import { saveClubUser } from '../../../actions/userActions'
import { setMessage } from '../../../actions/messageActions'
import Loading from '../../containers/Loading'
import HeaderApplications from '../../containers/club/HeaderApplications'
import ServicesBlocks from '../../containers/applications/ServicesBlocks'

class TourApplicationView extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            toursList: [],
            saved: {
                parents: [
                    {
                        first_name: '',
                        last_name: '',
                        email: '',
                        phone: '',
                        address: '',
                        city: '',
                        post_code: ''
                    }
                ],
                kids: [
                    {
                        first_name: '',
                        last_name: '',
                        birthday: '',
                        health_issues: ''
                    }
                ],
                terms: false,
                newsletter: false,
                gdpr: false,
                note: '',
                tour: '',
                id_organization: ''
            },
            selectedTour: {},
            isLogged: false,
            loading: true
        })
    }

    componentDidMount = () => {

        const { loadingUser } = this.props
        const { selected } = this.props.match.params

        this._getPublicToursList()
        this.checkUser()

        if(this.state.isLogged && !loadingUser) {
            this.setUser()
        }

        document.body.classList.add('application')

    }

    componentDidUpdate(prevProps) {
        const { loadingUser } = this.props

        if(prevProps.loadingUser !== loadingUser && !loadingUser) {
            this.setUser()
        }
    }

    checkUser = () => {
        firebase.auth().onAuthStateChanged((user) => {
            if(user) {
                firebase.auth().currentUser.getIdTokenResult()
                .then((idTokenResult) => {
                    if (!idTokenResult.claims.isAdmin) {
                        this.setState({
                            isLogged: true
                        })
                    }
                })
            } else {
                this.setState({
                    loading: false
                })
            }
        })
    }

    setUser = () => {

        const { user } = this.props

        const loggedParent = [
            {
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
                phone: user.phone,
                address: user.address,
                city: user.city,
                post_code: user.post_code
            }
        ]

        this.setState({
            saved: {
                ...this.state.saved,
                parents: loggedParent
            },
            loading: false
        })
    }

    _getPublicToursList = () => {
        getPublicToursList()
        .then((res) => {
            if(res.code === 1) {
                this.setState({
                    toursList: res.data
                })
            }
        })
    }

    saveData = (data) => {
        this.setState({
            saved: data
        })
    }

    saveSelected = (data) => {
        this.setState({
            selectedTour: data
        })
    }

    handleCheckbox = (e, data) => {
        this.setState({
            saved: {
                ...this.state.saved,
                [data.name]: data.checked
            } 
        })
    }

    submit = () => {

        this.setState({
            loadingData: true
        })

        createApplication(this.state.saved)
        .then((res) => {
            this.props.history.push(`${process.env.PUBLIC_URL}/prihlaska/zajezdy/done/potvrzeni/${res.link}`)
        })
    }

    accordion = () => {
        return <this.ToursContent />
    }

    content = () => {

        const { selected, type } = this.props.match.params
        const { saved, selectedTour, isLogged, loading } = this.state

        return (
            <Row>
                <Col sm={8}>
                    <ApplicationAccordion 
                        toursList={this.state.toursList} 
                        selected={selected}
                        saveData={this.saveData} 
                        saveSelected={this.saveSelected} 
                        isLogged={isLogged} 
                        user={isLogged ? this.props.user : null} 
                        loading={loading}
                    />
                </Col>
                <Col sm={4}>
                    <ApplicationSummary 
                        email={saved.parents[0].email} 
                        first_name={saved.parents[0].first_name} 
                        last_name={saved.parents[0].last_name}
                        phone={saved.parents[0].phone}
                        count_adults={saved.parents.length}
                        count_kids={saved.kids.length}
                        payment_deposit_kids={selectedTour.tour_payment_deposit_kids}
                        payment_final_kids={selectedTour.tour_payment_final_kids}
                        payment_deposit_adults={selectedTour.tour_payment_deposit_adults}
                        payment_final_adults={selectedTour.tour_payment_final_adults}
                        loading={loading}
                        handleCheckbox={this.handleCheckbox}
                        terms={this.state.saved.terms}
                        gdpr={this.state.saved.gdpr}
                        newsletter={this.state.saved.newsletter}
                        submit={this.submit}
                    />
                </Col>
            </Row>
        )
    }

    render() {

        const { utm, selected } = this.props.match.params
        const { saved, selectedTour, isLogged, loading } = this.state

        return (
            <>
            <HeaderApplications hasRoute={true} route={'Přihláška na zájezd'} backlink={'/prihlaska'} />
            <Container className='mt-5 mb-5'>
                {!loading ?
                this.content()
                :
                <Loading inEmbed={true} />
                }        
            </Container>
            <div className='other_services'>
                <Container>
                    <h2 className='text-center'>Vyberte si z naší nabídky</h2>
                    <ServicesBlocks />
                </Container>
            </div>
            </>
        )
    }

}

const mapStateToProps = state => ({
    user: state.user.user,
    loadingUser: state.user.loading
})
  
export default connect(mapStateToProps, { setMessage, saveClubUser })(TourApplicationView)