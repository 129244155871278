import React from 'react'
import { NoDataContainer } from '../../../styles'

const NoReservations = ({ text }) => {
    return (
        <NoDataContainer>
            <p>{text}</p>
        </NoDataContainer>
    )
}

export default NoReservations