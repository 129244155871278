import React, { Component } from 'react'
import { FormButtonGroup, FormSection, FormSectionBody, FormSectionHead, VoucherType } from '../../../styles'
import { getVoucherTypes } from '../../../api'
import { Row, Col } from 'reactstrap'

class VoucherSelectForm extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            vouchers: [],
            selected: '',
            loadingVouchers: true
        })
    }

    componentDidMount() {
        this.getVoucherTypesHandler()
    }

    getVoucherTypesHandler = () => {
        getVoucherTypes()
        .then((data) => {
            this.setState({
                vouchers: data.data,
                loadingVouchers: false
            })
        })
    }

    selectVoucher = (id) => {
        this.setState({
            selected: id
        }, () => {
            const data = this.state.vouchers.filter(i => i.id_voucher_type === id)
            this.props.selectVoucher(data)
        })
    }

    _renderVouchers = () => {
        return this.state.vouchers.map((voucher, index) => {
            return (
                <VoucherType key={index} selected={this.state.selected === voucher.id_voucher_type ? true : false} onClick={() => this.selectVoucher(voucher.id_voucher_type)}>
                    <Row>
                        <Col sm={8}>
                            <h2><span className="voucher_value">Hodnota voucheru</span>{voucher.value},- Kč</h2>
                            <p>{voucher.description}</p>
                        </Col>
                        <Col sm={4} className='voucher_price_container'>
                            <h2><span className="voucher_value">Cena</span>{voucher.price},- Kč</h2>
                        </Col>
                    </Row>
                    
                </VoucherType>
            )
        })
    }

    render() {
        return (
            <FormSection>
                <FormSectionHead>
                    <h5>Vyberte voucher</h5>
                </FormSectionHead>
                <FormSectionBody>
                    {!this.state.loadingVouchers && this._renderVouchers()}
                </FormSectionBody>
            </FormSection>
        )
    }

}

export default VoucherSelectForm