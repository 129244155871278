import React, { Component } from 'react'
import { FormButtonGroup, FormSection, FormSectionBody, FormSectionHead, FormSummary, FormConfirm } from '../../../styles'
import { Row } from 'reactstrap'
import { Form, Button, Checkbox, Message } from 'semantic-ui-react'
import { createVoucherOrder } from '../../../api'

class VoucherConfirmForm extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            selected: {
                id_voucher_type: 'not-selected',
                price: 0
            },
            buyer_info: {
                buyer_email: '',
                buyer_name: '',
                buyer_phone: '',
            },
            tos: false,
            loadingForm: true,
            not_selected: false,
            success_order: false,
            payment_link: ''
        })
    }

    componentDidMount() {
        this.setState({
            loadingForm: false
        })
    }

    componentDidUpdate(prevProps) {
        if(prevProps !== this.props) {
            this.setState({
                loadingForm: true
            }, () => {
                this.setState({
                    selected: this.props.selectedVoucher[0],
                    loadingForm: false
                })
            })
        }
    }

    onSubmit = () => {

        this.setState({
            loadingForm: true
        })

        if(this.state.buyer_info.buyer_email !== '' && this.state.buyer_info.buyer_name !== '' && this.state.buyer_info.buyer_phone !== '' && this.state.selected.id_voucher_type !== 'not-selected') {
            const data = {
                buyer_name: this.state.buyer_info.buyer_name,
                buyer_email: this.state.buyer_info.buyer_email,
                buyer_phone: this.state.buyer_info.buyer_phone,
                total_price: this.state.selected.price,
                id_voucher_type: this.state.selected.id_voucher_type
            }

            createVoucherOrder(data)
            .then((res) => {
                if(res.type === 'success') {
                    this.setState({
                        success_order: true,
                        payment_link: res.payment
                    })
                }

                this.setState({
                    loadingForm: false
                })
            })

        } else if(this.state.selected.id_voucher_type === 'not-selected') {
            this.setState({
                not_selected: true,
                loadingForm: false
            })
        }
    }

    onChange = (e) => this.setState({
        buyer_info: {
            ...this.state.buyer_info,
            [e.target.name]: e.target.value
        }
    })

    handleCheckbox = (e, data) => {
        this.setState({
            tos: data.checked
        })
    }

    render() {
        return (
            <FormSection sticky={true}>
                <FormSectionHead>
                    <h5>Dokončení objednávky</h5>
                </FormSectionHead>
                <FormSectionBody>
                    <Form loading={this.state.loadingForm} onSubmit={this.onSubmit}>
                        <Form.Field>
                            <Form.Input 
                                type="text"
                                name="buyer_name"
                                label="Vaše jméno"
                                required
                                onChange={this.onChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input 
                                type="email"
                                name="buyer_email"
                                label="Váš e-mail"
                                required
                                onChange={this.onChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input 
                                type="text"
                                name="buyer_phone"
                                label="Váš telefon"
                                required
                                onChange={this.onChange}
                            />
                        </Form.Field>
                        <FormSummary>
                            <p>Celkem k zaplacení: {this.state.selected.price},- Kč</p>
                        </FormSummary>
                        <Form.Field>
                            <Checkbox checked={this.state.tos} onChange={this.handleCheckbox} name='tos' label={<label className="small"><a href='https://www.kidsjoy.cz/cz/vseobecne-podminky' target='_blank'>Souhlasím s všeobecnými podmínkami a platebními podmínkami *</a></label>} />
                        </Form.Field>
                        {this.state.not_selected &&
                        <Message negative>
                            <Message.Header>Nevybrali jste voucher</Message.Header>
                            <p>Před odesláním objednávky musíte vybrat hodnotu voucheru, který chcete koupit.</p>
                        </Message>
                        }
                        <Button primary disabled={!this.state.tos}>Odeslat objednávku</Button>
                    </Form>
                    {this.state.success_order &&
                        <FormConfirm>
                            <div>
                                <h2>Děkujeme za vaši objednávku.</h2>
                                <p>Věnujte prosím pozornost emailu, který vám byl odeslán.</p>
                                <Button as="a" target="_blank" primary href={this.state.payment_link}>Zaplatit voucher</Button>
                            </div>
                        </FormConfirm>
                    }
                </FormSectionBody>
            </FormSection>
        )
    }

}

export default VoucherConfirmForm