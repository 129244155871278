import React, { Component } from 'react'
import { Form, Button } from 'semantic-ui-react'
import { FormButtonGroup } from '../../../styles'
import { getCoupon, getRecommend } from '../../../api'

class AdditionalInfoForm extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            data: {
                note: '',
                coupon: false,
                coupon_value: 0,
                id_coupon: ''
            }
        })
    }

    onChange = (e) => this.setState({
        data: {
            ...this.state.data,
            [e.target.name]: e.target.value
        }
    })

    _submit = () => {
        this.props.submit(this.state.data)
    }

    onChangeCoupon = (e) => {
        getCoupon(e.target.value)
        .then((res) => {
            if(res.isCoupon) {
                this.setState({
                    data: {
                        ...this.state.data,
                        coupon: true,
                        coupon_value: res.coupon_value,
                        id_coupon: res.id_coupon
                    }
                })
            } else {
                this.setState({
                    data: {
                        ...this.state.data,
                        coupon: false,
                        coupon_value: '',
                        id_coupon: null
                    }
                })
            }
        })
    }

    render() {
        return (
            <Form>
                <Form.Field>
                    <Form.Input 
                        type='text'
                        name='coupon'
                        id='coupon'
                        label='Slevový kód'
                        placeholder='Slevový kód'
                        onChange={this.onChangeCoupon}
                    />
                </Form.Field>
                <Form.Field>
                    <Form.TextArea
                        name='note'
                        label='Poznámka k přihlášce' 
                        value={this.state.data.note} 
                        placeholder='Poznámka k přihlášce'
                        onChange={this.onChange}
                    />
                </Form.Field>
                <FormButtonGroup>
                    <Button className='btn_end' primary onClick={this._submit}>Uložit informace</Button>
                </FormButtonGroup>
            </Form>
        )
    }
}

export default AdditionalInfoForm