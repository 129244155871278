import React, { Component } from 'react'
import { getToken } from '../../../api'
import { getNextLectures } from '../../../api/lecturers'
import Loading from '../../containers/Loading'
import { Card, CardHeader, CardBody } from '../../../styles'
import NoReservations from '../../containers/club/NoReservations'
import NextLectures from '../../containers/lecturers/NextLectures'

class DashboardLecturersView extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            loading: true,
            data: [],
            hasData: false
        })
    }

    Content = () => {
        return (
            <>
            <NextLectures type='moms' />
            <NextLectures type='kids' />
            <NextLectures type='hobby' />
            </>
        )
    }

    render() {
        return (
            <this.Content />
        )
    }
}

export default DashboardLecturersView