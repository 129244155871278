import React, { Component } from 'react'
import { getLocations, getToken } from '../../api'
import { LocationsTable } from '../containers'
import { AreYouSureModal } from '../modals'
import { Card, CardControls, CardHeader, Button } from '../../styles'
import { Link } from 'react-router-dom'
import _ from 'loadsh'
import { Dropdown, Input } from 'semantic-ui-react'
import { connect } from 'react-redux'

const stateOptions = [
    {key: '1', value: null, text: 'Vše'},
    {key: '2', value: 0, text: 'Nezveřejněno'},
    {key: '3', value: 1, text: 'Zveřejněno'}
]

class LocationsView extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            locations: [],
            baseData: [],
            column: 'id_location',
            direction: 'descending',
            hasSome: false,
            filter: false
        })
    }

    componentDidMount() {
        if(!this.props.loadingUser) {
            this.getLocationsHandler()
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.loadingUser !== this.props.loadingUser) {
            this.getLocationsHandler()
        }
    }

    getLocationsHandler = () => {

        const { user } = this.props

        getToken().then((token) => {
            const data = {
                token: token,
                id_org: user.id_organization,
                limit: 100,
                offset: 0
            }

            console.log(data)

            getLocations(data)
            .then((res) => {

                if(res.code === 1) {
                    if(_.keys(this.state.filters).length !== 0) {
                        this.setState({
                            locations: _.filter(res.data, this.state.filters),
                            baseData: res.data,
                            hasSome: true,
                            loading: false
                        })
                    } else {
                        this.setState({
                            locations: res.data,
                            baseData: res.data,
                            hasSome: true,
                            loading: false
                        })
                    }
                } else {
                    this.setState({
                        hasSome: false,
                        loading: false
                    })
                }
            })
            .catch((err) => {
                console.log(err)
            })
        })
    }

    handleSort = (clickedColumn) => () => {
        const { column, locations, direction } = this.state
    
        if (column !== clickedColumn) {
          this.setState({
            column: clickedColumn,
            locations: _.sortBy(locations, [clickedColumn]),
            direction: 'ascending',
          })
    
          return
        }
    
        this.setState({
          data: locations.reverse(),
          direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    }

    onChangeSelect = (e, data) => {
        if(data.value !== null) {
            this.setState({
                filter: true,
                filters: {
                    ...this.state.filters,
                    [data.name]: data.value
                }
            }, () => {
                this.setState({
                    locations: _.filter(this.state.baseData, this.state.filters)
                })
            })
        } else {
            this.setState({
                filters: _.omit(this.state.filters, [data.name])
            }, () => {
                this.setState({
                    locations: _.keys(this.state.filters).length !== 0 ? _.filter(this.state.baseData, this.state.filters) : this.state.baseData,
                    filter: _.keys(this.state.filters).length !== 0 ? true : false
                })
            })
        }
    }

    render() {
        return (
            <Card>
                <CardHeader>
                    <div className='row'>
                        <div className='col-sm-9 text-left'>
                            <h2>Lokality</h2>
                        </div>
                        <div className='col-sm-3 text-right'>
                            <Link to={`${process.env.PUBLIC_URL}/admin/manage/locations/edit/new`}><Button icon="\efc2">Nový</Button></Link>
                        </div>
                    </div>
                </CardHeader>
                <CardControls>
                    <div className='row'>
                        <div className='col-sm-6 text-left'>
                            <Input 
                                placeholder='Hledat...'
                                fluid
                                icon='search'
                            />
                        </div>
                        <div className='col-sm-6 text-left'>
                            <Dropdown
                                name='location_state'
                                options={stateOptions}
                                placeholder='Vyberte stav'
                                selection
                                onChange={this.onChangeSelect}
                                fluid
                            />
                        </div>
                    </div>
                </CardControls>
                <LocationsTable items={this.state.locations} refresh={this.getLocationsHandler} sort={this.handleSort} column={this.state.column} direction={this.state.direction} />
            </Card>
        )
    }

}

const mapStateToProps = state => ({
    user: state.user.user,
    loadingUser: state.user.loading
})
  
export default connect(mapStateToProps, {})(LocationsView)