import React, { Component } from 'react'
import { intToBool, toggleCampState, shortText } from '../../api'
import { AreYouSureModal } from '../modals'
import { Link } from 'react-router-dom'
import { Table } from 'semantic-ui-react'
import _ from 'loadsh'

class CampsTable extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            showModal: false,
            idToDelete: 2
        })
    }

    toggleStateHandler = (id) => {
        toggleCampState(id)
        .then(() => {
            this.props.refresh()
        })
    }

    toggleModal = (id) => {
        this.setState({
            showModal: !this.state.showModal,
            idToDelete: !this.state.showModal ? id : ''
        })
    }

    render() {

        const { items, sort, column, direction } = this.props

        return (
            <div>
                <Table sortable striped className='main-table'>
                    <Table.Header>
                        <Table.Row>

                            <Table.HeaderCell
                                sorted={column === 'id_camp' ? direction : null}
                                onClick={sort('id_camp')}
                            >
                                #
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'camp_name' ? direction : null}
                                onClick={sort('camp_name')}
                            >
                                Název
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'camp_description' ? direction : null}
                                onClick={sort('camp_description')}
                            >
                                Popis
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'organization_name' ? direction : null}
                                onClick={sort('organization_name')}
                            >
                                Organizace
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'camp_state' ? direction : null}
                                onClick={sort('camp_state')}
                            >
                                Stav
                            </Table.HeaderCell>

                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>
                <Table.Body>
                {_.map(items, ({ id_camp, camp_name, camp_description, organization_name, camp_state }) => (
                    <Table.Row key={id_camp}>
                        <Table.Cell>
                            {id_camp}
                        </Table.Cell>
                        <Table.Cell>
                            {camp_name}
                        </Table.Cell>
                        <Table.Cell>
                            {shortText(camp_description, 60)}
                        </Table.Cell>
                        <Table.Cell>
                            {shortText(organization_name, 16)}
                        </Table.Cell>
                        <Table.Cell>
                            {intToBool(camp_state) ? <i class="icofont-check-circled icon-state" onClick={() => this.toggleStateHandler(id_camp)}></i> : <i class="icofont-close-circled icon-state" onClick={() => this.toggleStateHandler(id_camp)}></i>}
                        </Table.Cell>
                        <Table.Cell>
                            <i class="icofont-trash" onClick={() => this.toggleModal(id_camp)}></i>
                        </Table.Cell>
                        <Table.Cell>
                            <Link to={`${process.env.PUBLIC_URL}/admin/manage/camps/edit/` + id_camp}><i class="icofont-ui-edit"></i></Link>
                        </Table.Cell>
                    </Table.Row>
                ))}
                </Table.Body>
            </Table>

            <AreYouSureModal show={this.state.showModal} toggle={this.toggleModal} id={this.state.idToDelete} refresh={this.props.refresh}/>

            </div>
        )
    }
}

export default CampsTable