import React, { Component } from 'react'
import { getOffersWidget } from '../../../api/offers'
import OffersItemSwiper from '../../containers/offers/OffersItemSwiper'
import { Row } from 'reactstrap'
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css'

class EmbedOffers extends Component {
    constructor(props) {
        super(props)

        this.state = ({
            items: [],
            loading: true,
            hasData: false
        })
    }

    componentDidMount() {
        this._getOffers()
        
        document.body.classList.add('application', 'transparent', 'offers')
    }

    _getOffers = () => {
        getOffersWidget()
        .then((res) => {
            if(res.code === 1) {
                this.setState({
                    items: res.data,
                    loading: false,
                    hasData: true
                })
            } else {
                this.setState({
                    items: [],
                    hasData: false,
                    loading: false
                })
            }
        })
    }

    displayData = () => {
        return this.state.items.map((item, index) => {
            return (
                <div key={index}><OffersItemSwiper item={item} /></div>
            )
        })
    }

    render() {
        const params = {
            slidesPerView: 4,
            spaceBetween: 30,
            autoplay: true,
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 20
                },
                480: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                767: {
                    slidesPerView: 3,
                    spaceBetween: 20
                },
                // when window width is <= 1024px
                1024: {
                    slidesPerView: 4,
                    spaceBetween: 30
                }
            },
        }

        return (
            <>
                <Swiper {...params} shouldSwiperUpdate>
                    {this.displayData()}
                </Swiper>
            </>
        )
    }
}

export default EmbedOffers