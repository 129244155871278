import styled from 'styled-components'

export const Section = styled.div`
    width: 100%;
    margin-bottom: 3rem;
`

export const StateIcon = styled.div`
    ::before {
        content: "\\eedd";
        display: inline-block;
        animation: spin 5s infinite;
    }

    font-family: IcoFont!important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    line-height: 1;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;

    @keyframes spin {
        from {transform:rotateZ(0deg);}
        to {transform:rotateZ(360deg);}
    }
`