import React, { Component } from 'react'
import { FormButtonGroup, FormSection, FormSectionBody, FormSectionHead, FormSummary, FormConfirm } from '../../../styles'
import { Form, Button, Checkbox, Message } from 'semantic-ui-react'
import { Row, Col } from 'reactstrap'

class ApplicationSummary extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            loadingForm: false,
            price: 120
        })
    }

    render() {

        const { first_name, last_name, email, phone, loading, terms, newsletter, handleCheckbox, submit, eventItem, gdpr, selected } = this.props

        return (
            <FormSection sticky={false}>
                <FormSectionHead>
                    <h5>Dokončení objednávky</h5>
                </FormSectionHead>
                <FormSectionBody>
                    <Form loading={this.state.loadingForm} loading={loading} onSubmit={submit}>
                        <Form.Field>
                            <Form.Input
                                type='text'
                                name='customer_name'
                                label='Vaše jméno'
                                value={first_name ? `${first_name} ${last_name}` : ''}
                                placeholder='Bude automaticky vyplněno'
                                disabled
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input
                                type='email'
                                name='customer_email'
                                label='Váš email'
                                value={`${email}`}
                                placeholder='Bude automaticky vyplněno'
                                disabled
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input
                                type='text'
                                name='customer_phone'
                                label='Váš telefon'
                                value={`${phone}`}
                                placeholder='Bude automaticky vyplněno'
                                disabled
                            />
                        </Form.Field>
                        <FormSummary>
                            <p>K zaplacení: {selected.price_per_lecture * selected.num_of_lectures},- Kč</p>
                        </FormSummary>
                        <Form.Field>
                            <Checkbox checked={terms} onChange={handleCheckbox} name='terms' label={<label className="small"><a href='https://www.1fit.cz/vseobecne-podminky' target='_blank'>Souhlasím s všeobecnými podmínkami a platebními podmínkami *</a></label>} />
                        </Form.Field>
                        <Form.Field>
                            <Checkbox checked={gdpr} onChange={handleCheckbox} name='gdpr' label={<label className="small"><a href='https://www.1fit.cz/zasady-zpracovani-a-ochrany-osobnich-udaju' target='_blank'>Souhlasím se zpracováním osobních údajů</a></label>} />
                        </Form.Field>
                        <Form.Field>
                            <Checkbox checked={newsletter} onChange={handleCheckbox} name='newsletter' label='Souhlasím se zasíláním newsletteru' />
                        </Form.Field>
                        <Button primary={eventItem.is_full ? false : true} className={eventItem.is_full && 'red'} disabled={!terms && !gdpr}>{eventItem.is_full ? 'Přihlásit jako náhradník' : 'Odeslat objednávku'}</Button>
                    </Form>
                    {this.state.success_order &&
                        <FormConfirm>
                            <div>
                                <h2>Děkujeme za vaši objednávku.</h2>
                                <p>Věnujte prosím pozornost emailu, který vám byl odeslán.</p>
                                <Button as="a" target="_blank" primary href={this.state.payment_link}>Zaplatit permanentku</Button>
                            </div>
                        </FormConfirm>
                    }
                </FormSectionBody>
            </FormSection>
        )
    }

}

export default ApplicationSummary