import React, { Component } from 'react'
import { FormButtonGroup, FormSection, FormSectionBody, FormSectionHead, FormSummary, FormConfirm } from '../../../styles'
import { Form, Button, Checkbox, Message } from 'semantic-ui-react'
import { Row, Col } from 'reactstrap'

class ApplicationSummary extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            loadingForm: false,
            price: {
                total_deposit: 0,
                total_final: 0
            }
        })
    }

    componentDidMount() {
        const { count_adults, count_kids, payment_deposit_kids, payment_final_kids, payment_deposit_adults, payment_final_adults } = this.props
        this.countTourPrice(payment_deposit_kids, payment_final_kids, payment_deposit_adults, payment_final_adults, count_kids, count_adults)
    }

    componentDidUpdate(prevProps) {
        if(prevProps !== this.props) {
            const { count_adults, count_kids, payment_deposit_kids, payment_final_kids, payment_deposit_adults, payment_final_adults } = this.props
            this.countTourPrice(payment_deposit_kids, payment_final_kids, payment_deposit_adults, payment_final_adults, count_kids, count_adults)
        }
    }

    countTourPrice = (deposit_kids, final_kids, deposit_adults, final_adults, num_kids, num_adults) => {
        var total_deposit_kids = deposit_kids * num_kids
        var total_final_kids = (deposit_kids + final_kids) * num_kids
        var total_deposit_adults = deposit_adults * num_adults
        var total_final_adults = (deposit_adults + final_adults) * num_adults

        const data = {
            total_deposit: total_deposit_adults + total_deposit_kids,
            total_final: total_deposit_kids + total_deposit_adults + total_final_kids + total_final_adults
        }

        this.setState({
            price: data
        })
    }

    render() {

        const { first_name, last_name, email, phone, count_adults, count_kids, loading, terms, newsletter, handleCheckbox, submit, gdpr } = this.props

        return (
            <FormSection sticky={false}>
                <FormSectionHead>
                    <h5>Dokončení objednávky</h5>
                </FormSectionHead>
                <FormSectionBody>
                    <Form loading={this.state.loadingForm} loading={loading} onSubmit={submit}>
                        <Form.Field>
                            <Form.Input
                                type='text'
                                name='customer_name'
                                label='Vaše jméno'
                                value={first_name ? `${first_name} ${last_name}` : ''}
                                placeholder='Bude automaticky vyplněno'
                                disabled
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input
                                type='email'
                                name='customer_email'
                                label='Váš email'
                                value={email ? `${email}` : ''}
                                placeholder='Bude automaticky vyplněno'
                                disabled
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input
                                type='text'
                                name='customer_phone'
                                label='Váš telefon'
                                value={phone ? `${phone}` : ''}
                                placeholder='Bude automaticky vyplněno'
                                disabled
                            />
                        </Form.Field>
                        <Row className='mb-3'>
                            <Col sm={6}>
                                <Form.Field>
                                    <Form.Input
                                        type='text'
                                        name='count_adults'
                                        label='Počet dospělých'
                                        value={`${count_adults}`}
                                        placeholder='Bude automaticky vyplněno'
                                        disabled
                                    />
                                </Form.Field>
                            </Col>
                            <Col sm={6}>
                                <Form.Field>
                                    <Form.Input
                                        type='text'
                                        name='count_kids'
                                        label='Počet dětí'
                                        value={`${count_kids}`}
                                        placeholder='Bude automaticky vyplněno'
                                        disabled
                                    />
                                </Form.Field>
                            </Col>
                        </Row>
                        
                        <FormSummary>
                            <p>Záloha k zaplacení: {this.state.price.total_deposit},- Kč</p>
                            <p className='small'>Celková částka: {this.state.price.total_final},- Kč</p>
                        </FormSummary>
                        <Form.Field>
                            <Checkbox checked={terms} onChange={handleCheckbox} name='terms' label={<label className="small"><a href='https://www.1fit.cz/vseobecne-podminky' target='_blank'>Souhlasím s všeobecnými podmínkami a platebními podmínkami *</a></label>} />
                        </Form.Field>
                        <Form.Field>
                            <Checkbox checked={gdpr} onChange={handleCheckbox} name='gdpr' label={<label className="small"><a href='https://www.1fit.cz/zasady-zpracovani-a-ochrany-osobnich-udaju' target='_blank'>Souhlasím se zpracováním osobních údajů</a></label>} />
                        </Form.Field>
                        <Form.Field>
                            <Checkbox checked={newsletter} onChange={handleCheckbox} name='newsletter' label='Souhlasím se zasíláním newsletteru' />
                        </Form.Field>
                        <Button primary disabled={!terms && !gdpr}>Odeslat rezervaci</Button>
                    </Form>
                </FormSectionBody>
            </FormSection>
        )
    }

}

export default ApplicationSummary