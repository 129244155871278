import React from 'react'
import { Form, Button, Dropdown } from 'semantic-ui-react'
import { FormButtonGroup } from '../../styles'

const options = [
    { key: 'no', value: 0, text: 'Nezveřejněno' },
    { key: 'yes', value: 1, text: 'Zveřejněno' },
]

const DateSecondaryForm = ({ date, onChangeSelect, loading, isNew, submit }) => {

    return (
        <Form loading={loading} onSubmit={submit}>
            <Form.Field>
                <label>Stav</label>
                <Dropdown 
                    name='date_state'
                    placeholder='Vyberte možnost'
                    options={options}
                    value={date.date_state}
                    onChange={onChangeSelect}
                    selection
                    className={'state-active-' + date.date_state}
                />
            </Form.Field>

            <FormButtonGroup>
                <Button primary>Uložit změny</Button>
            </FormButtonGroup>
        </Form>
    )
}

export default DateSecondaryForm