import React, { Component } from 'react'
import { intToBool, toggleLocationState, shortText, getToken } from '../../../api'
import { Table, Icon, Button, Popup } from 'semantic-ui-react'
import _ from 'loadsh'
import moment from 'moment'
import { StateIcon } from '../../../styles'
import { Link } from 'react-router-dom'

class PrepaidsAdminTable extends Component {

    constructor(props) {
        super(props)
        this.state = ({})
    }

    render() {

        const { items, column, direction, sort, type } = this.props

        return (
            <div className='admin_table_wrapper'>
                <Table sortable striped className='main-table select_table' unstackable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell
                                sorted={column === 'id_prepaid' ? direction : null}
                                onClick={sort('id_prepaid')}
                                width={1}
                            >
                                #
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'prepaid_name' ? direction : null}
                                onClick={sort('prepaid_name')}
                                width={3}
                                className='mw_300'
                            >
                                Název
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'workout_name' ? direction : null}
                                onClick={sort('workout_name')}
                                width={5}
                                className='mw_300'
                            >
                                Lekce
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'price_per_lecture' ? direction : null}
                                onClick={sort('price_per_lecture')}
                                width={1}
                            >
                                Cena za lekci
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'num_of_lectures' ? direction : null}
                                onClick={sort('num_of_lectures')}
                                width={3}
                            >
                                Počet lekcí
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'valid_months' ? direction : null}
                                onClick={sort('valid_months')}
                                width={3}
                            >
                                Platnost (měs.)
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'state' ? direction : null}
                                onClick={sort('state')}
                                width={1}
                            >
                                Stav
                            </Table.HeaderCell>

                            <Table.HeaderCell width={3}></Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                    {_.map(items, ({ id_prepaid, workout_name, prepaid_name, price_per_lecture, num_of_lectures, valid_months, state }, index) => (
                        <Table.Row key={id_prepaid}>
                            <Table.Cell>
                                {id_prepaid}
                            </Table.Cell>
                            <Table.Cell>
                                {prepaid_name}
                            </Table.Cell>
                            <Table.Cell>
                                {workout_name}
                            </Table.Cell>
                            <Table.Cell>
                                {price_per_lecture},- Kč
                            </Table.Cell>
                            <Table.Cell>
                                {num_of_lectures}
                            </Table.Cell>
                            <Table.Cell>
                                {valid_months}
                            </Table.Cell>
                            <Table.Cell className='text-center'>
                                <StateIcon state={state} />
                            </Table.Cell>
                            <Table.Cell className='text-right'>
                                <Link to={'/admin/manage/workouts/'+ type +'/prepaids/edit/' + id_prepaid}><Button icon><Icon name='edit' /></Button></Link>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                    </Table.Body>
                </Table>
            </div>
        )
    }
}

export default PrepaidsAdminTable