import * as types from '../actions/types'

let message = { items: [] }

const messages = (state = message, action) => {
    switch (action.type) {
        case types.SET_MESSAGE:
            return { 
                ...state,
                items: [...state.items, action.items]
           }

        case types.UNSET_MESSAGE:
            const messageItemRemove = state.items.find(i => i.id === action.id)
            return { 
                ...state, 
                items: [ ...state.items.filter(i => i !== messageItemRemove) ] 
            };

        default: 
            return state
    }
}

export default messages