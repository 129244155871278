import React, { Component } from 'react'
import { getToken } from '../../../api'
import { getListLecturer, trash } from '../../../api/workouts'
import { connect } from 'react-redux'
import WorkoutsTable from '../../tables/workouts/WorkoutsTable'
import Loading from '../../containers/Loading'
import NoReservations from '../../containers/club/NoReservations'
import { Card, CardBody, CardHeader, CardControls } from '../../../styles'
import _ from 'loadsh'
import { Row, Col } from 'reactstrap'
import { Button, Dropdown } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { setMessage } from '../../../actions/messageActions'
import queryString from 'query-string'
import { getCalendar, getCalendarItem, getLecturesFilter, getGroupOpts, getLocationsOpts } from '../../../api/workouts';
import LecturerWorkoutsTable from '../../tables/lecturers/LecturerWorkoutsTable'

const stateOpts = [
    {
        key: 2,
        value: null,
        text: 'Vše'
    },
    {
        key: 0,
        value: 0,
        text: 'Neaktivní'
    },
    {
        key: 1,
        value: 1,
        text: 'Aktivní'
    }
]

class ListWorkouts extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            items: [],
            loadingData: true,
            hasData: false,
            column: 'id_workout',
            direction: 'descending',
        })
    }

    setCardHeader = (type) => {
        switch (type) {
            case 'moms':
                return 'Seznam lekcí (Cvičení pro maminky)'
            
            case 'kids':
                return 'Seznam lekcí (Cvičení s dětmi)'

            case 'hobby':
                return 'Seznam kroužků'
    
            default: 
                return 'Moje rezervace'
        }
    }

    componentDidMount() {
        const { loadingUser } = this.props

        if(!loadingUser) {
            this._getList()

            this._getLocOpts()

            this._lecturesFilter()

            const parsed = queryString.parse(this.props.location.search);

            var filters = []

            if(parsed.id_location) {
                const data = {
                    name: 'id_location',
                    value: Number(parsed.id_location)
                }
                filters.push(data)
            }

            Object.keys(parsed).forEach(function(el){
                parsed[el] = parseInt(parsed[el])
            })

            this.filterByQuery(parsed)
        }
    }

    componentDidUpdate(prevProps) {

        const { loadingUser } = this.props

        if(prevProps.loadingUser !== loadingUser && !loadingUser) {
            this._getList()

            this._getLocOpts()

            this._lecturesFilter()

            const parsed = queryString.parse(this.props.location.search);

            Object.keys(parsed).forEach((el) => {
                parsed[el] = parseInt(parsed[el])
            })

            this.filterByQuery(parsed)
        }
    }

    _lecturesFilter = () => {
        const { type } = this.props.match.params
        const parsed = queryString.parse(this.props.location.search);

        var group = null;
        if(type === 'kids') {
            if(parsed.group) {
                group = Number(parsed.group)
            }
        }
        getLecturesFilter(type, group)
        .then((res) => {
            this.setState({
                lecturesFilter: res,
                loadingLecturesFilter: false
            })
        })
    }

    filterByQuery = (data) => {
        if(data.length !== 0) {
            this.setState({
                filter: true,
                filters: data
            }, () => {
                this.setState({
                    items: _.filter(this.state.baseData, this.state.filters)
                })
            })
        } else {
            this.setState({
                filters: _.omit(this.state.filters, [data.name])
            }, () => {
                this.setState({
                    items: _.keys(this.state.filters).length !== 0 ? _.filter(this.state.baseData, this.state.filters) : this.state.baseData,
                    filter: _.keys(this.state.filters).length !== 0 ? true : false
                })
            })
        }
    }

    _getList = () => {

        const { user } = this.props
        const { type } = this.props.match.params

        this.setState({
            loadingData: true
        })

        getToken().then((token) => {
            const data = {
                token: token,
                limit: 15,
                offset: 0,
                id_org: user.id_organization,
            }

            getListLecturer(data, type)
            .then((res) => {
                if(res.code === 1) {
                    if(_.keys(this.state.filters).length !== 0) {
                        this.setState({
                            items: _.filter(res.data, this.state.filters),
                            baseData: res.data,
                            hasData: true,
                            loadingData: false
                        })
                    } else {
                        this.setState({
                            items: res.data,
                            baseData: res.data,
                            hasData: true,
                            loadingData: false
                        })
                    }
                } else {
                    this.setState({
                        hasData: false,
                        loadingData: false
                    })
                }
            })
        })
    }

    handleSort = (clickedColumn) => () => {
        const { column, items, direction } = this.state
    
        if (column !== clickedColumn) {
          this.setState({
            column: clickedColumn,
            items: _.sortBy(items, [clickedColumn]),
            direction: 'ascending',
          })
    
          return
        }
    
        this.setState({
            items: items.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    }

    _trash = (id) => {

        const { type } = this.props.match.params

        getToken().then((token) => {
            const data = {
                token: token, 
                id_workout: id
            }

            trash(data, 'lecture', type)
            .then((res) => {
                this._getList()
                this.props.setMessage(res)
            })
        })
    }

    _getLocOpts = () => {

        const { type } = this.props.match.params

        getLocationsOpts(type)
        .then((res) => {
            this.setState({
                locOpts: res,
                loadingLocs: false
            })
        })
    }

    onChangeFilter = (e, data) => {

        const { type } = this.props.match.params

        const parsed = queryString.parse(this.props.location.search);

        const params = {
            ...parsed,
            [data.name]: [data.value] 
        }

        const url = queryString.stringify(params, {
            skipNull: true
        });

        this.props.history.push(`${process.env.PUBLIC_URL}/admin/manage/workouts/${type}/list/?${url}`)

    }

    content = () => {

        const { type } = this.props.match.params
        const { locOpts, lecturesFilter } = this.state
        const parsed = queryString.parse(this.props.location.search)

        return (
            <Card>
                <CardHeader>
                    <Row>
                        <Col sm={9}>
                            {this.setCardHeader(type)}
                        </Col>
                    </Row>
                </CardHeader>
                <CardControls>
                    <Row>
                        <Col sm={3} className='text-right'>
                            <Dropdown 
                                options={locOpts}
                                onChange={this.onChangeFilter}
                                name="id_location"
                                placeholder="Lokalita"
                                selection
                                fluid
                                value={parsed.id_location ? Number(parsed.id_location) : null}
                                search
                            />
                        </Col>
                        <Col sm={3} className='text-right'>
                            <Dropdown 
                                options={lecturesFilter}
                                name='id_workout'
                                onChange={this.onChangeFilter}
                                selection
                                placeholder='Druh lekce'
                                fluid
                                value={parsed.id_workout ? Number(parsed.id_workout) : null}
                                search
                            />
                        </Col>
                        <Col sm={3} className='text-right'>
                            <Dropdown 
                                options={stateOpts}
                                name='state'
                                onChange={this.onChangeFilter}
                                selection
                                placeholder='Stav'
                                fluid
                                value={parsed.state ? Number(parsed.state) : null}
                                search
                            />
                        </Col>
                    </Row>
                </CardControls>
                <LecturerWorkoutsTable items={this.state.items} sort={this.handleSort} column={this.state.column} direction={this.state.direction} type={type} trash={this._trash} />
            </Card>
        )
    }

    render() {
        const { loadingData, hasData } = this.state
        const { type } = this.props.match.params

        if(loadingData) {
            return (
                <Loading />
            )
        } else if(!loadingData && !hasData) {
            return (
                <Card>
                    <CardHeader>
                        {this.setCardHeader(type)}
                    </CardHeader>
                    <CardBody>
                        <NoReservations text='Nemáte přiřazené žádné lekce' />
                    </CardBody>
                </Card>
            )  
        } else {
            return (
                <>
                {this.content()}
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    user: state.user.user,
    loadingUser: state.user.loading
})
  
export default connect(mapStateToProps, { setMessage })(ListWorkouts)