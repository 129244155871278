import React, { Component } from 'react'
import Loading from '../../containers/Loading'
import NoReservations from '../../containers/club/NoReservations'
import { Card, CardHeader, CardControls } from '../../../styles'
import _ from 'loadsh'
import { Row, Col } from 'reactstrap'
import { Button, Dropdown, Form } from 'semantic-ui-react'
import WorkoutsApplicationsTable from '../../tables/workouts/WorkoutsApplicationsTable'
import { connect } from 'react-redux'
import { setMessage } from '../../../actions/messageActions'
import { getApplications, getLecturesFilter, getLocationsOptsAdmin } from '../../../api/workouts'
import { getToken } from '../../../api'
import queryString from 'query-string'
import { url } from '../../../config'

const paidOpts = [
    {
        key: 2,
        value: null,
        text: 'Vše'
    },
    {
        key: 0,
        value: 0,
        text: 'Nezaplaceno'
    },
    {
        key: 1,
        value: 1,
        text: 'Zaplaceno'
    }
]

const activeOpts = [
    {
        key: 0,
        value: 0,
        text: 'Neaktivní'
    },
    {
        key: 1,
        value: 1,
        text: 'Aktivní'
    }
]

const isFreeOpts = [
    {
        key: null,
        value: null,
        text: 'Ne'
    },
    {
        key: 1,
        value: 1,
        text: 'Ano'
    }
]

class ApplicationsListView extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            data: [],
            loadingData: true,
            hasData: false,
            column: 'id_application',
            direction: 'descending',
            qn: ''
        })
    }

    componentDidMount() {
        const { loadingUser, user } = this.props
        const parsed = queryString.parse(this.props.location.search);

        if(!loadingUser) {
            this._getApplications(user.id_organization)
            Object.keys(parsed).forEach((key) => {
                if(key === 'first_name' || key === 'last_name' || key === 'email') {
                    parsed[key] = parsed[key]
                } else {
                    parsed[key] = parseInt(parsed[key])
                }
            })
    
            this.filterByQuery(parsed)
        }
        this._lecturesFilter()
        this._getLocOpts()

        if(parsed.state === undefined) {
            this.setApplicationState()
        }
    }

    componentDidUpdate(prevProps) {
        const { loadingUser, user } = this.props
        const parsed = queryString.parse(this.props.location.search);

        if(prevProps.loadingUser !== loadingUser && !loadingUser) {
            this._getApplications(user.id_organization)

            Object.keys(parsed).forEach((key) => {
                if(key === 'first_name' || key === 'last_name' || key === 'email') {
                    parsed[key] = parsed[key]
                } else {
                    parsed[key] = parseInt(parsed[key])
                }
            })
    
            this.filterByQuery(parsed)
        }
    }

    _getLocOpts = () => {

        const { type } = this.props.match.params

        getLocationsOptsAdmin(type)
        .then((res) => {
            this.setState({
                locOpts: res,
                loadingLocs: false
            })
        })
    }

    setApplicationState = () => {
        const { type } = this.props.match.params
        const parsed = queryString.parse(this.props.location.search);
        const params = {
            ...parsed,
            state: 1
        }
        const url = queryString.stringify(params, {
            skipNull: true
        });
        this.props.history.push(`${process.env.PUBLIC_URL}/admin/manage/workouts/${type}/applications/list/?${url}`)
    }

    _getApplications = (id_org) => {

        this.setState({
            loadingData: true,
            hasData: false
        })

        const { type } = this.props.match.params
        const parsed = queryString.parse(this.props.location.search);

        getToken().then((token) => {
            const data = {
                token: token,
                id_org: id_org,
                limit: 15,
                offset: 0,
                state: Number(parsed.state)
            }

            getApplications(data, type)
            .then((res) => {
                console.log(res)
                if(res.code === 1) {
                    this.setState({
                        hasData: true,
                        loadingData: false,
                        data: _.filter(res.data, this.state.filters),
                        baseData: res.data
                    })
                } else {
                    this.setState({
                        hasData: false,
                        loadingData: false
                    })
                }
            })
        })
        .catch((err) => {
            this.props.setMessage({type: 'error', text: 'Nepodařilo se získat bezpečnostní token, aktualizuji stránku'})
            window.location.reload();
        })
    }

    _lecturesFilter = () => {
        const { type } = this.props.match.params
        const parsed = queryString.parse(this.props.location.search);

        var group = null;
        if(type === 'kids') {
            if(parsed.group) {
                group = Number(parsed.group)
            }
        }
        getLecturesFilter(type, group)
        .then((res) => {
            this.setState({
                lecturesFilter: res,
                loadingLecturesFilter: false
            })
        })
    }

    onChangeSelect = (e, data) => {

        const { type } = this.props.match.params

        const parsed = queryString.parse(this.props.location.search);

        const params = {
            ...parsed,
            [data.name]: [data.value] 
        }

        const url = queryString.stringify(params, {
            skipNull: true
        });

        this.props.history.push(`${process.env.PUBLIC_URL}/admin/manage/workouts/${type}/applications/list/?${url}`)

    }

    filterByQuery = (data) => {
        if(data.length !== 0) {
            this.setState({
                filter: true,
                filters: data
            }, () => {
                this.setState({
                    dates: _.filter(this.state.baseData, this.state.filters)
                })
            })
        } else {
            this.setState({
                filters: _.omit(this.state.filters, [data.name])
            }, () => {
                this.setState({
                    dates: _.keys(this.state.filters).length !== 0 ? _.filter(this.state.baseData, this.state.filters) : this.state.baseData,
                    filter: _.keys(this.state.filters).length !== 0 ? true : false
                })
            })
        }
    }

    getVS = (type) => {
        switch(type) {
            case 'moms':
                return '10'
            case 'kids':
                return '20'
            case 'hobby':
                return '30'
            case 'prepaids':
                return '40'
            case 'tours':
                return '50'
        }
    }

    _submitSearch = (name) => {
        const { type } = this.props.match.params
        const parsed = queryString.parse(this.props.location.search);

        const params = {
            ...parsed,
            [name]: this.state[name] !== '' ? this.state[name] : null
        }

        const url = queryString.stringify(params, {
            skipNull: true
        });

        this.props.history.push(`${process.env.PUBLIC_URL}/admin/manage/workouts/${type}/applications/list/?${url}`)
    }

    onChange = (e) => this.setState({
        [e.target.name]: e.target.value
    })

    exportApplications = () => {
        this.setState({
            loadingExport: true
        })
        getToken().then((token) => {
            
            const { id_organization } = this.props.user
            const { type } = this.props.match.params
            
            window.open(url.api + 'export/export-all-applications/' + token + '/' + type + '/' + id_organization, "_blank")

            this.setState({
                loadingExport: false
            })
        })
    }

    exportAll = () => {
        this.setState({
            loadingExport: true
        })
        getToken().then((token) => {
            
            const { id_organization } = this.props.user
            const { type } = this.props.match.params
            
            window.open(url.api + 'export/export-all/' + token + '/' + type, "_blank")

            this.setState({
                loadingExport: false
            })
        })
    }

    _renderExportDropdown = () => {
        return (
            <Dropdown
                text='Export'
                icon='download'
                floating
                labeled
                button
                className='icon'
                loading={this.state.loadingExport}
            >
                <Dropdown.Menu>
                    <Dropdown.Header icon='file outline' content='Typ exportu' />
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={this.exportApplications} icon='list' text='Všechny přihlášky' />
                    {this.props.user.user_admin === 1 && <Dropdown.Item onClick={this.exportAll} icon='list' text='Komplet export' />}
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    content = () => {
        const { hasData, loadingData, data, column, direction, lecturesFilter, locOpts } = this.state
        const { type } = this.props.match.params
        const { user } = this.props
        const parsed = queryString.parse(this.props.location.search);

        return (
            <Card>
                <CardHeader>
                    <Row>
                        <Col sm={9} className='col-6'>
                            Přihlášky na cvičení
                        </Col>
                        <Col sm={3} className='text-right col-6'>
                            {this._renderExportDropdown()}
                        </Col>
                    </Row>
                </CardHeader>
                <CardControls>
                    <Row className="mb-3">
                        <Col sm={3}>
                            <label className='lbl_filter'>Lokalita</label>
                            <Dropdown 
                                options={locOpts}
                                onChange={this.onChangeSelect}
                                name="id_location"
                                placeholder="Lokalita"
                                selection
                                fluid
                                value={parsed.id_location ? Number(parsed.id_location) : null}
                                search
                            />
                        </Col>
                        <Col sm={3}>
                            <label className='lbl_filter'>Druh cvičení</label>
                            <Dropdown
                                name='id_workout'
                                options={lecturesFilter}
                                placeholder='Vyberte cvičení'
                                selection
                                onChange={this.onChangeSelect}
                                value={parsed.id_workout ? Number(parsed.id_workout) : null}
                                fluid
                                search
                            />
                        </Col>
                        <Col sm={3}>
                            <label className='lbl_filter'>Stav platby</label>
                            <Dropdown
                                name='paid'
                                options={paidOpts}
                                placeholder='Zaplaceno / nezaplaceno'
                                selection
                                onChange={this.onChangeSelect}
                                value={parsed.paid ? Number(parsed.paid) : null}
                                fluid
                            />
                        </Col>
                        <Col sm={3}>
                            <label className='lbl_filter'>Pouze zkušební lekce</label>
                            <Dropdown
                                name='is_free'
                                options={isFreeOpts}
                                placeholder='Pouze zkušební lekce'
                                selection
                                onChange={this.onChangeSelect}
                                value={parsed.is_free ? Number(parsed.is_free) : null}
                                fluid
                                className={parsed.is_free ? 'state-active-' + parsed.is_free : 'none'}
                            />
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col sm={3}>
                            <Form onSubmit={() => this._submitSearch('email')}>
                                <Form.Input 
                                    type="text"
                                    label="Hledat podle emailu"
                                    name="email"
                                    defaultValue={parsed.email ? parsed.email : null}
                                    onChange={this.onChange}
                                    placeholder='Po zadání stiskněte enter'
                                />
                            </Form>
                        </Col>
                        <Col sm={3}>
                            <Form onSubmit={() => this._submitSearch('last_name')}>
                                <Form.Input 
                                    type="text"
                                    label="Hledat podle příjmení"
                                    name="last_name"
                                    defaultValue={parsed.last_name ? parsed.last_name : null}
                                    onChange={this.onChange}
                                    placeholder='Po zadání stiskněte enter'
                                />
                            </Form>
                        </Col>
                        <Col sm={3}>
                            <label className='lbl_filter'>Stav přihlášky</label>
                            <Dropdown
                                name='state'
                                options={activeOpts}
                                placeholder='Aktivní / neaktivní'
                                selection
                                onChange={this.onChangeSelect}
                                value={parsed.state ? Number(parsed.state) : null}
                                fluid
                                className={parsed.state && 'state-active-' + parsed.state}
                            />
                        </Col>
                        <Col sm={3}>
                            <Button fluid className='mt-label' onClick={() => this.props.history.push(`${process.env.PUBLIC_URL}/admin/manage/workouts/${type}/applications/list/`)}>Vymazat filter</Button>
                        </Col>
                    </Row>
                </CardControls>
                <WorkoutsApplicationsTable items={data} code={this.getVS(type)} sort={this.handleSort} column={column} direction={direction} type={type} setMessage={this.props.setMessage} refresh={() => this._getApplications(user.id_organization)} />
            </Card>
        )
    }

    handleSort = (clickedColumn) => () => {
        const { column, data, direction } = this.state
    
        if (column !== clickedColumn) {
          this.setState({
            column: clickedColumn,
            data: _.sortBy(data, [clickedColumn]),
            direction: 'ascending',
          })
    
          return
        }
    
        this.setState({
          data: data.reverse(),
          direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    }

    render() {
        const { loadingData, hasData } = this.state

        if(loadingData) {
            return (
                <Loading />
            )
        } else if(!loadingData && !hasData) {
            return (
                <NoReservations text='Nemáte žádné přihlášky' />
            )  
        } else {
            return (
                <>
                {this.content()}
                </>
            )
        }
    }

}

const mapStateToProps = state => ({
    user: state.user.user,
    loadingUser: state.user.loading
})
  
export default connect(mapStateToProps, { setMessage })(ApplicationsListView)