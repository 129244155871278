import React, { Component } from 'react'
import { getLocationItem, uploadLocationImage, updateLocation, updateLocationSecondary, createLocation, getToken } from '../../api'
import { Card, CardControls, CardBody, CardHeader } from '../../styles'
import { EditLocation, LocationSecondaryForm } from '../forms'
import { setMessage } from '../../actions/messageActions'
import { connect } from 'react-redux'

class EditLocationView extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            location: {
                location_name: '',
                location_description: '',
                location_address: '',
                location_image: '',
                location_state: 1
            },
            toUpload: [],
            loading: true,
            uploadState: {
				beforeUpload: true,
				isUploading: false,
				uploaded: false
			}
        })
    }

    componentDidMount() {

        if(!this.props.loadingUser) {
            this.refresh()
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.loadingUser !== this.props.loadingUser) {
            this.refresh()
        }
    }

    refresh = () => {
        const { id } = this.props.match.params
        const { user } = this.props

        if(id !== 'new') {

            getToken().then((token) => {
                const data = {
                    token: token,
                    id_org: user.id_organization,
                    id_item: id
                }

                getLocationItem(data)
                .then((res) => {
                    if(res.code === 1) {
                        this.setState({
                            location: res.data,
                            hasData: true,
                            loading: false
                        })
                    } else {
                        this.setState({
                            hasData: false,
                            loading: false
                        })
                    }
                })
            })
        } else {
            this.setState({
                loading: false
            })
        }
    }

    onChange = (e) => this.setState({
        location: {
            ...this.state.location,
            [e.target.name]: e.target.value
        }
    })

    onChangeSelect = (e, data) => this.setState({
        location: {
            ...this.state.location,
            [data.name]: data.value
        }
    })

    submit = () => {
        const { id } = this.props.match.params

        if(id !== 'new') {

            getToken().then((idToken) => {
                this.setState({
                    location: {
                        ...this.state.location,
                        token: idToken,
                        id_organization: this.props.user.id_organization
                    }
                }, () => {
                    updateLocation(id, this.state.location)
                    .then((res) => {
                        this.props.setMessage(res)
                        this.setState({
                            loading: true
                        }, () => {
                            this.refresh()
                        })
                    })
                })
            })
            
        } else {

            this.setState({
                loading: true,
                location: {
                    ...this.state.location,
                    id_organization: this.props.user.id_organization
                }
            }, () => {
                createLocation(this.state.location)
                .then((res) => {
                    this.setState({
                        loading: false
                    })
                    this.props.setMessage(res)
                })
                .then(() => {
                    this.props.history.push('/admin/manage/locations')
                })
                .catch((err) => {
                    this.props.setMessage(err)
                })
            })

        }
        
        
    }

    submitSecondary = () => {

        const { id } = this.props.match.params

        this.uploadImage(this.state.toUpload)
        updateLocationSecondary(id, this.state.location)
        .then((res) => {
            this.props.setMessage(res)
            this.setState({
                loading: true
            }, () => {
                this.refresh()
            })
        })
    }

    onDrop = (files) => {
        this.setState({
            toUpload: files
        })
    }

    uploadImage = (image) => {

        const { id } = this.props.match.params

        this.setState({
			loading: true,
			uploadState: {
				beforeUpload: false,
				isUploading: true,
				uploaded: false
			}
        })
        
        const fd = new FormData();

        image.forEach(file => {
            fd.append('image', file, file.name);
        })
        
        uploadLocationImage(id, fd)
        .then((res) => {
            this.setState({
                loading: false,
                toUpload: []
            }, () => {
                this.refresh()
            })
        })
        .catch((err) => {
            this.props.setMessage(err)
            this.setState({
                loading: false
            })
        })
    }
    
    render() {

        const { id } = this.props.match.params

        return (
            <div className='row'>
                <div className='col-sm-8'>
                    <Card>
                        <CardHeader>
                            Základní informace
                        </CardHeader>
                        <CardBody>
                            <EditLocation 
                                location={this.state.location}
                                onChange={this.onChange}
                                loading={this.state.loading}
                                submit={this.submit}
                            />
                        </CardBody>
                    </Card>
                </div>
                <div className='col-sm-4'>
                    <Card>
                        <CardHeader>
                            Další informace
                        </CardHeader>
                        <CardBody>
                            <LocationSecondaryForm 
                                location={this.state.location}
                                onChangeSelect={this.onChangeSelect}
                                loading={this.state.loading}
                                submit={this.submitSecondary}
                                onDrop={this.onDrop}
                                fileName={this.state.toUpload.length !== 0 ? this.state.toUpload[0].name : false}
                                isNew={id === 'new' ? true : false}
                            />
                        </CardBody>
                    </Card>
                </div>
            </div>
        )
    }

}

const mapStateToProps = state => ({
    user: state.user.user,
    loadingUser: state.user.loading
})
  
export default connect(mapStateToProps, { setMessage })(EditLocationView)