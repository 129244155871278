import React, { Component } from 'react'
import { Toasts, Toast } from '../../styles'
import { connect } from 'react-redux'

class MessagesContainer extends Component {

    constructor(props) {
        super(props)
    }

    renderToasts = () => {
        return (
            this.props.messages.map((item) => {
                return (
                    <Toast type={item.type}>
                        <p>{item.text}{item.message}</p>
                    </Toast>
                )
            })
        )
    }

    render() {
        return (
            <Toasts>
                <this.renderToasts />
            </Toasts>
        )
    }

}

const mapStateToProps = state => ({
    messages: state.messages.items
})
  
export default connect(mapStateToProps, {})(MessagesContainer)