import React, { Component } from 'react'
import { getToken } from '../../../api'
import { getTourApplications } from '../../../api/tours'
import { connect } from 'react-redux'
import { setMessage } from '../../../actions/messageActions'
import { Card, CardHeader } from '../../../styles'
import ApplicationsTable from '../../tables/tours/ApplicationsTable'
import _ from 'loadsh'

class TourOrgApplicationsView extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            data: [],
            loadingData: true,
            column: 'id_tour_application',
            direction: 'descending',
            filter: false,
        })
    }

    componentDidMount() {
        const { loadingUser, user } = this.props

        if(!loadingUser) {
            this._getTourApplications(user.id_organization)
        }
    }

    componentDidUpdate(prevProps) {
        const { loadingUser, user } = this.props

        if(prevProps.loadingUser !== loadingUser && !loadingUser) {
            this._getTourApplications(user.id_organization)
        }
    }

    _getTourApplications = (id_org) => {
        getToken().then((token) => {
            const data = {
                token: token,
                id_org: id_org,
                limit: 15,
                offset: 0
            }

            getTourApplications(data)
            .then((res) => {
                if(res.code === 1) {
                    this.setState({
                        hasData: true,
                        loadingData: false,
                        data: res.data
                    })
                } else {
                    this.setState({
                        hasData: false,
                        loadingData: false
                    })
                }
            })
        })
    }

    handleSort = (clickedColumn) => () => {
        const { column, data, direction } = this.state
    
        if (column !== clickedColumn) {
          this.setState({
            column: clickedColumn,
            data: _.sortBy(data, [clickedColumn]),
            direction: 'ascending',
          })
    
          return
        }
    
        this.setState({
          data: data.reverse(),
          direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    }

    render() {

        const { hasData, loadingData, data, column, direction } = this.state

        return (
            <Card>
                <CardHeader>
                    Přihlášky na vaše zájezdy
                </CardHeader>
                <ApplicationsTable items={data} items={data} sort={this.handleSort} column={column} direction={direction} />
            </Card>
        )
    }

}

const mapStateToProps = state => ({
    user: state.user.user,
    loadingUser: state.user.loading
})
  
export default connect(mapStateToProps, { setMessage })(TourOrgApplicationsView)