import React, { Component } from 'react'
import { intToBool, shortText, toggleDateState } from '../../api'
import { AreYouSureModal } from '../modals'
import { Link } from 'react-router-dom'
import { Table } from 'semantic-ui-react'
import _ from 'loadsh'
import moment from 'moment'

class DatesTable extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            showModal: false,
            idToDelete: 2
        })
    }

    toggleStateHandler = (id) => {
        toggleDateState(id)
        .then(() => {
            this.props.refresh()
        })
    }

    toggleModal = (id) => {
        this.setState({
            showModal: !this.state.showModal,
            idToDelete: !this.state.showModal ? id : ''
        })
    }

    render() {

        const { items, sort, column, direction } = this.props

        return (
            <div>
                <Table sortable striped className='main-table'>
                    <Table.Header>
                        <Table.Row>

                            <Table.HeaderCell
                                sorted={column === 'id_date' ? direction : null}
                                onClick={sort('id_date')}
                            >
                                #
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'camp_name' ? direction : null}
                                onClick={sort('camp_name')}
                            >
                                Tábor
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'date_from' ? direction : null}
                                onClick={sort('date_from')}
                            >
                                Od
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'date_to' ? direction : null}
                                onClick={sort('date_to')}
                            >
                                Do
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'location_name' ? direction : null}
                                onClick={sort('location_name')}
                            >
                                Lokace
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'date_capacity' ? direction : null}
                                onClick={sort('date_capacity')}
                            >
                                Počet míst
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'date_state' ? direction : null}
                                onClick={sort('date_state')}
                            >
                                Stav
                            </Table.HeaderCell>

                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>
                <Table.Body>
                {_.map(items, ({ id_date, date_signed, camp_name, date_from, date_to, is_full, location_name, date_capacity, date_state }) => (
                    <Table.Row key={id_date} className={is_full === 1 && 'full_date'}>
                        <Table.Cell>
                            {id_date}
                        </Table.Cell>
                        <Table.Cell>
                            {camp_name}
                        </Table.Cell>
                        <Table.Cell>
                            {moment(date_from).format("DD/MM/YYYY")}
                        </Table.Cell>
                        <Table.Cell>
                            {moment(date_to).format("DD/MM/YYYY")}
                        </Table.Cell>
                        <Table.Cell>
                            {location_name}
                        </Table.Cell>
                        <Table.Cell>
                            {(date_capacity - date_signed) + '/' + date_capacity}
                        </Table.Cell>
                        <Table.Cell>
                            {intToBool(date_state) ? <i class="icofont-check-circled icon-state" onClick={() => this.toggleStateHandler(id_date)}></i> : <i class="icofont-close-circled icon-state" onClick={() => this.toggleStateHandler(id_date)}></i>}
                        </Table.Cell>
                        <Table.Cell>
                            <i class="icofont-trash" onClick={() => this.toggleModal(id_date)}></i>
                        </Table.Cell>
                        <Table.Cell>
                            <Link to={`${process.env.PUBLIC_URL}/admin/manage/dates/edit/` + id_date}><i class="icofont-ui-edit"></i></Link>
                        </Table.Cell>
                    </Table.Row>
                ))}
                </Table.Body>
            </Table>

            <AreYouSureModal show={this.state.showModal} toggle={this.toggleModal} id={this.state.idToDelete} refresh={this.props.refresh}/>

            </div>
        )
    }
}

export default DatesTable