
import React, { Component } from 'react'
import { Card, CardHeader, CardBody } from '../../../styles'
import { getToken } from '../../../api'
import { getWorkoutsReservations, getEventsList } from '../../../api/club'
import NoReservations from './NoReservations'
import Loading from './Loading'
import UserReservationsTable from '../../tables/workouts/UserReservationsTable'
import _ from 'loadsh'
import { Row, Col } from 'reactstrap'
import { Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { setMessage } from '../../../actions/messageActions'
import { logOutUser } from '../../../actions/userActions'
import firebase from 'firebase'

class EventsReservations extends Component {
    _isMounted = false;
    constructor(props) {
        super(props)
        this.state = ({
            loadingData: true,
            data: [],
            column: 'id_tour_application',
            direction: 'descending',
            filter: false,
        })
    }

    componentDidMount() {
        this._isMounted = true;
        this._getUserTourReservations()
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setCardHeader = (type) => {
        switch (type) {
            case 'moms':
                return 'Moje rezervace cvičení pro maminky'
            
            case 'kids':
                return 'Moje rezervace cvičení pro děti'

            case 'hobby':
                return 'Moje rezervace kroužků'
    
            default: 
                return 'Moje rezervace'
        }
    }

    _getUserTourReservations = () => {

        const { type, active } = this.props

        if(this._isMounted) {
            this.setState({
                loadingData: true
            })
    
            getToken().then((token) => {
                const data = {
                    limit: 5,
                    offset: 0,
                    token: token
                }
    
                if(active) {
                    getEventsList(data, type)
                    .then((res) => {
                        if(res.code === 1) {
                            this.setState({
                                hasData: true,
                                data: res.data,
                                loadingData: false
                            })
                        } else {
                            this.setState({
                                hasData: false,
                                loadingData: false
                            })
                        }
                    })
                    .catch((err) => {
                        this.setState({
                            hasData: false,
                            loadingData: false
                        })
                    })
                } else {
                    getWorkoutsReservations(data, type)
                    .then((res) => {
                        if(res.code === 1) {
                            this.setState({
                                hasData: true,
                                data: res.data,
                                loadingData: false
                            })
                        } else {
                            this.setState({
                                hasData: false,
                                loadingData: false
                            })
                        }
                    })
                    .catch((err) => {
                        this.setState({
                            hasData: false,
                            loadingData: false
                        })
                    })
                }
                
            })
            .catch((err) => {
                this.props.setMessage({type: 'error', text: 'Nepodařilo se získat bezpečnostní token, budete odhlášeni'})
                firebase.auth().signOut()
                window.location.reload();
                this.props.logOutUser()
            })
        }
    }

    handleSort = (clickedColumn) => () => {
        const { column, data, direction } = this.state
    
        if (column !== clickedColumn) {
          this.setState({
            column: clickedColumn,
            data: _.sortBy(data, [clickedColumn]),
            direction: 'ascending',
          })
    
          return
        }
    
        this.setState({
          data: data.reverse(),
          direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    }

    render() {

        const { hasData, loadingData, data, column, direction } = this.state
        const { type, active, setMessage, canChange } = this.props

        return (
            <Card>
                <CardHeader>
                    <Row>
                        <Col sm={9}>
                            {this.setCardHeader(type)}
                        </Col>
                        <Col sm={3} className='text-right'>
                            {active && <Link to={`${process.env.PUBLIC_URL}/klub/lekce/rozvrh/${type}`}><Button>Zobrazit kalendář</Button></Link>}
                        </Col>
                    </Row>
                    
                </CardHeader>
                {hasData && !loadingData &&
                    <UserReservationsTable items={data} canChange={canChange} sort={this.handleSort} column={column} direction={direction} type={type} refresh={this._getUserTourReservations} setMessage={setMessage} />
                }
                {!hasData && !loadingData &&
                    <CardBody>
                        <NoReservations text='Nemáte žádné rezervace lekcí' />
                    </CardBody>
                }
                {loadingData && !hasData &&
                    <CardBody>
                        <Loading />
                    </CardBody>
                }
            </Card>
        )
    }

}

const mapStateToProps = state => ({})
  
export default connect(mapStateToProps, { setMessage, logOutUser })(EventsReservations)