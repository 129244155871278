import React, { Component } from 'react'
import { intToBool, toggleLocationState, shortText, getToken } from '../../../api'
import { Table, Icon, Button, Popup, Modal } from 'semantic-ui-react'
import _ from 'loadsh'
import moment from 'moment'
import { StateIcon } from '../../../styles'
import { Link } from 'react-router-dom'


class LecturerWorkoutsTable extends Component {

    constructor(props) {
        super(props)
        this.state = ({})
    }

    _trash = (id) => {
        this.props.trash(id)
    }

    deleteModal = (id_item) => {
        return (
            <Modal trigger={<Button className='red' icon><Icon name='trash alternate' /></Button>} onClose={this.hideModal}>
                <Modal.Header>
                    Smazat záznam této lekce?
                </Modal.Header>
                <Modal.Content>
                    <p>Lekce bude vymazána včetně jejích termínů v kalendáři. Informace o přihláškách zůstanou uložené v databázi.</p>
                    <p><strong style={{color: '#f5365c'}}>Tento krok nelze vrátit zpět!</strong></p>
                </Modal.Content>
                <Modal.Actions className='justify-content-end'>
                    <Button className='red' onClick={() => this._trash(id_item)} loading={this.state.loading} disabled={this.state.loading}>Smazat záznam</Button>
                </Modal.Actions>
            </Modal>
        )
    }

    render() {

        const { items, column, direction, sort, type } = this.props

        return (
            <div>
                <Table sortable striped className='main-table select_table'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell
                                sorted={column === 'id_workout' ? direction : null}
                                onClick={sort('id_workout')}
                                width={1}
                            >
                                #
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'name' ? direction : null}
                                onClick={sort('name')}
                                width={3}
                            >
                                Název
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'description' ? direction : null}
                                onClick={sort('description')}
                                width={3}
                            >
                                Termín/y
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'location_name' ? direction : null}
                                onClick={sort('location_name')}
                                width={4}
                            >
                                Lokalita
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'date_to' ? direction : null}
                                onClick={sort('date_to')}
                                width={1}
                            >
                                Kapacita
                            </Table.HeaderCell>

                            {/*<Table.HeaderCell
                                sorted={column === 'tour_decription' ? direction : null}
                                onClick={sort('tour_decription')}
                                width={3}
                            >
                                Vytvořeno
                            </Table.HeaderCell>*/}

                            <Table.HeaderCell
                                sorted={column === 'paid_deposit' ? direction : null}
                                onClick={sort('paid_deposit')}
                                width={1}
                            >
                                Stav
                            </Table.HeaderCell>

                            <Table.HeaderCell width={3}></Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                    {_.map(items, ({ id_workout, name, location_name, description, capacity, created, dates, state }, index) => (
                        <Table.Row key={id_workout}>
                            <Table.Cell>
                                {id_workout}
                            </Table.Cell>
                            <Table.Cell>
                                {name}
                            </Table.Cell>
                            <Table.Cell>
                                {dates.map((date) => {
                                    return (
                                        <p><strong>{moment(date.date).format('dddd')}</strong> ({moment(date.date).format('H:ss')})</p>
                                    )
                                })}
                            </Table.Cell>
                            <Table.Cell>
                                {location_name}
                            </Table.Cell>
                            <Table.Cell>
                                {capacity}
                            </Table.Cell>
                            {/*
                            <Table.Cell>
                                {moment(created).format('DD. MM. YYYY')}
                            </Table.Cell>
                            */}
                            <Table.Cell className='text-center'>
                                <StateIcon state={state} />
                            </Table.Cell>
                            <Table.Cell className='text-right'>
                                <Popup 
                                    content='Seznam jednotlivých termínů'
                                    position='top right' 
                                    trigger={
                                        <Link to={'/admin/manage/workouts/'+ type +'/dates/list/' + id_workout}><Button icon><Icon name='list' /></Button></Link>
                                    } 
                                />
                            </Table.Cell>
                        </Table.Row>
                    ))}
                    </Table.Body>
                </Table>
            </div>
        )
    }
}

export default LecturerWorkoutsTable