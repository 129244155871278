import React from 'react'
import { url } from "../config"
import axios from 'axios'

// Workouts
export function createWorkout(data, type) {
    return fetch(url.api + 'workouts/create/' + type, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function getWorkoutItem(data, type) {
    return fetch(url.api + 'workouts/get/item/' + type, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function changeDateProps(data, type) {
    return fetch(url.api + 'workouts/date-props/' + type, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function changeLectureTime(data, type) {
    return fetch(url.api + 'workouts/change-lecture-time/' + type, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function updateWorkout(data, id, type) {
    return fetch(url.api + 'workouts/update/' + id + '/' + type, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}


export function getInfo(data, type) {
    return fetch(url.api + 'workouts/get/info/' + type, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function getParticipants(data, type) {
    return fetch(url.api + 'workouts/get/date-participants/' + type, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function getDateKids(data, type) {
    return fetch(url.api + 'workouts/get/date-kids/' + type, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function getList(data, type) {
    return fetch(url.api + 'workouts/get/all/' + type, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function getListLecturer(data, type) {
    return fetch(url.api + 'workouts/get/all-lecturer/' + type, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function getLecturesList(data, type) {
    return fetch(url.api + 'workouts/get-lectures/0/' + type, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}


export function getCalendar(date, type, filters) {
    return fetch(url.api + 'workouts/get-calendar/' + date + '/' + type, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(filters)
    })
    .then((response) => { return response.json()})
}

export function getCalendarItem(date, type) {
    return fetch(url.api + 'workouts/get-calendar-item/' + date + '/' + type)
    .then((data) => { return data.json() })
}

export function createApplication(data, type) {
    return fetch(url.api + 'workouts/create-application/0/' + type, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function getApplications(data, type) {
    return fetch(url.api + 'workouts/get/applications/' + type, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function getApplication(data, type) {
    return fetch(url.api + 'workouts/get/application/' + type, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}


export function setPaid(data, type) {
    return fetch(url.api + 'workouts/set-paid/' + type, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function changeLecture(data, type) {
    return fetch(url.api + 'workouts/change-lecture/' + type, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function getSignedCalendar(data, date, type) {
    return fetch(url.api + 'workouts/get-signed-calendar/' + date + '/' + type, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function deleteItem(data, id_item, type) {

    console.log(url.api + 'workouts/delete/' + id_item + '/' + type)

    return fetch(url.api + 'workouts/delete/' + id_item + '/' + type, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function confirmData(id, type) {
    return fetch(url.api + 'workouts/confirm/' + id + '/' + type)
    .then((data) => { return data.json() })
}

export function getCategoriesAdmin(data, type) {
    return fetch(url.api + 'workouts/get/categories/' + type, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function getCategoriesOpts(data, type) {
    return fetch(url.api + 'workouts/get/categories-opts/' + type, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function createCategory(data) {
    return fetch(url.api + 'workouts/create-category/', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function getCategoryAdmin(data, type) {
    return fetch(url.api + 'workouts/get/category/' + type, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function getWorkoutsOpts(data, type) {
    return fetch(url.api + 'workouts/get/prepaid-workouts/' + type, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function createPrepaid(data, action, type) {
    return fetch(url.api + 'workouts/create-prepaid/' + action + '/' + type, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function getPrepaid(data, type) {
    return fetch(url.api + 'workouts/get/prepaid/' + type, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function getWorkoutsPrepaids(id, type) {
    return fetch(url.api + 'workouts/get-workouts-prepaids/' + id + '/' + type)
    .then((data) => { return data.json() })
}

export function createPrepaidApplication(data, id, type) {
    return fetch(url.api + 'workouts/create-prepaid-application/' + id + '/' + type, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function disableWorkoutApplication(data, type) {
    return fetch(url.api + 'workouts/disable/' + type, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function enableWorkoutApplication(data, type) {
    return fetch(url.api + 'workouts/enable/' + type, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function addMoreDates(data, id, type) {
    return fetch(url.api + 'workouts/more-dates/' + id + '/' + type, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function getPrepaidsList(data, type) {
    return fetch(url.api + 'workouts/get/prepaids/' + type, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function getPrepaidsOrders(data, type) {
    return fetch(url.api + 'workouts/get/prepaids-orders/' + type, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function getLecturesFilter(type, group) {
    return fetch(url.api + 'workouts/lectures-filter/' + type + '/' + group)
    .then((data) => { return data.json() })
}

export function getGroupOpts(type) {
    return fetch(url.api + 'workouts/group-opts/' + type)
    .then((data) => { return data.json() })
}

export function getLocationsOpts(type) {
    return fetch(url.api + 'workouts/locations-opts/' + type)
    .then((data) => { return data.json() })
}

export function getLocationsOptsAdmin(type) {
    return fetch(url.api + 'workouts/locations-opts-admin/' + type)
    .then((data) => { return data.json() })
}

export function sendEmail(data, id, type) {
    return fetch(url.api + 'workouts/send-email/' + id + '/' + type, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function trash(data, id, type) {
    return fetch(url.api + 'workouts/trash/' + id + '/' + type, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function unsetSub(data, type) {
    return fetch(url.api + 'workouts/unset-sub/' + type, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function changeKidsWorkout(data) {
    return fetch(url.api + 'workouts/change-kids-workout/', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function setAttended(data, type) {
    return fetch(url.api + 'workouts/attended/' + type, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function moveToPaid(data, type) {
    return fetch(url.api + 'workouts/move-to-paid/' + type, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function togglePriority(data, id, type) {
    return fetch(url.api + 'workouts/toggle-priority/' + id + '/' + type, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function toggleWorkoutState(data, id, type) {
    return fetch(url.api + 'workouts/toggle-workout-state/' + id + '/' + type, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function changePrice(data, type) {
    return fetch(url.api + 'workouts/change-price/' + type, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function addLectureToApplication(data, type) {
    return fetch(url.api + 'workouts/add-lecture-to-application/' + type, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function updateNote(data, token) {
    return axios({
        method: 'post',
        url: url.api + 'workouts/update-note',
        data: data,
        headers: { Token: token },
        withCredentials: true
    })
}

export function getItems(query, token, id, type) {
    return axios({
        method: 'get',
        url: url.api + 'workouts/get-items/'+id+'/'+type+'?'+query,
        headers: {Token: token},
        withCredentials: true
    })
}

export function getSignedNumbers(id, type, token, cancelTokenSource) {
    return axios({
        method: 'get',
        url: url.api + 'workouts/get-signed-numbers/'+id+'/'+type,
        headers: {Token: token},
        withCredentials: true,
        cancelToken: cancelTokenSource.token
    })
}