import React, { Component } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { getSignedCalendar, getCalendarItem } from '../../../api/workouts';
import { Event } from '../../../styles/calendar';
import Schedule from '../../containers/workouts/Schedule'
import { Card, CardHeader, CardBody } from '../../../styles'
import { Row, Col } from 'reactstrap';
import { getToken } from '../../../api';

class ScheduleView extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            dates: [],
            selectedItem: {},
            title: ''
        })
    }

    componentDidMount() {

        const { type } = this.props.match.params

        const date = moment()
        this._getCalendar(date)
        this._setScheduleTitle(type)
    }

    _setScheduleTitle = (type) => {
        if(type === 'moms') {
            this.setState({
                title: 'Rozvrh cvičení pro maminky'
            })
        } else if(type === 'kids') {
            this.setState({
                title: 'Rozvrh cvičení s dětmi'
            })
        }
    }

    _getCalendar = (date) => {

        const { type } = this.props.match.params
        const formatDate = moment(date).format('YYYY-MM-DD')

        getToken().then((token) => {
            const data = {
                token: token
            }

            getSignedCalendar(data, formatDate, type)
            .then((res) => {
                const newEvents = []
                res.data.map((item) => {
                    const newItem = {
                        title: item.title,
                        start: moment(item.start).toDate(),
                        end: moment(item.end).toDate(),
                        id: item.id,
                        color: item.color,
                        state: item.state,
                        description: item.description,
                        is_full: item.is_full,
                        can_change: item.can_change,
                        prepaids: item.prepaids,
                        id_workout: item.id_workout
                    }
                    newEvents.push(newItem)
                })

                this.setState({
                    dates: newEvents
                })
            })
        })
        .catch((err) => {
            window.location.reload();
        })
    }

    _getCalendarItem = (id) => {
        const { type } = this.props.match.params

        getCalendarItem(id, type)
        .then((res) => {
            if(res.code === 1) {
                this.setState({
                    selectedItem: res.data,
                    itemExist: true
                })
            } else {
                this.setState({
                    selectedItem: {},
                    itemExist: false
                })
            }
        })
    }

    render() {

        const { type } = this.props.match.params

        return (
            <Card>
                <CardHeader>
                    <Row>
                        <Col sm={9}>
                            {this.state.title}
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Schedule getData={this._getCalendar} dates={this.state.dates} getItem={this._getCalendarItem} selectedItem={this.state.selectedItem} type={type} />
                </CardBody>
            </Card>
        )
    }

}

export default ScheduleView