import { url } from "../config"

export function getOffers() {
    return fetch(url.api + 'offers/get-offers/')
    .then((data) => { return data.json() })
}

export function getOffersWidget() {
    return fetch(url.api + 'offers/get-offers-widget/')
    .then((data) => { return data.json() })
}

export function getOffer(id) {
    return fetch(url.api + 'offers/get-offer/' + id)
    .then((data) => { return data.json() })
}