import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getToursList } from '../../../api/tours'
import { Card, CardHeader } from '../../../styles'
import ToursTable from '../../tables/ToursTable'
import _ from 'loadsh'
import { getToken } from '../../../api'
import { Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom'
import { Button } from 'semantic-ui-react'

class ToursView extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            tours: [],
            hasData: false,
            column: 'id_tour',
            direction: 'descending',
        })
    }

    componentDidMount() {
        const { loadingUser } = this.props

        if(!loadingUser) {
            this._getToursList()
        }
    }

    componentDidUpdate(prevProps) {

        const { loadingUser } = this.props

        if(loadingUser !== prevProps.loadingUser && !loadingUser) {
            this._getToursList()
        }

    }
    
    _getToursList = () => {

        this.setState({
            loadingData: true
        })

        const { user } = this.props

        getToken().then((token) => {
            const data = {
                token: token,
                id_org: user.id_organization,
                limit: 20,
                offset: 0
            }

            getToursList(data)
            .then((res) => {
                if(res.code === 1) {
                    this.setState({
                        tours: res.data,
                        hasData: true,
                        loadingData: false
                    })
                } else {
                    this.setState({
                        tours: [],
                        hasData: false,
                        loadingData: false
                    })
                }
            })
        })
    }

    handleSort = (clickedColumn) => () => {
        const { column, tours, direction } = this.state
    
        if (column !== clickedColumn) {
          this.setState({
            column: clickedColumn,
            tours: _.sortBy(tours, [clickedColumn]),
            direction: 'ascending',
          })
    
          return
        }
    
        this.setState({
          data: tours.reverse(),
          direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    }

    render() {
        return (
            <Card>
                <CardHeader>
                    <Row>
                        <Col sm={9}>
                            Seznam vašich zájezdů
                        </Col>
                        <Col sm={3} className='text-right'>
                            <Link to={`${process.env.PUBLIC_URL}/admin/manage/tours/edit/new`}><Button icon="\efc2">Nový</Button></Link>
                        </Col>
                    </Row>
                </CardHeader>
                <ToursTable items={this.state.tours} sort={this.handleSort} column={this.state.column} direction={this.state.direction} refresh={this._getToursList} />
            </Card>
        )
    }

}

const mapStateToProps = state => ({
    user: state.user.user,
    loadingUser: state.user.loading
})
  
export default connect(mapStateToProps, { })(ToursView)