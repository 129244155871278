import styled from 'styled-components'

export const OffersWidgetItem = styled.div`
    width: 100%;
    background-color: #fff;
    box-shadow: 0 0 2rem 0 rgba(136,152,170,.3);
    border-radius: calc(.375rem - 1px);
    margin-bottom: 20px;

    ${props => props.valid === true ? "filter: grayscale(0);" : "filter: grayscale(1);"}

    .ui.button {
        background: #fff;
        color: ${props => props.bg};
    }
`

export const OfferContent = styled.div`
    padding: 1.5rem;

    h5 {
        font-size: 15px;
        margin-top: 10px;
    }

    p {
        color: rgba(0,0,0,.6);
    }

    a {
        color: #4CC7BE;
    }
`

export const OfferName = styled.h2`
    color: #000;
    font-weight: 700;
    font-size: 18px;
`

export const OfferBrand = styled.a`
    color: #4CC7BE;
`

export const OfferImg = styled.div`
    min-height: 200px;
    width: 100%;
    background-image: url('${props => props.img}');
    background-size: cover;
    background-position: center center;
    border-top-left-radius: calc(.375rem - 1px);
    border-top-right-radius: calc(.375rem - 1px);

`