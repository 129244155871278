import React from 'react'
import { Form, Button } from 'semantic-ui-react'
import { Row, Col } from 'reactstrap'
import moment from 'moment'

const ApplicationKidsForm = ({ kids }) => {
    return (
        <Form>
            <Row className='mb-3'>
                <Col sm={6}>
                    <Form.Field>
                        <Form.Input 
                            type='text'
                            name='first_name'
                            label='Křestní jméno'
                            value={kids.first_name}
                        />
                    </Form.Field>
                </Col>
                <Col sm={6}>
                    <Form.Field>
                        <Form.Input 
                            type='text'
                            name='last_name'
                            label='Příjmení'
                            value={kids.last_name}
                        />
                    </Form.Field>
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col sm={6}>
                    <Form.Field>
                        <Form.Input
                            name='birthday'
                            label='Datum narození'
                            value={moment(kids.birthday).format('D. M. YYYY')}
                            disabled
                        />
                    </Form.Field>
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col sm={12}>
                    <Form.Field>
                        <Form.TextArea 
                            name='health_issues'
                            label='Zdravotní komplikace'
                            value={kids.health_issues}
                            disabled
                        />
                    </Form.Field>
                </Col>
            </Row>
        </Form>
    )
}

export default ApplicationKidsForm