import React, { Component } from 'react'
import { intToBool, toggleLocationState, shortText } from '../../api'
import { AreYouSureModal } from '../modals'
import { Link } from 'react-router-dom'
import { Table } from 'semantic-ui-react'
import _ from 'loadsh'

class LocationsTable extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            showModal: false,
            idToDelete: 2
        })
    }

    toggleStateHandler = (id) => {
        toggleLocationState(id)
        .then(() => {
            this.props.refresh()
        })
    }

    toggleModal = (id) => {
        this.setState({
            showModal: !this.state.showModal,
            idToDelete: !this.state.showModal ? id : ''
        })
    }

    render() {

        const { items, column, direction, sort } = this.props

        return (
            <div className="admin_table_wrapper">
                <Table sortable striped className='main-table' unstackable>
                    <Table.Header>
                        <Table.Row>

                            <Table.HeaderCell
                                sorted={column === 'id_location' ? direction : null}
                                onClick={sort('id_location')}
                            >
                                #
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'location_name' ? direction : null}
                                onClick={sort('location_name')}
                                className='mw_300'
                            >
                                Název
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'location_address' ? direction : null}
                                onClick={sort('location_address')}
                                className='mw_300'
                            >
                                Adresa
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'location_state' ? direction : null}
                                onClick={sort('location_state')}
                            >
                                Stav
                            </Table.HeaderCell>

                            <Table.HeaderCell></Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                    {_.map(items, ({ id_location, location_name, location_address, location_state }) => (
                        <Table.Row key={id_location}>
                            <Table.Cell>
                                {id_location}
                            </Table.Cell>
                            <Table.Cell>
                                {location_name}
                            </Table.Cell>
                            <Table.Cell>
                                {shortText(location_address, 60)}
                            </Table.Cell>
                            <Table.Cell>
                                {intToBool(location_state) ? <i class="icofont-check-circled icon-state" onClick={() => this.toggleStateHandler(id_location)}></i> : <i class="icofont-close-circled icon-state" onClick={() => this.toggleStateHandler(id_location)}></i>}
                            </Table.Cell>
                            <Table.Cell>
                                <Link to={`${process.env.PUBLIC_URL}/admin/manage/locations/edit/` + id_location}><i class="icofont-ui-edit"></i></Link>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                    </Table.Body>
                </Table>

                <AreYouSureModal show={this.state.showModal} toggle={this.toggleModal} id={this.state.idToDelete} refresh={this.props.refresh}/>

            </div>
        )
    }
}

export default LocationsTable