import React, { Component } from 'react'
import { Form, Input, TextArea, Button, Message } from 'semantic-ui-react'
import { FormButtonGroup, FormSection, FormSectionBody, FormSectionHead } from '../../../styles'
import moment from 'moment'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css';
import 'moment/locale/cs';
import { DateUtils } from 'react-day-picker';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
  } from 'react-day-picker/moment';

class KidsForm extends Component {

    constructor(props) {
        super(props)
        this.handleDayChange = this.handleDayChange.bind(this);
        this.state = ({
            buttonActive: true,
            isFilled: false,
            basicKid: [{
                label: 'Jméno a příjmení',
                type: 'text',
                name: 'kids_name',
                placeholder: 'Jméno a příjmení',
                id: 'kids_name',
                required: true
            },
            {
                label: 'Datum narození',
                type: 'date',
                name: 'kids_birthday',
                placeholder: 'Datum narození',
                id: 'kids_birthday',
                required: true
            },
            {
                label: 'Adresa',
                type: 'text',
                name: 'kids_address',
                placeholder: 'Adresa',
                id: 'kids_address',
                required: true
            },
            {
                label: 'Město',
                type: 'text',
                name: 'kids_city',
                placeholder: 'Město',
                id: 'kids_city',
                required: true
            },
            {
                label: 'PSČ',
                type: 'number',
                name: 'kids_post_code',
                placeholder: 'PSČ',
                id: 'kids_post_code',
                required: true,
                onlyNumbers: true
            },
            {
                label: 'Zdravotní pojišťovna',
                type: 'text',
                name: 'kids_insurance',
                placeholder: 'Zdravotní pojišťovna',
                id: 'kids_insurance'
            },
            {
                label: 'Zdravotní omezení',
                type: 'textarea',
                name: 'kids_health_issues',
                placeholder: 'Zdravotní omezení',
                id: 'kids_health_issues'
            },
            {
                label: 'Stravovací omezení',
                type: 'textarea',
                name: 'kids_eating_issues',
                placeholder: 'Stravovací omezení',
                id: 'kids_eating_issues'
            },
            {
                label: 'S kým chce být ve skupině',
                type: 'text',
                name: 'kids_pairing',
                placeholder: 'Napšite jména jeho kamarádů',
                id: 'kids_pairing'
            }],
            basicData: {
                kids_name: '',
                kids_birthday: formatDate(new Date()),
                kids_address: '',
                kids_city: '',
                kids_post_code: '',
                kids_insurance: '',
                kids_health_issues: '',
                kids_pairing: '',
                kids_camps: []
            },
            formFields: [
                [{
                    label: 'Jméno a příjmení',
                    type: 'text',
                    name: 'kids_name',
                    placeholder: 'Jméno a příjmení',
                    id: 'kids_name',
                    required: true
                },
                {
                    label: 'Datum narození ve tvaru (DD/MM/RRRR)',
                    type: 'date',
                    name: 'kids_birthday',
                    placeholder: 'Datum narození',
                    id: 'kids_birthday',
                    required: true
                },
                {
                    label: 'Adresa',
                    type: 'text',
                    name: 'kids_address',
                    placeholder: 'Adresa',
                    id: 'kids_address',
                    required: true
                },
                {
                    label: 'Město',
                    type: 'text',
                    name: 'kids_city',
                    placeholder: 'Město',
                    id: 'kids_city',
                    required: true
                },
                {
                    label: 'PSČ',
                    type: 'number',
                    name: 'kids_post_code',
                    placeholder: 'PSČ',
                    id: 'kids_post_code',
                    required: true,
                    onlyNumbers: true
                },
                {
                    label: 'Zdravotní pojišťovna',
                    type: 'text',
                    name: 'kids_insurance',
                    placeholder: 'Zdravotní pojišťovna',
                    id: 'kids_insurance'
                },
                {
                    label: 'Zdravotní omezení',
                    type: 'textarea',
                    name: 'kids_health_issues',
                    placeholder: 'Alergie na pyl, včelí bodnutí, skolióza, silné dipotrické brýle, ...',
                    id: 'kids_health_issues'
                },
                {
                    label: 'Stravovací omezení',
                    type: 'textarea',
                    name: 'kids_eating_issues',
                    placeholder: 'Alergie na mléko, bezlepková dieta, alergie na ořechy, ...',
                    id: 'kids_eating_issues'
                },
                {
                    label: 'S kým chce být ve skupině',
                    type: 'text',
                    name: 'kids_pairing',
                    placeholder: 'Napšite jména jeho kamarádů',
                    id: 'kids_pairing'
                }]
            ],
            errors: [
                {
                    kids_name: '',
                    kids_birthday: '',
                    kids_address: '',
                    kids_city: '',
                    kids_post_code: '',
                    kids_insurance: '',
                    kids_health_issues: '',
                    kids_eating_issues: '',
                    kids_pairing: '',
                }
            ],
            data: [
                {
                    kids_name: '',
                    kids_birthday: new Date(),
                    kids_address: '',
                    kids_city: '',
                    kids_post_code: '',
                    kids_insurance: '',
                    kids_health_issues: '',
                    kids_eating_issues: '',
                    kids_pairing: '',
                    kids_camps: []
                }
            ]
        })
    }

    checkInputs = () => {

        var minimum_age = moment().subtract(3, 'year');
        var maximum_age = moment().subtract(15, 'year');

        this.state.data.map((item, index) => {
            if(item.kids_name !== '') {
                let errors = [...this.state.errors]
                errors[index]['kids_name'] = ''
            }

            if(item.kids_birthday !== '') {
                let errors = [...this.state.errors]
                errors[index]['kids_birthday'] = ''
            }

            if(item.kids_address !== '') {
                let errors = [...this.state.errors]
                errors[index]['kids_address'] = ''
            }

            if(item.kids_city !== '') {
                let errors = [...this.state.errors]
                errors[index]['kids_city'] = ''
            }

            if(item.kids_post_code !== '') {
                let errors = [...this.state.errors]
                errors[index]['kids_post_code'] = ''
            }
        })

        for (var i = 0; i < this.state.data.length; i++) {

            var diff = moment(this.state.data[i]['kids_birthday']).diff('2020-07-06', 'years', true);
            var interDiff = -diff

            if (this.state.data[i]['kids_name'] === '') {

                const errors = [...this.state.errors]
                errors[i]['kids_name'] = 'Vyplňte jméno dítěte'

                this.setState({
                    isFilled: false,
                    errors: errors
                })
              break;

            } else if (this.state.data[i]['kids_birthday'] === '') {

                const errors = [...this.state.errors]
                errors[i]['kids_birthday'] = 'Vyplňte rok narození dítěte'

                this.setState({
                    isFilled: false,
                    errors: errors
                })
              break;
            } else if (interDiff < 3) {

                const errors = [...this.state.errors]
                errors[i]['kids_birthday'] = 'Dítě nesmí být mladší než 3 roky'

                this.setState({
                    isFilled: false,
                    errors: errors
                })
              break;
            } else if (interDiff > 15) {

                const errors = [...this.state.errors]
                errors[i]['kids_birthday'] = 'Dítě nesmí být starší než 15 let'

                this.setState({
                    isFilled: false,
                    errors: errors
                })
              break;
            } else if (this.state.data[i]['kids_address'] === '') {
                const errors = [...this.state.errors]
                errors[i]['kids_address'] = 'Vyplňte adresu'

                this.setState({
                    isFilled: false,
                    errors: errors
                })
              break;
            } else if (this.state.data[i]['kids_city'] === '') {

                const errors = [...this.state.errors]
                errors[i]['kids_city'] = 'Vyplňte město'

                this.setState({
                    isFilled: false,
                    errors: errors
                })
              break;
            } else if (this.state.data[i]['kids_post_code'] === '') {

                const errors = [...this.state.errors]
                errors[i]['kids_post_code'] = 'Vyplňte PSČ'

                this.setState({
                    isFilled: false,
                    errors: errors
                })

              break;
            } else {
                this.setState({
                    isFilled: true
                })
            }
        }

    }

    addKidHandler = () => {
        this.setState({
            formFields: [
                ...this.state.formFields,
                this.state.basicKid
            ],
            data: [
                ...this.state.data,
                {
                    kids_name: '',
                    kids_birthday: formatDate(new Date()),
                    kids_address: '',
                    kids_city: '',
                    kids_post_code: '',
                    kids_insurance: '',
                    kids_health_issues: '',
                    kids_eating_issues: '',
                    kids_pairing: '',
                    kids_camps: []
                }
            ],
            errors: [
                ...this.state.errors,
                {
                    kids_name: '',
                    kids_birthday: '',
                    kids_address: '',
                    kids_city: '',
                    kids_post_code: '',
                    kids_insurance: '',
                    kids_health_issues: '',
                    kids_eating_issues: '',
                    kids_pairing: '',
                }
            ]
        }, () => {
            this.checkInputs()
        })
    }

    nextHandler = () => {
        this.props.setKids(this.state.data)
    }

    handleChange = (e) => {
        if (["kids_name", "kids_birthday", "kids_address", "kids_city", "kids_post_code", "kids_insurance", "kids_eating_issues", "kids_health_issues", "kids_pairing"].includes(e.target.className) ) {
          const kids = [...this.state.data]
          kids[e.target.dataset.id][e.target.className] = e.target.value
          this.setState({ data: kids })
          this.checkInputs()
        }
    }

    handleDayChange(value, modifiers, dayPickerInput) {
        const input = dayPickerInput.getInput();
        if (["kids_name", "kids_birthday", "kids_address", "kids_city", "kids_post_code", "kids_insurance", "kids_eating_issues", "kids_health_issues", "kids_pairing"].includes(input.name) ) {
            const kids = [...this.state.data]
            kids[input.id][input.name] = moment(value).format('YYYY-MM-DD')
            this.setState({ data: kids }, () => {
                this.checkInputs()
            })
        }
    }

    renderFields = () => {

        const format = "DD-MM-YYYY";

        return (
            this.state.formFields.map((item, index) => {
                return (
                    <FormSection key={index}>
                        <FormSectionHead>
                            <h5>Dítě #{index + 1}</h5>
                        </FormSectionHead>
                        <FormSectionBody>
                            {item.map((field) => {
                                return (
                                    <Form.Field key={field.id} className={this.state.errors[index][field.name] !== '' ? 'error' : 'success'}>
                                        <label>{field.label} {field.required ? '*' : ''}</label>
                                        {(field.type !== 'textarea' && field.name !== 'kids_birthday') ?
                                        <input 
                                            type={field.type}
                                            name={field.name + (index+1)}
                                            placeholder={field.placeholder}
                                            id={field.id}
                                            data-id={index}
                                            value={field.onlyNumbers && this.state.data[index]['kids_post_code'].replace(/\s/g,'')}
                                            onChange={(e) => this.handleChange(e)}
                                            className={field.name}
                                        />
                                        : (field.type === 'textarea') ?
                                        <TextArea
                                            name={field.name}
                                            placeholder={field.placeholder}
                                            id={field.id}
                                            data-id={index}
                                            //value={this.state.data[field.name]}
                                            onChange={this.handleChange}
                                            className={field.name}
                                        />
                                        :
                                        <DayPickerInput 
                                            onDayChange={this.handleDayChange} 
                                            inputProps={{ 
                                                id: index, 
                                                name: 'kids_birthday',
                                                locale: 'cs',
                                                localeUtils: MomentLocaleUtils,
                                            }}
                                            dayPickerProps={{ 
                                                locale: 'cs',
                                                localeUtils: MomentLocaleUtils,
                                                format: 'DD-MM-YYYY',
                                                formatDate: 'DD-MM-YYYY',
                                                parseDate: 'DD-MM-YYYY'
                                            }}
                                            format={'DD-MM-YYYY'}
                                            formatDate={formatDate}
                                            parseDate={parseDate}
                                            placeholder={new Date()}
                                            value={formatDate(this.state.data[index][field.name], format)}
                                        />
                                        }
                                        {field.required && field.name === 'kids_birthday' &&
                                        <p className='form-desc'>Dítěti musí být alespoň 3 roky a ne více než 15 let v době začátku táborů (6. 7. 2020)</p>
                                        }
                                        <p className='form-error-text'>{this.state.errors[index][field.name]}</p>
                                    </Form.Field>
                                )
                            })}
                        </FormSectionBody>
                    </FormSection>
                )
            })
        )
    }

    render() {
        return (
            <Form>
                <Message
                    color='red'
                    icon='warning sign'
                    header='Pokud přihlašujete sourozence, přidejte všechny do jedné přihlášky'
                    content='V případě, že na této stránce nepřidáte všechny sourozence, nebude Vám započítána sleva. Využijte prosím tlačítko "Přidat sourozence" v dolní části.'
                />
                <this.renderFields />
                <FormButtonGroup>
                    <Button className='ghost btn_end' onClick={this.addKidHandler}>Přidat sourozence</Button>
                    <Button onClick={this.nextHandler} disabled={!this.state.isFilled} className='btn_end'>Další</Button>
                </FormButtonGroup>
            </Form>
        )
    }

}

export default KidsForm