import React, { Component } from 'react'
import { Card, CardHeader, CardBody } from '../../../styles'
import { connect } from 'react-redux'
import { getCategoriesAdmin } from '../../../api/workouts'
import { getToken } from '../../../api'
import Loading from '../../containers/Loading'
import NoReservations from '../../containers/club/NoReservations'
import _ from 'loadsh'
import CategoriesTable from '../../tables/workouts/CategoriesTable'

class ListCategoriesView extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            data: [],
            loadingData: true,
            hasData: false,
            column: 'id_category',
            direction: 'descending'
        })
    }

    componentDidMount() {
        const { loadingUser } = this.props

        if(!loadingUser) {
            this._getCategoriesAdmin()
        }
    }

    componentDidUpdate(prevProps) {

        const { loadingUser } = this.props

        if(prevProps.loadingUser !== loadingUser && !loadingUser) {
            this._getCategoriesAdmin()
        }
    }

    handleSort = (clickedColumn) => () => {
        const { column, data, direction } = this.state
    
        if (column !== clickedColumn) {
          this.setState({
            column: clickedColumn,
            data: _.sortBy(data, [clickedColumn]),
            direction: 'ascending',
          })
    
          return
        }
    
        this.setState({
          data: data.reverse(),
          direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    }

    _getCategoriesAdmin() {

        const { user } = this.props
        const { type } = this.props.match.params

        getToken().then((token) => {
            const data = {
                token: token,
                id_org: user.id_organization
            }

            getCategoriesAdmin(data, type)
            .then((res) => {

                if(res.code === 1) {
                    this.setState({
                        loadingData: false,
                        hasData: true,
                        data: res.data
                    })
                } else {
                    this.setState({
                        loadingData: false,
                        hasData: false
                    })
                }
            })
        })

    }

    content = () => {

        const { data, column, direction } = this.state

        return (
            <Card>
                <CardHeader>
                    Věkové kategorie
                </CardHeader>
                <CategoriesTable column={column} direction={direction} sort={this.handleSort} items={data} />
            </Card>
        )
    }

    render() {
        const { loadingData, hasData } = this.state

        if(loadingData) {
            return (
                <Loading />
            )
        } else if(!loadingData && !hasData) {
            return (
                <Card>
                    <CardHeader>
                        Věkové kategorie
                    </CardHeader>
                    <CardBody>
                        <NoReservations text='Nemáte vytvořené žádné kategorie' />
                    </CardBody>
                </Card>
            )  
        } else {
            return (
                <>
                {this.content()}
                </>
            )
        }
    }

}

const mapStateToProps = state => ({
    user: state.user.user,
    loadingUser: state.user.loading
})
  
export default connect(mapStateToProps, {  })(ListCategoriesView)