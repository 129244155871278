import React, { Component } from 'react'
import { getCalendarItem } from '../../../api/workouts'
import { EventItem } from '../../../styles/events'
import { shortText } from '../../../api'
import { Row, Col } from 'reactstrap'
import moment from 'moment'
import { FormButtonGroup } from '../../../styles'
import { Button, Message, Dropdown } from 'semantic-ui-react'

const opts = [
    {
        key: 1,
        value: 1,
        text: 'Jednou týdně'
    },
    {
        key: 2,
        value: 2,
        text: 'Dvakrát týdně'
    }
]

class SelectEventForm extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            item: {},
            loading: true
        })
    }

    componentDidMount() {
        this._getEvent()
    }

    componentDidUpdate(prevProps) {
        if(prevProps.event !== this.props.event) {
            this._getEvent()
        }
    }

    _getEvent = () => {

        const { event } = this.props

        this.setState({
            item: event,
            loading: Object.keys(event).length !== 0 ? false : true
        })

    }

    _submit = () => {

        const { item } = this.state
        const { type } = this.props

        if(type !== 'hobby') {
            this.props.submit(item.id_workout, item.id_date, item.price_lecture, item.is_full, item.is_free, item.semester)
        } else {
            this.props.submit(item.id_workout, item.id_date, item.price_lecture, item.is_full, item.is_free, item.semester, item.times_per_week, item.price_twice_week)
        }
        
    }

    onChangeSelect = (e, data) => this.setState({
        item: {
            ...this.state.item,
            [data.name]: data.value
        }
    })

    render() {

        const { item, loading } = this.state
        const { type } = this.props

        return (
            <>

            {type === 'kids' &&
            <Message negative>
                <Message.Header>Upozornění</Message.Header>
                <p>Zkontrolujte zvolený kurz, jestli opravdu odpovídá věku dítěte nebo jeho dovednostem.</p>
            </Message>
            }

            <EventItem full={item.is_full}>
                {!loading &&
                <>
                <Row>
                    <Col sm={4}>
                        <h2>{item.name}</h2>
                    </Col>
                    <Col sm={4}>
                        <h2>{type !== 'moms' ? moment(item.date).format('dddd - h:ss') : moment(item.date).format('DD. MM. YYYY - H:mm')}</h2>
                    </Col>
                    <Col sm={4} className='text-right'>
                        <h2>{item.is_full ? 'Náhradník' : item.price_lecture + ',- Kč'}</h2>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <p>{shortText(item.description, 200)}</p>
                    </Col>
                </Row>
                </>
                }
            </EventItem>
            {type === 'hobby' && item.price_twice_week !== null &&
                <>
                <label style={{display: 'block'}}>Jednou / dvakrát týdně</label>
                <Dropdown 
                    name='times_per_week'
                    placeholder='Vyberte možnost'
                    options={opts}
                    onChange={this.onChangeSelect}
                    selection
                    fluid
                />
                </>
            }
            <FormButtonGroup>
                <Button primary onClick={this._submit}>Potvrdit výběr</Button>
            </FormButtonGroup>
            </>
        )
    }

}

export default SelectEventForm
