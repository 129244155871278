import React, { Component } from 'react'
import { Modal, ModalContent, ModalHeader, ModalBody, ModalActions, Button, ButtonGroup } from '../../styles'
import { deleteLocation } from '../../api'

class AreYouSureModal extends Component {

    deleteHandler = (id) => {
        return deleteLocation(id)
        .then((res) => {
            this.props.refresh()
            this.props.toggle(id)
        })
        .then((res) => {
            alert(res.text)
        })
        .catch((err) => {
            console.log(err)
        })
    }

    render() {

        const { id, show, toggle } = this.props

        return (
            <Modal visible={show}>
                <ModalContent>
                    <ModalHeader>
                        <p>Opravdu chcete lokalitu smazat?</p>
                    </ModalHeader>
                    <ModalBody>
                        <p>Tato akce smaže záznam a veškeré jeho části a je nevratná. Pokud si nejste jistí na 100%, lokalitu pouze znezveřejněte.</p>
                    </ModalBody>
                    <ModalActions>
                        <ButtonGroup>
                            <Button icon='\eee4' outline onClick={() => toggle(id)}>Chci zpět</Button>
                            <Button icon='\eed8' danger onClick={() => this.deleteHandler(id)}>Jsem si jistý, smazat</Button>
                        </ButtonGroup>
                    </ModalActions>
                </ModalContent>
            </Modal>
        )
    }

}

export default AreYouSureModal