import React from 'react'
import { OffersWidgetItem, OfferName, OfferBrand, OfferImg, OfferContent } from '../../../styles/offers'
import { Col, Row } from 'reactstrap'
import { url } from '../../../config'
import moment from 'moment'

const OffersItemSwiper = ({item}) => {
    return (

            <OffersWidgetItem valid={true}>
                <OfferImg img={url.images + '/offers/' + item.id_offer + '/' + item.image}></OfferImg>
                <OfferContent>
                    <OfferName>
                        {item.name}
                    </OfferName>
                    <Row>
                        <Col sm={6}>
                            <OfferBrand href={item.link} target='_blank'>{item.brand}</OfferBrand>
                        </Col>
                        <Col sm={6} className='text-right'>
                            <OfferBrand href={`${process.env.PUBLIC_URL}/klub/auth/registrace`} target='_blank'>Získat slevu</OfferBrand>
                        </Col>
                    </Row>
                    
                </OfferContent>
                
            </OffersWidgetItem>

    )
}

export default OffersItemSwiper