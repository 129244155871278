import React, { Component } from 'react'
import { Card, CardHeader, CardBody } from '../../../styles'
import { getToken } from '../../../api'
import { getTourReservations } from '../../../api/club'
import NoReservations from './NoReservations'
import Loading from './Loading'
import ReservationsTable from '../../tables/tours/ReservationsTable'
import _ from 'loadsh'

class TourReservations extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            loadingData: true,
            data: [],
            column: 'id_tour_application',
            direction: 'descending',
            filter: false,
        })
    }

    componentDidMount() {
        this._getUserTourReservations()
    }

    _getUserTourReservations = () => {
        this.setState({
            loadingData: true
        })

        getToken().then((token) => {
            const data = {
                limit: 5,
                offset: 0,
                token: token
            }

            getTourReservations(data)
            .then((res) => {
                if(res.code === 1) {
                    this.setState({
                        hasData: true,
                        data: res.data,
                        loadingData: false
                    })
                } else {
                    this.setState({
                        hasData: false,
                        loadingData: false
                    })
                }
            })
        })
    }

    handleSort = (clickedColumn) => () => {
        const { column, data, direction } = this.state
    
        if (column !== clickedColumn) {
          this.setState({
            column: clickedColumn,
            data: _.sortBy(data, [clickedColumn]),
            direction: 'ascending',
          })
    
          return
        }
    
        this.setState({
          data: data.reverse(),
          direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    }

    render() {

        const { hasData, loadingData, data, column, direction } = this.state

        return (
            <Card>
                <CardHeader>
                    Moje rezervace zájezdů
                </CardHeader>
                {hasData && !loadingData &&
                    <ReservationsTable items={data} sort={this.handleSort} column={column} direction={direction} />
                }
                {!hasData && !loadingData &&
                    <CardBody>
                        <NoReservations text='Nemáte žádné rezervace zájezdů' />
                    </CardBody>
                }
                {loadingData && !hasData &&
                    <CardBody>
                        <Loading />
                    </CardBody>
                }
            </Card>
        )
    }

}

export default TourReservations