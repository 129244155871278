import React from 'react'
import Widget from './Widget'
import { Loader } from 'semantic-ui-react'
import { DashboardWidget } from '../../../styles/club'
import { Row } from 'reactstrap'


const LoadingWidgets = () => {
    return (
        <DashboardWidget bg='#f6f6f6'>
            <Row className='d-flex justify-content-center align-items-center mh-300'>
                <Loader active />
            </Row>
        </DashboardWidget>
    )
}

export default LoadingWidgets