import React, { Component } from 'react'
import { Card, CardControls, CardBody } from '../../styles'
import { EditOrganizationForm, OrganizationSecondaryForm } from '../forms'
import { getOrganizationItem, createOrganization, updateOrganization, updateOrganizationSecondary } from '../../api'
import { connect } from 'react-redux'
import { setMessage } from '../../actions/messageActions'

class EditOrganizationView extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            organization: {
                organization_name: '',
                organization_ic: '',
                organization_vat_num: '',
                organization_address: '',
                organization_bank_acc: '',
                organization_email: '',
                organization_person: '',
                organization_phone: '',
                organization_state: 0
            },
            loading: true
        })
    }

    componentDidMount() {
        this.refresh()
    }

    onChange = (e) => this.setState({
        organization: {
            ...this.state.organization,
            [e.target.name]: e.target.value
        }
    })

    onChangeSelect = (e, data) => this.setState({
        organization: {
            ...this.state.organization,
            [data.name]: data.value
        }
    })

    refresh = () => {
        const { id } = this.props.match.params

        if(id !== 'new') {
            getOrganizationItem(id)
            .then((data) => {
                this.setState({
                    organization: data
                })
            })
            .then(() => {
                this.setState({
                    loading: false
                })
            })
        } else {
            this.setState({
                loading: false
            })
        }
    }

    submit = () => {
        const { id } = this.props.match.params

        if(id !== 'new') {

            this.setState({
                loading: true
            })

            updateOrganization(id, this.state.organization)
            .then((res) => {
                this.props.setMessage(res)
                this.setState({
                    loading: false
                }, () => {
                    this.refresh()
                })
            })   
        } else {

            this.setState({
                loading: true
            })

            createOrganization(this.state.organization)
            .then((res) => {
                this.setState({
                    loading: false
                })
                this.props.setMessage(res)
            })
            .then(() => {
                this.props.history.push('/admin/manage/organizations')
            })
            .catch((err) => {
                this.props.setMessage(err)
            })
        }
    }

    submitSecondary = () => {

        const { id } = this.props.match.params

        updateOrganizationSecondary(id, this.state.organization)
        .then((res) => {
            this.props.setMessage(res)
            this.setState({
                loading: true
            }, () => {
                this.refresh()
            })
        })
    }

    render() {
        return (
            <div className='row'>
                <div className='col-sm-8'>
                    <Card>
                        <CardControls>
                            <div className='row'>
                                <div className='col-sm-6 text-left'>
                                    <h2>Základní informace</h2>
                                </div>
                            </div>
                        </CardControls>
                        <CardBody>
                            <EditOrganizationForm organization={this.state.organization} loading={this.state.loading} onChange={this.onChange} onChangeSelect={this.onChangeSelect} submit={this.submit} />
                        </CardBody>
                    </Card>
                </div>
                <div className='col-sm-4'>
                    <Card>
                        <CardControls>
                            <div className='row'>
                                <div className='col-sm-12 text-left'>
                                    <h2>Další informace</h2>
                                </div>
                            </div>
                        </CardControls>
                        <CardBody>
                            <OrganizationSecondaryForm organization={this.state.organization} loading={this.state.loading} onChange={this.onChange} onChangeSelect={this.onChangeSelect} submit={this.submitSecondary} />
                        </CardBody>
                    </Card>
                </div>
            </div>
        )
    }

}

const mapStateToProps = state => ({})
  
export default connect(mapStateToProps, { setMessage })(EditOrganizationView)