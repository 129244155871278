import React from 'react'
import { url } from "../config"

// Tours
export function createTour(data) {
    return fetch(url.api + 'tours/create', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function updateTour(data) {
    return fetch(url.api + 'tours/update', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function getTourItem(data) {
    return fetch(url.api + 'tours/get/item', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function getToursList(data) {
    return fetch(url.api + 'tours/get/all', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function getPublicToursList() {
    return fetch(url.api + 'tours/get-public-items/')
    .then((data) => { return data.json() })
}

export function getFilter(type) {
    return fetch(url.api + 'tours/get-filter/' + type)
    .then((data) => { return data.json() })
}

export function getSelectedTour(id) {
    return fetch(url.api + 'tours/get-selected/' + id)
    .then((data) => { return data.json() })
}

export function toggleState(data) {
    return fetch(url.api + 'tours/toggle-state', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function createApplication(data) {
    return fetch(url.api + 'tours/create-application', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function getTourApplications(data) {
    return fetch(url.api + 'tours/get/applications', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}