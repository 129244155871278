import React, { useState } from 'react'
import { Sidebar, SidebarNav, SidebarItem, SidebarDivider, SidebarHeader, SidebarIcon } from '../../../styles'
import { NavLink } from 'react-router-dom'
import Dropdown from '../sidebar/Dropdown'
import {
    MobileView,
} from "react-device-detect";
import { Button, Icon } from 'semantic-ui-react';
import { Images } from '../../../config';

/*const SettingsDropdownItems = [
    {
        link: '/admin/manage/organizations',
        name: 'Správa organizací'
    },
    {
        link: '/admin/manage/users',
        name: 'Správa uživatelů'
    },
    {
        link: '/admin/settings/coupons',
        name: 'Správa slevových kuponů'
    },
    {
        link: '/admin/settings/recommendations',
        name: 'Přehled doporučení'
    }
]*/

const CampsDropdownItems = [
    {
        link: '/klub/rozvrh/kids',
        name: 'Cvičení pro děti'
    },
    {
        link: '/klub/rozvrh/moms',
        name: 'Cvičení pro maminky'
    },
    {
        link: '/klub/rozvrh/hobby',
        name: 'Kroužky'
    }
]

/*const ToursDropdownItems = [
    {
        link: '/admin/manage/tours/applications',
        name: 'Správa přihlášek'
    },
    {
        link: '/admin/manage/tours/list',
        name: 'Termíny zájezdů'
    },
    {
        link: '/admin/manage/tours/edit/new',
        name: 'Nový zájezd'
    }
]*/

const SidebarClub = ({isMobile}) => {

    const [open, openDropdown] = useState(false);
    const toggle = () => openDropdown(!open);

        return (
            <Sidebar isMobile={isMobile} open={isMobile === true ? open : true}>
                {open &&
                <SidebarIcon>
                    <MobileView>
                        <Button icon onClick={toggle}><Icon name='arrow left' /></Button>
                    </MobileView>
                </SidebarIcon>
                }
                {!open &&
                <SidebarIcon>
                    <MobileView>
                        <Button icon onClick={toggle}><Icon name='arrow bars' /></Button>
                    </MobileView>
                </SidebarIcon>
                }
                <SidebarHeader>
                    <img src={Images.logo_c} />
                </SidebarHeader>
                <SidebarNav>
                    <SidebarItem icon="\ec5e" iconColor='#5e72e4'>
                        <NavLink to={`${process.env.PUBLIC_URL}/klub/nastenka`} activeClassName='active'>Nástěnka</NavLink>
                    </SidebarItem>

                    <SidebarDivider />

                    <SidebarItem icon="\f000" iconColor='#f3d74b'>
                        <NavLink to={`${process.env.PUBLIC_URL}/klub/nabidky`} activeClassName='active'>Speciální nabídky</NavLink>
                    </SidebarItem>
                    {window.location.origin !== 'https://klub.tabory-krouzky.cz' &&
                    <>
                    <SidebarDivider />

                    <SidebarItem icon="\eec0" iconColor='#f5365c'>
                        <NavLink to={`${process.env.PUBLIC_URL}/klub/rezervace`} activeClassName='active'>Moje rezervace</NavLink>
                    </SidebarItem>

                    <SidebarItem icon="\ecfd" iconColor='#11cdef'>
                        <NavLink to={`${process.env.PUBLIC_URL}/klub/lekce/list`} activeClassName='active'>Moje lekce</NavLink>
                    </SidebarItem>

                    <SidebarItem icon="\eedc" iconColor='#f5365c'>
                        <NavLink to={`${process.env.PUBLIC_URL}/klub/permanentky`} activeClassName='active'>Moje permanentky</NavLink>
                    </SidebarItem>

                    <SidebarItem icon="\eea3" iconColor='#2dce89'>
                        <NavLink to={`${process.env.PUBLIC_URL}/klub/lekce/watchlist`} activeClassName='active'>Hlídané lekce</NavLink>
                    </SidebarItem>

                    <SidebarDivider />

                    <Dropdown 
                        name='Rozvrh lekcí'
                        items={CampsDropdownItems}
                        icon='\ec45'
                    />
                    </>
                    }
                    <SidebarDivider />

                    <SidebarItem icon="\ec83">
                        <NavLink to={`${process.env.PUBLIC_URL}/klub/muj-ucet`} activeClassName='active'>Můj účet</NavLink>
                    </SidebarItem>
                </SidebarNav>
            </Sidebar>
        )

}

export default SidebarClub