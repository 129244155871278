import React, { Component } from 'react'
import { getToken } from '../../../api'
import { getClubUsers } from '../../../api/users'
import Loading from '../../containers/Loading'
import { NoDataContainer, Card, CardHeader } from '../../../styles'
import NoReservations from '../../containers/club/NoReservations'
import ClubUsersTable from '../../tables/users/ClubUsersTable'
import _ from 'loadsh'

class ClubUsersListView extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            data: [],
            error: '',
            hasError: false,
            column: 'id_user',
            direction: 'descending',
        })
    }

    componentDidMount() {
        this._getClubUsers()
    }

    _getClubUsers = () => {

        this.setState({
            loading: true
        })

        getToken().then((token) => {
            getClubUsers(token)
            .then((res) => {
                if(res.code === 1) {
                    this.setState({
                        data: res.data,
                        auth: true,
                        loading: false,
                        hasData: true
                    })
                } else if(res.code === 0) {
                    this.setState({
                        data: [],
                        loading: false,
                        hasData: false,
                        auth: true,
                        text: res.text
                    })
                } else if(res.code === 403) {
                    this.setState({
                        loading: false,
                        hasData: false,
                        auth: false,
                        text: res.text
                    })
                }
            })
            .catch((err) => {
                this.setState({
                    error: 'Nepodařilo se získat data, zkuste to prosím později',
                    hasError: true,
                    loading: false
                })
            })
        })
    }

    handleSort = (clickedColumn) => () => {
        const { column, data, direction } = this.state
    
        if (column !== clickedColumn) {
          this.setState({
            column: clickedColumn,
            data: _.sortBy(data, [clickedColumn]),
            direction: 'ascending',
          })
    
          return
        }
    
        this.setState({
          data: data.reverse(),
          direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    }

    content = () => {
        if(this.state.loading) {
            return (
                <Loading />
            )
        } else if(!this.state.loading && this.state.hasData) {
            return (
                <ClubUsersTable items={this.state.data} sort={this.handleSort} column={this.state.column} direction={this.state.direction} />
            )
        } else if(!this.state.loading && !this.state.hasData && this.state.auth) {
            return (
                <NoReservations text={this.state.text} />
            )
        } else if(!this.state.loading && !this.state.auth) {
            return (
                <NoReservations text={this.state.text} />
            )
        } else if(!this.state.loading && this.state.hasError) {
            return (
                <NoReservations text={this.state.error} />
            )
        }
    }

    render() {
        return (
            <Card>
                <CardHeader>
                    Uživatelé v klubu
                </CardHeader>
                {this.content()}
            </Card>
        )
    }

}

export default ClubUsersListView