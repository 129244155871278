import React from 'react'
import { url } from "../config"
import axios from 'axios'
import firebase from 'firebase'
import { Link } from 'react-router-dom'

export function getToken() {
    return firebase.auth().currentUser.getIdToken(true).then((idToken) => {
        return idToken
    }).catch((error) => {
        return error
    });
}

export function intToBool(int) {
    return int === 0 ? false : true
}

export function shortText(str, length) {
    var emp = ''
    if(str.length > length) emp = '...'

    return str.substring(0, length) + emp
}

export function formatMoney(number, decPlaces, decSep, thouSep) {
    var decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 0 : decPlaces
    var decSep = typeof decSep === "undefined" ? "," : decSep;
    var thouSep = typeof thouSep === "undefined" ? "\xa0" : thouSep;

    var sign = number < 0 ? "-" : "";
    var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
    var j = (j = i.length) > 3 ? j % 3 : 0;
    
    return sign +
        (j ? i.substr(0, j) + thouSep : "") +
        i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
        (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
}

export function getVS(vs, type) {
    switch(type) {
        case 'moms':
            return '10' + vs
        case 'kids':
            return '20' + vs
        case 'hobby':
            return '30' + vs
        case 'prepaids':
            return '40' + vs
        case 'tours':
            return '50' + vs
    }
}

//Users
export function getUserInfo(id) {
    return fetch(url.api + 'users/get-user/' + id + '/0/')
    .then((data) => { return data.json() })
}

export function getLecturersOpts(data) {
    return fetch(url.api + 'users/lecturers-opts', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

//Locations
export function getLocations(data) {
    return fetch(url.api + 'locations/get/all', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function getLocationItem(data) {
    return fetch(url.api + 'locations/get/item', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function getLocationsFilter() {
    return fetch(url.api + 'locations/get-filter/')
    .then((data) => { return data.json() })
}

export function getLocationsFilterAdmin(data) {

    return fetch(url.api + 'locations/get/admin-opts', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})

}

export function toggleLocationState(id) {
    return fetch(url.api + 'locations/toggle-state/' + id)
    .then((data) => { return data.json() })
}

export function deleteLocation(id) {
    return fetch(url.api + 'locations/delete/' + id)
    .then((data) => { return data.json() })
}

export function uploadLocationImage(id, file) {
    return axios.post(url.api + 'locations/upload/' + id, file)
}

export function updateLocation(id, data) {
    return fetch(url.api + 'locations/update/' + id, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function updateLocationSecondary(id, data) {
    return fetch(url.api + 'locations/update-secondary/' + id, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function createLocation(data) {
    return fetch(url.api + 'locations/create/', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

// Organizations
export function getOrganizations() {
    return fetch(url.api + 'organizations/get/')
    .then((data) => { return data.json() })
}

export function getOrganizationItem(id) {
    return fetch(url.api + 'organizations/item/' + id)
    .then((data) => { return data.json() })
}

export function updateOrganization(id, data) {
    return fetch(url.api + 'organizations/update/' + id, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function createOrganization(data) {
    return fetch(url.api + 'organizations/create/', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function toggleOrganizationState(id) {
    return fetch(url.api + 'organizations/toggle-state/' + id)
    .then((data) => { return data.json() })
}

export function updateOrganizationSecondary(id, data) {
    return fetch(url.api + 'organizations/update-secondary/' + id, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function deleteOrganization(id) {
    return fetch(url.api + 'organizations/delete/' + id)
    .then((data) => { return data.json() })
}

// Users
export function getUsersOrganization() {
    return fetch(url.api + 'users/get-organizations/')
    .then((data) => { return data.json() })
}

export function logUser(data) {
    return fetch(url.api + 'users/create/test' + '/0', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function getUsers() {
    return fetch(url.api + 'users/get-users/')
    .then((data) => { return data.json() })
}

export function getUser(uid) {
    return fetch(url.api + 'users/get-user/' + uid + '/0')
    .then((data) => { return data.json() })
}

// Camps 
export function getCamps(data) {
    return fetch(url.api + 'camps/get/all', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function getCamp(data) {
    return fetch(url.api + 'camps/get/item', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function createCamp(data) {
    return fetch(url.api + 'camps/create/', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function updateCamp(id, data) {
    return fetch(url.api + 'camps/update/' + id, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function toggleCampState(id) {
    return fetch(url.api + 'camps/toggle-state/' + id)
    .then((data) => { return data.json() })
}

export function uploadCampImage(id, file) {
    return axios.post(url.api + 'camps/upload/' + id, file)
}

export function updateCampSecondary(id, data) {
    return fetch(url.api + 'camps/update-secondary/' + id, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

//Dates
export function getDates() {
    return fetch(url.api + 'dates/get/')
    .then((data) => { return data.json() })
}

export function getDatesAdmin(data) {
    return fetch(url.api + 'dates/get-admin/', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function getDate(data) {
    return fetch(url.api + 'dates/item/', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function dateFilterFrom(date) {
    return fetch(url.api + 'dates/filter-from/?date=' + date)
    .then((data) => { return data.json() })
}

export function dateFilterTo(date) {
    return fetch(url.api + 'dates/filter-to/?date=' + date)
    .then((data) => { return data.json() })
}

export function getLocationsValues(id) {
    return fetch(url.api + 'dates/get-locations/')
    .then((data) => { return data.json() })
}

export function getCampsValues(id) {
    return fetch(url.api + 'dates/get-camps?id=' + id)
    .then((data) => { return data.json() })
}

export function selectCamp(id) {
    return fetch(url.api + 'dates/select/' + id)
    .then((data) => { return data.json() })
}

export function createDate(data) {
    return fetch(url.api + 'dates/create/', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function toggleDateState(id) {
    return fetch(url.api + 'dates/toggle-state/' + id)
    .then((data) => { return data.json() })
}

export function updateDate(data) {
    return fetch(url.api + 'dates/update/', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function updateDateSecondary(id, data) {
    return fetch(url.api + 'dates/update-secondary/' + id, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

//Applications 
export function createApplication(data) {
    return fetch(url.api + 'applications/create/', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function unsetSub(data) {
    return fetch(url.api + 'applications/unset-sub/', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function toggleNewsletter(data) {
    return fetch(url.api + 'applications/toggle-newsletter/', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function togglePhotos(data) {
    return fetch(url.api + 'applications/toggle-photos/', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function getDateApplications(id) {
    return fetch(url.api + 'applications/get-date/' + id)
    .then((data) => { return data.json() })
}

export function getDateSubsApplications(id) {
    return fetch(url.api + 'applications/get-date-subs/' + id)
    .then((data) => { return data.json() })
}

export function getApplications(data) {
    return fetch(url.api + 'applications/get/all', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function getDisabled(data) {
    return fetch(url.api + 'applications/get/disabled', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function togglePaid(id) {
    return fetch(url.api + 'applications/toggle-paid/' + id)
    .then((data) => { return data.json() })
}

export function getApplicationDetail(data) {
    return fetch(url.api + 'applications/get/item', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function getCoupon(val) {
    return fetch(url.api + 'coupons/get/' + val)
    .then((data) => { return data.json() })
}

export function getRecommend(val) {
    return fetch(url.api + 'coupons/recommend/' + val)
    .then((data) => { return data.json() })
}

/*export function cancelDate(id, idKid, email) {
    return fetch(url.api + 'applications/delete-date/' + id + '?id=' + idKid + '&email=' + email)
    .then((data) => { return data.json() })
}*/

export function updatePrice(data) {
    return fetch(url.api + 'applications/update-price/', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function cancelDate(data) {
    return fetch(url.api + 'applications/delete-date/', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function setPaid(data) {
    return fetch(url.api + 'applications/set-paid/', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function sendReminder(id) {
    return fetch(url.api + 'applications/reminder/' + id)
    .then((data) => { return data.json() })
}

export function sendConfirm(id) {
    return fetch(url.api + 'applications/confirm/' + id)
    .then((data) => { return data.json() })
}

export function disableApplication(id) {
    return fetch(url.api + 'applications/disable/' + id)
    .then((data) => { return data.json() })
}

export function multipleDisable(data) {
    return fetch(url.api + 'applications/multiple-disable/', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function toggleFlag(id) {
    return fetch(url.api + 'applications/toggle-flag/' + id)
    .then((data) => { return data.json() })
}

export function toggleInvoice(data) {
    return fetch(url.api + 'applications/toggle-invoice/', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function addKid(data) {
    return fetch(url.api + 'applications/add-kid/', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function enableApplication(id) {
    return fetch(url.api + 'applications/enable/' + id)
    .then((data) => { return data.json() })
}

export function changeKidsDate(data) {
    return fetch(url.api + 'applications/change-date/', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function addKidsDate(data) {
    return fetch(url.api + 'applications/add-date/', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function updateParents(data) {
    return fetch(url.api + 'applications/update-parents/', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function updateKids(data) {
    return fetch(url.api + 'applications/update-kids/', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function searchApplications(q, state) {
    return fetch(url.api + 'applications/search?q=' + q + '&state=' + state)
    .then((data) => { return data.json() })
}

export function sendMultipleReminders(data) {
    return fetch(url.api + 'applications/multiple-reminders/', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function sendMultipleConfirms(data) {
    return fetch(url.api + 'applications/multiple-confirms/', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function lastFiveApplications(data) {
    return fetch(url.api + 'applications/get/last-five/', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

// Contacts
export function getContacts(data, id) {
    return fetch(url.api + 'contacts/get/' + id, {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function searchContact(q) {
    return fetch(url.api + 'contacts/search?q=' + q)
    .then((data) => { return data.json() })
}

export function sendNewsletter(data) {
    return fetch(url.api + 'messages/send-newsletter/', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function getRecommendations() {
    return fetch(url.api + 'recommendations/get/')
    .then((data) => { return data.json() })
}

export function itemEmailHistory(data) {
    return fetch(url.api + 'messages/get-item-email-history/', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

// Stats 
export function countApplications(token) {
    return fetch(url.api + 'stats/count-applications/' + token)
    .then((data) => { return data.json() })
}

export function countKids(token) {
    return fetch(url.api + 'stats/count-kids/' + token)
    .then((data) => { return data.json() })
}

export function todayApplications(token) {
    return fetch(url.api + 'stats/today-applications/' + token)
    .then((data) => { return data.json() })
}

export function todayKids(token) {
    return fetch(url.api + 'stats/today-kids/' + token)
    .then((data) => { return data.json() })
}

export function paidUnpaid(token) {
    return fetch(url.api + 'stats/paid/' + token)
    .then((data) => { return data.json() })
}

export function getNumberPaid(token) {
    return fetch(url.api + 'stats/number-paid/' + token)
    .then((data) => { return data.json() })
}

export function unsubscribe(data) {
    return fetch(url.api + 'messages/unsubscribe/', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

// Coupons
export function createCoupon(data) {
    return fetch(url.api + 'coupons/create/', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function deleteCoupon(data) {
    return fetch(url.api + 'coupons/delete/', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function getCouponsList() {
    return fetch(url.api + 'coupons/get-list/')
    .then((data) => { return data.json() })
}

export function getCouponsStats() {
    return fetch(url.api + 'coupons/get-stats/')
    .then((data) => { return data.json() })
}

// Payment
export function requestPaymentLink(data) {
    return fetch(url.api + '/payment/', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

// Additional payments 
export function getAddPayments(data) {
    return fetch(url.api + 'applications/get-add-payments', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function createAddPayment(data) {
    return fetch(url.api + 'applications/create-add-payment', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function deleteAddPayment(data) {
    return fetch(url.api + 'applications/delete-add-payment', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function confirmAddPayment(data) {
    return fetch(url.api + 'applications/confirm-add-payment', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

// Vouchers
export function getVoucherTypes() {
    return fetch(url.api + 'vouchers/get-voucher-types/')
    .then((data) => { return data.json() })
}

export function createVoucherOrder(data) {
    return fetch(url.api + 'vouchers/create-voucher-order', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function getVouchersOrders(data) {
    return fetch(url.api + 'vouchers/get-vouchers-orders', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function toggleVoucherPaid(data) {
    return fetch(url.api + 'vouchers/toggle-paid', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function deleteVoucher(data) {
    return fetch(url.api + 'vouchers/delete-voucher', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}

export function searchVouchers(q) {
    return fetch(url.api + 'vouchers/search?q=' + q)
    .then((data) => { return data.json() })
}

// Export data 
export function exportPhotoAgreement(data) {
    return fetch(url.api + 'export/export-photo', {
        method: 'POST',
        headers: 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then((response) => { return response.json()})
}