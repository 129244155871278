import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap'
import HeaderApplications from '../../containers/club/HeaderApplications'
import { ServiceBlock } from '../../../styles/applications'
import { Images } from '../../../config'
import { Link } from 'react-router-dom'
import { getDates, getLocationsFilter, dateFilterFrom } from '../../../api'
import { DateItems } from '../../containers'
import { Dropdown, Form } from 'semantic-ui-react'
import _ from 'loadsh'
import { Card, CardHeader, CardBody } from '../../../styles'
import { getPublicToursList } from '../../../api/tours'
import ToursTable from '../../tables/tours/ToursTable'
import FilterItem from '../../containers/tours/FilterItem'
import queryString from 'query-string'
import ApplicationsSuspended from '../../containers/applications/ApplicationsSuspended'

const stateOptions = [
    {key: '1', value: null, text: 'Vše'},
    {key: '2', value: 6, text: 'Kidsjoy'},
    {key: '3', value: 2, text: 'Sportjoy'},
    {key: '4', value: 7, text: 'Babyjoy'}
]

const dateOptions = [
    {key: '1', value: null, text: 'Vše'},
    {key: '2', value: '2020-07-06', text: '6.7. - 10.7.2020'},
    {key: '3', value: '2020-07-13', text: '13.7. - 17.7.2020'},
    {key: '4', value: '2020-07-20', text: '20.7. - 24.7.2020'},
    {key: '5', value: '2020-07-27', text: '27.7. - 31.7.2020'},
    {key: '6', value: '2020-08-03', text: '3.8. - 7.8.2020'},
    {key: '7', value: '2020-08-10', text: '10.8. - 14.8.2020'},
    {key: '8', value: '2020-08-17', text: '17.8. - 21.8.2020'},
    {key: '9', value: '2020-08-24', text: '24.8. - 28.8.2020'}
]

class ApplicationToursView extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            dates: [],
            baseData: [],
            column: 'id_date',
            direction: 'descending',
            locOptions: [
                {key: '0', value: null, text: 'Vše'},
            ],
            filter: false,
            filter_date_from: '', 
            utm: ''
        })
    }

    componentDidMount() {

        const { utm } = this.props.match.params
        document.title = "Termíny táborů | 1FIT Klub"

        this.setState({
            utm: utm !== undefined ? utm : ''
        })

        document.body.classList.add('application')

        getLocationsFilter()
        .then((data) => {
            this.setState({
                locOptions: this.state.locOptions.concat(data)
            })
        })

        const parsed = queryString.parse(this.props.location.search);
        var filters = []

        if(parsed.id_category) {
            const data = {
                name: 'id_category',
                value: Number(parsed.id_category)
            }
            filters.push(data)
           
        }

        Object.keys(parsed).forEach(function(el){
            parsed[el] = parseInt(parsed[el])
        })

        this.filterByQuery(parsed)

        this.getDatesHandler()
    }

    handleSort = (clickedColumn) => () => {
        const { column, dates, direction } = this.state
    
        if (column !== clickedColumn) {
          this.setState({
            column: clickedColumn,
            dates: _.sortBy(dates, [clickedColumn]),
            direction: 'ascending',
          })
    
          return
        }
    
        this.setState({
          data: dates.reverse(),
          direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    }

    onChangeSelect = (e, data) => {
        if(data.value !== null) {
            this.setState({
                filter: true,
                filters: {
                    ...this.state.filters,
                    [data.name]: data.value
                }
            }, () => {
                this.setState({
                    dates: _.filter(this.state.baseData, this.state.filters)
                })
            })
        } else {
            this.setState({
                filters: _.omit(this.state.filters, [data.name])
            }, () => {
                this.setState({
                    dates: _.keys(this.state.filters).length !== 0 ? _.filter(this.state.baseData, this.state.filters) : this.state.baseData,
                    filter: _.keys(this.state.filters).length !== 0 ? true : false
                })
            })
        }
    }

    onChangeDate = (e, data) => {
        this.setState({
            [data.name]: data.value
        }, () => {
            if(this.state.filter_date_from !== '' && this.state.filter_date_from !== null) {
                this.getFilteredHandler(this.state.filter_date_from)
            } else {
                this.getDatesHandler()
            }
        })
    }

    /*onChangeDate = (e) => this.setState({
        [e.target.name]: e.target.value
    }, () => {
        if(this.state.filter_date_from !== '') {
            this.getFilteredHandler(this.state.filter_date_from)
        } else {
            this.getDatesHandler()
        }
        
    })*/

    filterByQuery = (data) => {
        if(data.length !== 0) {
            this.setState({
                filter: true,
                filters: data
            }, () => {
                this.setState({
                    dates: _.filter(this.state.baseData, this.state.filters)
                })
            })
        } else {
            this.setState({
                filters: _.omit(this.state.filters, [data.name])
            }, () => {
                this.setState({
                    dates: _.keys(this.state.filters).length !== 0 ? _.filter(this.state.baseData, this.state.filters) : this.state.baseData,
                    filter: _.keys(this.state.filters).length !== 0 ? true : false
                })
            })
        }
    }

    onChangeFilter = (e, data) => {

        const { type } = this.props.match.params

        const parsed = queryString.parse(this.props.location.search);

        const params = {
            ...parsed,
            [data.name]: [data.value] 
        }

        const url = queryString.stringify(params, {
            skipNull: true
        });

        this.props.history.push(`${process.env.PUBLIC_URL}/prihlaska/nabidka/zajezdy?${url}`)

    }

    getFilteredHandler = (date) => {
        dateFilterFrom(date)
        .then((dates) => {
            if(dates.length !== 0) {
                if(_.keys(this.state.filters).length !== 0) {
                    this.setState({
                        dates: _.filter(dates, this.state.filters),
                        baseData: dates,
                        hasSome: true
                    })
                } else {
                    this.setState({
                        dates: dates,
                        baseData: dates,
                        hasSome: true
                    })
                }
            } else {
                this.setState({
                    hasSome: false
                })
            }
        })
    }

    getDatesHandler = () => {
        getPublicToursList()
        .then((dates) => {
            if(dates.length !== 0) {
                if(_.keys(this.state.filters).length !== 0) {
                    this.setState({
                        dates: _.filter(dates.data, this.state.filters),
                        baseData: dates.data,
                        hasSome: true
                    })
                } else {
                    this.setState({
                        dates: dates.data,
                        baseData: dates.data,
                        hasSome: true
                    })
                }
            } else {
                this.setState({
                    hasSome: false
                })
            }
        })
    }

    render() {

        const { type } = this.props.match.params
        const parsed = queryString.parse(this.props.location.search);

        return (
            <>
                <HeaderApplications hasRoute={true} route={'Sportovní zájezdy'} backlink={'/prihlaska'} />
                {/*
                <Card>
                    <CardHeader>
                        <Row>
                            <Col sm={6} className='d-block'>
                                <label>Kategorie</label>
                                <FilterItem 
                                    name='id_category'
                                    placeholder='Vyberte kategorii'
                                    search={false}
                                    type='categories'
                                    onChange={this.onChangeFilter}
                                    value={parsed.id_category ? Number(parsed.id_category) : null}
                                />
                            </Col>
                            <Col sm={6} className='d-block'>
                                <label>ČR / Zahraničí</label>
                                <FilterItem 
                                    name='id_location_type'
                                    placeholder='Vyberte kategorii'
                                    search={false}
                                    type='location-type'
                                    onChange={this.onChangeFilter}
                                    value={parsed.id_location_type ? Number(parsed.id_location_type) : null}
                                />
                            </Col>
                        </Row>
                    </CardHeader>
                    
                    <ToursTable items={this.state.dates} refresh={this.getDatesHandler} sort={this.handleSort} column={this.state.column} direction={this.state.direction} type={type} />

                </Card>
                */}
                <ApplicationsSuspended />
            </>
        )
    }

}

export default ApplicationToursView