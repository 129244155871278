import React, { Component } from 'react'
import { getToken } from '../../../api'
import { getItems, getList, trash } from '../../../api/workouts'
import { connect } from 'react-redux'
import WorkoutsTable from '../../tables/workouts/WorkoutsTable'
import Loading from '../../containers/Loading'
import NoReservations from '../../containers/club/NoReservations'
import { Card, CardBody, CardHeader, CardControls } from '../../../styles'
import _ from 'loadsh'
import { Row, Col } from 'reactstrap'
import { Button, Dropdown, Pagination } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { setMessage } from '../../../actions/messageActions'
import queryString from 'query-string'
import { getCalendar, getCalendarItem, getLecturesFilter, getGroupOpts, getLocationsOpts, getLocationsOptsAdmin } from '../../../api/workouts';
import ExportDayByDayModal from '../../modals/ExportDayByDayModal'

const stateOpts = [
    {
        key: 2,
        value: null,
        text: 'Vše'
    },
    {
        key: 0,
        value: 0,
        text: 'Neaktivní'
    },
    {
        key: 1,
        value: 1,
        text: 'Aktivní'
    },
    {
        key: 3,
        value: -1,
        text: 'V koši'
    }
]

const ageOpts = [
    {
        key: 0,
        value: null,
        text: 'Vše'
    },
    {
        key: 1,
        value: 6,
        text: '4 - 6 měsíců'
    },
    {
        key: 2,
        value: 7,
        text: '7 - 10 měsíců'
    },
    {
        key: 3,
        value: 8,
        text: '11 - 17 měsíců'
    }
    ,
    {
        key: 4,
        value: 9,
        text: '1,5 - 2,5 roky'
    },
    {
        key: 5,
        value: 10,
        text: '2,5 - 4 roky'
    },
    {
        key: 6,
        value: 11,
        text: '4 - 6 let'
    }
]

const hobbyAgeOpts = [
    {
        key: 2,
        value: null,
        text: 'Vše'
    },
    {
        key: 0,
        value: 0,
        text: '6-9 let'
    },
    {
        key: 1,
        value: 1,
        text: '10-15 let'
    }
]

class ListMomsWorkouts extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            items: [],
            loadingData: true,
            hasData: false,
            column: 'id_workout',
            direction: 'descending',
        })
    }

    setCardHeader = (type) => {
        switch (type) {
            case 'moms':
                return 'Seznam lekcí (Cvičení pro maminky)'
            
            case 'kids':
                return 'Seznam lekcí (Cvičení s dětmi)'

            case 'hobby':
                return 'Seznam kroužků'
    
            default: 
                return 'Moje rezervace'
        }
    }

    componentDidMount() {
        const { loadingUser } = this.props

        if(!loadingUser) {
            this._getList()

            this._getLocOpts()

            this._lecturesFilter()
        }
    }

    componentDidUpdate(prevProps) {

        const { loadingUser } = this.props

        if(prevProps.loadingUser !== loadingUser && !loadingUser) {
            this._getList()

            this._getLocOpts()

            this._lecturesFilter()

        }
    }

    _lecturesFilter = () => {
        const { type } = this.props.match.params
        const parsed = queryString.parse(this.props.location.search);

        var group = null;
        if(type === 'kids') {
            if(parsed.group) {
                group = Number(parsed.group)
            }
        }
        getLecturesFilter(type, group)
        .then((res) => {
            this.setState({
                lecturesFilter: res,
                loadingLecturesFilter: false
            })
        })
    }

    _getList = () => {

        const { user } = this.props
        const { type } = this.props.match.params

        this.setState({
            loadingData: true
        })

        getToken().then((token) => {

            const parsed = queryString.parse(this.props.location.search);

            const url = queryString.stringify(parsed, {
                skipNull: true
            });

            getItems(url, token, user.id_organization, type)
            .then((res) => {
                this.setState({
                    items: res.data.data,
                    hasData: true,
                    loadingData: false,
                    count: res.data.count,
                    pages: res.data.count / 25
                }, () => console.log(this.state.items))
            })
            

            /*const data = {
                token: token,
                limit: 15,
                offset: 0,
                id_org: user.id_organization,
            }

            getList(data, type)
            .then((res) => {
                console.log(res)
                if(res.code === 1) {
                    if(_.keys(this.state.filters).length !== 0) {
                        this.setState({
                            items: _.filter(res.data, this.state.filters),
                            baseData: res.data,
                            hasData: true,
                            loadingData: false
                        })
                    } else {
                        this.setState({
                            items: res.data,
                            baseData: res.data,
                            hasData: true,
                            loadingData: false
                        })
                    }
                } else {
                    this.setState({
                        hasData: false,
                        loadingData: false
                    })
                }
            })*/
        })
        .catch((err) => {
            this.props.setMessage({type: 'error', text: 'Nepodařilo se získat bezpečnostní token, aktualizuji stránku'})
            window.location.reload();
        })
    }

    handleSort = (clickedColumn) => () => {
        const { column, items, direction } = this.state
    
        if (column !== clickedColumn) {
          this.setState({
            column: clickedColumn,
            items: _.sortBy(items, [clickedColumn]),
            direction: 'ascending',
          })
    
          return
        }
    
        this.setState({
            items: items.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    }

    _trash = (id) => {

        const { type } = this.props.match.params

        getToken().then((token) => {
            const data = {
                token: token, 
                id_workout: id
            }

            trash(data, 'lecture', type)
            .then((res) => {
                this._getList()
                this.props.setMessage(res)
            })
        })
    }

    _getLocOpts = () => {

        const { type } = this.props.match.params

        getLocationsOptsAdmin(type)
        .then((res) => {
            this.setState({
                locOpts: res,
                loadingLocs: false
            })
        })
    }

    handlePaginationChange = (e, { activePage }) => {
        const { type } = this.props.match.params

        const parsed = queryString.parse(this.props.location.search);

        const params = {
            ...parsed,
            page: activePage
        }

        const url = queryString.stringify(params, {
            skipNull: true
        });

        this.props.history.push(`${process.env.PUBLIC_URL}/admin/manage/workouts/${type}/list/?${url}`)
    }

    onChangeFilter = (e, data) => {

        const { type } = this.props.match.params

        const parsed = queryString.parse(this.props.location.search);

        const params = {
            ...parsed,
            [data.name]: [data.value] 
        }

        delete params.page

        const url = queryString.stringify(params, {
            skipNull: true
        });

        this.props.history.push(`${process.env.PUBLIC_URL}/admin/manage/workouts/${type}/list/?${url}`)

    }

    content = () => {

        const { type } = this.props.match.params
        const { locOpts, lecturesFilter } = this.state
        const parsed = queryString.parse(this.props.location.search)

        return (
            <>
            <Card>
                <CardHeader>
                    <Row>
                        <Col sm={9}>
                            {this.setCardHeader(type)}
                        </Col>
                        <Col sm={3} className='text-right'>
                            <ExportDayByDayModal type={type} />
                            <Link to={`${process.env.PUBLIC_URL}/admin/manage/workouts/${type}/edit/new`}><Button icon="\efc2">Nový</Button></Link>
                        </Col>
                    </Row>
                </CardHeader>
                <CardControls>
                    <Row>
                        <Col sm={3} className='text-right'>
                            <Dropdown 
                                options={locOpts}
                                onChange={this.onChangeFilter}
                                name="l.id_location"
                                placeholder="Lokalita"
                                selection
                                fluid
                                value={parsed['l.id_location'] ? Number(parsed['l.id_location']) : null}
                                search
                            />
                        </Col>
                        <Col sm={3} className='text-right'>
                            <Dropdown 
                                options={lecturesFilter}
                                name='id_workout'
                                onChange={this.onChangeFilter}
                                selection
                                placeholder='Druh lekce'
                                fluid
                                value={parsed.id_workout ? Number(parsed.id_workout) : null}
                                search
                            />
                        </Col>
                        {type !== 'moms' &&
                        <Col sm={3}>
                            <Dropdown 
                                options={type === 'hobby' ? hobbyAgeOpts : (type === 'kids' && ageOpts)}
                                name='age_group'
                                onChange={this.onChangeFilter}
                                selection
                                placeholder='Věk'
                                fluid
                                value={parsed.age_group ? Number(parsed.age_group) : null}
                                search
                            />
                        </Col>
                        }
                        <Col sm={3} className='text-right'>
                            <Dropdown 
                                options={stateOpts}
                                name='state'
                                onChange={this.onChangeFilter}
                                selection
                                placeholder='Stav'
                                fluid
                                value={parsed.state ? Number(parsed.state) : null}
                                search
                            />
                        </Col>
                    </Row>
                </CardControls>
                <WorkoutsTable items={this.state.items} refresh={this._getList} setMessage={this.props.setMessage} sort={this.handleSort} column={this.state.column} direction={this.state.direction} type={type} trash={this._trash} />
            </Card>
            <Row className='mt-3 d-flex justify-content-center'>
                <Pagination 
                    activePage={parsed.page ? Number(parsed.page) : 1} 
                    totalPages={this.state.pages} 
                    onPageChange={this.handlePaginationChange}
                />
            </Row>
            </>
        )
    }

    render() {
        const { loadingData, hasData } = this.state

        if(loadingData) {
            return (
                <Loading />
            )
        } else if(!loadingData && !hasData) {
            return (
                <NoReservations text='Nemáte vytvořené žádné lekce' />
            )  
        } else {
            return (
                <>
                {this.content()}
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    user: state.user.user,
    loadingUser: state.user.loading
})
  
export default connect(mapStateToProps, { setMessage })(ListMomsWorkouts)