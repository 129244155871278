import React from 'react'
import { Form, TextArea, Button } from 'semantic-ui-react'
import { FormButtonGroup } from '../../styles'

const EditOrganizationForm = ({ organization, onChange, loading, submit }) => {
    return (
        <Form loading={loading} onSubmit={submit}>
            <Form.Field>
                <label>Název organizace</label>
                <input
                    type='text'
                    name='organization_name'
                    id='organization_name'
                    value={organization.organization_name}
                    onChange={onChange}  
                />
            </Form.Field>
            <Form.Field>
                <label>Adresa organizace</label>
                <input
                    type='text'
                    name='organization_address'
                    id='organization_address'
                    value={organization.organization_address}
                    onChange={onChange}  
                />
            </Form.Field>
            <Form.Field>
                <label>Konktatní osoba</label>
                <input
                    type='text'
                    name='organization_person'
                    id='organization_person'
                    value={organization.organization_person}
                    onChange={onChange}  
                />
            </Form.Field>
            <Form.Field>
                <label>Email</label>
                <input
                    type='email'
                    name='organization_email'
                    id='organization_email'
                    value={organization.organization_email}
                    onChange={onChange}  
                />
            </Form.Field>
            <Form.Field>
                <label>Telefonní číslo</label>
                <input
                    type='tel'
                    name='organization_phone'
                    id='organization_phone'
                    value={organization.organization_phone}
                    onChange={onChange}  
                />
            </Form.Field>
            <FormButtonGroup>
                <Button primary>Uložit změny</Button>
            </FormButtonGroup>
        </Form>
    )
}

export default EditOrganizationForm