import React, { Component } from 'react'
import { intToBool, toggleLocationState, shortText, getToken } from '../../api'
import { AreYouSureModal } from '../modals'
import { Link } from 'react-router-dom'
import { Table, Icon, Button, Popup } from 'semantic-ui-react'
import _ from 'loadsh'
import moment from 'moment'
import { toggleState } from '../../api/tours'
import { StateIcon } from '../../styles/forms'

class ToursTable extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            showModal: false,
            idToDelete: 2,
            loadingToggle: 3
        })
    }

    editButton = (id) => {
        return (
            <Popup 
                position='top center' 
                content='Upravit záznam'
                trigger={
                    <Link to={`${process.env.PUBLIC_URL}/admin/manage/tours/edit/` + id}><Button icon><Icon name='pencil alternate' /></Button></Link>
                } 
                
            />
        )
    }

    _toggleState = (id) => {

        this.setState({
            loadingToggle: id
        })

        getToken().then((token) => {
            const data = {
                token: token,
                id_item: id
            }

            toggleState(data)
            .then((res) => {
                this.props.refresh()
            })
            .then(() => {
                this.setState({
                    loadingToggle: ''
                })
            })
        })
    }

    render() {

        const { items, column, direction, sort } = this.props

        return (
            <div>
                <Table sortable striped className='main-table'>
                    <Table.Header>
                        <Table.Row>

                            <Table.HeaderCell
                                sorted={column === 'id_tour' ? direction : null}
                                onClick={sort('id_tour')}
                                width={1}
                            >
                                #
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'tour_name' ? direction : null}
                                onClick={sort('tour_name')}
                                width={3}
                            >
                                Název zájezdu
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'date_from' ? direction : null}
                                onClick={sort('date_from')}
                                width={2}
                            >
                                Datum od
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'date_to' ? direction : null}
                                onClick={sort('date_to')}
                                width={2}
                            >
                                Datum do
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'tour_decription' ? direction : null}
                                onClick={sort('tour_decription')}
                                width={4}
                            >
                                Popis
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'created' ? direction : null}
                                onClick={sort('created')}
                                width={2}
                            >
                                Vytvořeno
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'state' ? direction : null}
                                onClick={sort('state')}
                                width={1}
                            >
                                Stav
                            </Table.HeaderCell>

                            <Table.HeaderCell></Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                    {_.map(items, ({ id_tour, tour_name, tour_description, date_from, date_to, created, state }, index) => (
                        <Table.Row key={id_tour}>
                            <Table.Cell>
                                {id_tour}
                            </Table.Cell>
                            <Table.Cell>
                                {tour_name}
                            </Table.Cell>
                            <Table.Cell>
                                {moment(date_from).format('DD. MM. YYYY')}
                            </Table.Cell>
                            <Table.Cell>
                                {moment(date_to).format('DD. MM. YYYY')}
                            </Table.Cell>
                            <Table.Cell>
                                {shortText(tour_description, 40)}
                            </Table.Cell>
                            <Table.Cell>
                                {moment(created).format('DD. MM. YYYY')}
                            </Table.Cell>
                            <Table.Cell>
                                {intToBool(state) ? 
                                    <i class={this.state.loadingToggle === id_tour ? "icofont-check-circled icon-state loading" : "icofont-check-circled icon-state"} onClick={() => this._toggleState(id_tour)}></i>
                                    : 
                                    <i class={this.state.loadingToggle === id_tour ? "icofont-close-circled icon-state loading" : "icofont-close-circled icon-state"} onClick={() => this._toggleState(id_tour)}></i>
                                }
                            </Table.Cell>
                            <Table.Cell>
                                {this.editButton(id_tour)}
                            </Table.Cell>
                        </Table.Row>
                    ))}
                    </Table.Body>
                </Table>
            </div>
        )
    }
}

export default ToursTable