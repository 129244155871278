import React, { Component } from 'react'
import { intToBool, toggleLocationState, shortText, getToken, formatMoney } from '../../../api'
import { Table, Icon, Button, Popup, Modal, Divider } from 'semantic-ui-react'
import _ from 'loadsh'
import moment from 'moment'
import { StateIcon } from '../../../styles'
import { Link } from 'react-router-dom'
import { deleteItem } from '../../../api/workouts'
import ChangeLectureModal from '../../modals/ChangeLectureModal'
import { Row, Col } from 'reactstrap'
import {
    isMobile
} from "react-device-detect";

class UserReservationsTable extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            showModal: false,
            loading: false
        })
    }

    hideModal = () => this.setState({ showModal: false })
    openModal = () => this.setState({ showModal: true })

    _deleteItem = (id) => {

        this.setState({
            loading: true
        })

        const { setMessage, refresh, type } = this.props

        getToken().then((token) => {
            const data = {
                token: token
            }

            deleteItem(data, id, type)
            .then((res) => {
                setMessage(res)
                refresh()

                this.setState({
                    loading: false
                })
            })

        })
    }

    setCode = (type, id) => {
        switch(type) {
            case 'moms':
                return '10' + id
            case 'kids':
                return '20' + id
            case 'hobby':
                return '30' + id
        }
    }

    paymentModal = (id_item, id_application, total_price, workout_name, type, created, bank_acc, bank_code) => {
        return (
            <Modal trigger={<Button className='primary' icon>Zaplatit přihlášku</Button>} onClose={this.hideModal} closeIcon>
                <Modal.Header>
                    Zaplatit přihlášku
                </Modal.Header>
                <Modal.Content>
                    <Row>
                        <Col sm={4}>
                            <p className='small mb-1'>Celková cena</p>
                            <h3>{formatMoney(total_price)},- Kč</h3>
                        </Col>
                        <Col sm={8}>
                            <p className='mb-1'>Platbu je nutné uhradit nejpozději do <strong>{moment(created).add(7, 'days').format('D. M. YYYY')}</strong> a to převodem na účet (viz níže), nebo platební bránou.</p>
                        </Col>
                    </Row>
                    <Row className='mt-4'>
                        <Col sm={12}>
                            <Divider />
                        </Col>
                    </Row>
                    <Row className='mt-4 d-lg-flex align-items-center'>
                        <Col sm={4}>
                            <img className='img-fluid' src={`https://api.paylibo.com/paylibo/generator/czech/image?accountNumber=${bank_acc}&bankCode=${bank_code}&amount=${total_price}&currency=CZK&vs=${this.setCode(type, id_application)}`} />
                        </Col>
                        <Col sm={8}>
                            <h4>Platba převodem</h4>
                            <p>
                            Platbu prosím zašlete na číslo účtu: <strong>{bank_acc}/{bank_code}</strong><br />
                            Variabilní symbol: <strong>{this.setCode(type, id_application)}</strong>
                            </p>
                        </Col>
                    </Row>
                </Modal.Content>
            </Modal>
        )
    }

    deleteModal = (id_item, type) => {
        return (
            <Modal trigger={<Button className='red' icon>Omluvit</Button>} onClose={this.hideModal}>
                <Modal.Header>
                    Omluvit se z této lekce
                </Modal.Header>
                <Modal.Content>
                    {type !== 'hobby' && <p>Po omluvení budete z lekce vyřazeni a budete si ji moci nahradit jindy.</p>}
                    {type === 'hobby' && <p>Po omluvení budete z lekce vyřazeni a <strong>nebudete si ji moci nahradit jindy.</strong></p>}
                </Modal.Content>
                <Modal.Actions className='justify-content-end'>
                    <Button className='red' onClick={() => this._deleteItem(id_item)} loading={this.state.loading} disabled={this.state.loading}>Omluvit lekci</Button>
                </Modal.Actions>
            </Modal>
        )
    }

    _changeLectureModal = (type, id_signed) => {

        const { setMessage, refresh } = this.props

        return (
            <ChangeLectureModal type={type} id={id_signed} refresh={refresh} setMessage={setMessage} />
        )
    }   

    render() {

        const { items, column, direction, sort, type, canChange } = this.props

        return (
            <div>
                <Table sortable striped className='main-table'>
                    {!isMobile &&
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell
                                sorted={column === 'id_application' ? direction : null}
                                onClick={sort('id_application')}
                                width={1}
                            >
                                #
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'workout_name' ? direction : null}
                                onClick={sort('workout_name')}
                                width={3}
                            >
                                Název lekce
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'date' ? direction : null}
                                onClick={sort('date')}
                                width={2}
                            >
                                Datum a čas
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'total_price' ? direction : null}
                                onClick={sort('total_price')}
                                width={2}
                            >
                                Cena
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'created' ? direction : null}
                                onClick={sort('created')}
                                width={2}
                            >
                                Vytvořeno
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'paid' ? direction : null}
                                onClick={sort('paid')}
                                width={1}
                            >
                                Zaplaceno
                            </Table.HeaderCell>

                            <Table.HeaderCell width={2}></Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>
                    }
                    <Table.Body>
                    {_.map(items, ({ id_application, id_signed, id_workout, date, total_price, paid, state, created, workout_name, bank_acc, bank_code }, index) => (
                        <Table.Row key={id_application}>
                            {!isMobile &&
                            <Table.Cell>
                                {id_application}
                            </Table.Cell>
                            }
                            <Table.Cell>
                                <span className='only-phone'>Název lekce: </span> {workout_name}
                            </Table.Cell>
                            <Table.Cell>
                                <span className='only-phone club_table_label'>Datum a čas: </span>
                                {moment(date).format('DD. MM. YYYY H:mm')}
                            </Table.Cell>
                           
                            <Table.Cell>
                                <span className='only-phone club_table_label'>Cena: </span>
                                {formatMoney(total_price)},- Kč
                            </Table.Cell>

                            <Table.Cell>
                                <span className='only-phone club_table_label'>Vytvořeno: </span>
                                {moment(created).format('DD. MM. YYYY')}
                            </Table.Cell>

                            <Table.Cell className='sm-text-center'>
                                <span className='only-phone club_table_label'>Zaplaceno: </span>
                                <StateIcon state={paid} />
                            </Table.Cell>

                            <Table.Cell className='text-sm-right'>
                                {canChange && state === 2 && this._changeLectureModal(type, id_signed)}
                                {paid === 0 && this.paymentModal(id_signed, id_application, total_price, workout_name, type, created, bank_acc, bank_code)}
                            </Table.Cell>

                        </Table.Row>
                    ))}
                    </Table.Body>
                </Table>
            </div>
        )
    }
}

export default UserReservationsTable